<template>
    <span>
        <nav class="panel">
            <p class="panel-heading">
                <b>Convenor Tools</b>
            </p>
            <a class="panel-block" href="/dashboard/convenors/poster-generator">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Poster Generator
            </a>
            <a class="panel-block" href="/dashboard/convenors/budget-generator">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Budget Calculator
            </a>
          
            <a class="panel-block" href="/dashboard/convenors/round-robin-generator">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Round Robin Generator
            </a>
            <a class="panel-block" href="/dashboard/convenors/playoff-bracket-generator">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Playoff Bracket Generator
            </a>
        </nav>
    </span>
</template>

<script>


export default {
	data() {
		return {
         
		};
	},
	components: {
		
	},
	mounted: function() {
        
	},
	computed: {
	
	},
	methods: {
	
	}
}
</script>

<style lang="scss">
    
</style>
