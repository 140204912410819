<template>
    <span>
        <h2>Poster Generator</h2>
        <div class="poster-generator-sidebar" v-if="sidebarClose">
            <a v-on:click="sidebarClose = false" class="close">Close</a>
            <div class="panel">
                <div class="panel-heading" v-on:click="selectBackground = !selectBackground" style="font-size: 14px;">Select a Background
                    <i class="fas fa-caret-down" v-if="selectBackground === false"></i>
                    <i class="fas fa-caret-up" v-if="selectBackground === true"></i>
                </div>
                <div class="panel-content" v-if="selectBackground">
                    <div class="padding">
                        <b-field label="Search for a particular background photo">
                            <b-tooltip type="is-dark" multilined label="Search for a particular photo here. All photos come from unsplash.com, a free photo website.">
                                <b-input v-on:input="changePhotos" :value="photos_name" ></b-input>
                            </b-tooltip>
                        </b-field>
                        <b-field label="Opacity of Background Image">
                            <b-slider
                                :min="0"
                                :max="1"
                                :step="0.01"
                                v-model="canvasBackgroundImageOpacity"></b-slider>
                        </b-field>
                        <div class="images">
                            <div :class="['unsplash-image', { 'selected': selectedImage === image }]"  :key="key" v-for="(image,key) in images" v-on:click="selectTheImage(image)">
                                <img :src="image.urls.small" alt="Select Unsplash Image BG">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel">
                <div class="panel-heading" v-on:click="customizeTheText = !customizeTheText" style="font-size: 14px;">Customize The Text
                    <i class="fas fa-caret-down" v-if="customizeTheText === false"></i>
                    <i class="fas fa-caret-up" v-if="customizeTheText === true"></i>
                </div>
                <div class="panel-content" v-if="customizeTheText">
                    <div class="padding">
                        <h4 style="margin-top: 0;">Set the default colour and style</h4>
                        <v-swatches 
                            v-model="canvasBackgroundColour"
                            swatches="text-advanced"></v-swatches>
                        <b-field label="Opacity of Colour">
                            <b-slider
                                :min="0"
                                :max="1"
                                :step="0.01"
                                v-model="canvasBackgroundOpacity"></b-slider>
                        </b-field>
                        <h4>Select Title Font</h4>
                        <b-dropdown v-model="canvasMainFont" aria-role="list">
                            <button class="button is-primary" slot="trigger" slot-scope="{ active }">
                                <span>{{ canvasMainFont }}</span>
                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                            </button>

                            <b-dropdown-item :value="font" :key="font" v-for="font in fontsAvailable" aria-role="listitem">
                                <span :style="{ fontSize: 24 + 'px', fontFamily: font }">{{ font }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <h4>Select Secondary Font</h4>
                        <b-dropdown v-model="canvasSecondaryFont" aria-role="list">
                            <button class="button is-primary" slot="trigger" slot-scope="{ active }">
                                <span>{{ canvasSecondaryFont }}</span>
                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                            </button>

                            <b-dropdown-item :value="font" :key="font" v-for="font in fontsAvailable" aria-role="listitem" >
                                <span :style="{ fontSize: 24 + 'px', fontFamily: font }">{{ font }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <hr>
                        <h4>Poster Text Content</h4>
                        <b-field label="Prefill The Poster Details">
                        </b-field>
                        <b-dropdown v-model="prefillTournament" aria-role="list">
                            <button class="button" slot="trigger" slot-scope="{ active }">
                                <span v-if="prefillTournament">{{ prefillTournament.name }}</span>
                                <span v-if="prefillTournament === null">Select a Tournament</span>
                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                            </button>

                            <b-dropdown-item :value="tournament" :key="key" v-for="(tournament, key) in tournaments" aria-role="listitem">
                                <span>{{ tournament.name }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <a class="button is-primary" v-on:click="prefillTournamentDetails()">Prefill</a>
                        
                        <hr>
                        <div class="row">
                            <div class="col-xs-8">
                                <b-field label="Event Name Text">
                                    <b-input v-model="canvasEventNameText"></b-input>
                                </b-field>
                            </div>
                            <div class="col-xs-1"></div>
                            <div class="col-xs-3">
                                <b-field label="Color">
                                    <v-swatches 
                                        v-model="canvasEventNameColor"
                                        swatches="text-advanced"></v-swatches>
                                </b-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-8">
                                <b-field label="Price Per Team">
                                    <b-input v-model="canvasPricePerTeam"></b-input>
                                </b-field>
                            </div>
                            <div class="col-xs-1"></div>
                            <div class="col-xs-3">
                                <b-field label="Color">
                                    <v-swatches 
                                        v-model="canvasPricePerTeamColor"
                                        swatches="text-advanced"></v-swatches>
                                </b-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-8">
                                <b-field label="Division Text">
                                    <b-input 
                                        v-model="canvasDivisionText"
                                        maxlength="200" type="textarea"></b-input>
                                </b-field>
                            </div>
                            <div class="col-xs-1"></div>
                            <div class="col-xs-3">
                                <b-field label="Color">
                                    <v-swatches 
                                        v-model="canvasDivisionTextColor"
                                        swatches="text-advanced"></v-swatches>
                                </b-field>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-xs-8">
                                <b-field label="Contact Me Text">
                                    <b-input v-model="canvasContactText"></b-input>
                                </b-field>
                            </div>
                            <div class="col-xs-1"></div>
                            <div class="col-xs-3">
                                <b-field label="Color">
                                    <v-swatches 
                                        v-model="canvasContextTextColor"
                                        swatches="text-advanced"></v-swatches>
                                </b-field>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-xs-8">
                                <b-field label="Additional Details Left">
                                    <b-input v-model="canvasAdditionalDetailsLeft"
                                        maxlength="200" type="textarea"></b-input>
                                </b-field>
                            </div>
                            <div class="col-xs-1"></div>
                            <div class="col-xs-3">
                                <b-field label="Color">
                                    <v-swatches 
                                        v-model="canvasAdditionalDetailsLeftColor"
                                        swatches="text-advanced"></v-swatches>
                                </b-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-8">
                                <b-field label="Additional Details Right">
                                    <b-input v-model="canvasAdditionalDetailsRight"
                                        maxlength="200" type="textarea"></b-input>
                                </b-field>
                            </div>
                            <div class="col-xs-1"></div>
                            <div class="col-xs-3">
                                <b-field label="Color">
                                    <v-swatches 
                                        v-model="canvasAdditionalDetailsRightColor"
                                        swatches="text-advanced"
                                        maxlength="200" type="textarea"></v-swatches>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <a class="button" v-on:click="saveButton()">Download Poster</a>
                        <a v-on:click="sidebarClose = true" class="button is-info">Open Edit Sidebar</a>
                    </div>
                    <div class="col-md-4">
                        <b-field label="Select Your Sponsor Bar">
                            <b-select v-model="region">
                                <option :value="region" v-for="(region, key) in regions" :key="key">{{ region }}</option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="col-md-4" style="text-align: right;">
                        
                        <b-select v-model="tournamentSelected">
                            <option :value="tournament" :key="key" v-for="(tournament, key) in tournaments">{{ tournament.name }}</option>
                        </b-select>
                        <a class="button is-secondary" v-on:click="saveAndDownloadButton()">Save to Selected Event & Download</a>
                    </div>
                </div>
                
                <br><br>
                <div id="posterKonva">

                </div>
                <div class="row">
                    <div class="col-md-6">
                        
                    </div>
                    <div class="col-md-6">
                        <div class="padding" style="padding: 20px;">
                            
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </span>
</template>

<script>

import VSwatches from 'vue-swatches'

import axios from 'axios'

import spnImage from '../../../../images/header/SPNLogo2019-White.png'

import original from '../../../../images/poster-bars/default.png'
import alberta from '../../../../images/poster-bars/alberta.png'
import bc from '../../../../images/poster-bars/bc.png'
import manitoba from '../../../../images/poster-bars/manitoba.png'
import maritimes from '../../../../images/poster-bars/maritimes.png'



// price per team txt
                    // event name text
                    // divisions text
                    // contact text
                    // additional details left
                    // additional details right

export default {
	data() {
		return {
            sidebarClose: false,
            customizeTheText: false,
            selectBackground: false,
            prefillTournament: null,
            photos_name: 'baseball',
            selectedImage: null,
            selectedImageCanvas: null,
            images: [],
            tournamentSelected: null,
            tournaments: [],
            vueCanvas: null,
            region: 'Ontario',
            regions: ['Alberta','British Columbia','Manitoba', 'Ontario', 'Maritimes', 'Quebec', 'Newfoundland Labrador', 'Saskatchewan'],
            canvasBackgroundColour: '#000',
            canvasBackgroundImageOpacity: 0.8,
            canvasBackgroundOpacity: 0.7,
            canvasMainFont: 'Arial',
            canvasSecondaryFont: 'Arial',
            canvasEventNameText: 'YOUR EVENT NAME',
            canvasEventNameColor: '#fff',
            canvasPricePerTeam: '$400',
            canvasPricePerTeamColor: '#fff',
            canvasDivisionText: 'Coed D & E',
            canvasDivisionTextColor: '#fff',
            canvasContactText: 'Contact youremail@gmail.com to register',
            canvasContextTextColor: '#fff',
            canvasAdditionalDetailsLeft: 'RAFFLES \nPRIZING \n50-50 DRAW ON SITE \n\n\nAdd content anywhere in this column!',
            canvasAdditionalDetailsLeftColor: '#fff',
            canvasAdditionalDetailsRight: 'Raffles, SPN Prizing & More',
            canvasAdditionalDetailsRightColor: '#fff',
            fontsAvailable: ['Lato','Merriweather','Open Sans Condensed','Oswald','Roboto','Jawbreak-Sans', 'Jawbreak-Serif', 'Montserrat', 'Gotham', 'Helvetica', 'Arial']
		};
    },
    watch: {
        canvasBackgroundImageOpacity: function(val) {
            this.redrawCanvas()
        },
        canvasBackgroundColour: function(val) {
            this.redrawCanvas()
        },
        canvasBackgroundOpacity: function() {
            this.redrawCanvas()
        },
        canvasMainFont: function() {
            this.redrawCanvas()
        },
        canvasSecondaryFont: function() {
            this.redrawCanvas()
        },
        canvasEventNameText: function() {
            this.redrawCanvas()
        },
        region: function() {
            this.redrawCanvas()
        },
        canvasEventNameColor: function() {
            this.redrawCanvas()
        },
        canvasPricePerTeam: function() {
            this.redrawCanvas()
        },
        canvasPricePerTeamColor: function() {
            this.redrawCanvas()
        },
        canvasDivisionText: function() {
            this.redrawCanvas()
        },
        canvasDivisionTextColor: function() {
            this.redrawCanvas()
        },
        canvasContactText: function() {
            this.redrawCanvas()
        },
        canvasContextTextColor: function() {
            this.redrawCanvas()
        },
        canvasAdditionalDetailsLeft: function() {
            this.redrawCanvas()
        },
        canvasAdditionalDetailsLeftColor: function() {
            this.redrawCanvas()
        },
        canvasAdditionalDetailsRight: function() {
            this.redrawCanvas()
        },
        canvasAdditionalDetailsRightColor: function() {
            this.redrawCanvas()
        },
    },
	components: {
        
        VSwatches
	},
	mounted: function() {
        var vm = this
        axios.get('https://api.unsplash.com/search/photos?page=1&query=baseball', {
            headers: {
                'Authorization': 'Client-ID Io-AmQsm4Yjxn7k1wJaUeoNayeTVEsrbxKAcNzHvYTs'
            }
        }).then(results => {
            vm.images = results.data.results
            vm.selectTheImage(results.data.results[0])
        })

        axios.get('/api/tournaments/your-tournaments').then(results => {
            if(results.data.success === true) {
                
                vm.tournaments = results.data.tournaments
            }
        })

        window.addEventListener('load', () => {
            var c = document.getElementById('posterCanvas');

            vm.vueCanvas = new Konva.Stage({
                container: 'posterKonva',
                width: 1000,
                height: 628
            })

            vm.$nextTick(() => {
                vm.redrawCanvas()
            })

        })
	},
	computed: {

	},
	methods: {
        downloadURI: function(uri, name) {
            var link = document.createElement('a');
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        saveAndDownloadButton: function() {
            var vm = this
            // var dataURL = vm.vueCanvas.toDataURL()
            // vm.downloadURI(dataURL, 'poster.png')

            // add saving to the images folder etc
            
            vm.vueCanvas.toCanvas().toBlob(blob => {
                
                const form = new FormData()
                form.append('file', blob, 'poster.jpg')

                

                axios.post(`/api/tournaments/upload-photo`, form, { headers: { 'Content-Type': 'multipart/form-data' }}).then(results => {
                    if(results.data.success === true) {
                        
                        var poster_url = results.data.data.logo_path
                        // now take that and upload to the event
                        // append all the form parts and store in database

                        axios.post('/api/tournaments/upload-poster-to-event', {
                            event: vm.tournamentSelected.id,
                            poster_url: poster_url,
                            posterData: {
                                canvasBackgroundColour: vm.canvasBackgroundColour,
                                canvasBackgroundImageOpacity: vm.canvasBackgroundImageOpacity,
                                canvasBackgroundOpacity: vm.canvasBackgroundOpacity,
                                canvasMainFont: vm.canvasMainFont,
                                canvasSecondaryFont: vm.canvasSecondaryFont,
                                canvasEventNameText: vm.canvasEventNameText,
                                canvasEventNameColor: vm.canvasEventNameColor,
                                canvasPricePerTeam: vm.canvasPricePerTeam,
                                canvasPricePerTeamColor: vm.canvasPricePerTeamColor,
                                canvasDivisionTextColor: vm.canvasDivisionTextColor,
                                canvasContactText: vm.canvasContactText,
                                canvasContextTextColor: vm.canvasContextTextColor,
                                canvasAdditionalDetailsLeft: vm.canvasAdditionalDetailsLeft,
                                canvasAdditionalDetailsLeftColor: vm.canvasAdditionalDetailsLeftColor,
                                canvasAdditionalDetailsRight: vm.canvasAdditionalDetailsRight,
                                canvasAdditionalDetailsRightColor: vm.canvasAdditionalDetailsRightColor,
                                selectedImage: vm.selectedImage,
                                photos_name: vm.photos_name
                            }
                        }).then(results2 => {

                        })

                    }
                })
            }, 'image/jpeg')
        },
        saveButton: function() {
            var vm = this
            var dataURL = vm.vueCanvas.toDataURL()
            vm.downloadURI(dataURL, 'poster.png')

           
        },
        changePhotos: function($event) {
            var vm = this
            
            var query = $event
            axios.get('https://api.unsplash.com/search/photos?page=1&query='+query, {
                headers: {
                    'Authorization': 'Client-ID Io-AmQsm4Yjxn7k1wJaUeoNayeTVEsrbxKAcNzHvYTs'
                }
            }).then(results => {
                

                vm.images = results.data.results

                
            })
        },
        selectTheImage: function(image) {
            var vm = this
            
            

            vm.selectedImage = `/api/users/reformat-photo?url=${image.urls.regular}`

            vm.$nextTick(() => {
                vm.redrawCanvas()
            })

           
            // now do canvas stuff
        },
        redrawCanvas: function() {
            var vm = this
            if(vm.vueCanvas) {
                var layer = new Konva.Layer()

                var imageObj = new Image()
                var spnImageObj = new Image()
                spnImageObj.src = spnImage
                imageObj.crossOrigin = 'Anonymous';

                var originalImgObj = new Image()
                originalImgObj.src = original

                if(vm.region === 'Alberta') {
                    
                    originalImgObj.src = alberta
                }

                if(vm.region === 'British Columba') {
                    
                    originalImgObj.src = bc
                }

                if(vm.region === 'Manitoba') {
                    
                    originalImgObj.src = manitoba
                }

                if(vm.region === 'Maritimes') {
                    
                    originalImgObj.src = maritimes
                }
                
                originalImgObj.crossOrigin = 'Anonymous';
                

                imageObj.onload = function() {
                    // spnImageObj.onload = function() {
                        // background image
                        var mainImage =  new Konva.Image({
                            x: 0,
                            y: 0,
                            image: imageObj,
                            width: 1000,
                            height: 628,
                            opacity: vm.canvasBackgroundImageOpacity
                        })

                    

                        layer.add(mainImage)
                        vm.vueCanvas.add(layer)
                        layer.batchDraw()

                        

                        // main colour overlay
                        // var layer1 = new Konva.Layer()

                        var rect1 = new Konva.Rect({
                            x: 0,
                            y: 0,
                            width: 1000,
                            height: 628,
                            fill: vm.canvasBackgroundColour,
                            opacity: vm.canvasBackgroundOpacity
                        })

                        layer.add(rect1)

                        vm.vueCanvas.add(layer)

                        // add SPN logo to Konva.Image({
                            
                        var spnImage = new Konva.Image({
                            x: 20,
                            y: 20,
                            image: spnImageObj,
                            width: 100,
                            height: 86,
                            opacity: 1
                        })

                        layer.add(spnImage)

                        vm.vueCanvas.add(layer)

                        var spnImage2 = new Konva.Image({
                            x: 880,
                            y: 20,
                            image: spnImageObj,
                            width: 100,
                            height: 86,
                            opacity: 1
                        })


                        layer.add(spnImage2)

                        vm.vueCanvas.add(layer)

                        

                        // main SPN bar
                        // var layer2 = new Konva.Layer()

                        // var rect2 = new Konva.Rect({
                        //     x: 0,
                        //     y: 528,
                        //     width: 1000,
                        //     height: 100,
                        //     fill: 'white'
                        // })

                        // layer.add(rect2)

                        // vm.vueCanvas.add(layer)

                        var spnImage3 = new Konva.Image({
                            x: 0,
                            y: 528,
                            image: originalImgObj,
                            width: 1000,
                            height: 100,
                            opacity: 1,
                        })

                        layer.add(spnImage3)

                        vm.vueCanvas.add(layer)

                        // spn contact bar
                        var rect3 = new Konva.Rect({
                            x: 0,
                            y: 478,
                            width: 1000,
                            height: 50,
                            fill: 'black',
                            opacity: 0.5
                        })

                        layer.add(rect3)

                        vm.vueCanvas.add(layer)

                        // event name text
                        var text = new Konva.Text({
                            x: 200,
                            y: 40,
                            width: 600,
                            align: 'center',
                            fontSize: 60,
                            lineHeight: 1.3,
                            fill: vm.canvasEventNameColor,
                            fontFamily: vm.canvasMainFont,
                            text: vm.canvasEventNameText
                        })

                        layer.add(text)
                        vm.vueCanvas.add(layer)

                        // price per team txt
                        var text2 = new Konva.Text({
                            x: 140,
                            y: 220,
                            width: 720,
                            align: 'center',
                            fontSize: 80,
                            lineHeight: 1.3,
                            fill: vm.canvasPricePerTeamColor,
                            fontFamily: vm.canvasMainFont,
                            text: vm.canvasPricePerTeam
                        })

                        layer.add(text2)
                        vm.vueCanvas.add(layer)

                        var text3 = new Konva.Text({
                            x: 140,
                            y: 320,
                            width: 720,
                            align: 'center',
                            fontSize: 18,
                            lineHeight: 1.3,
                            fontWeight: 700,
                            fill: vm.canvasPricePerTeamColor,
                            fontFamily: vm.canvasMainFont,
                            text: "PER TEAM"
                        })

                        layer.add(text3)
                        vm.vueCanvas.add(layer)
                        
                        // divisions text

                        var text4 = new Konva.Text({
                            x: 140,
                            y: 380,
                            width: 720,
                            align: 'center',
                            fontSize: 30,
                            lineHeight: 1.3,
                            fontWeight: 700,
                            fill: vm.canvasDivisionTextColor,
                            fontFamily: vm.canvasSecondaryFont,
                            text: vm.canvasDivisionText
                        })

                        layer.add(text4)
                        vm.vueCanvas.add(layer)


                        // contact text
                        var text5 = new Konva.Text({
                            x: 20,
                            y: 483,
                            width: 980,
                            align: 'center',
                            fontSize: 30,
                            lineHeight: 1.3,
                            fontWeight: 700,
                            fill: vm.canvasContextTextColor,
                            fontFamily: vm.canvasSecondaryFont,
                            text: vm.canvasContactText
                        })

                        layer.add(text5)
                        vm.vueCanvas.add(layer)
                        // additional details left

                        var text6 = new Konva.Text({
                            x: 20,
                            y: 140,
                            width: 260,
                            align: 'left',
                            fontSize: 22,
                            lineHeight: 1.3,
                            fontWeight: 700,
                            fill: vm.canvasAdditionalDetailsLeftColor,
                            fontFamily: vm.canvasSecondaryFont,
                            text: vm.canvasAdditionalDetailsLeft
                        })

                        layer.add(text6)
                        vm.vueCanvas.add(layer)

                        var text7 = new Konva.Text({
                            x: 720,
                            y: 140,
                            width: 260,
                            align: 'right',
                            fontSize: 22,
                            lineHeight: 1.3,
                            fontWeight: 700,
                            fill: vm.canvasAdditionalDetailsRightColor,
                            fontFamily: vm.canvasSecondaryFont,
                            text: vm.canvasAdditionalDetailsRight
                        })

                        layer.add(text7)
                        vm.vueCanvas.add(layer)
                        // additional details right
                    // }
                }
                imageObj.src = vm.selectedImage

            }
        },
        prefillTournamentDetails: function() {
            // take this and move it into the canvas details
            var vm = this
            
            if(vm.prefillTournament) {
                vm.canvasEventNameText = vm.prefillTournament.name
                vm.canvasPricePerTeam = '$' + vm.prefillTournament.team_registration_fee
            }
        }
	}
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&family=Merriweather:wght@300;400;700&family=Montserrat:wght@100;400;700&family=Open+Sans+Condensed:wght@300&family=Oswald:wght@200;400;700&family=Roboto:wght@100;400;700&display=swap');
</style>
