<template>
<span>
    <div class="dashboard-header">
        
    </div>
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            user_coordinates: {
                lat: null,
                long: null
            },
            menu: [],
            submenus: []
        };
    },
    components: {
        
    },
    computed: {
        
    },
    mounted: function() {
        var vm = this

        axios.get('/api/navigation/primary').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            // console.log(err)
        })
    },
    methods: {

        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            }
            if(item.href) {
                // redirect
                // router.push({ path: '/content/' + item.href })
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Dashboard.scss';
</style>
