import axios from 'axios'

export default function auth({ next, router }) {
    
    axios.get('/auth/check').then(results => {  
        if(results.data.type === "success") {
            return next();
        } else {
            return router.push({ name: 'Login' });
        }
    })
    
  
    return next();
}