<template>
	<div>
        <b-modal full-screen  v-model="registerForTournamentSwitch">
			<template #default="props">
				<div class="modal-card" style="overflow: scroll;">
					<header class="modal-card-head">
						<p class="modal-card-title">Tournament Registration for {{ registerForTournamentDetails.tournamentSelected.name }} </p>
					</header>
					
					<RegisterToTournament
                        v-on:register:complete="props.close"
						:registerForTournamentDetails="registerForTournamentDetails"
					>
					</RegisterToTournament>
				</div>
			</template>
		</b-modal>	
		<b-modal 
            v-model="createTeamModal"
            has-modal-card
            trap-focus
            style="z-index: 40 !important;"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal>
            <template #default="props">
                <div class="modal-card" style="width: 350px">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ en === true ? 'Create Team' : 'Créer Une Équipe' }}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
                    </header>
                    <section class="modal-card-body">
                      
                        <b-field :label="(en === true ? 'Team Name' : 'Nom de L’équipe')">
                            <b-tooltip type="is-dark" label="Choose something creative!">
                                <b-input v-model="newTeam.name" required></b-input>
                            </b-tooltip>
                        </b-field>

                        <b-field :label="(en === true ? `Last Year's Team Name` : `Nom de L’équipe de L’année Dernière`)">
                            <b-tooltip type="is-dark" label="If this team name changed after last year, let us know here!">
                                <b-input v-model="newTeam.last_year_team_name"></b-input>
                            </b-tooltip>
                        </b-field>

                        <b-field :label="(en === true ? 'Primary City' : 'De Quelle Ville')">
                            <!-- <b-tooltip type="is-dark" label="Choose the core city of your team!" required>
                                <b-input 
                                    placeholder="Search for your city"
                                    v-model="newTeam.primary_location"
                                    id="autocomplete" ref="primary_location"></b-input>
                            </b-tooltip> -->
                            <b-autocomplete
                                v-model="newTeam.primary_location"
                                ref="autocomplete"
                                :data="filteredCities"
                                placeholder="e.g. Brampton"
                                @select="option => selected = option">
                                <!-- <template #header>
                                    <a @click="showAddFruit">
                                        <span> Add new... </span>
                                    </a>
                                </template> -->
                                <template #empty>No results for {{name}}</template>
                            </b-autocomplete>
                        </b-field>
                        <p>Please make sure you select a city from the Google dropdown after filling this out.</p>

                        <b-field :label="(en === true ? 'Team Primary Colour' : 'Couleur Primaire de L’équipe')">
                            <b-tooltip type="is-dark" label="Choose your team's primary colour!" required>
                                <v-swatches 
                                v-model="newTeam.color"
                                swatches="text-advanced"></v-swatches>
                            </b-tooltip>
                        </b-field>

                        <b-field :label="(en === true ? 'Gender' : 'Sexe')">
                            <b-tooltip type="is-dark" label="This is the gender of your created team.">
                                <b-select  v-model="newTeam.gender">
                                    <option value="mens">{{ en === true ? 'Mens' : 'Hommes' }}</option>
                                    <option value="ladies">{{ en === true ? 'Ladies' : 'Femmes' }}</option>
                                    <option value="coed">{{ en === true ? 'Coed' : 'Mixte' }}</option>
                                </b-select>
                                
                            </b-tooltip>
                        </b-field>

                        <p class="note" v-if="en === true">
                            <span>Please note:</span> your team will automatically be moved to the appropriate division based on the players you've added to your roster, and the tournaments and leagues you select to enter.
                        </p>
                        <p class="note" v-if="en === false">
                            <span>VEUILLEZ NOTER: </span> QUE VOTRE ÉQUIPE SERA AUTOMATIQUEMENT DÉPLACÉE VERS LA DIVISION APPROPRIÉE EN FONCTION DES JOUEURS QUE VOUS AVEZ AJOUTÉS À VOTRE LISTE, ET DES TOURNOIS ET LIGUES OU VOUS  PARTICIPER.
                        </p>


                      
                    </section>
                   
                    <footer class="modal-card-foot">
                        <a class="admin-button" v-on:click="saveTeam()">
                            <i class="far fa-save"></i>&nbsp;{{ en === true ? 'Save team' : 'ENREGISTRER VOTRE ÉQUIPE' }}
                        </a>
                    </footer>
                </div>
            </template>
        </b-modal>
		<Header v-on:switch:navigation="switchWidth($event)"  @update-lang="updateLanguage($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="page-header-title" v-if="en === true">
                                Player Central
                            </div>
                            <div class="page-header-title" v-if="en === false">
                                Centrale des Joueurs
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <span v-if="user">
                                <div class="padding" v-if="user.default_region_id !== 4">
                                    <b-field grouped position="is-right" v-if="en === true">
                                        <a v-on:click="createTeamModal = true" class="button">Create New Player Pool Team</a>
                                    </b-field>
                                    <b-field grouped position="is-right" v-if="en === false">
                                        <a v-on:click="createTeamModal = true" class="button">Créer votre équipe à partir de votre pool de joueurs</a>
                                    </b-field>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <span v-if="page === 'my-teams'">
					<div class="row">
						<div class="col-md-12">
						
							<MyTeams></MyTeams>
							
						</div>
						
					</div>
					
				</span>
				<div v-if="page === 'events-registered-in'">
					<EventsRegisteredIn
                        :en="en"
                        v-on:register:now="registerButtonModal($event)"></EventsRegisteredIn>
				</div>

                <div v-if="page === 'tournament-calendar'">
					<TournamentCalendarBackend
                        :en="en"
                        v-on:register:now="registerButtonModal($event)"></TournamentCalendarBackend>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MyTeams from './components/MyTeams.vue'
import EventsRegisteredIn from './components/EventsRegisteredIn.vue'
import TournamentCalendarBackend from './components/TournamentCalendarBackend.vue'
import RegisterToTournament from '../../components/RegisterToTournament.vue'
import CoachTools from './components/CoachTools.vue'
import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import VSwatches from 'vue-swatches'
import axios from 'axios'
var canada = require('canada')

export default {
	data() {
		return {
		   page: null,
		   content_width: false,
           createTeamModal: false,
           cities: [],
           user: null,
           regions: [],
            registerForTournamentSwitch: false,
            registerForTournamentDetails: null,
            en: true,
            newTeamCity: '',
            newTeamRegion: '',
		    newTeam: {
               name: null,
               last_year_team_name: null,
               primary_location: '',
               region: null,
               google_primary_location: {
                    city: null,
                    province: null,
                    latitude:  null,
                    longitude: null,
               },
               color: '#333',
               gender: 'Mens'
            }
		};
	},
	components: {
		Header,
		Footer,
		CoachTools,
		MyTeams,
        EventsRegisteredIn,
        VSwatches,
        RegisterToTournament,
        TournamentCalendarBackend
    },
    watch: {
        createTeamModal: function(result) {
            var vm = this
            if(result === true) {
                vm.$nextTick(() => {
                    var options = {
                        types: ['(cities)']
                    }
         
                })
            }
            
        }
    },
	mounted: function() {
        // grab all the dashboard information now
        var vm = this

        

        this.cities = canada.cities.map(function (cityData) {
            return cityData[0] + ', ' + cityData[1]
        })
        // console.log('canada', cities)

        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        
        axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.user = results.data.user
            }
        })
        
		this.page = this.$route.params.page

        //  var options = {
        //     types: ['(cities)']
        // }
        
	},
	computed: {
        filteredCities() {
            return this.cities.filter((option) => {
                return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.newTeam.primary_location.toLowerCase()) >= 0
            })
        }
	},
	methods: {
        fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()

			this.newTeam.primary_location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				vm.newTeam.region = region_data
			} else if (province.short_name === 'NT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'AB') {
						return true
					}
				})
                
				vm.newTeam.region = region_data
            } else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTeam.region = region_data
            } else if (province.short_name === 'YT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'BC') {
						return true
					}
				})
                
				vm.newTeam.region = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				vm.newTeam.region = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			vm.newTeam.google_primary_location = {
				city: city.long_name,
				province: province.short_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}
		},
		switchWidth: function($event) {
			this.content_width = !$event
        },
        registerButtonModal: function($event) {
			this.registerForTournamentSwitch = true
            this.registerForTournamentDetails = $event
		},
        saveTeam: function() {
            // first, do validation (look up on buefy)
            var newTeam = this.newTeam
            var user = localStorage.getItem('auth')
            if(newTeam.name === null || '') {
                // validation error
                return false
            }
            if(newTeam.primary_location === null || '') {
                // validation error
                return false
            }

            

            axios.post('/api/teams/save', {
                newTeam: newTeam,
                user: user
            }).then(results => {
                if(results.data.success === true) {
                    // now you want to redirect to the edit page on success

                    // redirect to

                    this.$router.push({ name: 'EditTeam', params: { slug:  results.data.data.team.slug } })
                    this.$buefy.snackbar.open({
                        message: 'Team created! You will receive an email soon.',
                        type: 'is-success', // is-danger
                        duration: 5000,
                        queue: false
                    })


                    return true
                } else {
                    
                    this.$buefy.snackbar.open({
                        message: 'Something went wrong when submitting your team! Contact us.',
                        type: 'is-danger', // is-danger
                        duration: 5000,
                        queue: false
                    })
                }
            })

        },
        updateLanguage: function($event) {
            
            this.en = $event

            this.$emit('update-lang', $event)
        }
	}
}
</script>

<style lang="scss">
    
</style>
