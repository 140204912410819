import axios from 'axios'

export default function auth({ next, router }) {
    
        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                if(results.data.user_permissions) {
                    if(results.data.user_permissions.superadmin_access === 1) {
                        return next();
                    } else {
                        return router.push({ name: 'Dashboard' });    
                    }
                } else {
                    return router.push({ name: 'Dashboard' });    
                }
            } else {
                return router.push({ name: 'Dashboard' });
            }
        })
    
  
    return next();
}