<template>
<div class="notification-area">
    <transition name="fade" :key="index" v-for="(notification, index) in activeNotifications">
        <div :class="['notification', notification.className]" v-on:click="notification.active = false">
            <div class="notification-title">Notification:</div>
            <div class="notification-close"><i class="fas fa-times"></i></div>
            {{ notification.message }}
        </div>
    </transition>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    data() {
        return {
            notifications: [],
            notificationText: null,
            notificationClassName: null,
            isConnected: false
        };
    },
    computed: {
        activeNotifications: function() {
            var self = this

            var notifications = self.notifications
            
            var filtered_notifications = _.filter(notifications, { 'active': true })

            return filtered_notifications
        }
    },
    mounted: function() {
        var self = this

        // if(typeof window !== 'undefined') {
        //     window.socket.on('newNotification', (data) => {
        //         self.notifications.push({
        //             className: data.className,
        //             message: data.message,
        //             active: true
        //         })
        //     })
        // }

        setInterval(function() {
            if(self.activeNotifications.length > 0) {
                var i = 0

                for(i; i < self.notifications.length; i++) {
                    
                    var notification = self.notifications[i]
                    
                    self.exitNotification(i)
                }
            }
        }, 1000)
        
        

    },
    methods: {
        exitNotification: function(i) {
            var self = this

            setTimeout(function() {
                self.notifications[i].active = false
            }, 3000)
        }
    }
}
</script>
