<template>
    <div class="edit-tournament-box">
        <b-modal
			v-model="addNewDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Add New Division</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="addDivisionDetails.gender">
								<option value="mens" >Mens</option>
								<option value="ladies">Ladies</option>
								<option value="coed">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="addDivisionDetails.rank_id">
								<option :value="division" :key="division.id" v-for="division in divisions">{{ division.name }}</option>
							</b-select>
						</b-field>
						<b-field label="Min Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_age"></b-numberinput>
						</b-field>
						<b-field label="Max Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_age"></b-numberinput>
						</b-field>
						<b-field label="Min # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_number_of_teams"></b-numberinput>
						</b-field>
						<b-field label="Max # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_number_of_teams"></b-numberinput>
						</b-field>

						<p class="note">Note: team changing gendered division will change their league insurance.</p>
						<div>
							<a role="button" v-on:click="addNewDivision()" style="margin-top: 10px;" class="admin-button">Add Division</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>
        <b-tabs v-if="editTournament">
            <b-tab-item label="Edit Basic Details">			
                <div class="row">
                    <div class="col-md-4">
                        <b-field label="Tournament Name">
                            <b-input v-model="editTournament.name"></b-input>
                        </b-field>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-4">
                        <b-field label="Tournament Name (FR)">
                            <b-input v-model="editTournament.fr_name"></b-input>
                        </b-field>
                    </div>
                    <div class="col-md-3">

                    </div>
                </div>
				<div class="row row-margin">
					<div class="col-md-4">
						<b-field label="Type of Event">
							<b-select placeholder="Select Gender" v-model="editTournament.status">
								<option value="sanctioned">Sanctioned</option>
								<option value="unapproved">Unapproved</option>
								<option value="rejected">Rejected</option>
								<option value="nationals">Nationals</option>
								<option value="provincials">Provincials</option>
							</b-select>
                            
                        </b-field>
					</div>
					<div class="col-md-4">
						<b-field label="Edit Region">
							<b-select placeholder="Select Region" v-model="editTournament.region_id">
								<option value="1">Alberta</option>
								<option value="2">British Columbia</option>
								<option value="3">Manitoba</option>
								<option value="4">Ontario</option>
								<option value="5">Maritimes</option>
								<option value="6">Quebec</option>
								<option value="7">Newfoundland Labrador</option>
								<option value="8">Saskatchewan</option>
							</b-select>
                            
                        </b-field>
					</div>
				</div>
                <div class="row row-margin">
                    <div class="col-md-6">
                        <b-field label="Tournament Start Date">
                            <b-datetimepicker
                                placeholder="Click to select..."
                                icon="calendar-today"
                                v-model="editTournament.event_start_date"
                                horizontal-time-picker>
                            </b-datetimepicker>
                        </b-field>
                    </div>
                    <div class="col-md-6">
                        <b-field label="Tournament End Date">
                            <b-datetimepicker
                                placeholder="Click to select..."
                                icon="calendar-today"
                                v-model="editTournament.event_end_date"
                                horizontal-time-picker>
                            </b-datetimepicker>
                        </b-field>
                    </div>
                </div>

                <div class="row row-margin">
                    <div class="col-md-6">
                        <b-field label="Registration Start Date">
                            <b-datetimepicker
                                placeholder="Click to select..."
                                icon="calendar-today"
                                v-model="editTournament.registration_start_date"
                                horizontal-time-picker>
                            </b-datetimepicker>
                        </b-field>
                    
                    </div>
                    <div class="col-md-6">
                        <b-field label="Registration End Date">
                            <b-datetimepicker
                                placeholder="Click to select..."
                                icon="calendar-today"
                                v-model="editTournament.registration_end_date"
                                horizontal-time-picker>
                            </b-datetimepicker>
                        </b-field>
                    </div>
                </div>

				<div class="row row-margin">
					 <div class="col-md-6">
                        <b-field label="Roster Lock Date">
                            <b-datetimepicker
                                placeholder="Click to select..."
                                icon="calendar-today"
                                v-model="editTournament.roster_lock_date"
                                horizontal-time-picker>
                            </b-datetimepicker>
                        </b-field>
                    </div>
				</div>

                <div class="row row-margin">
                    <div class="col-md-6">
                        <b-field label="Team Price">
                            <b-input 
                                v-model="editTournament.team_registration_fee"
                                icon-pack="fas"
                                icon="dollar-sign"
                            ></b-input>
                        </b-field>
                    </div>
                    
                </div>
                <div class="row" style="margin-top:10px;">
                    <div class="col-md-6">
                        <b-field extended label="Tournament Description" style="margin-right: 10px;"> 
                            <b-input style="width: 100%;" v-model="editTournament.description" maxlength="2000" type="textarea"></b-input>
                        </b-field>
                    </div>
                </div>

				<div class="row" style="margin-top:10px;">
                    <div class="col-md-6">
                        <b-field extended label="Preferred Email" style="margin-right: 10px;"> 
                            <b-input style="width: 100%;" v-model="editTournament.preferred_email"></b-input>
                        </b-field>
                    </div>
					<div class="col-md-6">
                        <b-field extended label="Preferred Phone" style="margin-right: 10px;"> 
                            <b-input style="width: 100%;" v-model="editTournament.preferred_phone"></b-input>
                        </b-field>
                    </div>
                </div>

				<b-field label="Make Tournament Visible In Calendar">
					<b-switch v-model="editTournament.invitation_only"
						:true-value="null"
                		:false-value="1"></b-switch>
				</b-field>

				<div class="padding">
					<b-field label="Max Roster Size">
						<b-numberinput v-model="editTournament.max_roster_size"></b-numberinput>
					</b-field>
				</div>
				


                <a role="button" class="admin-button" v-on:click="updateTournamentInfo()"><i class="fas fa-save"></i> Update Tournament Info</a>
            </b-tab-item>
            <b-tab-item label="Edit Divisions">
                <p class="note">You cannot delete a division until you have moved all teams from that division. Use the registered teams tab to change the division of the teams. When the team count for that division hits zero, the delete button will appear.</p>
                <a role="button" class="admin-button" v-on:click="addNewDivisionSwitch = true">Add a New Division</a>
                <div class="panel" style="margin-top: 20px;" v-for="division in editTournament.divisions" :key="division.id">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-md-3">
                                {{ division.gender_slug.toUpperCase() }} {{ division.rank_id }}
                                <br><span style="font-size: 14px;">Team Count: {{ teamCount(division) }}</span>
                            </div>
                            
                            <div class="col-md-5">
                                <b-field label="Qualifier Type" horizontal custom-class="is-small">	
                                    <b-dropdown hoverable aria-role="list" v-model="division.qualifier">
                                        <div class="button is-info" slot="trigger">
                                            <span>
                                                {{ division.qualifier }}
                                            </span>
                                            <b-icon icon="menu-down"></b-icon>
                                        </div>

                                        <b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
                                            Provincial Qualifer
                                        </b-dropdown-item>
                                        <b-dropdown-item value="National Qualifer" aria-role="listitem">
                                            National Qualifer
                                        </b-dropdown-item>
                                        <b-dropdown-item value="Non-Qualifer" aria-role="listitem">
                                            Non-Qualifer
                                        </b-dropdown-item>
										<b-dropdown-item value="League Non-Qualifer" aria-role="listitem">
                                            League Non-Qualifer
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-field>
                            </div>
                            <div class="col-md-4" v-if="teamCount(division) === 0">
                                <a class="button is-small is-success" v-on:click="saveDivision(division)">Save Updated Division</a>
                                <a class="button is-small is-danger" v-on:click="deleteDivision(division)">Delete Division</a>
                            </div>
                        </div>
                    </div>
                    <div class="panel-block" style="display: block;">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="padding">
                                    <b-field label="Minimum # of Teams" min="3" custom-class="is-small">
                                        <b-numberinput type="is-danger" v-model="division.min_number_of_teams"></b-numberinput>
                                    </b-field>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="padding">
                                    <b-field label="Max # of Teams" custom-class="is-small">
                                        <b-numberinput type="is-danger" min="3" v-model="division.max_number_of_teams"></b-numberinput>
                                    </b-field>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="padding">
                                    <b-field label="Minimum Age" custom-class="is-small">
                                        <b-numberinput type="is-danger" min="16" max="99" v-model="division.min_age"></b-numberinput>
                                    </b-field>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="padding">
                                    <b-field label="Maximum Age" custom-class="is-small">
                                        <b-numberinput type="is-danger" min="16" max="99" v-model="division.max_age"></b-numberinput>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Edit Headquarters">
                <p><b>Current Address:</b> {{ tournament.written_address }}</p>
				<p><b>Current City:</b> {{ tournament.city }}</p>
                <b-field label="Update The Address">
                    <b-input icon-pack="fas"
                        icon="street-view"
                        expanded
                       	v-model="newTournament.written_address"></b-input>
                </b-field>
				<b-field label="Update The City">
                    <b-input icon-pack="fas"
                        icon="street-view"
                        expanded
                       	v-model="newTournament.city"></b-input>
                </b-field>
                <a role="button" class="admin-button" v-on:click="updateLocation()">Update Location</a>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            tournament: null,
            editTournament: null,
            addNewDivisionSwitch: false,
            divisions: [],
            addDivisionDetails: {
				gender: null,
				rank_id: null,
				min_number_of_teams: 4,
				max_number_of_teams: 8,
				min_age: 18,
				max_age: 99
			},
            newTournament: {
				headquarter_location: null,
				headquarter_google_address: null,
			},
        };
    },
    props: ['eventId', 'eventType'],
    computed: {
        
    },
    mounted: function() {
        var self = this
        self.grabTournament()

        axios.get('/api/superuser/divisions').then(results => {
			self.divisions = results.data.divisions
		})
    },
    methods: {
       grabTournament: function() {
            var vm = this

            axios.get('/api/superuser/grab-tournament/' + vm.eventId)
            .then(results => {
                if(results.data.success === true) {
                    
                    vm.tournament = results.data.tournament

                    vm.editTournament = results.data.tournament

					if(vm.editTournament.event_start_date) {
						vm.editTournament.event_start_date = new Date(vm.editTournament.event_start_date)
					}
					if(vm.editTournament.event_end_date) {
						vm.editTournament.event_end_date = new Date(vm.editTournament.event_end_date)
					}
					if(vm.editTournament.registration_start_date) {
						vm.editTournament.registration_start_date = new Date(vm.editTournament.registration_start_date)
					}
			
					if(vm.editTournament.registration_end_date) {
						vm.editTournament.registration_end_date = new Date(vm.editTournament.registration_end_date)
					}

					if(vm.editTournament.roster_lock_date) {
						vm.editTournament.roster_lock_date = new Date(vm.editTournament.roster_lock_date)
					}
                } else {

                }
            })
        },
      
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.newTournament.headquarter_location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'AB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'YT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'BC') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				}  else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			var street_number_long_name = null

			if(street_number) {
				street_number_long_name = street_number.long_name
			}

			var route_long_name = null

			if(route) {
				route_long_name = route.long_name
			}


			vm.newTournament.headquarter_google_address = {
				written_address: place.formatted_address,
				street_address: street_number_long_name + ' ' + route_long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}
		},
        updateTournamentInfo: function() {
			var vm = this

			var tourney = vm.editTournament

			delete tourney.divisions
			delete tourney.registrants

			axios.post('/api/superuser/update-tournament', {
				tournament: tourney
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Tournament Info Has Been Updated',
						type: 'is-success' // is-danger
					})
					vm.tournament = vm.editTournament
					vm.grabTournament()
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong when saving',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
        teamCount: function(division) {
			var vm = this

			var teams = _.filter(vm.registered_teams, function(team) {
				return team.league_division_id === division.id
			})

			return teams.length
		},
        addNewDivision: function() {
			var vm = this

			axios.post('/api/tournaments/add-new-division', {
				tournament: vm.tournament.id,
				division: vm.addDivisionDetails
			}).then(results => {
				if(results.data.success === true) {
					vm.grabTournament()
					vm.addNewDivisionSwitch = false
					this.$buefy.snackbar.open({
						message: 'Added new division',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Division not added successfully. Are you sure you do not already have that division?',
						type: 'is-danger', // is-danger
						duration: 4000,
					})
				}
			})
		},
        deleteDivision: function(division) {
			var r = confirm('Are you sure you want to delete this division?')
			var vm = this
			if(r === true) {
				axios.post('/api/superuser/delete-tournament-division', {
					division: division.id
				}).then(results => {
					if(results.data.success === true) {
						vm.grabTournament()
						this.$buefy.snackbar.open({
							message: 'Deleted the division',
							type: 'is-success' // is-danger
						})
					} else {
						this.$buefy.snackbar.open({
							message: 'Something went wrong',
							type: 'is-danger' // is-danger
						})
					}
				})
			}
			
		},
        saveDivision: function(division) {
			
			var vm = this
			
			axios.post('/api/superuser/save-tournament-division', {
				division: division
			}).then(results => {
				if(results.data.success === true) {
					vm.grabTournament()
					this.$buefy.snackbar.open({
						message: 'Updated the division',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong',
						type: 'is-danger' // is-danger
					})
				}
			})
		
			
		},
        updateLocation: function() {
			var vm = this
			axios.post('/api/superuser/update-tournament-location', {
				tournament: vm.tournament.id,
				headquarters: vm.newTournament
			}).then(results => {
				if(results.data.success === true) {
					vm.grabTournament()
					this.$buefy.snackbar.open({
						message: 'Updated tournament location.',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				}
			})
		},
    }
}
</script>
