<template>
    <div>
        <VueSignaturePad class="signature-pad"  width="600px" height="250px" ref="signaturePad" />
        <div v-if="saved === false">
            <button v-on:click="undo" class="button is-danger">Reset/Clear Signature Area</button>
            <button v-on:click="saveSignature()" class="button is-info">Click to Save Signature - Required</button>
        </div>
        <div v-if="saved === true">Signature has been saved to the form.</div>
    </div>
    
</template>

<script>
export default {
    data() {
        return {
            data: null,
            form: null,
            user: null,
            en: true,
            selectedRegion: null,
            selectedRegionsError: null,
            saved: false
        };
    },
    methods: {
        undo() {
        this.$refs.signaturePad.undoSignature();
        },
        saveSignature: function() {
            // console.log(key, question)
            
            // console.log(this.$refs.signaturePad[0].toData())
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
            // console.log(isEmpty)
            // console.log(data)

            this.$emit('signature:sent', { 
                data: data
            })

            this.saved = true
            
        }
    }
}
</script>

<style lang="scss">
    
</style>