<template>
<span>
    <div class="header">
        <div class="header-wrapper">
            <div class="logo-main">
                <a href="/" role="button"><img src="../../../images/header/SPNLogo2019-White.png" alt="SPN Logo"></a>
            </div>
            
            <div class="language-bar">
                <ul>
                    <li>
                        <a role="button" class="lang selected" href="#">EN</a>|<a role="button" class="lang" href="#">FR</a>
                    </li>
                    <li>
                        
                    </li>
                </ul>
            </div>
        </div>
    </div>

</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            submenu_filtered: [],
            menu: [],
            submenus: []
        };
    },
    components: {
        
    },
    computed: {
        
    },
    mounted: function() {
        var vm = this

        axios.get('/api/navigation/primary').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            console.log(err)
        })
    },
    methods: {
       
        selectMenu(item) {
            var vm = this
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            }
            if(item.href) {
                // redirect
                this.$router.push({ path: '/' + item.href }).catch(err => { console.log(err)})   
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Header.scss';
</style>
