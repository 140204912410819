<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>


		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Communication Log
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
               
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-md-12">
                        <b-table
                            :data="logs"
                            ref="table"
                            :paginated="true"
                            :current-page="1"
                            per-page="100"
                            :pagination-simple="false"
                            :pagination-position="'bottom'"
                            :opened-detailed="defaultOpenedDetails"
                            detailed
                            detail-key="id"
                            
                            :show-detail-icon="showDetailIcon"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">

                            <b-table-column :searchable="true" field="from_email" label="From Email" sortable v-slot="props">
                                {{ props.row.from_email }}
                            </b-table-column>
                            <b-table-column :searchable="true" field="bcc_email" label="BCC Email" sortable v-slot="props">
                                {{ props.row.bcc_email }}
                            </b-table-column>
                            <b-table-column :searchable="true" field="subject" label="Subject" sortable v-slot="props">
                                {{ props.row.subject }}
                            </b-table-column>
                                

                            <b-table-column field="updated_at" label="Updated At" sortable v-slot="props">
                                {{ moment(props.row.updated_at).format('YYYY-MM-DD h:mmA') }}
                            </b-table-column>

                            <template slot="detail" slot-scope="props">
                                {{ props.row.computed_content }}
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

        
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import CreatePage from './components/CreatePage.vue'
import moment from 'moment'

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { VueEditor, Quill } from "vue2-editor";

import axios from 'axios'
export default {
	data() {

		return {
            isLoading: true,
            editPageSwitch: false,
            authUser: { first_name: null },
            createNewPageSwitch: false,
            content_width: false,
            faqs: [],
            logs: [],
            newFaq: {
                question: null,
                question_fr: null,
                answer: null,
                answer_fr: null
            },
            newPage: {
                name: null,
                slug: null,
                content: null,
                fr_content: null,  
            },
            contentAreas: [{
                type: 'textarea',
                widget_type: null,
                form_id: null,
                columns: [
                    {
                        width: 12,
                        content: null,
                        fr_content: null
                    },
                ]
            }],
            forms: [],
            tabSelected: 0,
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            pageSelectedForEditing: null,
            pages: [],
            latest_news: []
            
		};
	},
	components: {
		Header,
        Footer,
        VueEditor,
        CreatePage
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			 
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.communication_log !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/communication-logs').then(results => {
                    if(results.data.success === true) {
                        vm.logs = results.data.logs
                        // format the content
                    }
                })

                vm.isLoading = false
            }
        })
	},
	computed: {
        
    },
    watch: {
        createNewPageSwitch: function(result) {
            var vm = this
            if(result === false) {
                vm.pageSelectedForEditing = null
            }
        }
    },
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        goBack: function(data){
            var r = confirm('Are you sure you want to do this? You will lose this work.')

            if(r === true) {
                this.pageSelectedForEditing = null
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        saveNewPage: function() {
            var vm = this
            
            axios.post('/api/superuser/save-new-page', {
                page: vm.newPage,
                content: vm.contentAreas
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.pages = results.data.pages
                    vm.$buefy.snackbar.open({
                        message: 'Saved page successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.createNewPageSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving this page',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        updatePage: function() {
            var vm = this
            
            axios.post('/api/superuser/update-page', {
                page: vm.pageSelectedForEditing
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.pages = results.data.pages
                    vm.editPageSwitch = false
                    vm.$buefy.snackbar.open({
                        message: 'Updated page successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when updating this page',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        saveNewFaq: function() {
            var vm = this

            axios.post('/api/superuser/save-faq', {
                newFaq: vm.newFaq
            }).then(results => {
                if(results.data.success === true) {
                    
                    vm.$buefy.snackbar.open({
                        message: 'Updated/saved FAQ successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.faqs = results.data.faqs
                }
            })
        },
        removeQuestion: function(question_id) {
            var vm = this

            var r = confirm('Are you sure you want to remove this question?')
            
            if(r === true) {
                axios.post('/api/superuser/remove-faq', {
                    question_id: question_id
                }).then(results => {
                    if(results.data.success === true) {
                        
                        vm.faqs = results.data.faqs
                    }
                })
            }
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addPageRow: function(area = null) {
            var vm = this
            
            if(area) {
                area.push({
                    type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                })
            } else {
                vm.contentAreas.push({
                    type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                })
            }
        },
        removeRow: function(index) {
            this.contentAreas.splice(index, 1);
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("logo", file);

            

            axios.post('/api/teams/upload-photo', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    Editor.insertEmbed(cursorLocation, "image", results.data.data.logo_path);
                    resetUploader();
                    // console.log('upload_path', results.data.data.logo_path)
                }
            })
        },
        closeModal: function() {
            this.createNewPageSwitch = false
        },
        changeColumns: function(number, key, area, edit = false) {
            
            if(number === 1) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            if(number === 2) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 6,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 6,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            if(number === 3) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            
            if(edit === false) {
                this.contentAreas.splice(key, 1, contentArea)
            } else {
                this.pageSelectedForEditing.content.splice(key,1, contentArea)
            }
        },
        editQuestion: function(question) {
            
            var vm = this
            vm.newFaq = question
        },
        parsePageForEditing: function(page) {
            var vm = this

            page.contentAreas = page.content

            vm.pageSelectedForEditing = page

            vm.createNewPageSwitch = true
        },
        triggerCreatePageClose: function() {
            var vm = this

            vm.createNewPageSwitch = false
            
            vm.pageSelectedForEditing = null
        }
	},
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
