<template>
    <div class="edit-tournament-box" v-if="tournament">
        <p class="note">This tab will allow you to create permissions for unlimited numbers of members to access your tournament as a "convenor".</p>
        <p class="note">They will only receive limited convenor access. They will not be able to create leagues or events. Please get them to apply for full convenor access if they need more control.</p>

        <h3>Executive Access</h3>
        <table class="table" v-if="executiveAccessPlayers">
            <tr>
                <td><b>User</b></td>
                <td><b>Role</b></td>
                <td><b>Action</b></td>
            </tr>
            <tr :key="player.id" v-for="player in executiveAccessPlayers">
                <td>{{ player.user.first_name }} {{ player.user.last_name }}</td>
                <td>{{ player.role }}</td>
                <td>
                    <a v-on:click="makePrimaryConvenor(player)" class="button is-small is-info" v-if="tournament.convenor_user_id !== player.user.id">Make Primary Convenor</a>
                    <a v-on:click="removeTheirAccess(player)" class="button is-danger is-small">Remove Their Access</a>
                </td>
            </tr>
        </table>
        
        <h3>Add to Executive Access</h3>
        
        <div class="row">
            <div class="col-md-9">
                <b-field label="Search for someone" message="You can enter email, phone, username or first &amp; last name">
                    <b-input v-on:input="updatePlayerSearch()" v-model="searchName"  placeholder="Search">
                    </b-input>
                </b-field>
            </div>
            <div class="col-md-3">
                <b-field>
                    <b-checkbox v-model="allRegionSearch" v-on:input="updatePlayerSearch()"> Include out of region users in your search</b-checkbox>    
                </b-field>
            </div>
        </div>
        <div style="max-height: 400px; overflow: scroll; -webkit-overflow-scrolling: touch;">
            <nav class="level" v-for="(user, key) in searchList" :key="key" >
                <div class="level-left">
                    <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                    <p class="level-item">{{ user.city }}</p>
                    <p class="level-item">
                        {{ user.email }}	
                    </p>
                    <p class="level-item">{{ user.gender }}</p>
       
                    <p class="level-item">
                        <span v-if="user.latest_waiver_signed === $latest_waiver">Signed</span>
                    </p>
                </div>
                <div class="level-right">
                    <a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
                </div>
            </nav>
        </div>
        <div class="adding">
            <p><b>Adding:</b></p>
        </div>
        
        <section class="modal-card-body">
            <nav class="level" v-for="(user, key) in playersSelected" :key="key">
                <div class="level-left">
                    <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                    <p class="level-item">
                        <b-select v-model="user.access_title">
                            <option value="Primary Convenor">
                                Primary Convenor
                            </option>
                            <option value="Secondary Convenor">
                                Secondary Convenor
                            </option>
                            <option value="Secretary">
                                Secretary
                            </option>
                            <option value="UIC">
                                UIC
                            </option>
                        </b-select>
                    </p>
                </div>
                <div class="level-right">
                    <a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
                </div>
            </nav>
        </section>
        <button class="admin-button" style="margin-top: 20px;" v-on:click="addToExecutiveAccess()">Add User to Executive Access</button>
        
        
        
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
// import moment from 'moment'
export default {
    data() {
        return {
            executiveAccessPlayers: null,
            tournament: null,
            searchList: [],
            playersSelected: [],
            searchName: null,
            allRegionSearch: false
        };
    },
    computed: {
        
    },
    props: ['eventId', 'eventType'],
    mounted: function() {
        var self = this

        self.grabTournament()
        self.grabExecutiveAccessList()
    },
    methods: {
       grabTournament: function() {
            var vm = this

            axios.get('/api/superuser/grab-tournament/' + vm.eventId)
            .then(results => {
                if(results.data.success === true) {
                    
                    vm.tournament = results.data.tournament
                } else {

                }
            })
        },
        grabExecutiveAccessList: function() {
			var vm = this
            
			axios.get('/api/tournaments/tournament-executive-access/' + vm.eventId).then(results => {
            
				if(results.data.success === true) {
					vm.executiveAccessPlayers = results.data.executive_access
				}
			})
		},
        makePrimaryConvenor: function(player) {
			var vm = this
			
			axios.post('/api/tournaments/make-primary-convenor', {
				player: player,
				tournament: vm.tournament.id
			}).then(results => {
				if(results.data.success === true) {
					vm.grabTournament()
					this.$buefy.snackbar.open({
                        message: 'Convenor marked as primary executive!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {

				}
			})
		},
        removeTheirAccess: function(player) {
			var vm = this

			axios.post('/api/tournaments/remove-executive-access', {
				player: player,
				tournament: vm.tournament.id
			}).then(results => {
				if(results.data.success === true) {
					vm.grabTournament()
                    vm.grabExecutiveAccessList()
					this.$buefy.snackbar.open({
                        message: 'Removed executive access successfully!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {

				}
			})
		},
        updatePlayerSearch: function($event) {            
            var vm = this
            
			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				axios.post(`/api/users/search/players`, {
					search_name: vm.searchName,
                    region: vm.tournament.region_id,
                    all_regions: vm.allRegionSearch
                    
				}).then(results => {
					if(results.data.success === true) {
						vm.searchList = results.data.users
					}
				})
			}, 500)
		},
        addToExecutiveAccess: function() {
			var vm = this

			axios.post('/api/tournaments/add-to-tournament-executive-access', {
				tournament_id: vm.tournament.id,
				users: vm.playersSelected,
			}).then(results => {
				if(results.data.success === true) {
					vm.playersSelected = []
					vm.grabTournament()
                    vm.grabExecutiveAccessList()
					this.$buefy.snackbar.open({
						message: 'Added members to the executive!',
						type: 'is-success', // is-danger
						duration: 4000,
						queue: false
					})
				}
			})
		},
        addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
    }
}
</script>

