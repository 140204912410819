import axios from 'axios'

export default function login({ next, router }) {
    if(typeof window !== 'undefined') {
        if (localStorage.getItem('auth')) {
            
            return router.push({ name: 'Dashboard' });
        }
    }
  
    return next();
}