<template>
    <span>
        <b-tag v-if="currentRank === 'A'" type="is-success">
            A{{ rankChanged ? '*' : '' }}
        </b-tag>
        <b-tag v-else-if="currentRank === 'B'" type="is-danger">
            B{{ rankChanged ? '*' : '' }}
        </b-tag>
        <b-tag v-else-if="currentRank === 'C'" type="is-warning">
            C{{ rankChanged ? '*' : '' }}
        </b-tag>
        <b-tag v-else-if="currentRank === 'D'" type="is-info">
            D{{ rankChanged ? '*' : '' }}
        </b-tag>
        <b-tag v-else-if="currentRank === 'E'" type="is-link" style="color: #fff;">
            E{{ rankChanged ? '*' : '' }}
        </b-tag>
        <b-tag v-else>
            {{ currentRank }}{{ rankChanged ? '*' : '' }}
        </b-tag>
        
    </span>
</template>

<script>
export default {
    props: ['currentRank','previousRank'],
    computed: {
        rankChanged() {
            return this.previousRank && this.previousRank !== this.currentRank;
        }
    }
}
</script>

