<template>
	<div>
		<Header  @update-lang="updateLanguage($event)"></Header>
		
		<div class="content-area">
			<div class="standard-page-header">
				{{ en === true ? 'Tournament Calendar' : 'Calendrier des tournois' }}
			</div>
            <div class="tournament-calendar-main-area">
                <TournamentCalendar :en="en"></TournamentCalendar>
          
            </div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'

import ServeTournaments from '../components/ServeTournaments.vue'
import RegisterToTournament from '../components/RegisterToTournament.vue'
import TournamentCalendar from './TournamentCalendar.vue'

import moment from 'moment'
export default {
    metaInfo() {
        return { 
            title: "Tournament Calendar - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'View any of our tournaments in any of our regions here!' },
                { property: 'og:title', content: "Tournament Calendar - Slo-Pitch National"},
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:url', content:  'https://www.slo-pitch.com/tournament-calendar' },
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/tournamentcalendar.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
            en: true,
            current_month: null,
            page_url: null,
            page: null,
            month: null,
            tournaments: [],
            year: null,
            divisions: ['mens','ladies','coed'],
            rank: ['A','B','C','D','E','Special'],
            regions: [1,2,3,4,5,6,7,8]
		};
	},
	components: {
		Header,
		Footer,
        Form,
        ServeTournaments,
        RegisterToTournament,
        TournamentCalendar
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this


        vm.month = moment().format('MMMM')
        vm.number_month = moment().format('MM')
        vm.year = moment().format('YYYY')


        // axios.post('/api/tournaments/calendar', {
        //     divisions: vm.divisions,
        //     rank: vm.rank,
        //     regions: vm.regions,
        //     month: vm.number_month,
        //     year: vm.year
        // }).then(results => {
        //     if(results.data.success === true) {
        //         vm.tournaments = results.data.tournaments
        //     }
        // })
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
        previousMonth: function() {
            var vm = this

            var prev = moment(vm.month + '/01/' + vm.year, 'MMMM/DD/YYYY').subtract(1, 'months')

            return {
                month: prev.format('MMMM'),
                year: prev.format('YYYY')
            }
        },
        nextMonth: function() {
            var vm = this

            var next = moment(vm.month + '/01/' + vm.year, 'MMMM/DD/YYYY').add(1, 'months')

            return {
                month: next.format('MMMM'),
                year: next.format('YYYY')
            }
        }
	},
	methods: {
        // updateCalendar() {
        //     var vm = this
        //     axios.post('/api/tournaments/calendar', {
        //         divisions: vm.divisions,
        //         rank: vm.rank,
        //         regions: vm.regions
        //     }).then(results => {
        //         if(results.data.success === true) {
        //             vm.tournaments = results.data.tournaments
        //         }
        //     })
        // },
        prevMonthButton: function() {
            var vm = this
            var current_month = vm.month

            var next_month = moment(vm.year + ' ' + vm.month + ' 01', 'YYYY MMMM DD').subtract('1', 'months')
            vm.number_month = next_month.format("MM")
            vm.month = next_month.format('MMMM')
            vm.year = next_month.format('YYYY')
            vm.triggerCalendar()
        },
        nextMonthButton: function() {
            var vm = this
            var current_month = vm.month

            var next_month = moment(vm.year + ' ' + vm.month + ' 01', 'YYYY MMMM DD').add('1', 'months')

            vm.number_month = next_month.format("MM")
            vm.month = next_month.format('MMMM')
            vm.year = next_month.format('YYYY')

            vm.triggerCalendar()
        },
        // triggerCalendar: function() {
        //     var vm = this
        //     axios.post('/api/tournaments/calendar', {
        //         divisions: vm.divisions,
        //         rank: vm.rank,
        //         regions: vm.regions,
        //         month: vm.number_month,
        //         year: vm.year
        //     }).then(results => {
        //         if(results.data.success === true) {
        //             vm.tournaments = results.data.tournaments
        //         }
        //     })
        // },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        updateLanguage: function($event) {
			this.en = $event
			
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
