<template>
	<div>
		
		<b-modal full-screen  v-model="registerForTournamentSwitch" v-on:close="triggerFullClose()">
			<template #default="props">
				<div class="modal-card" style="overflow: scroll;">
					<header class="modal-card-head">
						<p class="modal-card-title">Tournament Registration for {{ registerForTournamentDetails.tournamentSelected.name }} </p>
					</header>
					
					<RegisterToTournament
						:registerForTournamentDetails="registerForTournamentDetails"
						v-on:register:complete="triggerFullClose(props.close)"	
					>
					</RegisterToTournament>
				</div>
			</template>
		</b-modal>	

		<b-modal v-model="clinicSwitch"  :width="640">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Email Clinician</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Send Your Message">
							<b-input type="textarea" v-model="clinicianMessage"></b-input>
						</b-field>

						<a class="admin-button" role="button" v-on:click="sendClinicianMessage()">Send Message</a>
					</section>
				</div>
			</template>
		</b-modal>	
		
		<Header @update-lang="updateLanguage($event)" v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<img src="../../images/dashboard/memberbanner.png"  width="100%" v-if="en === true" alt="Member Banner">
			<img src="../../images/dashboard/memberbannerfr.png" width="100%" v-if="en === false" alt="Member Banner">
			<div class="content-wrapper" style="padding-top: 0px;">
				<h1 v-if="en === true">Welcome {{ authUser.first_name }}!</h1>
				<h1 v-if="en === false">Bienvenue {{ authUser.first_name }}!</h1>

				<div class="row">
					<div class="col-md-4">
						<div class="padding">
							<h2>Like us on Facebook!</h2>
							
							<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSloPitchNational%2F&tabs=timeline&width=340&height=120&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1323561621426720" width="100%" height="130" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
							<div v-if="authUser.default_region_id == '4'">
							<h2>Join our SPN Ontario Group</h2>
								<iframe src="https://www.facebook.com/plugins/group.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2F226434884479997&width=280&show_metadata=false&appId=1323561621426720&height=239" width="280" height="239" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; picture-in-picture; "></iframe>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="padding" v-if="en">
							<h2>Quick Links</h2>
							<span v-if="authUser && authUser.default_region_id === 4">
								<a href="https://playslopitch.com" role="button" class="admin-button">Ontario - Head to PlaySlopitch.com</a>
							</span>
							<span v-if="authUser && authUser.default_region_id !== 4">
								<a href="/dashboard/players/tournament-calendar" role="button" class="admin-button">Register for Provincials</a>
								<br><br>
								<a href="/dashboard/players/tournament-calendar" role="button" class="admin-button">Find Nearby Tournaments</a>
								<br><br>
								<img src="../../images/registerfortournament.jpg" alt="Find Nearby Tournaments">
							</span>
						</div>
						<div class="padding" v-if="en === false">
							<h2>MISES À JOUR DE LA CENTRALE SPN</h2>
							<img src="../../images/registerfortournament.jpg" alt="Find Nearby Tournaments">
						</div>
					</div>
					<div class="col-md-5">
						<div class="padding">
							<h2>{{ en === true ? 'Clinics Registered To' : 'Cliniques inscrites ' }}</h2>
							<table class="table" v-if="clinics.length > 0">
								<tr>
									<td>
										<b v-if="en === true">Clinic Name</b>
										<b v-if="en === false">Nom de la clinique</b>
									</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr v-for="(clinic, key) in clinics" :key="key">
									<td>
										{{ clinic.clinic.name }}
									</td>
									<td>
										{{ moment(clinic.clinic.event_date).add('8', 'hours').format('YYYY-MM-DD') }}
									</td>
									<td>
										{{ momentUtc(clinic.clinic.start_time,'hh:mm:ss').local().format('h:mm A') }}
									</td>
									<td>
										{{ clinic.clinic.written_address }}
									</td>
									<td>
										<a class="button is-small is-info" v-on:click="emailTheClinician(clinic.clinic)">Email Clinician</a>
									</td>
								</tr>
							</table>
							
							
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

// put together
import UpcomingTournaments from '../components/UpcomingTournaments.vue'
import RegisterToTournament from '../components/RegisterToTournament.vue'
// put together
import PaymentProcessor from '../components/PaymentProcessor.vue'
import NewPaymentProcessor from '../components/NewPaymentProcessor.vue'

import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			registerForTournamentSwitch: false,
			registerForTournamentDetails: null,
			authUser: { first_name: null },
			content_width: false,
			orders: [],
			selectedOrder: null,
			paymentSuccess: false,
			en: true,
			clinics: [],
			clinicSelected: null,
			clinicSwitch: false,
			clinicianMessage: null
		};
	},
	components: {
		Header,
		Footer,
		UpcomingTournaments,
		RegisterToTournament,
		PaymentProcessor,
		NewPaymentProcessor
	},

	mounted: function() {
		// grab all the dashboard information now
		
		var vm = this
		var query = this.$route.query

		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
		
		if(query.q) {

			var q = query.q
			var r = query.r

			if(q === 'league') {
				// get the league or tournament and prompt the registration modal
			}
			if(q === 'tournament') {
				// get the tournament and prompt the registration model to open (call axios and then fire it opening)
				var tourneySelected = null
				var region_rate = null
				//region_rate = tournamentSelected
				axios.get(`/api/tournaments/get-tournament/${r}`).then(results => {
					if(results.data.success === true) {				
						tourneySelected = results.data.tournament

						axios.post('/api/tournaments/region-rate', {
							region: tourneySelected.region_id
						}).then(results => {
							
							if(results.data.success === true) {
								
								region_rate = results.data.region_rate
								
								vm.registerForTournamentDetails = {
									tournamentSelected: tourneySelected,
									region_rate: region_rate
								}
								vm.registerForTournamentSwitch = true
							}
						})

						
					}
				})
			}
		}

		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
				vm.authUser = results.data.user
				
				

				axios.get('/api/users/clinics-entered').then(results => {
					if(results.data.success === true) {
						vm.clinics = results.data.clinics
						
					}
				})
            }
        })
	},
	computed: {
	
	},
	methods: {
		paymentSuccessSwitch: function($event) {
            var vm = this
			vm.paymentSuccess = $event

            // this.triggerFinalize = true
        },
		switchWidth: function($event) {
			this.content_width = !$event
		},
		registerButtonModal: function($event) {
			this.registerForTournamentSwitch = true
			this.registerForTournamentDetails = $event
			
			// console.log('registerButtonModal', )
		},
		triggerFullClose: function(close) {
			this.$router.push({ path: '/dashboard', query: null })
			return close
		},
		outstandingPaymentMade: function($event) {
			var vm = this
			vm.selectedOrder = null
			
			axios.get('/api/users/outstanding-payments-due').then(results => {
				if(results.data.success === true) {
					vm.orders = results.data.orders
				}
			})
		},
		updateLanguage: function($event) {
			this.en = $event
			
		},
		moment: function(date, format = null) {
            return moment(date, format)
		},
		momentUtc: function(date, format) {
			return moment.utc(date, format)
		},
		emailTheClinician: function(clinic) {
			var vm = this
			vm.clinicSwitch = true
			vm.clinicSelected = clinic
		},
		sendClinicianMessage: function() {
			var vm = this
			axios.post('/api/users/message-clinician', {
				clinic: vm.clinicSelected,
				message: vm.clinicianMessage
			}).then(results => {
				if(results.data.success === true) {
					vm.clinicianMessage = null
					vm.clinicSelected = null
					vm.clinicSwitch = false

					this.$buefy.snackbar.open({
						message: 'Message sent to clinician! They will email you.',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Could not send message',
						type: 'is-danger' // is-danger
					})
				}
			})
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
