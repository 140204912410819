<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				British Columbia's Hall of Honour
			</div>
            <div class="frontend-content-wrapper">
				<div class="section-spacing">

                    <div style="margin-top: 20px;">
                        <b-tabs >
                            <b-tab-item label="BC's Hall of Honour">
                                <PageContentBox :content="parseMainContent(hall_main_content.content)"></PageContentBox>
                            </b-tab-item>
                            <b-tab-item label="Inductees">
                                <div class="panel" :key="key" v-for="(inductee, key) in hall_members">
                                    <div class="panel-heading">
                                        {{ inductee.name }}
                                    </div>
                                    <div class="panel-content">
                                        <PageContentBox :content="parseMainContent(inductee.content)"></PageContentBox>
                                    </div>
                                    
                                </div>
                            </b-tab-item>
                         
                        </b-tabs>
                    </div>
				</div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "BC's Hall of Honour - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'The Hall of Honour features some of the most memorable, talented and dedicated members of Slo-Pitch National in British Columbia!' },
                { property: 'og:title', content: "BC's Hall of Honour - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/hall-of-honour' },
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/hallofhonour.png' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            previous_results: null,
            first_decade: false,
            second_decade: false,
            third_decade: false,
            nationals_hosts: null,
            brampton: false,
            winnipeg: false,
            edmonton: false,
            leduc: false,
            kelowna: false,
            hall_main_content: null,
            hall_members: null
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this

        axios.get('/api/pages/hall-of-honour').then(results => {
            if(results.data.success === true) {
                vm.hall_members = results.data.hall_members
                vm.hall_main_content = results.data.hall_main_content
            }
        })
     
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
        parseMainContent: function(content) {
            return JSON.parse(content)
        },
        grabNationalsHost: function(city) {
            var vm = this
            var tourney = _.find(vm.nationals_hosts, function(result) {
                return result.slug === 'nationals-host-' + city
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
        grabTournamentResults: function(year) {
            var vm = this
            var tourney = _.find(vm.previous_results, function(result) {
                return result.slug === 'nc-' + year
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)
                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"

			} else {
				panel.switched = "false"

			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {

				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
