<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				Challenge Cup
			</div>
            <div class="frontend-content-wrapper" >
				<div class="section-spacing">
                    <div class="textarea-content-block">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/Challenge Cup Logo.png" width="100%" alt="Challenge Cup">
                            </div>
                            <div class="col-md-8">
                                <!-- <div id="map"></div> -->
                            </div>
                        </div>
                        <hr>
                        <Accordion v-if="grandslam" v-for="slam of grandslam" :key="slam.id">
                            <template v-slot:header>
                                {{  slam.name }}
                            </template>
                            <template v-slot:content>
                                <PageContentBox :content="parseMainContent(slam.content)"></PageContentBox>
                            </template>
                        </Accordion>
            
                    </div>                    
				</div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "Challenge Cup - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Come check out all the information about the new upcoming event, the Challenge Cup!' },
                { property: 'og:title', content: "Challenge Cup - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/challenge-cup' },
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/nationalchampionships.png' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            previous_results: null,
            first_decade: false,
            second_decade: false,
            third_decade: false,
            nationals_hosts: null,
            brampton: false,
            winnipeg: false,
            edmonton: false,
            grandslam: null,
            leduc: false,
            kelowna: false,
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this

        axios.get('/api/pages/challenge-cup').then(results => {
            if(results.data.success === true) {
                vm.grandslam = results.data.challenge
            }
        })

        const brampton = { lat: 43.7249125, lng:-79.8297545 }
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 6,
            center: brampton,
        });

        const marker5 = new google.maps.Marker({
            position: brampton,
            map: map,
        });
   

     
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
        parseMainContent: function(content) {
            return JSON.parse(content)
        },
        grabNationalsHost: function(city) {
            var vm = this
            var tourney = _.find(vm.nationals_hosts, function(result) {
                return result.slug === 'nationals-host-' + city
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
        grabTournamentResults: function(year) {
            var vm = this
            var tourney = _.find(vm.previous_results, function(result) {
                return result.slug === 'nc-' + year
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
