<template>
	<div>
	
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
					<div class="row">
						<div class="col-md-3">
							<div class="page-header-title">
								Manage Region Rates 
							</div>
						</div>
						<div class="col-md-9">
							<div class="padding">
							
							</div>
						</div>
					</div>
				</div>
				
				<div class="admin-area">
                    <b-field label="Select Region">
                        <b-select placeholder="Select Region" v-model="regionSelected">
                            <option :value="null">None</option>
                            <option :value="1">Alberta</option>
                            <option :value="2">British Columbia</option>
                            <option :value="3">Manitoba/Nunavut/Thunder Bay</option>
                            <option :value="4">Ontario</option>
                            <option :value="5">Maritimes</option>
                            <option :value="6">Quebec</option>
                            <option :value="7">Newfoundland Labrador</option>
                            <option :value="8">Saskatchewan</option>
                        </b-select>
                    </b-field>
                    <div v-if="regionSelected !== null">
                    
                        <b-field label="League Application Fee">
                            <b-input v-model="selectedRates.league_application_fee"></b-input>
                        </b-field>
                        <b-field label="Non Qualifier Tournament Insurance Fee">
                            <b-input v-model="selectedRates.non_qualifier_tournament_insurance_fee" ></b-input>
                        </b-field>
                        <b-field label="Qualifier Tournament Insurance Fee">
                            <b-input v-model="selectedRates.qualifier_tournament_insurance_fee"></b-input>
                        </b-field>
                        <b-field label="Fully Insured League Team">
                            <b-input v-model="selectedRates.fully_insured_league_team"></b-input>
                        </b-field>
                        <b-field label="Uninsured League Team">
                            <b-input v-model="selectedRates.un_insured_league_team"></b-input>
                        </b-field>
                        <b-field label="Fully Insured Tournament Team">
                            <b-input v-model="selectedRates.fully_insured_tournament_team"></b-input>
                        </b-field>
                        <b-field label="Uninsured Insured Tournament Team">
                            <b-input v-model="selectedRates.un_insured_tournament_team"></b-input>
                        </b-field>
                        <b-field label="Single Use Tournament Team">
                            <b-input v-model="selectedRates.single_use_tournament_team"></b-input>
                        </b-field>
                        <b-field label="Umpire Registration">
                            <b-input v-model="selectedRates.umpire_registration"></b-input>
                        </b-field>
                        <b-field label="Upgrade to Fully Insured Tournament Team">
                            <b-input v-model="selectedRates.upgrade_to_fully_insured_tournament_team"></b-input>
                        </b-field>
                        <b-field label="Upgrade to Uninsured Tournament Team">
                            <b-input v-model="selectedRates.upgrade_to_un_insured_tournament_team"></b-input>
                        </b-field>
                        <b-field label="League Tournament">
                            <b-input v-model="selectedRates.league_tournament"></b-input>
                        </b-field>
                    
                        <br>
                        <b-button
                            type="is-primary"
                            @click.prevent="saveRates()">
                            Save Rates
                        </b-button>
                    </div>
                </div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
export default {
	data() {
		return {
            content_width: false,
			rates: [],
            regionSelected: null,
			selectedRates: null
		};
	},
	components: {
		Header,
		Footer,
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

		if(this.$route.query.id) {
			this.searchables.id = this.$route.query.id
		}

		if(this.$route.params.slug === 'exam') {
			vm.tabs = 'exam'	
		}

		if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user
				vm.user_permissions = results.data.user_permissions
                if(results.data.user_permissions.manage_umpires !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }
				// vm.searchables.specific_region = vm.user_permissions.specific_region_only
                vm.isLoading = false
                vm.getRegions()
			}
        })
	},
    computed: {
        filteredRates() {
            return this.rates.filter(rate => rate.region_id === this.regionSelected);
        }
	},
    watch: {
        regionSelected(newVal) {
            this.selectedRates = this.rates.find(rate => rate.region_id === newVal);
        }
    },
	methods: {
        getRegions() {
            axios.get('/api/superuser/rates').then(results => {
                if(results.data.success === true) {
                    this.rates = results.data.rates;
                }
            });
        },

		moment:function(date, format = null) {
			return moment(date, format)
		},
        saveRates: function() {
            var vm = this
            axios.post('/api/superuser/save-rates', { rates: vm.selectedRates }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.toast.open({
                        message: 'Rates saved successfully',
                        type: 'is-success'
                    });
                }
            });
        }
	
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>