<template>
	<div>
		<Header @update-lang="updateLanguage($event)" v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Convenor Central
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding" v-if="user">
								<b-field  grouped position="is-right" v-if="user.default_region_id !== 4">
									
									<span v-if="page === 'my-tournaments' || page === 'my-leagues'">
										<a href="/dashboard/convenors/create-league"  class="button">{{ en === true ? 'Create New League' : 'Créer une nouvelle ligue' }}</a>
										<a href="/dashboard/convenors/create-tournament"  class="button">{{ en === true ? 'Create New Tournament' : 'Créer un nouveau tournoi' }}</a>
									</span>
									<span v-else-if="page === 'become-a-convenor'"></span>
									<span v-else>
										<a href="/dashboard/convenors/create-tournament"  class="button">Back to Convenor/League Central</a>
									</span>
									
								</b-field>
                            </div>
                        </div>
                    </div>
					
                </div>
				<div v-if="page === 'become-a-convenor'">
					<div style="max-width: 600px; width: 100%;" v-if="applicationComplete === false">
						<h1>Become a Convenor</h1>
						<p style="font-size: 20px;"><b>Are you the kind of person who:</b></p>
						<p><b>- Loves organizing big events</b></p>
						<p><b>- Has a large network of friends and softball players?</b></p>
						<p><b>- Can be used as a team fundraiser or create additional income?</b></p>

						<h3>Becoming an tournament convenor may be right for you!</h3>

						<h5>We are always looking to expand and grow our team.</h5>

						<p> With us, your recieve access to the largest network of softballers in your area, all within your reach as well as a National brand to gain traction and the competitive nature needed to fill your events!</p>
						
						<hr>
						<h3>Fill out the form below to apply:</h3>
						<b-field label="What area are you looking to convene in? (City, Park, Region)">
							<b-input v-model="becomeConvenor.areaToConvene"></b-input>
						</b-field>
						<b-field label="What do you think your best skills are?">
							<b-input v-model="becomeConvenor.bestSkills"></b-input>
						</b-field>
						<b-field label="What is your biggest weakness/what can we help support you with?">
							<b-input v-model="becomeConvenor.biggestWeakness"></b-input>
						</b-field>
						<b-field label="Did anyone refer you to sign up?">
							<b-input v-model="becomeConvenor.referral"></b-input>
						</b-field>

						<a v-on:click="requestToBecomeConvenor()" role="button" class="admin-button">Submit Request to Become Convenor Now!</a>
					</div>
					<div style="max-width: 600px; width: 100%;" v-if="applicationComplete === true">
						<h1>Application Recieved</h1>
						<p>We will reach out to you very soon! In the meantime, please consider reading through the SPN Rulebook to get you prepped!</p>
					</div>
				</div>
				<div v-if="page === 'my-tournaments'">
					<div v-if="user">
						<b-message type="is-danger" has-icon aria-close-label="Close message" v-if="user.default_region_id === 4">
							<b>Ontario Region Convenors will be able to access the new system by the middle of February. You are not able to create leagues or tournaments until then. For more information, please click here: <a href="https://slo-pitch.com/news/united-in-building-a-stronger-game">United in Building a Stronger Game</a></b>
						</b-message>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="padding">
								<MyTournaments :en="en"></MyTournaments>
							</div>
						</div>
					
					</div>
				
				</div>
				<div v-if="page === 'my-leagues'">
					<div v-if="user">
						<b-message type="is-danger" has-icon aria-close-label="Close message" v-if="user.default_region_id === 4">
							<b>Ontario Region Convenors will be able to access the new system by the middle of February. You are not able to create leagues or tournaments until then. For more information, please click here: <a href="https://slo-pitch.com/news/united-in-building-a-stronger-game">United in Building a Stronger Game</a></b>
						</b-message>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="padding">
							<MyLeagues :en="en"></MyLeagues>
							</div>
						</div>
					</div>
			
				</div>
				<div v-if="page === 'poster-generator'">
					<PosterGenerator></PosterGenerator>
				</div>
				<div v-if="page === 'budget-generator'">
					<BudgetGenerator></BudgetGenerator>
				</div>
				<div v-if="page === 'event-checklist-generator'">
					<EventChecklist></EventChecklist>
				</div>
				<div v-if="page === 'round-robin-generator'">
					<RoundRobinGenerator></RoundRobinGenerator>
				</div>
				<div v-if="page === 'playoff-bracket-generator'">
					<PlayoffBracketGenerator></PlayoffBracketGenerator>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MyLeagues from './components/MyLeagues.vue'
import MyTournaments from './components/MyTournaments.vue'
import LeagueTools from './components/LeagueTools.vue'
import BudgetGenerator from './components/BudgetGenerator.vue'
import PosterGenerator from './components/PosterGenerator.vue'
import EventChecklist from './components/EventChecklist.vue'
import PlayoffBracketGenerator from './components/PlayoffBracketGenerator.vue'
import RoundRobinGenerator from './components/RoundRobinGenerator.vue'
import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'

import axios from 'axios'

export default {
	data() {
		return {
			page: null,
			becomeConvenor: {
				areaToConvene: null,
				bestSkills: null,
				biggestWeakness: null,
				referral: null,
			},
			en: true,
			activeTab: 0,
			convenor_permissions: null,
			content_width: false,
			applicationComplete: false,
			user: null
		};
	},
	components: {
		Header,
		Footer,
		MyLeagues,
		BudgetGenerator,
		PosterGenerator,
		EventChecklist,
		PlayoffBracketGenerator,
		RoundRobinGenerator,
		LeagueTools,
		MyTournaments
		
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page

		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

		axios.get('/api/tournaments/check-convenor-application').then(results=> {
			if(results.data.success) {
				if(results.data.application) {
					vm.applicationComplete = true
				}
			}
		})

		axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                vm.user = results.data.user
                
                if(results.data.convenor_permissions) {
                    vm.convenor_permissions = results.data.convenor_permissions
                }
            }
		})
	},
	computed: {
	
	},
	methods: {
		updateLanguage: function($event) {
			this.en = $event	
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		requestToBecomeConvenor: function() {
			var vm = this

			axios.post('/api/users/become-a-convenor', {
				becomeConvenor: vm.becomeConvenor
			}).then(results => {
				if(results.data.success === true) {
					// complete
					vm.applicationComplete = true
				}
			})
		}
	}
}
</script>

<style lang="scss">
    
</style>
