<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Manage Form Results
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="padding" v-if="!selectedForm">
                    
                                <b-table
                                    :data="forms"
                                    ref="table"
                                    aria-next-label="Next page"
                                    aria-previous-label="Previous page"
                                    aria-page-label="Page"
                                    aria-current-label="Current page">

                                    <b-table-column field="name" label="Form Name" sortable centered v-slot="props">
                                        {{ props.row.name }}
                                    </b-table-column>
                                    <b-table-column field="name" label="French Name" sortable centered v-slot="props">
                                        {{ props.row.fr_name }}
                                    </b-table-column>
                                    <b-table-column field="name" label="Region" sortable centered v-slot="props">
                                        <span v-if="props.row.region_id === '-1'">All</span>
                                        <span v-if="props.row.region_id === '1'">Alberta</span>
                                        <span v-if="props.row.region_id === '2'">British Columbia</span>
                                        <span v-if="props.row.region_id === '3'">Manitoba</span>
                                        <span v-if="props.row.region_id === '4'">Ontario</span>
                                        <span v-if="props.row.region_id === '5'">Maritimes</span>
                                        <span v-if="props.row.region_id === '6'">Quebec</span>
                                        <span v-if="props.row.region_id === '7'">Newfoundland Labrador</span>
                                        <span v-if="props.row.region_id === '8'">Saskatchewan</span>
                                    </b-table-column>
                                    <b-table-column field="edit" label="Edit" sortable centered v-slot="props">
                                        <a class="button is-small" v-on:click="viewFormResults(props.row)">View Form Results</a>
                                    </b-table-column>
                                

                                </b-table>
                            </div>
                            <div class="padding" v-if="selectedForm">
                                <a v-on:click="selectedForm = null" class="button">Back to All Forms</a>
                                <vue-excel-xlsx
                                    :data="computedResponses"
                                    :columns="computedExcelColumns"
                                    :filename="'filename'"
                                    :sheetname="'sheetname'"
                                    :class="'button is-success'"
                                    >
                                    <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
                                </vue-excel-xlsx>
                                
                                <h3 style="margin-top: 30px;">Submitted Forms for {{ selectedForm.name }}</h3>
                                <div>
                                    <b-table
                                        :data="computedFormResults"
                                        ref="table"
                                        paginated
                                        per-page="100"
                                        detailed
                                        detail-key="id"
                                        aria-next-label="Next page"
                                        aria-previous-label="Previous page"
                                        aria-page-label="Page"
                                        aria-current-label="Current page">
                                        <b-table-column field="created_at" label="Submitted On" sortable centered v-slot="props">
                                            {{ moment(props.row.created_at).format('YYYY-MM-DD h:mma') }}
                                        </b-table-column>
                                        <b-table-column field="region_id" label="Region" sortable centered v-slot="props">
                                            
                                            {{ props.row.region.short_name }}
                                            
                                        </b-table-column>
                                        <b-table-column field="first_name" label="First Name" sortable centered v-slot="props">
                                            
                                            {{ props.row.first_name || props.row.user.first_name }}
                                            
                                        </b-table-column>
                                        <b-table-column field="last_name" label="Last Name" sortable centered v-slot="props">
                                            {{ props.row.last_name || props.row.user.last_name }}
                                        </b-table-column>
                                        <b-table-column field="email" label="Email" sortable centered v-slot="props">
                                            {{ props.row.email || props.row.user.email  }}
                                        </b-table-column>
                                        <b-table-column field="email" label="Email" sortable centered v-slot="props">
                                            <a :href="`/superadmin/manage/form-results/${props.row.id}`" class="button">View Form</a>
                                        </b-table-column>
                                        <template slot="detail" slot-scope="props">
                                            <b-field :label="answer.question.label" v-for="answer in props.row.answers" :key="answer.id">
                                                <p>{{ answer.question.description }}</p>
                                                <span v-if="answer.question.type_id === 'checkbox'">
                                                    <span v-if="answer.value === 1">Checked</span>
                                                </span>
                                                <span v-else-if="answer.question.type_id === 'true_or_false'">
                                                    <span v-if="answer.value === 1">True</span>
                                                    <span v-else>False</span>
                                                </span>
                                                <span v-else>
                                                    {{ answer.value }}
                                                </span>
                                            </b-field>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			
		</div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
export default {
	data() {
		return {
            isLoading: true,
            createNewFormSwitch: false,
            authUser: { first_name: null },
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            waivers: [],
            excelColumns: [
                {
                    label: 'Submitted On',
                    field: 'created_at'
                },
                {
                    label: 'Region',
                    field: 'region.short_name'
                },
                {
                    label: 'First Name',
                    field: 'first_name'
                },
                {
                    label: 'Last Name',
                    field: 'last_name'
                },
                {
                    label: 'Email',
                    field: 'email'
                },
                
            ],
            questionTypes: [
                {
                    value: 'short_text',
                    name: 'Short Text'
                },
                {
                    value: 'long_text',
                    name: 'Long Text'
                },
                {
                    value: 'true_or_false',
                    name: 'True or False'
                },
                {
                    value: 'select',
                    name: 'Select'
                },
                {
                    value: 'checkbox',
                    name: 'Checkbox'
                },
                {
                    value: 'date',
                    name: 'Date'
                },
                {
                    value: 'time',
                    name: 'Time'
                },
                {
                    value: 'first_name',
                    name: 'First Name'
                },
                {
                    value: 'last_name',
                    name: 'Last Name'
                },
                {
                    value: 'email',
                    name: 'Email'
                },
                {
                    value: 'hidden',
                    name: 'Hidden'
                },
                
            ],

            form: {
                name: null,
                fr_name: null,
                collection: 'form_collection',
                region: '-1'
            },
            form_questions: [
                {
                    type: 'section_break', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Section 1 - ',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                },
                {
                    type: 'short_text', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Label',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                }
            ],
            confirmSaveWaiver: false,
            content_width: false,
            forms: [],
            submitted_forms: [],
            selectedForm: null,
            editFormSwitch: null,
            user_perms: null
		};
	},
	components: {
		Header,
		Footer,
    },
    computed: {
        computedWaiverSubmission: function() {
            
        },
        computedFormResults: function() {
            var vm = this
            
            if(vm.selectedForm) {
                var formResults = vm.selectedForm.responses
                if(vm.user_perms) {
                    if(vm.user_perms.specific_region_only) {
                        formResults = _.filter(formResults, function(result) {
                            return result.region_id === vm.user_perms.specific_region_only
                        })
                    }
                }

                return formResults
            }

            return []
        },
        computedExcelColumns: function() {
            var vm = this
            var columns = vm.excelColumns

            if(vm.selectedForm) {

                var i = 0

                var answersColumns = []
                
                for(i; i < vm.selectedForm.questions.length; i++) {
                    var question = vm.selectedForm.questions[i]
                    columns.push({
                        label: question.label,
                        field: 'question' + question.id
                    })
                }
                
                return columns
            }
            return []
        },
        computedResponses: function() {
            var vm = this

            var array = []

            if(vm.selectedForm) {
                var responses = vm.selectedForm.responses

                var i = 0

                for(i; i < responses.length; i++) {
                    var response = responses[i]

                    var s = 0

                    for(s; s < response.answers.length; s++) {
                        var answer = response.answers[s]
                        
                        responses[i]['question' + answer.form_question_id] = answer.value
                    }
                }
                return responses
            }
            
            return []
        }
    },
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.user_perms = results.data.user_permissions
                if(results.data.user_permissions.manage_form_results !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                
			
                axios.get('/api/superuser/forms').then(results => {
                    
                    if(results.data.success === true) {
                        vm.forms = results.data.forms
                        vm.isLoading = false
                    }
                })
            }
        })
	},
	methods: {
        removeOption: function(question, key) {
            
            question.options.splice(key, 1)
        },
        removeEditRow: function(index) {
            this.selectedForm.questions.splice(index, 1);
        },
        removeRow: function(index) {
            this.form_questions.splice(index, 1);
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addFormQuestion: function() {
			this.form_questions.push({
                type: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addSectionBreak: function() {
            this.form_questions.push({
                type: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddFormQuestion: function() {
            this.selectedForm.questions.push({
                type_id: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddSectionBreak: function() {
            
            this.selectedForm.questions.push({
                type_id: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addFormOption: function(question) {
            question.options.push({
                name: '',
                fr_name: ''
            })
        },
        editForm: function(form) {
            var vm = this
            vm.selectedForm = form
            vm.editFormSwitch = true
        },
        saveForm: function() {
            var vm = this
            axios.post('/api/superuser/save-new-form', {
                form: vm.form,
                form_questions: vm.form_questions
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.createNewFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        updateForm: function() {
            var vm = this
            axios.post('/api/superuser/update-form', {
                selected_form: vm.selectedForm,
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.editFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        viewFormResults: function(selectedForm) {
            var vm = this

            vm.selectedForm = selectedForm
        },
        moment: function(date, format = null) {
            return moment(date, format)
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
