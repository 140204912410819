export default class Scoring {

    roster = null
    cutoff = 2

    classes = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5
    }

    constructor(roster, isUser = false) {
        this.roster = roster
        this.isUser = isUser
    }

    findFirstScore(tally) {
        for(const [letter, count] of Object.entries(tally)) {
            if(count === 0) {
                continue
            }

            return letter
        }
    }

    buildTally() {
        const tally = {}

        for(const letter of Object.keys(this.classes)) {
            tally[letter] = 0
        }

        for(const player of this.roster) {
            const user = this.isUser ? player : player.user
            tally[user.current_rank_id]++
        }

        for(const letter of Object.keys(this.classes)) {
            if(!tally[letter]) {
                delete tally[letter]
            }
        }

        return tally
    }

    getNextLetter(letter) {
        const entries = Object.entries(this.classes)
        const index = entries.findIndex(item => letter === item[0])

        return entries[index + 1][0]
    }

    processEntry(entry) {
        if(!entry) {
            return null
        }

        const [ letter, count ] = entry

        return {
            letter,
            count,
            score: this.classes[letter]
        }
    }

    getHighestGraded(tally) {
        const entries = Object.entries(tally)


        return [ this.processEntry(entries[0]), this.processEntry(entries[1]) ]
    }

    score() {
        const tally = this.buildTally()
        let rosterScore = this.findFirstScore(tally)

        if(tally[rosterScore] <= this.cutoff) {
            for(const [letter, count] of Object.entries(tally)) {
                if(rosterScore === letter) {
                    continue
                }

                if(count > 2) {
                    rosterScore = letter
                }
            }

            const [ first, second ] = this.getHighestGraded(tally)

            if(first && second) {
                if((second.score - first.score) >= this.cutoff) {
                    rosterScore = this.getNextLetter(first.letter)
                }
            }
        }

        return rosterScore
    }

}
