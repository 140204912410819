<template>
	<div class="login-bg">
		<Header></Header>
		<div class="login-area">
            
            <div class="login-area-wrapper">
                <div style="background: #fff; padding: 20px;">
                    <h1 style="text-align: center; font-size: 30px; font-weight: 700; color: #da0000;">Your account is now confirmed.</h1>
                    <p style="text-align: center;"><b>Please login at the button below:</b></p>
                    <a href="/login" class="admin-button">Login</a>
                  
                </div>
                
            </div>
        </div>
		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/SecondaryHeader.vue'
import Footer from './components/SecondaryFooter.vue'
import LoginRegister from '../components/LoginRegister.vue'
import axios from 'axios'

export default {
	data() {
		return {
            email: null,
            phone: null,
            username: null,
            password: null,
            error: null,
            slug: null,
            roster_id: null,
            type: null,
		};
	},
	components: {
		Header,
        Footer,
        LoginRegister
	},
	mounted: function() {
        var vm = this

        this.slug = this.$route.query.slug;
        this.roster_id = this.$route.query.roster_id;
        this.type = this.$route.query.type;
        
        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                this.$buefy.toast.open({
                    message: 'You are already logged in!',
                    type: 'is-success', // is-danger
                    duration: 1000,
                    queue: false
                })
                this.$router.push({ 
                    path: '/dashboard', 
                    query: {
                        slug: vm.slug,
                        roster_id: vm.roster_id,
                        type: vm.type
                    } 
                })
                
            }
        })
	},
	computed: {
	
	},
	methods: {
        loginAttempt: function() {
            // send username and password via axios to auth
            // if successful, send back just the email address - maybe a few other fields

            var vm = this
            axios.get('https://api.ipify.org/?format=json').then(ip => {
                axios.post('/auth/attempt', {
                    email: vm.email,
                    phone: vm.phone,
                    username: vm.username,
                    password: vm.password,
                    ip: ip.data.ip
                }).then(results => {
                    // spit back results
                    
                    vm.error = null
                    if(results.data.type === "success") {
                        // redirect the user after saving everything to localstorage

                        this.$nextTick(function() {
                            this.$buefy.snackbar.open({
                                message: 'Successfully logged in.',
                                type: 'is-success' // is-danger
                            })
                            this.$router.push({ 
                                path: '/dashboard', 
                                query: {
                                    slug: vm.slug,
                                    roster_id: vm.roster_id,
                                    type: vm.type
                                } 
                            })
                            
                        })
                        
                    } else {
                        // display error
                        vm.error = results.data.error

                        this.$buefy.snackbar.open({
                            message: vm.error,
                            type: 'is-danger' // is-danger
                        })
                    }
                    
                })
            })
            
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
