<template>
	<div>
		<div >
            <div class="content-wrapper" v-if="tournament" style="background: #fff;">
				<div :class="['page-header']" >
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title" style="font-size: 18px;" v-if="tournament">
                                Event Rosters For {{ tournament.name }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area printable" style="padding-left: 40px; padding-right: 40px;">
                    <div class="one-roster" :key="team.id" v-for="team in registered_teams">
                        <div class="row">
                            <div class="col-md-2">
                                <h1 :style="'margin-top: 0px; font-size: 24px; font-weight: 700; color: ' + team.team.color_1">{{ team.team.name }}</h1>    

                                
                                <br>
                                <p><b>Home City: </b>{{ team.team.city }}</p>
                                <br>
                                <p><b>Tournament Entered: </b> {{ team.tournament.name }}</p>
                                <p v-if="team.division"><b>Division Entered: </b> {{ team.division.gender_slug.charAt(0).toUpperCase() + team.division.gender_slug.slice(1) }} {{ team.division.rank_id }}</p>
                                <hr>
                            </div>
                            <div class="col-md-10">
                                <b-table
                                    :narrowed="true"
                                    style="margin-top: 10px;"
                                    :data="team.roster.roster_players"
                                    :striped="true"
                                >
                                    <b-table-column
                                        field="waiver_signed"
                                        label="Waiver"
                                    >
                                        <template slot="header" slot-scope="{ column }">
                                            <b-tooltip :label="column.label" dashed>
                                                {{ column.label }}
                                            </b-tooltip>
                                        </template>
                                        <template v-slot="props">
                                             <span v-if="checkWaivers(props.row.user.waivers) === true">
												<b-tag type="is-success">
													<i class="fas fa-check"></i>
												</b-tag>
											</span>
											<span v-if="checkWaivers(props.row.user.waivers) === false">
												<b-tag type="is-danger">
													<i class="fas fa-times"></i>
												</b-tag>
											</span>
                                        </template>
                                    </b-table-column>
                                     <b-table-column
                                        field="user.current_rank_id"
                                        label="Level"
                                    >
                                        <template v-slot="props">
                                            {{ props.row.user.current_rank_id }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.current_rank_id"
                                        label="Role"
                                    >
                                        <template v-slot="props">
                                            
                                            <span class="tag is-success" v-if="props.row.core_roster_player.role_on_team === 1">
                                                {{ team.team.primary_coach_id === props.row.user_id ? "Primary" : "" }}
                                                Coach
                                            </span>
                                            <span class="tag is-warning" v-if="props.row.core_roster_player.role_on_team === 2">
                                                Team Contact
                                            </span>
                                            <span class="tag is-danger" v-if="props.row.core_roster_player.role_on_team === 3">
                                                Player
                                            </span>
                                            <span class="tag is-info" v-if="props.row.core_roster_player.coach_only === 1">
                                                Coach Only
                                            </span>
                                        </template>
                                    </b-table-column>
                                    
                                    <b-table-column field="user.gender" label="Gender">
                                        <template v-slot="props">
                                            <span class="tag is-light">
                                                <b>{{ props.row.user.gender }}</b>
                                                <p>{{ props.row.team }}</p>
                                            </span>
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.first_name"
                                        label="First Name"
                                    >
            
                                        <template v-slot="props">
                                            {{ props.row.user.first_name }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.last_name"
                                        label="Last Name"
                                    >
                                        <template v-slot="props">
                                            <b>{{ props.row.user.last_name }}</b>
                                        </template>
                                    </b-table-column>
                                   
                                    <b-table-column field="user.phone" label="Phone">
                                        <template v-slot="props">
                                            
                                            <b>{{ props.row.user.phone }}</b>
                                            
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="user.email" label="Email">
                                        <template v-slot="props">
                                            
                                            <b>{{ props.row.user.email }}</b>
                                            
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.street_address"
                                        label="Street Address"
                                    >
                                        <template v-slot="props">
                                            {{ props.row.user.street_address }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.city"
                                        label="City"
                                    >
                                        <template v-slot="props">
                                            {{ props.row.user.city }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="user.postal_code" label="Postal Code">
                                        <template v-slot="props">
                                            {{ props.row.user.postal_code }}
                                        </template>
                                    </b-table-column>
                                    
                                    <b-table-column field="user.date_of_birth" label="Date of Birth">
                                
                                        <template v-slot="props">
                                            <span class="tag is-light">
                                                <b>{{ moment(props.row.user.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                            </span>
                                        </template>
                                    </b-table-column>
                                    
                                </b-table>
                                

                            </div>
                        </div>
                        <hr>
                    </div>
                    
                </div>
				
            </div>
            
		</div>
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'

import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			page: null,
			addPlayerModal: false,
			tournament: null,
			previous: null,
			upcoming: null,
			searchForTeam: null,
			addTeamToEventSwitch: false,
			umpSearch: null,
			registered_teams: [],
			content_width: false,
			mountCheckboxes: null,
			searchList: [],
			canvasAdditionalDetailsLeft: null,
			savedCheatSheet: false,
			umpireFound: null,
			addedToCheatSheet: null,
			searchResults: null,
			selectedTeam: null,
			selectRegion: false,
			eventTeam: null,
			playersSelected: [],
			registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
			cheatSheet: {
				innings: 7,
				call_last_inning_after: 75,
				minimum_players: 10,
				hr_rule: '1',
				anticipation: true,
				ties: true,
				courtesy_runners: true,
				max_runs_per_inning: 6,
				mercy_rule: true,
				tag_at_home: true,
				throw_to_first_from_outfield: true,
				additional_rules: null
			}
		};
	},
	components: {
		Header,
		Footer,
		
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page
		var slug = this.$route.params.slug
		

		axios.post('/api/tournaments/review-checklist').then(results => {
			if(results.data.success === true) {
				vm.mountCheckboxes = results.data.checklist
			}
		})

		axios.get(`/api/tournaments/get-tournament/${slug}`).then(results => {
			if(results.data.success === true) {				
				vm.tournament = results.data.tournament
			}
        })
        
        axios.get(`/api/tournaments/get-registered-teams-with-rosters/${slug}`).then(results => {
			if(results.data.success === true) {
				vm.registered_teams = results.data.registrants
			}
		})
	
	},
	computed: {
		// spnRegistration: function() {
        //     // grab the registrations of insurance from the team
        //     // cross reference the division
        //     var vm = this

        //     var team = vm.selectedTeam

        //     var selected_gender = vm.registerForTournament.gender

            
        //     if(team) {
                
        //         var insurance = team.insurance
                
        //         var find_insurance = _.find(insurance, function(paper) {
        //             if(paper.gender === selected_gender) {
        //                 return true
        //             }
        //         })
        //         // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
        //         // if a single use one is found, it must be upgraded
        //         // when updated it will remove single use from the row

        //         if(find_insurance) {

        //             vm.currentInsurance = find_insurance
        //             vm.triggerFinalize = true
        //             return true
        //         }
        //         vm.triggerFinalize = false
        //         return false
        //     } else {
        //         vm.triggerFinalize = false
        //         return false
        //     }
        // },
		// totalDivisions: function() {
        //     var vm = this
        //     var mens = vm.filterDivision(vm.tournament.divisions, 'mens')
        //     var ladies = vm.filterDivision(vm.tournament.divisions, 'ladies')
        //     var coed = vm.filterDivision(vm.tournament.divisions, 'coed')

        //     var count = 0
        //     if(mens.length > 0) {
        //         count = count + 1
        //     } 
        //     if(ladies.length > 0) {
        //         count = count + 1
        //     }
        //     if(coed.length > 0) {
        //         count = count + 1
        //     }

        //     return {
        //         mens: mens,
        //         ladies: ladies,
        //         coed: coed,
        //         count: count
        //     }
        // },
		// filterGroup: function() {
		// 	var vm = this
		// 	var grouped = _.groupBy(vm.mountCheckboxes, 'group')

		// 	return grouped
		// }
	},
	methods: {
		filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
		switchWidth: function($event) {
			this.content_width = !$event
		},
		
		moment: function(date, format = null) {
			return moment(date, format)
		},
		
        checkWaivers: function(row) {
			
			var filter = _.filter(row, function(waiver) {
				if(waiver.waiver_id === 18 ) { return true }
			})

			return filter.length > 0
		}
	}
}
</script>

<style lang="scss">
    
</style>
