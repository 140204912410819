<template>
	<div class="login-bg">
		<Header></Header>
		<div class="login-area">
            
            <div class="login-area-wrapper" v-if="passwordReset === false">
                <div style="background: #fff; padding: 20px;">
                    <p>You are here because you've clicked your reset password link. Now, confirm your final details to update your password.</p>
                    <br><br>
                    <b-field label="Confirm Your Email Address">
                        <b-input v-model="email"></b-input>
                    </b-field>
                    <b-field label="Enter New Password">
                        <b-input type="password" v-model="password"></b-input>
                    </b-field>
                    <b-field label="Repeat New Password">
                        <b-input type="password" v-model="confirmPassword"></b-input>
                    </b-field>

                    <a class="admin-button" style="margin: 0 auto; display: inline-block;" v-on:click="completeResetPassword()">Reset My Password & Go To Login</a>
                    <div style="margin-top: 30px;">
                        <p class="note" :key="key" v-for="(errorMessage, key) in errors">
                            {{ errorMessage }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="login-area-wrapper" v-if="passwordReset === true">
                <div style="background: #fff; padding: 20px;">
                    <div class="payment-complete">
                        <div class="icon-row">
                            <i class="far fa-check-circle"></i>
                        </div>
                        <div class="complete-text">
                            <p><b>Password has been reset succesfully.</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/SecondaryHeader.vue'
import Footer from './components/SecondaryFooter.vue'
import LoginRegister from '../components/LoginRegister.vue'
import axios from 'axios'

export default {
	data() {
		return {
            email: null,
            phone: null,
            username: null,
            password: null,
            confirmPassword: null,
            error: null,
            token: null,
            errors: [],
            passwordReset: false,
		};
	},
	components: {
		Header,
        Footer,
        LoginRegister
	},
	mounted: function() {
        var vm = this

        vm.token = this.$route.params.id
        
        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                this.$buefy.toast.open({
                    message: 'You are already logged in!',
                    type: 'is-success', // is-danger
                    duration: 1000,
                    queue: false
                })
                this.$router.push({ path: '/dashboard' })
                
            }
        })

        axios.get('/auth/check-valid-token/' + vm.token).then(results=> {
            if(results.data.success === true) {

            } else {
                this.$router.push({ path: '/login' })
                this.$buefy.snackbar.open({
                    message: 'This is not a valid reset token.',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })
            }
        })

        // check their account 

        // check that it's less than 24 hours
	},
	computed: {
	
	},
	methods: {
        completeResetPassword: function() {
            var vm = this
            vm.errors = []
            if(vm.email === null || vm.email === '') {
                vm.errors.push(
                    'Email needs to be entered'
                )
            }
            if(vm.password === null || vm.password === '') {
                vm.errors.push(
                    'Password needs to be entered'
                )
            }
            if(vm.confirmPassword === null || vm.confirmPassword === '') {
                vm.errors.push(
                    'Confirm password needs to be entered'
                )
            }
            if(vm.password !== vm.confirmPassword) {
                vm.errors.push(
                    'Passwords do not match'
                )
            }

            if(vm.errors.length === 0) {
                axios.post('/auth/finalize-reset', {
                    token: vm.token,
                    email: vm.email,
                    password: vm.password
                }).then(results => {
                    if(results.data.success === true) {
                        this.$buefy.snackbar.open({
                            message: 'Your password was successfully reset. You will be redirected to the login screen.',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })

                        vm.passwordReset = true

                        this.$router.push({ path: '/login' }).catch(err => {})   
                    }
                    if(results.data.success === false) {
                        this.$buefy.snackbar.open({
                            message: results.data.reason,
                            type: 'is-danger', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    }
                })
            }
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
