<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<h1>{{ en === true ? 'Your Account Information' : `Les informations de votre compte` }}
                    </h1>
                <div class="row">
                    <div class="col-md-6">
                        <h3>
                            {{ en === true ? 'User Information' : `Informations de l'utilisateur` }}
                            <a class="edit" v-on:click="editUserInfo = !editUserInfo">{{ en === true ? 'Edit This Info' : `Modifier cette information` }}</a>
                        </h3>
                        
                        <b-message type="is-danger" has-icon aria-close-label="Close message">
                            <b>{{ en === true ? 'Note: falsified data may result in forfeits or suspensions in provincial and national events.' : `Remarque: des données falsifiées peuvent entraîner des forfaits ou des suspensions lors d'événements provinciaux et nationaux.` }}</b>
                        </b-message>
                        <table class="vertical-table" v-if="authUser">
                            <tr>
                                <td class="table-title">
                                    ID
                                </td>
                                <td class="response">
                                    <span>
                                        {{ authUser.id }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    First Name
                                </td>
                                <td class="response">
                                    <b-field v-if="editUserInfo">
                                        <b-input v-model="editableUser.first_name"></b-input>
                                    </b-field>
                                    <span v-if="!editUserInfo">
                                        {{ authUser.first_name }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Last Name
                                </td>
                                <td class="response">
                                    <b-field v-if="editUserInfo">
                                        <b-input v-model="editableUser.last_name"></b-input>
                                    </b-field>
                                    <span v-if="!editUserInfo">
                                        {{ authUser.last_name }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    SPN Player #
                                </td>
                                <td class="response">
                                    <span>
                                        {{ authUser.spn_number }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Phone
                                </td>
                                <td class="response">
                                    <b-field v-if="editUserInfo">
                                        <b-input v-model="editableUser.phone"></b-input>
                                    </b-field>
                                    <span v-if="!editUserInfo">
                                        {{ authUser.phone }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Gender
                                </td>
                                <td class="response">
                                   
                                   
                                    {{ authUser.gender }}
                                   
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Date of Birth
                                </td>
                                <td class="response">
                                    <span v-if="editUserInfo">Please contact us to update your date of birth.</span>
                                    <span v-if="!editUserInfo">
                                        {{ moment(authUser.date_of_birth).utc().format('YYYY-MM-DD') }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Ranking
                                </td>
                                <td class="response">
                                    <span v-if="editUserInfo">
                                        <span v-if="authUser.default_region_id === 2 || authUser.default_region_id === 3 || authUser.default_region_id === 8">
                                            <b-field>
                                                <b-select placeholder="Select Ranking" v-model="editableUser.current_rank_id">
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                    <option value="E">E</option>
                                                </b-select>
                                            </b-field>
                                        </span>
                                        <span v-else>
                                            {{ authUser.current_rank_id }}
                                        </span>
                                    </span>
                                    <span v-else>
                                        {{ authUser.current_rank_id }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Positions
                                </td>
                                <td class="response">
                                    <span v-if="editUserInfo">
                                        <b-dropdown
                                            position="is-top-right"
                                            v-model="editableUser.positions"
                                            multiple
                                            aria-role="list">
                                            <button class="button is-secondary" type="button" slot="trigger">
                                                <span>Selected ({{ editableUser.positions.length }})</span>
                                                <b-icon icon="menu-down"></b-icon>
                                            </button>

                                            <b-dropdown-item value="P" aria-role="listitem">
                                                <span>Pitcher</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="C" aria-role="listitem">
                                                <span>Catcher</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="1B" aria-role="listitem">
                                                <span>First Base</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="2B" aria-role="listitem">
                                                <span>Second Base</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="SS" aria-role="listitem">
                                                <span>Shortstop</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="3B" aria-role="listitem">
                                                <span>Third Base</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="LF" aria-role="listitem">
                                                <span>Left Field</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="LCF" aria-role="listitem">
                                                <span>Left Center Field</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="RCF" aria-role="listitem">
                                                <span>Right Center Field</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="RF" aria-role="listitem">
                                                <span>Right Field</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item value="Ro" aria-role="listitem">
                                                <span>Rover</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                    <span v-if="!editUserInfo">
                                        <b-tag :key="position" v-for="position in JSON.parse(authUser.positions)">
                                            <b>{{ position }}</b>
                                        </b-tag>
                                    </span>
                                </td>
                            </tr>
                        </table>
                        <a v-if="editUserInfo" role="button"  v-on:click="saveUserInfo()" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i> Save User Information</a>
                        <h3>
                            {{ en === true ? 'Emergency Information' : `Information d'urgence` }}
                            <a class="edit" v-on:click="editEmergencyInfo = !editEmergencyInfo">{{ en === true ? 'Edit This Info' : `Modifier cette information` }}</a>
                        </h3>
                        <table class="vertical-table" v-if="authUser">
                            <tr>
                                <td class="table-title">
                                    Name
                                </td>
                                <td class="response">
                                    <b-field v-if="editEmergencyInfo">
                                        <b-input v-model="editableUser.emergency_contact"></b-input>
                                    </b-field>
                                    <span v-if="!editEmergencyInfo">
                                        {{ authUser.emergency_contact }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Phone Number
                                </td>
                                <td class="response">
                                    <b-field v-if="editEmergencyInfo">
                                        <b-input v-model="editableUser.emergency_contact_phone"></b-input>
                                    </b-field>
                                    <span v-if="!editEmergencyInfo">
                                        {{ authUser.emergency_contact_phone }}
                                    </span>
                                </td>
                            </tr>
                        </table>
                        <a v-if="editEmergencyInfo" role="button" v-on:click="saveEmergencyInfo()" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i> Save Emergency Information</a>
                        <h3>{{ en === true ? 'Account Information' : `Information sur le compte` }} <a class="edit" v-on:click="editAccountInfo = !editAccountInfo">{{ en === true ? 'Edit This Info' : `Modifier cette information` }}</a></h3>
                        <table class="vertical-table" v-if="authUser">
              
                            <tr>
                                <td class="table-title">
                                    Security Question
                                </td>
                                <td class="response">
                                    <b-field v-if="editAccountInfo">
                                        <b-input v-model="editableUser.security_question"></b-input>
                                    </b-field>
                                    <span v-if="!editAccountInfo">
                                        {{ authUser.security_question }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Security Answer
                                </td>
                                <td class="response">
                                    <b-field v-if="editAccountInfo">
                                        <b-input v-model="editableUser.security_answer"></b-input>
                                    </b-field>
                                    <span v-if="!editAccountInfo">
                                        {{ authUser.security_answer }}
                                    </span>
                                </td>
                            </tr>
                        </table>
                        <a v-if="editAccountInfo" role="button" v-on:click="saveAccountInfo()" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i> Save Account Information</a>
                    </div>
                    
                    <div class="col-md-6">
                        <h3>{{ en === true ? 'Address' : `Addresse` }} <a class="edit" v-on:click="editAddressInfo = !editAddressInfo">{{ en === true ? 'Edit This Info' : `Modifier cette information` }}</a></h3>
                        <table class="vertical-table" v-if="authUser">
                            <tr>
                                <td class="table-title">
                                    Address
                                </td>
                                <td class="response">
                                    <b-field v-if="editAddressInfo">
                                        <b-input v-model="editableUser.street_address"></b-input>
                                    </b-field>
                                    <span v-if="!editAddressInfo">
                                        {{ authUser.street_address }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    City
                                </td>
                                <td class="response">
                                    <b-field v-if="editAddressInfo">
                                        <b-input  v-model="editableUser.city"></b-input>
                                    </b-field>
                                    <span v-if="!editAddressInfo">
                                        {{ authUser.city }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Province
                                </td>
                                <td class="response">
                                    <b-field v-if="editAddressInfo">
                                        <b-select  v-model="editableUser.province">
                                            <option :value="province" v-for="province of provinces" :key="province">
                                                {{ province }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <span v-if="!editAddressInfo">
                                        {{ authUser.province }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-title">
                                    Postal Code
                                </td>
                                <td class="response">
                                    <b-field v-if="editAddressInfo">
                                        <b-input v-model="editableUser.postal_code"></b-input>
                                    </b-field>
                                    <span v-if="!editAddressInfo">
                                        {{ authUser.postal_code }}
                                    </span>
                                </td>
                            </tr>
                        </table>
                        <a v-if="editAddressInfo" role="button" v-on:click="saveAddressInfo()" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i> Save Address Information</a>
                        
                        <h3>{{ en === true ? 'Edit Email Address' : `Modifier l'adresse e-mail` }} <a class="edit" v-on:click="editYourEmail = !editYourEmail">{{ en === true ? 'Edit Your Email' : `Modifier cette information` }}</a></h3>
                        <div v-if="editYourEmail">
                            <b-field label="New Email Address">
                                <b-input v-model="newEmailAddress"></b-input>
                            </b-field>
                            <b-field label="Confirm/Repeat New Email Address">
                                <b-input v-model="confirmNewEmailAddress"></b-input>
                            </b-field>
                            <p class="note">On completion of changing your email address, you will be logged out and sent an email to confirm your new address. After that, you can log in again.</p>
                            <a v-on:click="confirmEmailChange()" role="button" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i> Save & Confirm New Email Address</a>
                        </div>
                        
                        
                        <h3>{{ en === true ? 'Update Password' : `Mettre à jour le mot de passe` }} <a class="edit" v-on:click="editYourPassword = !editYourPassword">{{ en === true ? 'Edit Your Password' : `Modifier cette information` }}</a></h3>
                        <div v-if="editYourPassword">
                            <b-field label="Current Password">
                                <b-input v-model="currentPassword" type="password"></b-input>
                            </b-field>
                            <b-field label="New Password">
                                <b-input v-model="newPassword" type="password"></b-input>
                            </b-field>
                            <b-field label="Confirm/Repeat New Password">
                                <b-input v-model="confirmNewPassword" type="password"></b-input>
                            </b-field>
                            <p class="note">On completion of changing your password, you will be logged out and be required to log back in again.</p>
                            <a v-on:click="confirmPasswordChange()" role="button" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i> Save & Confirm New Password</a>
                        </div>

                        <h2>{{ en === true ? 'Outstanding Payments Due' : 'PAIEMENTS IMPAYÉS DUS' }}</h2>

                        <b-message type="is-danger" has-icon aria-close-label="Close message">
                            <span v-if="en === true">
                                <b>EMTs may take a week to clear, and cheques 2 weeks, so be patient! YOU DO NOT HAVE TO RESUBMIT USING THIS AREA IF IT IS AN EMT OR CHEQUE. THIS AREA IS TO CLEAR UP FAILED CREDIT CARD TRANSACTIONS, AND TO LET YOU KNOW THAT WE HAVE NOT RECEIVED THE EMT OR CHEQUE YET.</b>
                            </span>
                            <span v-if="en === false">
                                <b>S’il vous plaît assurez-vous que ceci est toujours réglé! Les remises Inter-ac peuvent prendre jusqu'à une semaine à compléter, et les chèques 2 semaines, alors soyez patient!</b>
                            </span>
                        </b-message>	
            
                        
                        <table class="table">
                            <tr>
                                <td><b>{{ en === true ? 'Type' : 'Type' }}</b></td>
                                <td><b>{{ en === true ? 'Product' : 'Produit' }}</b></td>
                                <td></td>
                                <td>Date Created</td>
                                <td><b>{{ en === true ? 'Amount Due' : 'Montant dû' }}</b></td>
                                
                                <td><b></b></td>
                            </tr>
                            <tr v-for="order in orders" :key="order.id">
                                <td>
                                    <span v-if="order.payment_type === 'card'">Online</span>
                                    <span v-if="order.payment_type === 'emt'">EMT</span>
                                    <span v-if="order.payment_type === 'cheque'">Cheque</span>
                                        &nbsp;
                                </td>
                                <td>
                                    <span v-if="order.order_item">
                                        <span v-if="order.order_item.product">
                                            {{ order.order_item.product.name }}
                                        </span>
                                    </span>&nbsp;
                                </td>
                                <td><span v-if="order.tournament">{{ order.tournament.name }}</span><br><span v-if="order.team">{{ order.team.name }}</span></td>
                                <td>{{ moment(order.created_at).utc().format('YYYY-MM-DD') }}</td>
                                <td>${{ order.sale_total }}&nbsp;</td>
                                
                                <td><a class="button" v-on:click="selectedOrder = order">Make Payment</a></td>
                            </tr>
                        </table>
                        <div v-if="selectedOrder">
                            <NewPaymentProcessor
                                :productId="selectedOrder.order_item.product_id"
                                :regionId="selectedOrder.region_id"
                                :orderId="selectedOrder.id"
                                :quantityOverride="selectedOrder.order_item.qty"
                                v-on:payment:successful="outstandingPaymentMade($event)"
                                >
                            </NewPaymentProcessor>
                        </div>
                    </div>
                </div>  
			</div>
		</div>
        
		
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import axios from 'axios'
// import moment from 'moment'
import NewPaymentProcessor from '../components/NewPaymentProcessor.vue'
import moment from 'moment-timezone';
export default {
	data() {
		return {
            newEmailAddress: null,
            confirmNewEmailAddress: null,
            currentPassword: null,
            newPassword: null,
            confirmNewPassword: null,
            editYourPassword: false,
            editUserInfo: false,
            paymentSuccess: false,
            selectedOrder: null,
            editEmergencyInfo: false,
            orders: [],
            editAccountInfo: false,
            editYourEmail: false,
            editAddressInfo: false,
            authUser: null,
            editableUser: null,
            content_width: false,
            provinces: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
            en: true
		};
	},
	components: {
		Header,
		Footer,
        NewPaymentProcessor
	},
	mounted: function() {
        var vm = this
        // grab all the dashboard information now
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.editableUser = _.clone(results.data.user)
                vm.editableUser.date_of_birth = this.convertUTCDateToLocalDate(vm.editableUser.date_of_birth)
                vm.editableUser.positions = JSON.parse(vm.editableUser.positions)

                axios.get('/api/users/outstanding-payments-due').then(results => {
					if(results.data.success === true) {
						vm.orders = results.data.orders
					}
				})
            }
        })



        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
	},
	computed: {
        
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        saveUserInfo: function() {
            var vm = this

            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            vm.editableUser.date_of_birth = moment.tz(vm.editableUser.date_of_birth + ' 12:00', timezone).utc().format('YYYY-MM-DD');


            axios.post('/api/users/save-user-info', {
                userDetails: vm.editableUser
            }).then(results => {
                if(results.data.success === true) {
                    vm.authUser.date_of_birth = vm.editableUser.date_of_birth
                    vm.authUser.phone = vm.editableUser.phone
                    vm.authUser.first_name = vm.editableUser.first_name
                    vm.authUser.last_name = vm.editableUser.last_name

                    vm.editUserInfo = false

                    this.$buefy.snackbar.open({
                        message: 'Saved new user info',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'There was an error saving your info. If this continues, please contact us.',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        saveEmergencyInfo: function() {
            var vm = this
            axios.post('/api/users/save-emergency-info', {
                userDetails: vm.editableUser
            }).then(results => {
                if(results.data.success === true) {
                    vm.authUser.emergency_contact = vm.editableUser.emergency_contact
                    vm.authUser.emergency_contact_phone = vm.editableUser.emergency_contact_phone

                    vm.editEmergencyInfo = false

                    this.$buefy.snackbar.open({
                        message: 'Saved new emergency info',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'There was an error saving your info. If this continues, please contact us.',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        convertUTCDateToLocalDate(date) {
            console.log('date', date)
            if (typeof date === 'string') {
                date = new Date(date);
            }
            return date;
        },
        saveAccountInfo: function() {
            var vm = this
            axios.post('/api/users/save-account-info', {
                userDetails: vm.editableUser
            }).then(results => {
                if(results.data.success === true) {
                    vm.authUser.username = vm.editableUser.username
                    vm.authUser.security_question = vm.editableUser.security_question
                    vm.authUser.security_answer = vm.editableUser.security_answer

                    vm.editAccountInfo = false

                    this.$buefy.snackbar.open({
                        message: 'Saved new account info',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })   
                } else {
                    this.$buefy.snackbar.open({
                        message: 'There was an error saving your info. If this continues, please contact us.',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        saveAddressInfo: function() {
            var vm = this
            axios.post('/api/users/save-address-info', {
                userDetails: vm.editableUser
            }).then(results => {
                if(results.data.success === true) {
                    vm.authUser.street_address = vm.editableUser.street_address
                    vm.authUser.city = vm.editableUser.city
                    vm.authUser.province = vm.editableUser.province
                    vm.authUser.postal_code = vm.editableUser.postal_code

                    vm.editAddressInfo = false

                    this.$buefy.snackbar.open({
                        message: 'Saved new address info',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'There was an error saving your info. If this continues, please contact us.',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        paymentSuccessSwitch: function($event) {
            var vm = this
			vm.paymentSuccess = $event

            // this.triggerFinalize = true
        },
        outstandingPaymentMade: function($event) {
			var vm = this
			vm.selectedOrder = null
			
			axios.get('/api/users/outstanding-payments-due').then(results => {
				if(results.data.success === true) {
					vm.orders = results.data.orders
				}
			})
		},
        confirmEmailChange: function() {
            var vm = this

            // validate that it's an email
            if(vm.newEmailAddress === vm.confirmNewEmailAddress) {
                axios.post('/api/users/update-email', {
                    email: vm.newEmailAddress
                }).then(results => {
                    if(results.data.success === true) {
                        
                        localStorage.removeItem('auth')
                        axios.get('/auth/logout').then(results => {
                            if(results.data.success === true) {
                                this.$router.push({ path: '/' }).catch(err => {})  
                            }
                        })

                        
                    } else {
                        this.$buefy.snackbar.open({
                            message: 'This email is already in the system.',
                            type: 'is-danger', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    }
                    
                })

            } else {
                this.$buefy.snackbar.open({
                    message: 'Your new email and confirmed email address do not match.',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })
            }
            // axios post
        },
        confirmPasswordChange: function() {
            var vm = this
            
            if(vm.newPassword === vm.confirmNewPassword) {
                axios.post('/api/users/update-password', {
                    currentPassword: vm.currentPassword,
                    newPassword: vm.newPassword
                }).then(results => {
                    if(results.data.success === true) {
                        vm.currentPassword = null
                        vm.newPassword = null
                        vm.confirmNewPassword = null

                        this.$buefy.snackbar.open({
                            message: 'You have updated your password.',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    } else {
                        this.$buefy.snackbar.open({
                            message: results.data.error,
                            type: 'is-danger', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    }
                })
            } else {
                this.$buefy.snackbar.open({
                    message: 'Your new password and confirmed password do not match.',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })
            }
        }
	}
}
</script>

<style lang="scss">
    
</style>
