<template>
    <div class="accordion">
      <div @click="isOpen = !isOpen" class="accordion-header">
        <slot name="header"></slot>
        <i :class="['fas', isOpen ? 'fa-angle-up' : 'fa-angle-down']" class="toggle-icon"></i>
      </div>
      <b-collapse :open="isOpen">
        <div class="accordion-content">
          <slot name="content"></slot>
        </div>
      </b-collapse>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      preOpen: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isOpen: this.preOpen
      }
    }
  }
  </script>
  
  <style scoped>
  .accordion {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .accordion-header {
    background-color: black;
    color: white;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    border-left: 5px solid #d12118;
  }
  
  .accordion-content {
    padding: 20px;
    background: #fff;
  }
  
  .toggle-icon {
    color: #fff;
  }
  </style>