<template>
	<div class="login-bg">
		<Header></Header>
		<div class="login-area">
            <div class="login-area-wrapper">
                <div class="login-header">
                    Recover Your Password
                </div>
                <div style="background: #fff; padding: 10px;">
                    <b-tabs>
                        <b-tab-item label="I Remember My Email">
                            <div class="padding">
                                <b-field label="Email Address">
                                    <b-input v-model="recoverPassword.email"></b-input>
                                </b-field>
                            </div>
                        </b-tab-item>
                        <b-tab-item label="I Don't Remember">
                            <div class="padding">
                                <p>Please start a chat using the bubble in the bottom right.</p>
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
    
                <div class="error" v-if="error">
                    {{ error }}
                </div>
                <div style="text-align: center;">

                    <a class="login-button" v-on:click="registerMyAccount()">Register My Account</a>
                </div>
            
            </div>
        </div>
		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/SecondaryHeader.vue'
import Footer from './components/SecondaryFooter.vue'
import axios from 'axios'

export default {
	data() {
		return {
            recoverPassword: {
                email: null,
                password: null
            },
            error: null
		};
	},
	components: {
		Header,
		Footer
	},
	mounted: function() {
        
       
	},
	computed: {
        reviewForDuplicates: function() {
            // run an axios that validates all the information

        }
	}, 
	methods: {
        registerMyAccount: function() {
            
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
