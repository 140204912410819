<template>
  <div class="search-overlay" v-if="searchComponentOpen" @click="closeSearch">
    <div class="search-container" @click.stop>
      <div class="input-wrapper">
          <input
              type="text"
              class="search-input"
              placeholder="Search..."
              autofocus
              v-model="searchQuery"
              @input="handleInput"
          />
        <div class="loader" v-if="loading"></div>
      </div>
      <div class="search-results" v-if="!loading && results.length">
          <ul>
              <li v-for="result in results" :key="result.id" @click="handleResultClick(result)">
                  <h3>
                      {{ result.title }}
                      <i v-if="result.type === 'url'" class="fas fa-external-link-alt"></i>
                  </h3>
                  <p v-if="result.type === 'accordion' && result.isExpanded">{{ result.description }}</p>
              </li>
          </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import _ from 'lodash';
  import axios from 'axios';
  export default {
    data() {
      return {
        test: true,
        searchQuery: '',
        loading: false,
        results: []
      }
    },
    props: ['searchComponentOpen'],
    mounted() {
      window.addEventListener('keydown', this.toggleSearch);
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.toggleSearch);
    },
    created() {
        // Create a debounced version of the search method
        this.debouncedSearch = _.debounce(this.search, 1000);
    },
    methods: {
        toggleSearch(e) {
            if (e.metaKey && e.keyCode === 32) {
                this.test = !this.test;
            }
            },
            search() {
                this.loading = true;
                axios.get(`/api/pages/search/${this.searchQuery}`).then(response => {
                    if (response.data.success) {
                      this.results = response.data.results;
                    } else {
                    // Handle the case where the search was not successful
                      this.results = [];
                    }
                }).catch(error => {
                    // Handle any errors that occur during the API call
                    console.error('Search API error:', error);
                    this.results = [];
                }).finally(() => {
                    this.loading = false; // Ensure loading is set to false when the API call is complete
                });
            },
            handleInput() {
                this.debouncedSearch();
            },
            getMockResults() {
                // Mock search results
                return [
                    { id: 1, title: 'Result 1', description: 'Description of result 1' },
                    { id: 2, title: 'Result 2', description: 'Description of result 2' },
                    // ... more results
                ];
            },
            handleResultClick(result) {
                if (result.type === 'url') {
                    window.location.href = result.url; // Or use router.push if using Vue Router
                } else if (result.type === 'accordion') {
                    this.toggleAccordion(result);
                }
            },
            closeSearch() {
              this.$emit('close-search');
         // Assuming you have a way to control the visibility of the search component
            },
            toggleAccordion(result) {
                // Assuming each result has an `isExpanded` property
                // that tracks its accordion state
                result.isExpanded = !result.isExpanded;
                // Since Vue cannot detect property addition or deletion,
                // you need to ensure `isExpanded` is a reactive property
                // when you initially populate your results array.
            }
        }
    }
  
  </script>
  
  <style scoped>
  .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .search-container {
    width: 50%;
    max-width: 600px;
  }
  
  .input-wrapper {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 15px;
  padding-right: 50px; /* Make room for the spinner */
  font-size: 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.fas.fa-external-link-alt {
  font-size: 14px; /* Adjust the size as needed */
  position: absolute;
  right: 10px; /* Adjust the distance from the right as needed */
  top: 50%;
  transform: translateY(-50%);
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  position: absolute;
  right: 15px;
  top: 20%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.search-results {
  margin-top: 20px;
}

.search-results ul {
  list-style: none;
  padding: 0;
}

.search-results li {
  cursor: pointer;
  background: #fff;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
  font-size: 18px;
  position: relative; 
}

.search-results h3 {
  margin: 0;
  font-size: 18px;
  padding-right: 30px; 
}

.search-results p {
  font-size: 14px;
  color: #666;
}
  </style>