<template>
	<div>
        <b-modal 
            v-model="addPlayerModal"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal>
            <template #default="props">
                <div class="modal-card" style="width: 350px">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Add Players To Team</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
                    </header>
                    <section class="modal-card-body" style="min-height: 140px; overflow: hidden;">
                        <b-field 
                            label="Search for a player"
                            message="You can enter email, phone, username or first &amp; last name">
                            
                            <b-input v-on:input="updatePlayerSearch($event)" placeholder="Search"></b-input>
                        
                        </b-field>
                    </section>
                    <section class="modal-card-body">
                        <nav class="level" v-for="(user, key) in searchList" :key="key">
                            <div class="level-right">
                                <a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
                            </div>
                            <div class="level-left">
                                <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                                <p class="level-item">{{ user.city }}</p>
                                <p class="level-item">{{ user.gender }}</p>
                                
                                <p class="level-item">
                                    
                                    <RankComponent :currentRank="user.current_rank_id"
                                    :previousRank="user.previous_rank_id"></RankComponent>
                                </p>
                                <p class="level-item">
                                    <span v-if="user.latest_waiver_signed === $latest_waiver">Signed</span>
                                </p>
                            </div>
                           
                        </nav>

                      
                    </section>
                    <div class="adding">
                        <p><b>Adding:</b></p>
                    </div>
                    
                    <section class="modal-card-body">
                        <nav class="level" v-for="(user, key) in playersSelected" :key="key">
                            <div class="level-left">
                                <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                                <p class="level-item">{{ user.city }}</p>
                            </div>
                            <div class="level-right">
                                <a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
                            </div>
                        </nav>
                    </section>
                    <footer class="modal-card-foot">
                        <a class="admin-button" role="button" v-on:click="addToRoster()">Add Players To Roster</a>
                    </footer>
                </div>
            </template>
        </b-modal>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="team">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
                                Manage {{ team.name }} - ID #{{ team.id }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a onclick="history.back(); return false;" class="button">Back to Manage Teams</a>
                                    <a v-on:click="addPlayerModal = !addPlayerModal" class="button">Add Player to Team</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area">
                    <div class="row">
                        <div class="col-md-12">
                            <b-tabs>
                                <b-tab-item label="Player Pool">
                                    <vue-excel-xlsx
                                        :data="selectedPlayers"
                                        :columns="columnTemplate"
                                        :filename="'filename'"
                                        :sheetname="'sheetname'"
                                        :class="'button is-success'"
                                        >
                                        <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
                                    </vue-excel-xlsx>
                                    <b-table
                                        :data="team.core_roster"
                                        :striped="true"
                                        :focusable="true"
                                        paginated
                                        checkable
                                        :checked-rows.sync="selectedPlayers"
                                        per-page="20"
                                    >
                                        <b-table-column
                                            field="waiver_signed"
                                            label="Waiver"
                                        >
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <div style="text-align: center;">
                                                    <i v-if="props.row.latest_waiver_signed === $latest_waiver" class="fas fa-check-circle"></i>
                                                </div>
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="first_name"
                                            label="First Name"
                                            :searchable="true"
                                        >
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    placeholder="Search"
                                                    size="is-small"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.first_name }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column
                                            field="last_name"
                                            label="Last Name"
                                            :searchable="true"
                                        >

                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    placeholder="Search"
                                                    size="is-small"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                <b>{{ props.row.last_name }}</b>
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="current_rank_id"
                                            label="Level"
                                            :searchable="true"
                                        >
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    size="is-small"
                                                    style="width: 30px;"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                <!-- here {{ props.row}} -->
                                                <RankComponent 
                                                    :currentRank="props.row.current_rank_id"
                                                    :previousRank="props.row.previous_rank_id"
                                                ></RankComponent>
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="role" label="Role">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <span class="tag is-success" v-if="props.row.role_on_team === 1">
                                                    Coach
                                                </span>
                                                <span class="tag is-warning" v-if="props.row.role_on_team === 2">
                                                    Team Contact
                                                </span>
                                                <span class="tag is-danger" v-if="props.row.role_on_team === 3">
                                                    Player
                                                </span>
                                                <span class="tag is-info" v-if="props.row.coach_only === 1">
                                                    Coach Only
                                                </span>
                                            
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="city" label="City">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.city }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="gender" label="Gender">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <span class="tag is-light">
                                                    <b>{{ props.row.gender }}</b>
                                                </span>
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="date_of_birth" label="Date of Birth">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <span class="tag is-light">
                                                    <b>{{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                                </span>
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="positions"
                                            label="Positions"
                                            :searchable="true"
                                        >
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    placeholder="Search"
                                                    size="is-small"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                <span v-if="props.row.positions">
                                                    <span class="tag is-light" :key="key" v-for="(position, key) in JSON.parse(props.row.positions)">
                                                        {{ position }}
                                                    </span>
                                                </span>
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="actions" label="Actions">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <b-dropdown hoverable aria-role="list">
                                                    <button class="button is-info" slot="trigger">
                                                        <span>Actions</span>
                                                        <b-icon icon="menu-down"></b-icon>
                                                    </button>

                                                    <b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 1)" v-if="props.row.role_on_team !== 1">Change To Coach</b-dropdown-item>
                                                    <b-dropdown-item aria-role="listitem" v-on:click="changeToPrimaryCoach(props.row)" v-if="team.primary_coach_id !== props.row.user_id">Change To Primary Coach</b-dropdown-item>
                                                    <b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 2)" v-if="props.row.role_on_team !== 2">Change To Team Contact</b-dropdown-item>
                                                    <b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 3)"  v-if="props.row.role_on_team !== 3">Change to Player</b-dropdown-item>
                                                    
                                                    <b-dropdown-item
                                                    v-on:click="removeFromTeam(props.row)"
                                                    aria-role="listitem">Remove from Team</b-dropdown-item>
                                                </b-dropdown>
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="actions" label="View User">
                                            <template v-slot="props">
                                                <a :href="'/superadmin/manage/users/' + props.row.user_id" class="button">View User</a>
                                            </template>
                                        </b-table-column>
                                    </b-table>
                                </b-tab-item>
                                <b-tab-item label="Leagues/Tournaments">
                                    <div class="padding">
                                        <div class="panel">
                                            <div class="panel-heading">
                                                Leagues They're In
                                            </div>
                                            <div class="panel-content">
                                                <table class="table" style="width: 100%;"  v-if="team.leagues_entered_in">
                                                    <tr :key="key" v-for="(league, key) in team.leagues_entered_in">
                                                        <td>
                                                            {{ league.league.name }}
                                                            <br><span style="font-size: 12px; font-weight: 700;">{{ league.team.name }}</span>
                                                        </td>
                                                        <td>
                                                            {{ league.league.season_id}}
                                                        </td>
                                                        <td>
                                                            <a :href="'/superadmin/manage/leagues/' + league.league.id" class="button is-small">View League</a>
                                                            
                                                        </td>
                                                        <td>
                                                            <a :href="`/superadmin/manage/leagues/${league.league.slug}/event-roster/${league.registration.id}`" class="button is-small is-warning">View Event Roster</a>
                                                            
                                                        </td>
                                                        <td>
                                                            <a :href="'/superadmin/manage/teams/' + league.team.id" class="button is-small is-danger">View Team</a>
                                                        </td>
                                                        
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="padding">
                                        <div class="panel">
                                            <div class="panel-heading">
                                                Tournaments They're In
                                            </div>
                                            <div class="panel-content">
                                                <table class="table" style="width: 100%;"  v-if="team.tournaments_entered_in">
                                                    <tr :key="key" v-for="(tournament, key) in team.tournaments_entered_in">
                                                        <td>
                                                            {{ tournament.tournament.name }}
                                                            
                                                        </td>
                                                    
                                                        <td>
                                                            {{ tournament.tournament.season_id }}
                                                        </td>
                                                    
                                                        <td>
                                                            
                                                        </td>
                                                        <td>
                                                            <a :href="'/superadmin/manage/tournaments/' + tournament.tournament.id" class="button 
                                                            is-small">View Tournament</a>
                                                            
                                                        </td>
                                                        <td>
                                                            <a :href="`/superadmin/manage/tournament/${tournament.tournament.slug}/event-roster/${tournament.registration.id}`" class="button is-small is-warning">View Event Roster</a>
                                                            
                                                        </td>
                                                        <td>
                                                            <a :href="'/superadmin/manage/teams/' + tournament.team.id" class="button is-small is-danger">View Team</a>
                                                        </td>
                                        
                                                    </tr>
                                                </table>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </b-tab-item>
                                <b-tab-item label="Your Event Rosters">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <b-menu
                                                :accordion="false"
                                            >
                                                <b-menu-list label="Event Rosters">
                                                    <b-menu-item
                                                        expanded>
                                                        <template
                                                            slot="label" slot-scope="props">
                                                            <b>Your Event Rosters</b>
                                                        </template>
                                                        <b-menu-item
                                                            :label="formatRosterName(roster.name) + ' *' + moment(roster.created_at).format('YYYY-MM-DD') + '*'"
                                                            v-for="(roster, key) in team.event_rosters"
                                                            :key="key"
                                                            v-on:click="event_roster_menu = roster">
                                                        </b-menu-item>

                                                    </b-menu-item>

                                                </b-menu-list>
                                            </b-menu>
                                        </div>
                                        <div class="col-md-9">
                                            <div style="padding: 20px;">
                                                <div v-if="event_roster_menu">
                                                    <div v-if="event_roster_menu !== 'create'">
                                                        <h3>{{ event_roster_menu.name }} Event Roster</h3>
                                                        <h5><b>Current Team Rank Based on Roster:</b> {{ event_roster_menu.roster_score }}</h5>
                                                        <b-table
                                                            :data="event_roster_menu.roster"
                                                            :striped="true"
                                                            :focusable="true"
                                                            paginated
                                                            per-page="20"
                                                        >
                                                        
                                                            <b-table-column
                                                                field="first_name"
                                                                label="First Name"
                                                                :searchable="true"
                                                            >
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    {{ props.row.first_name }}
                                                                </template>
                                                            </b-table-column>

                                                            <b-table-column
                                                                field="last_name"
                                                                label="Last Name"
                                                                :searchable="true"
                                                            >

                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <b>{{ props.row.last_name }}</b>
                                                                </template>
                                                            </b-table-column>
                                                            <b-table-column
                                                                field="level"
                                                                label="Level"
                                                                :searchable="true"
                                                            >
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <RankComponent 
                                                                        :currentRank="props.row.current_rank_id"
                                                                        :previousRank="props.row.previous_rank_id"
                                                                    ></RankComponent>
                                                                </template>
                                                            </b-table-column>


                                                            <b-table-column field="gender" label="Gender">
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <span class="tag is-light">
                                                                        <b>{{ props.row.gender }}</b>
                                                                    </span>
                                                                </template>
                                                            </b-table-column>
                                                            <b-table-column field="date_of_birth" label="Date of Birth">
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <span class="tag is-light">
                                                                        <b>{{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                                                    </span>
                                                                </template>
                                                            </b-table-column>
                                                            <b-table-column
                                                                field="positions"
                                                                label="Positions"
                                                                :searchable="true"
                                                            >
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <span v-if="props.row.positions">
                                                                        <span class="tag is-light" :key="key" v-for="(position, key) in JSON.parse(props.row.positions)">
                                                                            {{ position }}
                                                                        </span>
                                                                    </span>
                                                                </template>
                                                            </b-table-column>
                                          
                                                        </b-table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    
                                </b-tab-item>
                                <b-tab-item label="Edit Your Team">
                                    <div class="row">
                                        <div class="col-md-4">
                                            
                                            <b-field>
                                                <!-- <img src="" alt=""> -->
                                                <b-upload
                                                    v-model="teamLogo"
                                                    expanded
                                                    drag-drop>
                                                    <section class="section">
                                                        <div v-if="team.logo">
                                                            <img :src="team.logo" width="100%" alt="Upload your Logo">
                                                            <p style="text-align: center; font-size: 12px; font-weight: 700;">Upload your logo here! Then click update team.</p>
                                                        </div>
                                                        <div class="content has-text-centered" v-if="team.logo === null">
                                                            <p>
                                                                <b-icon
                                                                    icon="upload"
                                                                    size="is-large">
                                                                </b-icon>
                                                            </p>
                                                            <p style="text-align: center; font-size: 12px; font-weight: 700;">Upload your logo here! Then click update team.</p>
                                                        </div>
                                                    </section>
                                                </b-upload>
                                            </b-field>
                                        </div>
                                        <div class="col-md-8"  style="padding-left: 20px;">
                                            <b-field label="Team Name">
                                                <b-tooltip type="is-dark" label="Choose something creative!">
                                                    <b-input v-model="team.name"></b-input>
                                                </b-tooltip>
                                            </b-field>

                                            <b-field label="Primary City">
                                                <b-tooltip type="is-dark" label="Choose the core city of your team!">
                                                    <b-input v-model="team.city"></b-input>
                                                </b-tooltip>
                                            </b-field>

                                            <b-field label="Season ID">
                                                <b-tooltip type="is-dark" label="Choose the core city of your team!">
                                                    <b-input v-model="team.season"></b-input>
                                                </b-tooltip>
                                            </b-field>

                                            

                                            <label for="" class="standard-input">
                                                <span>Team's Primary Colour</span>
                                                <v-swatches
                                                v-model="team.color_1"
                                                swatches="text-advanced"></v-swatches>
                                            </label>

                                            <p class="note">
                                                <span>Please note:</span> your team will automatically be moved to the appropriate division based on the players you've added to your roster, and the tournaments and leagues you select to enter.
                                            </p>



                                            <a v-on:click="updateTeam()" role="button" class="admin-button">
                                                <i class="far fa-save"></i>&nbsp;Update Team
                                            </a>
                                            <br><br>
                                            <b-field label="Change Team Region">
                                                <b-select v-model="team.region_id">
                                                    <option :value="1">
                                                        Alberta
                                                    </option>
                                                    <option :value="2">
                                                        British Columbia
                                                    </option>
                                                    <option :value="3">
                                                        Manitoba
                                                    </option>
                                                    <option :value="4">
                                                        Ontario
                                                    </option>
                                                    <option :value="5">
                                                        Maritimes
                                                    </option>
                                                    <option :value="6">
                                                        Quebec
                                                    </option>
                                                    <option :value="7">
                                                        Newfoundland
                                                    </option>
                                                    <option :value="8">
                                                        Saskatchewan
                                                    </option>
                                                </b-select>
                                            </b-field>
                                            <a v-on:click="updateRegion()" role="button" class="admin-button">
                                                <i class="far fa-save"></i>&nbsp;Update Region
                                            </a>

                                        </div>
                                    </div>
                                </b-tab-item>
                                <b-tab-item :label="'SPN Registration #s'">
                                    <SPNRegistrationNumbers :show_revoke="true" v-if="team" :team_slug="team.slug"></SPNRegistrationNumbers>
                                </b-tab-item>
                                <b-tab-item :label="'Archive Team'" >
                                    <p>Archive your team if you no longer use it or it's outdated. </p>
                                    <p>Do not archive your team if it's a team you're still on.</p>
                                    <p><b>Click this only when you're sure!</b></p>
                                    <b-field label="Type CONFIRMDELETE to prove you're not a robot">
                                        <b-input v-model="confirmdelete"></b-input>
                                    </b-field>
                                    <a v-on:click="archiveTeam()" class="button">Archive Your Team</a>
                                </b-tab-item>
                            </b-tabs>
                        </div>
                    </div>
                    <nav class="panel" style="background: #fff;">
                        <div class="panel-heading" style="text-align: center;">
                            <b>Roster Layout</b>
                        </div>
                        <div class="panel-block">
                            <table class="roster-table">
                                <tr>
                                    <td class="head" :key="key" v-for="(item, key) in team.core_roster_count">
                                        <b-tag v-if="key === 'A'" type="is-success">
                                            {{ key === 'null' ? 'N/A': key }}
                                        </b-tag>
                                        <b-tag v-else-if="key === 'B'" type="is-danger">
                                            {{ key === 'null' ? 'N/A': key }}
                                        </b-tag>
                                        <b-tag v-else-if="key === 'C'" type="is-warning">
                                            {{ key === 'null' ? 'N/A': key }}
                                        </b-tag>
                                        <b-tag v-else-if="key === 'D'" type="is-info">
                                            {{ key === 'null' ? 'N/A': key }}
                                        </b-tag>
                                        <b-tag v-else-if="key === 'E'" type="is-link" style="color: #fff;">
                                            {{ key === 'null' ? 'N/A': key }}
                                        </b-tag>
                                        <b-tag v-else>
                                            {{ key === 'null' ? 'N/A': key }}
                                        </b-tag>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="num" :key="key" v-for="(item, key) in team.core_roster_count">
                                        {{ item.length }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import VSwatches from 'vue-swatches'
import Scoring from '../../helpers/RosterScoring'
import NewPaymentProcessor from '../components/NewPaymentProcessor.vue'
import RankComponent from '../components/RankComponent.vue'

import SPNRegistrationNumbers from '../SPNRegistrationNumbers.vue'

export default {
	data() {
		return {
            selectedPlayers: [],
            selected_gendered_registration: null,
            completedPurchase: false,
            products: [],
            confirmdelete: null,
            selectedInsuranceOption: null,
            region_rate: null,
            addPlayerModal: false,
            playersSelected: [],
            page: null,
            user: null,
            timer: null,
            logo: null,
            content_width: false,
            teamLogo: null,
            columnTemplate: [
				{ title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'First Name', label: 'First Name', field: 'first_name', visible: true, searchable: false, width: 40 },
				{ title: 'Last Name', label: 'Last Name', field: 'last_name', visible: true, searchable: false },
				{ title: 'Level', label: 'Level', field: 'current_rank_id', visible: true, searchable: false },
                { title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
                { title: 'Role', label: 'Role', field: 'role_on_team', visible: true, searchable: false },
                { title: 'Gender', label: 'Gender', field: 'gender', visible: true, searchable: false },
                { title: 'DOB', label: 'DOB', field: 'date_of_birth', visible: true, searchable: false },
			
			],
            newTeam: {
                name: null,
                last_year_team_name: null,
                primary_location: null
            },
            newEventRoster: {
                name: null,
                roster: [],
            },
            error: null,
            searchList: [],
            selected_players_for_event_roster: [],
            event_roster_menu: null,
            team: { color: '#000' },
            roster: null,
            cutoff: 2,
            classes: {
                A: 1,
                B: 2,
                C: 3,
                D: 4,
                E: 5
            }
		};
	},
	components: {
		Header,
		Footer,
        NewPaymentProcessor,
        SPNRegistrationNumbers,
        RankComponent,
        VSwatches
    },
    watch: {
        'selected_gendered_registration': function() {
            var vm = this

            var insurance = _.find(vm.team.insurance, function(insurance) {
                return insurance.gender === $event && insurance.season === moment().format('YYYY')
            })
        },
        teamLogo: function() {
            
            var vm = this

            const form = new FormData()
            form.append('logo', vm.teamLogo)

            axios.post('/api/teams/upload-photo', form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    
                    vm.team.logo = results.data.data.logo_path

                    axios.post(`/api/superuser/teams/${vm.page}`, {
                        user: user
                    }).then(results => {
                        if(results.data.success === true) {
                            vm.team = results.data.data.team

                            axios.get(`/api/teams/${vm.page}/rosters`).then(results => {
                                
                            })
                        }
                    })
                }
            })
     
        }
    },
    computed: {
        computedEventTeamRank: function() {
            var vm = this
            
            const scorer = new Scoring(vm.newEventRoster.roster, true)
            var result = scorer.score()
            
            return result
        }
    },
	mounted: function() {
		// grab all the dashboard information now
        var vm = this
        var id = this.$route.params.id
        
        var options = {}

        
        axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})
        
        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			 
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_players !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.post(`/api/superuser/team/${id}`, {
                    user: vm.authUser
                }).then(results => {
                    
                    if(results.data.success === true) {
                        vm.team = results.data.data.team

                        axios.get(`/api/teams/${id}/rosters`).then(results => {
                            
                        })

                        axios.post('/api/users/region-rate', {
                            region: vm.team.region_id
                        }).then(results => {
                            if(results.data.success === true) {
                                vm.region_rate = results.data.region_rate
                                vm.products = []
                                var rate = vm.region_rate.rate
                                

                                vm.products.push({
                                    id: 6,
                                    name: 'Fully Insured Tournament Team',
                                    price: rate.fully_insured_tournament_team,
                                    full_price: rate.fully_insured_tournament_team + 28.50
                                })
                                if(rate.un_insured_tournament_team !== 0) {
                                    vm.products.push({
                                        id: 7,
                                        name: 'Uninsured Tournament Team',
                                        price: rate.un_insured_tournament_team,
                                        full_price: rate.un_insured_tournament_team
                                    })
                                }
                                if(rate.single_use_tournament_team !== 0) {
                                    vm.products.push({
                                        id: 8,
                                        name: 'Play One Time',
                                        price: rate.single_use_tournament_team,
                                        full_price: rate.single_use_tournament_team
                                    })
                                }
                            }
                        })
                    }
                })
            }
        })
	},
    watch: {
        teamLogo: function() {
            
            var vm = this

            const form = new FormData()
            form.append('logo', vm.teamLogo)

            axios.post('/api/teams/upload-photo', form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    
                    vm.team.logo = results.data.data.logo_path
                }
            })
     
        }
    },
	methods: {
        updateRegion: function() {
            var vm = this
            axios.post('/api/superuser/update-region-of-team', {
                team_id: vm.team.id,
                region_id: vm.team.region_id
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Updated team region',
                        type: 'is-success' // is-danger
                    })
                }
            })
        },
        changeToPrimaryCoach: function(player) {
			var vm = this
            
            var r = confirm('Are you sure you want to set a new primary coach?')
            if(r === true) {
                axios.post('/api/teams/set-primary-coach', {
                    team_id: vm.team.id,
                    user_id: player.user_id,
					player_id: player.core_roster_id,
                }).then(results => {
                    if(results.data.success === true) {
                        
                        this.$buefy.snackbar.open({
                            message: 'New player set as primary coach',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
		},
        changePlayerRole: function(player, role) {
            var vm = this
            
            axios.post('/api/teams/update-role', {
                role: role,
                player_id: player.core_roster_id,
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateTeamAPI()
                }
            })
        },
        addToRoster: function(user_id) {
            
            var vm = this

            axios.post('/api/teams/add-to-roster', {
                roster_players: vm.playersSelected,
                team_id: vm.team.id
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.updateTeamAPI()
                    vm.playersSelected = []
                    vm.addPlayerModal = false

                    this.$buefy.snackbar.open({
                        message: 'You have successfully added player(s) to your team.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    vm.error = results.data.error
                    this.$buefy.snackbar.open({
                        message: 'Adding players to your team did not work.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
      
        updatePlayerSearch: function($event) {            
            var vm = this

			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {

                axios.post(`/api/users/search/players`, {
                    search_name: $event,
                    region: vm.team.region_id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.searchList = results.data.users
                    }
                })
            }, 1000)
        },
        createEventRoster: function() {
            var vm = this
            

            var user_keyby = _.keyBy(vm.newEventRoster.roster, 'user_id')

            var user_ids = Object.keys(user_keyby)

            

            axios.post('/api/teams/create-new-event-roster', {
                team_id: vm.team.id,
                name: vm.newEventRoster.name,
                user_ids: user_ids
            }).then(results => {
                

                if(results.data.success === true) {

                    vm.fireTeamUpdate().then(results => {
                        if(results.data.success === true) {
                            vm.team = results.data.data.team
                            vm.newEventRoster.roster = []
                            vm.newEventRoster.name = null
                        }
                    })


                }
            })
        },
        updateTeam: function() {
            var vm = this  
            

            const form = new FormData()
            form.append('id', vm.team.id)
            form.append('name', vm.team.name)
            form.append('city', vm.team.city)
            form.append('color_1', vm.team.color_1)
            form.append('logo', vm.team.logo)
            form.append('season', vm.team.season)

            axios.post(`/api/teams/${vm.page}/update-team`, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Team updated',
                        type: 'is-success' // is-danger
                    })   
                }
            })
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        fireTeamUpdate: function() {
            var vm = this
            return axios.post(`/api/superuser/team/${vm.page}`, {
                user: vm.user
            })
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
        purchaseOfInsuranceComplete: function($event) {
            var vm = this

            // purchase is complete

            // now we need to assign the insurance based on the selection
            axios.post('/api/users/assign-insurance-on-purchase', {
                info: $event,
                insurance: vm.selectedInsuranceOption,
                team: vm.team.id,
                gender: vm.selected_gendered_registration
            }).then(results => {
                if(results.data.success === true) {
                    // success and add to their SPN registrations lists
                    var registration = results.data.team_registration
                    vm.completedPurchase = true

                    vm.latest_registration = registration

                    axios.post(`/api/superuser/team/${vm.team.slug}`, {
                        user: vm.user
                    }).then(results => {
                        if(results.data.success === true) {
                            vm.team = results.data.data.team
                        }
                    })

                    this.$buefy.snackbar.open({
                        message: 'You have successfully received your SPN Membership.',
                        type: 'is-success' // is-danger
                    })
                }
            })
        },
        updateTeamAPI: function() {
            var vm = this
            
            axios.post(`/api/superuser/team/${vm.team.slug}`, {
                    user: vm.user
                }).then(results => {
                    
                    if(results.data.success === true) {
                        vm.team = results.data.data.team

                        this.$buefy.snackbar.open({
                            message: 'Updated your team data',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })  
                    }
            })
        },
        removeFromTeam: function(user) {
            var vm = this
            
            var r = confirm('Are you sure you want to kick this player off your roster?')
            
            if(r === true) {
                axios.post('/api/teams/remove-player', {
                    team_id: vm.team.id,
                    user_id: user.core_roster_id
                }).then(results => {
                    if(results.data.success === true) {
                        
                        vm.updateTeamAPI()

                        this.$buefy.snackbar.open({
                            message: 'Player removed from roster',
                            type: 'is-success' // is-danger
                        })
                    } else {
                        this.$buefy.snackbar.open({
                            message: 'Did not remove player from roster successfully.',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        },
        archiveTeam: function() {
            var vm = this

            if(vm.confirmdelete === 'CONFIRMDELETE') {
                axios.post('/api/teams/archive-team', {
                    team_id: vm.team.id,
                }).then(results => {
                    if(results.data.success === true) {
                        this.$buefy.snackbar.open({
                            message: 'Your team has been archived. You will be redirected momentarily.',
                            type: 'is-success' // is-danger
                        })

                        this.$router.push({ path: '/superadmin/manage/teams' })
                    }
                })
            } else {
                this.$buefy.snackbar.open({
                    message: 'You did not type CONFIRMDELETE correctly.',
                    type: 'is-danger' // is-danger
                })
            }
        },
        revokeInsurance: function(team) {
            var vm = this
            

            var r = confirm('Are you sure you want to do this?')

            if(r === true) {
                axios.post('/api/superuser/revoke-insurance', {
                    team: team
                }).then(results => {
                    if(results.data.success === true) {
                        this.$buefy.snackbar.open({
							message: 'Insurance revoked from applicable team, leagues and tournaments.',
							type: 'is-success' // is-danger
						})
                    }
                })
            }
        },
        formatRosterName: function(name) {
            return name.split('- 2')[0] || name
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>