<template>
    <span>
        <nav class="panel">
            <p class="panel-heading">
                <b>Coach/Player Tools</b>
            </p>
            <a class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Player Finder
            </a>
            <a class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Injury Report
            </a>
            <a class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Protest Form
            </a>
            <a href="/insurance" class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Insurance Program
            </a>
            <a href="https://spncloud.egnyte.com/dl/HJN8crwky6" target="_blank" class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                SPN Rulebook
            </a>
        </nav>
    </span>
</template>

<script>


export default {
	data() {
		return {
         
		};
	},
	components: {
		
	},
	mounted: function() {
        
	},
	computed: {
	
	},
	methods: {
	
	}
}
</script>

<style lang="scss">
    
</style>
