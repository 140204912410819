<template>
    <span v-if="authUser">
		<div v-if="umpire_permissions.approved !== null">
			<div style="text-align: right; margin-top: 10px;">
				<a :href="'/dashboard/umpires/printable-certificate/' + authUser.id" class="button">{{ en === true ? 'Print Your Certificate' : 'Imprimez Votre Certificat' }}</a>
			</div>
			<UmpCertificate :en="en" :userId="authUser.id"></UmpCertificate>
		</div>
		<div v-else>
			<h2>Your certificate will appear here when you are approved.</h2>
		</div>
    </span>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

import UmpCertificate from '../components/UmpCertificate.vue'
export default {
	data() {
		return {
			authUser: null,
			umpire_permissions: null
		};
	},
	components: {
		UmpCertificate
	},
	props: ['en'],
	mounted: function() {
		var vm = this
        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
				vm.umpire_permissions = results.data.umpire_permissions
            }
        })
	},
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		}
	}
}
</script>

<style lang="scss">
    
</style>
