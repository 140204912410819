<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
		
			<div :class="['page-header', { sidebarClosed: !content_width }]">
				<div class="row">
					<div class="col-md-3">
						<div class="page-header-title">
							Manage Memberships
						</div>
					</div>
					<div class="col-md-9">
						<div class="padding">
				
						</div>
					</div>
				</div>
			</div>
			<div class="admin-area">
				

				<b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
					<div
						slot="trigger" 
						slot-scope="props"
						class="card-header"
						role="button"
						aria-controls="contentIdForA11y3">
						<p class="card-header-title">
							Search & Control
						</p>
						<a class="card-header-icon">
							<b-icon
								:icon="props.open ? 'menu-down' : 'menu-up'">
							</b-icon>
						</a>
					</div>
					<div class="card-content">
						<div class="content">
							<b-field grouped group-multiline>
								<div v-for="(column, index) in columnTemplate"
									:key="index"
									class="control">
									<b-checkbox v-model="column.visible">
										{{ column.title }}
									</b-checkbox>
								</div>
							</b-field>
							<hr>
							<b-field grouped group-multiline >
								<b-field label="Region" v-if="specific_region === false">
									<b-select v-model="searchables.region">
										<option value="*">All</option>
										<option :value="1">Alberta</option>
										<option :value="2">British Columbia</option>
										<option :value="3">Manitoba</option>
										<option :value="4">Ontario</option>
										<option :value="5">Maritimes</option>
										<option :value="6">Quebec</option>
										<option :value="7">Newfoundland & Labrador</option>
										<option :value="8">Saskatchewan</option>
									</b-select>
								</b-field>
	
								<b-field label="Number of Results">
									<b-select v-model="searchables.limit">
										<option :value="50">50</option>
										<option :value="100">100</option>
										<option :value="500">500</option>
										<option :value="1000">1000</option>
										<option :value="2500">2500</option>
										<option :value="5000">5000</option>
									</b-select>
								</b-field>
								<b-field label="Per Page">
									<b-select v-model="perPage">
										<option value="10">10 per page</option>
										<option value="25">25 per page</option>
										<option value="50">50 per page</option>
										<option value="100">100 per page</option>
                                        <option value="1000">1000 per page</option>
                                        <option value="2000">2000 per page</option>
									</b-select>
								</b-field>
								<!-- <b-field label="Show Deleted">
									<b-switch v-model="searchables.deleted_at"></b-switch>
								</b-field>
								<b-field label="Filter By Year">
									<b-select v-model="searchables.season" placeholder="Select a year">
										<option
											v-for="option in years"
											:value="option"
											:key="option">
											{{ option }}
										</option>
									</b-select>
								</b-field> -->
							</b-field>
							<b-field grouped group-multiline style="margin-top: 30px;" >
								
								<!-- <b-field label="ID" style="width: 60px;">
									<b-input v-model="searchables.id"></b-input>
								</b-field> -->
                                <b-field label="Gender">
									<b-select v-model="searchables.gender">
										<option value="*">All</option>
										<option value="mens">Mens</option>
										<option value="ladies">Ladies</option>
										<option value="coed">Coed</option>
									</b-select>
								</b-field>
								<!-- <b-field label="Type">
									<b-select v-model="searchables.type">
										<option value="*">All</option>
										<option value="1">Fully Insured</option>
										<option value="2">Uninsured</option>
										<option value="3">League Tournament</option>
									</b-select>
								</b-field>
								<b-field label="Status">
									<b-select v-model="searchables.status">
										<option value="*">All</option>
										<option value="sanctioned">Sanctioned</option>
										<option value="unapproved">Unapproved</option>
										<option value="rejected">Rejected</option>
										<option value="nationals">Nationals</option>
										<option value="provincials">Provincials</option>
									</b-select>
								</b-field>
								<b-field label="Name" style="width: 150px;">
									<b-input v-model="searchables.tournament_name"></b-input>
								</b-field>
								<b-field label="City" style="width: 150px;">
									<b-input v-model="searchables.city"></b-input>
								</b-field>
								<b-field label="Convenor's First Name">
									<b-input v-model="searchables.convenor_first_name"></b-input>
								</b-field>
								<b-field label="Convenor's Last Name">
									<b-input v-model="searchables.convenor_last_name"></b-input>
								</b-field>
								<b-field label="Convenor's Email">
									<b-input v-model="searchables.convenor_email"></b-input>
								</b-field>
								<b-field label="Convenor Phone">
									<b-input v-model="searchables.convenor_phone"></b-input>
								</b-field>
								<b-field label="Event Start Date">
									<b-datepicker v-model="searchables.event_start_date"></b-datepicker>
								</b-field>
								<b-field label="Event End Date">
									<b-datepicker v-model="searchables.event_end_date"></b-datepicker>
								</b-field> -->
							</b-field>
							<hr>
							<div class="content">
								<a class="button is-info" v-on:click="sendMessage = true"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp; Send Email To Selected Tournament</a>
								<vue-excel-xlsx
									:data="selectedMemberships"
									:columns="columnTemplate"
									:filename="'filename'"
									:sheetname="'sheetname'"
									:class="'button is-success'"
									>
									<i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel ({{ selectedMemberships.length }})
								</vue-excel-xlsx>
								
								<div class="send-email" v-if="sendMessage" style="margin-top: 30px;">
									<div class="selectable-contacts">
										<b-field grouped group-multiline>
											<b-field label="Convenors">
												<b-switch v-model="emailSelectables.convenors"></b-switch>
											</b-field>
											<b-field label="All Team Coaches">
												<b-switch v-model="emailSelectables.all_coaches"></b-switch>
											</b-field>
					
										</b-field>
									</div>
									<div class="contacts-selected" style="margin-top: 30px;">
										<p><b>Sending To:</b></p>
										<b-tag v-for="(email, index) in computedEmails" :key="index">{{ email }}</b-tag>
									</div>
									<b-field label="Subject" style="margin-top: 30px;">
										<b-input v-model="sendEmail.subject"></b-input>
									</b-field>
									<b-field label="Message">
										<b-input v-model="sendEmail.message"
										maxlength="2000" type="textarea"></b-input>
									</b-field>

									<a class="admin-button" v-on:click="sendMessageToPlayers()">Send Message to Selected Players</a>
								</div>
							</div>
						</div>
					</div>
				</b-collapse>


				<b-table
					:data="memberships"
					ref="table"
					:sticky-header="true"
					:height="'100%'"
					:paginated="true"
					style="margin-top: 30px;"
					:current-page="1"
					:per-page="perPage"
					:pagination-simple="false"
					:pagination-position="'both'"
					:checked-rows.sync="selectedMemberships"
					checkable
					:show-detail-icon="true"
					default-sort="user.first_name"
					aria-next-label="Next page"
					aria-previous-label="Previous page"
					aria-page-label="Page"
					aria-current-label="Current page">
					<template
						v-for="(column, index) in columnTemplate"
						>
						<b-table-column 
							v-bind="column"
							
							:key="index" 
							:sortable="true"
							:label="column.title"
							:visible="column.visible">

							
							<template v-slot="props" :width="props.column.width">
								<span v-if="column.field === 'spn_number'">
									<b-tag type="is-small">{{ props.row.spn_number }}</b-tag>
                                    <b-tag v-if="props.row.fully_paid === 1">PAID</b-tag>
								</span>
                                <span v-if="column.field === 'region_name'">
									{{ props.row.region_shortname }}
								</span>
                                 <span v-if="column.field === 'gender'">
									{{ props.row.gender.toUpperCase() }}
								</span>
                                 <span v-if="column.field === 'team_name'">
									{{ props.row.team_name }}
								</span>
								<span v-else-if="column.field === 'fully_insured'">
									<span v-if="props.row.with_insurance === 1">Fully Insured</span>
									<span v-if="props.row.with_insurance === 2">Uninsured</span>
								</span>
								<span v-else-if="column.field === 'type'">
									<span v-if="props.row.from_league === 1">League</span>
									<span v-else>Tournament</span>
								</span>
                                <span v-else-if="column.field === 'coach_first_name'">
									{{ props.row.team.primary_coach.first_name }}
								</span>
                                <span v-else-if="column.field === 'coach_last_name'">
									{{ props.row.team.primary_coach.last_name }}
								</span>
                                <span v-else-if="column.field === 'coach_email'">
									{{ props.row.team.primary_coach.email }}
								</span>
								<!-- <span v-else-if="column.field === 'convenor.last_name'">
									{{ props.row.convenor.last_name }}
								</span>
								<span v-else-if="column.field === 'convenor.email'">
									{{ props.row.convenor.email }}
								</span>
								<span v-else-if="column.field === 'convenor.phone'">
									{{ props.row.convenor.phone }}
								</span>
								<span v-else-if="column.field === 'divisions'">
									<b-tag v-for="division in props.row.divisions" :key="division.id">
										{{ division.gender_slug.toUpperCase() }} {{ division.rank_id }}
									</b-tag>
								</span>
								<span v-else-if="column.field === 'event_start_date'">
									{{ moment(props.row.event_start_date).format('YYYY-MM-DD') }}
								</span>
								<span v-else-if="column.field === 'event_end_date'">
									{{ moment(props.row.event_end_date).format('YYYY-MM-DD') }}
								</span>
								<span v-else-if="column.field === 'status'">
									<b-tag type="is-warning" v-if="props.row.status === 'unapproved'">{{ props.row.status.toUpperCase() }}</b-tag>
									<b-tag type="is-danger" v-else-if="props.row.status === 'rejected'">{{ props.row.status.toUpperCase() }}</b-tag>
									<b-tag type="is-success" v-else-if="props.row.status === 'sanctioned'">{{ props.row.status.toUpperCase() }}</b-tag>
									<b-tag type="is-info" v-else>{{ props.row.status.toUpperCase() }}</b-tag>
								</span> -->
								<!-- <span v-else>
									{{ props.row[column.field] }}
								</span> -->
							</template>
						</b-table-column>
						
					</template>
					<template slot="detail" slot-scope="props">
						<b-field grouped>
							
						</b-field>
						<div class="row">
							<div class="col-md-4">
								<a :href="'/superadmin/manage/tournaments/' + props.row.id"  class="button is-info">View Tournament Profile</a>
								<a :href="'/superadmin/manage/users/' + props.row.convenor_user_id"  class="button is-small" style="margin-top: 10px;">View Tournament Convenor Profile</a>
								<div v-if="props.row.status !== 'nationals' && props.row.status !== 'provincials'">
									<a v-on:click="changeTournamentStatus(props.row, 'sanctioned')" v-if="props.row.status !== 'sanctioned'" class="button is-info" style="margin-top: 10px;">Approve Tournament</a>
								</div>
								<a v-on:click="changeTournamentStatus(props.row, 'rejected')" class="button is-danger" style="margin-top: 10px;">Reject Tournament</a>
								<div class="padding" style="padding: 20px 0p;">
									<div class="panel is-info" >
										<div class="panel-heading" style="font-size: 12px; color: #fff;">
											Notes
										</div>
										<div class="panel-content" v-if="props.row.notes.length > 0">
											<div class="user-note " :key="key" v-for="(note, key) in props.row.notes">
												<div class="user-note-note" style="padding-bottom: 10px;">{{ note.notes }}</div>
												<div class="note">{{ moment(note.created_at).format('YYYY-MM-DD h:mmA') }} by {{ note.written.first_name }} {{ note.written.last_name }}</div>
											</div>


										</div>
										<b-field>
											<b-input type="text" v-model="note"></b-input>
										</b-field>
										<div style="text-align: center; margin-top: 10px; padding-bottom: 10px;">
											<a class="admin-button" role="button" v-on:click="addNote(props.row)">Add Note</a>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-5">
								<div class="padding">
									<div class="panel is-info">
										<div class="panel-heading" style="font-size: 12px; color: #fff;">
											Registrants
										</div>
										<div class="panel-content">
											<table class="table">
												<tr>
													<td><b>Colour</b></td>
													<td><b>Team Name</b></td>
													<td><b>City</b></td>
												</tr>
												<tr :key="key" v-for="(registrant, key) in props.row.registrants">
													<td>
														<div class="color-box" :style="'background: ' + registrant.team.color_1">
												
														</div>
														
													</td>
													<td>
														<b>{{ registrant.team.name }}</b>
													</td>
													<td>
														<b>{{ registrant.team.city }}</b>
													</td>
													<td>
														<a :href="'/superadmin/manage/teams/' + registrant.team.slug" class="button is-info is-small">View Team Profile</a>
														<a :href="'/superadmin/manage/tournament/' + props.row.slug  + '/event-roster/' + registrant.id" class="button is-warning is-small">Event Roster</a>
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<div class="panel is-primary">
										<div class="panel-heading" style="font-size: 12px; color: #fff;">
											Divisions
										</div>
										<div class="panel-content">
											<table class="table">
												<tr>
													<td><b>Division</b></td>
													<td><b>Min Teams</b></td>
													<td><b>Max Teams</b></td>
													<td><b>Registrants</b></td>
												</tr>
												<tr :key="key" v-for="(division, key) in props.row.divisions">
													<td>
														<b>{{ division.gender_slug.slice(0,1).toUpperCase() + division.gender_slug.slice(1) }} {{ division.rank_id }}</b>
													</td>
													<td>
														{{ division.min_number_of_teams }}
													</td>
													<td>
														{{ division.max_number_of_teams }}
													</td>
													<td>
														{{ division.registrants.length }}
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
							
							
						</div>
					</template>
				</b-table>


				
			</div>
		</div>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import axios from 'axios'
import moment from 'moment'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import validation from './../../validation'

export default {
	data() {
		return {
            memberships: [],
            selectedMemberships: [],
			isLoading: true,
			authUser: { first_name: null },
			content_width: false,
			createNewProvincialEvent: false,
			createNewNationalEvent: false,
			note: null,
			allTournaments: [],
			perPage: 50,
			timer: null,
			selectedTournaments: [],
			regions: [],
			newTournament: {
				name: null,
				fr_name: null,
				qualifier: 1,
				event_start_date: null,
				event_end_date: null,
				deposit_required: false,
				deposit_amount: null,
				overnight: false,
				registration_start_date: null,
				registration_end_date: null,
				roster_lock_date: null,
				description: '',
				headquarter_location: null,
				headquarter_google_address: null,
				team_price: null,
				individual_price: null,
				selling_alcohol: false,
				mens_divisions: [],
				ladies_divisions: [],
				coed_divisions: [],
				website_url: null,
				facebook_event_url: null,
				facebook_group_url: null,
				method_of_payment: 'card',
				registrants_pay_via_spn: false,
				registrants_pay_via_emt: false,
				registrants_pay_via_cash: false,
				emt_email: null,
				emt_password: null,
				reg_payment: []
			},
			years: ['All', 2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010],
			selectedTeams: [],
			sendMessage: false,
			specific_region: false,
			sendEmail: {
                subject: null,
                message: null
			},
			divisions: [],
			emailSelectables: {
				convenors: false,
				all_coaches: false,
				team_contacts: false,
				rostered_players: false,
			},
			newTournamentRegion: null,
			searchables: {
				id: null,
				region: '*',
				type: '*',
				status: '*',
				season: moment().format('YYYY'),
				tournament_name: null,
				city: null,
                gender: '*',
				convenor_email: null,
				convenor_phone: null,
				convenor_first_name: null,
				convenor_last_name: null,
				event_start_date: null,
				event_end_date: null,
				deleted_at: false,
                limit: 50,
			},
			columnTemplate: [
				{ label: 'ID', title: 'ID', field: 'id', visible: false, searchable: false },
				{ label: 'SPN Number', title: 'SPN Number', field: 'spn_number', visible: true, searchable: false },
				{ label: 'Region', title: 'Region', field: 'region_name', visible: true, searchable: false, width: 40 },
                { label: 'Gender', title: 'Gender', field: 'gender', visible: true, searchable: false },
				{ label: 'Team Name', title: 'Team Name', field: 'team_name', visible: true, searchable: false },
				{ label: 'Insured', title: 'Insured', field: 'fully_insured', visible: true, searchable: false },
				{ label: 'Type', title: 'Type', field: 'type', visible: true, searchable: false },
                { label: 'Coach First Name', title: 'Coach First Name', field: 'coach_first_name', visible: true, searchable: false },
                { label: 'Coach Last Name', title: 'Coach Last Name', field: 'coach_last_name', visible: true, searchable: false },
                { label: 'Coach Email', title: 'Coach Email', field: 'coach_email', visible: true, searchable: false },

			]
		};
	},
	components: {
		Header,
		Footer,
		ValidationProvider,
		ValidationObserver
	},
	watch: {
		yearSelected: function($event) {
			
		},
		searchables: {
            handler(val) {

                this.checkingThis()
            }, deep: true
		},
		createNewProvincialEvent: function($event) {
			var vm = this
			
			if($event === true) {
				vm.$nextTick(() => {
					
				})
			}
		},
		createNewNationalEvent: function($event) {
			var vm = this
			
			if($event === true) {
				vm.$nextTick(() => {
		
				})
			}
		}
	},
	mounted: function() {
	
		// grab all the dashboard information now
		var vm = this
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                if(results.data.user_permissions.manage_tournaments !== 1) {
                    this.$router.push({ name: 'Dashboard' })
				}

				if(results.data.user_permissions.specific_region_only) {
                    vm.searchables.region = results.data.user_permissions.specific_region_only
                    vm.specific_region = true
                }
				
				 axios.get('/auth/regions').then(results => {
					vm.regions = results.data.regions
				})
				

				axios.get('/api/tournaments/divisions').then(results => {
					vm.divisions = results.data.divisions
				})

				
                vm.getMemberships()
				vm.isLoading = false
			}
		})

		if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		
	},
	computed: {
		computedEmails: function() {
			var vm = this
			
			var i = 0

			var emails = []

			for(i; i < vm.selectedTournaments.length; i++) {
				var tournament = vm.selectedTournaments[i]
				var registrants = tournament.registrants

				if(vm.emailSelectables.convenors) {
					
					emails.push(tournament.convenor.email)
					
				}

				if(vm.emailSelectables.all_coaches) {
					var w = 0
					for(w; w < registrants.length; w++) {
						var registrant = registrants[w]
						if(registrant.team.primary_coach) {
							emails.push(registrant.team.primary_coach.email)
						}
					}
				}
			}

			return emails
		}
	},
	methods: {
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.newTournament.headquarter_location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'AB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'YT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'BC') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			vm.newTournament.headquarter_google_address = {
				written_address: place.formatted_address,
				street_address: street_number.long_name + ' ' + route.long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}


		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		getTournaments: function() {
			var vm = this

			// axios.get('/api/superuser/tournaments', { params: vm.searchables }).then(results => {
			// 	if(results.data.success === true) {
			// 		vm.allTournaments = results.data.tournaments
	
			// 	}
			// })
		},
        getMemberships: function() {
			var vm = this

			axios.get('/api/superuser/memberships', { params: vm.searchables }).then(results => {
				if(results.data.success === true) {
                    vm.memberships = results.data.memberships
				}
			})
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		changeTournamentStatus: function(tournament, status) {
			axios.post('/api/superuser/tournament-change-status', {
				tournament_id: tournament.id,
				status: status
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
                        message: 'Tournament status successfully changed to ' + status,
                        type: 'is-success' // is-danger
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Tournament status change failed',
                        type: 'is-danger' // is-danger
                    })
				}
			})
		},
		checkingThis: function() {
			var vm = this
			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {

				vm.getMemberships()
			}, 1000)
		},
		sendMessageToPlayers: function() {
            var vm = this

            axios.post('/api/superuser/send-messages-to-players', {
                message: vm.sendEmail,
                emails: vm.computedEmails,
            }).then(results => {
                if(results.data.success === true) {
                    vm.sendEmail = {
                        subject: null,
                        message: null
                    }

                    vm.selectedUsers = []
                    this.$buefy.snackbar.open({
                        message: 'You have successfully sent your message!',
                        type: 'is-success' // is-danger
                    })
                }
            })
		},
		saveNewNationalEvent: function() {
			var vm = this
			axios.post('/api/superuser/save-national-event', {
				tournament: vm.newTournament,
				region: vm.newTournamentRegion
			}).then(results => {
				if(results.data.success === true) {

					vm.createNewNationalEvent = false

					this.$buefy.snackbar.open({
                        message: 'You have created a National Event!',
                        type: 'is-success' // is-danger
                    })
				} else {

					this.$buefy.snackbar.open({
                        message: 'Did not save',
                        type: 'is-danger' // is-danger
                    })
				}
			}).catch(err => {
				this.$buefy.snackbar.open({
					message: 'Did not save',
					type: 'is-danger' // is-danger
				})
			})
		},
		saveNewProvincialEvent: function() {
			var vm = this
			
			axios.post('/api/superuser/save-provincial-event', {
				tournament: vm.newTournament,
				region: vm.newTournamentRegion
			}).then(results => {
				if(results.data.success === true) {
					vm.createNewProvincialEvent = false

					this.$buefy.snackbar.open({
                        message: 'You have created a Provincial Event!',
                        type: 'is-success' // is-danger
                    })
				} else {

					this.$buefy.snackbar.open({
                        message: 'Did not save',
                        type: 'is-danger' // is-danger
                    })
				}
			}).catch(err => {
				this.$buefy.snackbar.open({
					message: 'Did not save',
					type: 'is-danger' // is-danger
				})
			})
		},
		addNote: function(tournament) {
			var vm = this

			axios.post('/api/superuser/add-note-to-tournament', {
				tournament: tournament,
				note: vm.note
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Note is added to the tournament!',
						type: 'is-success' // is-danger
					})
				}
			})
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
