<template>
<span>
    <div class="header">
        <div class="header-wrapper">
            <div class="logo-main">
                <a href="/"><img src="../../../images/header/SPNLogo2019-White.png" alt="SPN Logo"></a>
            </div>
            
            <div class="language-bar">
                <ul>
                    <li>
                        <a role="button" :class="['lang', { selected: en === true }]" v-on:click="selectLang(true)">EN</a>|<a role="button" :class="['lang', { selected: en === false }]" v-on:click="selectLang(false)">FR</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            en: true,
            user_coordinates: {
                lat: null,
                long: null
            },
            submenu_filtered: [],
            menu: [],
            submenus: []
        };
    },
    components: {
        
    },
    computed: {
        
    },
    mounted: function() {
        var vm = this
        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        // if(!sessionStorage.getItem('user_location')) {
        //     if(!navigator.geolocation) {
        //         vm.$buefy.toast.open({
        //             message: 'Geolocation is not supported by your browser',
        //             type: 'is-success', // is-danger
        //             duration: 2000,
        //             queue: false
        //         })
        //     } else {
        //         // status.textContent = 'Locating…';
        //         navigator.geolocation.getCurrentPosition(vm.geoLocateSuccess, vm.geoLocateError);
        //     }
        // }

        axios.get('/api/navigation/primary').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            // console.log(err)
        })
    },
    methods: {
        geoLocateSuccess(position) {
            this.user_coordinates.lat = position.coords.latitude;
            this.user_coordinates.long = position.coords.longitude;
            var pos = JSON.stringify(position)

            axios.post('/auth/update-location', {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                location_accuracy: position.coords.accuracy
            }).then(results => {
                
            })

            sessionStorage.setItem('user_location', pos)
        },
        geoLocateError() {
            var vm = this
            // vm.$buefy.toast.open({
            //     message: 'There was an error getting your location',
            //     type: 'is-danger', // is-danger
            //     duration: 2000,
            //     queue: false
            // })
        },
        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            } else {
                if(item.href) {
                    // redirect
                    this.$router.push({ path: '/' + item.href }).catch(err => { })   
                }
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        },
        selectLang: function(lang) {
            this.en = lang

            sessionStorage.setItem('lang', lang)
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Header.scss';
</style>
