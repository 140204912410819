<template>
	<div>
        <div class="filters">
            <div class="filter-group">
                <div class="filter-group-header">
                    {{ en === true ? `Filter By Region` : `Filtrer Par Région` }}
                </div>
                <div class="filter-group-mobile-content">
                    <b-dropdown
                        v-model="regions"
                        multiple
                        expanded
                        v-on:change="updateCalendar()"
                        aria-role="list">
                        <template #trigger>
                            <b-button
                                type=""
                                icon-right="menu-down">
                                Selected ({{ regions.length }})
                            </b-button>
                        </template>
                        <b-dropdown-item :value="1" aria-role="listitem">
                            <span>Alberta</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="2" aria-role="listitem">
                            <span>British Columbia</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="3" aria-role="listitem">
                            <span>Manitoba</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="4" aria-role="listitem">
                            <span>Ontario</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="5" aria-role="listitem">
                            <span>Maritimes</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="6" aria-role="listitem">
                            <span>Quebec</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="7" aria-role="listitem">
                            <span>Newfoundland & Labrador</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="8" aria-role="listitem">
                            <span>Saskatchewan</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="filter-group-content">
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="2"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/BCCrest.png" width="100%" alt="BC Crest">
                        </div>
                    </div>
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="1"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/ABCrest.png" width="100%" alt="AB Crest">
                        </div>
                    </div>
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="8"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/SKCrest.png" width="100%" alt="SK Crest">
                        </div>
                    </div>
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="3"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/MBCrest.png" width="100%" alt="MB Crest">
                        </div>
                    </div>
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="4"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/ONCrest.png"  width="100%" alt="ON Crest">
                        </div>
                    </div>
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="6"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/QCCrest.png" width="100%" alt="QC Crest">
                        </div>
                    </div>
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="5"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/MTCrest.png" width="100%" alt="MT Crest">
                        </div>
                    </div>
                    <div class="filter-region" style="max-width: 80px;">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()" type="is-danger" v-model="regions" :native-value="7"></b-checkbox>
                        </div>
                        <div class="image-box">
                            <img src="../../images/header/crest/NLCrest.png" width="100%" alt="NL Crest">
                        </div>
                    </div>
                </div>
            </div>

            <div class="filter-group">
                <div class="filter-group-header">
                    {{ en === true ? 'Filter By Divisions Available' : 'FILTRE PAR DIVISIONS DISPONIBLE' }}
                </div>
                <div class="filter-group-mobile-content">
                    <b-dropdown
                        v-model="divisions"
                        multiple
                        expanded
                        v-on:change="updateCalendar()"
                        aria-role="list">
                        <template #trigger>
                            <b-button
                                type=""
                                icon-right="menu-down">
                                Selected ({{ divisions.length }})
                            </b-button>
                        </template>
                        <b-dropdown-item :value="'mens'" aria-role="listitem">
                            <span v-if="en">Men's</span>
                            <span v-if="en === false">Hommes</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="'ladies'" aria-role="listitem">
                            <span v-if="en">Ladies</span>
                            <span v-if="en === false">Femmes</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="'coed'" aria-role="listitem">
                            <span v-if="en">Coed</span>
                            <span v-if="en === false">Mixte</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="filter-group-content">
                    <div class="filter-region">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()"  type="is-danger" size="is-large" v-model="divisions" native-value="mens">
                                <span v-if="en">Mens</span>
                                <span v-if="en === false">Hommes</span>
                            </b-checkbox>
                        </div>
                    </div>
                    <div class="filter-region">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()"  type="is-danger" size="is-large" v-model="divisions" native-value="ladies">
                                <span v-if="en">Ladies</span>
                                <span v-if="en === false">Femmes</span>
                            </b-checkbox>
                        </div>
                    </div>
                    <div class="filter-region">
                        <div class="checkbox-top">
                            <b-checkbox v-on:input="updateCalendar()"  type="is-danger" size="is-large" v-model="divisions" native-value="coed">
                                <span v-if="en">Coed</span>
                                <span v-if="en === false">Mixte</span>
                            </b-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filter-group">
                <div class="filter-group-header">
                    {{ en === true ? 'Choose your view' : 'Choose your view' }}
                </div>
                <div class="filter-group-mobile-content">
                    <b-dropdown
                        v-model="listOrBox"
                        expanded
                        aria-role="list">
                        <template #trigger>
                            <b-button
                                type=""
                                icon-right="menu-down">
                                Selected ({{ listOrBox }})
                            </b-button>
                        </template>
                        <b-dropdown-item :value="'list'" aria-role="listitem">
                            <span v-if="en">List</span>
                            <span v-if="en === false">Liste</span>
                        </b-dropdown-item>
                        <b-dropdown-item :value="'box'" aria-role="listitem">
                            <span v-if="en">Box</span>
                            <span v-if="en === false">Box</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="filter-group-content">
                    <div class="filter-region">
                        <div class="checkbox-top">
                            <b-radio type="is-danger" size="is-large" v-model="listOrBox" native-value="box">
                                <span v-if="en">Box</span>
                                <span v-if="en === false">Box</span>
                            </b-radio>
                        </div>
                    </div>
                    <div class="filter-region">
                        <div class="checkbox-top">
                            <b-radio type="is-danger" size="is-large" v-model="listOrBox" native-value="list">
                                <span v-if="en">List</span>
                                <span v-if="en === false">Liste</span>
                            </b-radio>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="main-calendar-area">
            <div class="calendar-month">
                <div class="left-calendar-arrow" v-on:click="prevMonthButton()">
                    <i class="fas fa-arrow-circle-left"></i> {{ previousMonth.month }} {{ previousMonth.year }}
                </div>
                <div class="calendar-month-name">
                    {{ month }} {{ year }}
                </div>
                <div class="right-calendar-arrow" v-on:click="nextMonthButton()">
                    {{ nextMonth.month }} {{ nextMonth.year }} <i class="fas fa-arrow-circle-right"></i>
                </div>
            </div>
            <div class="week-of">
                <div class="carousel" v-if="tournaments.length > 0">
                    <ServeTournaments
                        v-on:register:now="registerButtonModal($event)"
                        :tournaments="tournaments"
                        :listOrBox="listOrBox"
                        :carousel-size="carouselSize">
                    </ServeTournaments>
                    <div class="full-tournament-list">

                    </div>
                </div>
                <div class="carousel" v-if="tournaments.length === 0">
                    <div class="tournaments-info" v-if="en">
                        No tournaments came up from your search! Try expanding your search by clicking more checkmarks above!
                    </div>
                    <div class="tournaments-info" v-if="en === false">
                        Aucun tournoi n’est ressorti de votre recherche ! Essayez d’élargir votre recherche en cliquant sur d’autres choix ci-dessus.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'

import ServeTournaments from '../components/ServeTournaments.vue'
import RegisterToTournament from '../components/RegisterToTournament.vue'

import moment from 'moment'
export default {
    metaInfo() {
        return { 
            title: "Tournament Calendar - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'View any of our tournaments in any of our regions here!' },
                { property: 'og:title', content: "Tournament Calendar - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/tournament-calendar' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/tournamentcalendar.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
            en: true,
            carouselSize: 3,
            current_month: null,
            page_url: null,
            page: null,
            month: null,
            user_coordinates: {
                lat: null,
                long: null
            },
            tournaments: [],
            year: null,
            divisions: ['mens','ladies','coed'],
            rank: [],
            regions: [],
            listOrBox: 'box'
		};
	},
	components: {
		Header,
		Footer,
        Form,
        ServeTournaments,
		RegisterToTournament
	},
    props: ['en'],
    watch: {
        divisions: function() {
            var vm = this
            vm.triggerCalendar()
        },
        rank: function() {
            var vm = this
            vm.triggerCalendar()
        },
        regions: function(newVal ) {
            var vm = this
            console.log('newVal', newVal)
            if (vm.regions.includes(4)) {
                const index = vm.regions.indexOf(4);
                if (index > -1) {
                    vm.regions.splice(index, 1); // Remove region '4' from the list
                }
                window.location.href = 'https://playslopitch.com';
            }
            vm.triggerCalendar()
        }
    },
    
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this


        if(localStorage.getItem('province')) {
            var currentRegion = localStorage.getItem('province')
            if(currentRegion === 'ab') {
                vm.regions.push(1)
            }
            if(currentRegion === 'bc') {
                vm.regions.push(2)
            }
            if(currentRegion === 'mb') {
                vm.regions.push(3)
            }
            if(currentRegion === 'on') {
                vm.regions.push(4)
            }
            if(currentRegion === 'mt') {
                vm.regions.push(5)
            }
            if(currentRegion === 'qc') {
                vm.regions.push(6)
            }
            if(currentRegion === 'nf') {
                vm.regions.push(7)
            }
            if(currentRegion === 'sk') {
                vm.regions.push(8)
            }
            // vm.currentProvince = localStorage.getItem('province')
        }

        if(localStorage.getItem('regions_selected')) {
            var regions = JSON.parse(localStorage.getItem('regions_selected'))

            vm.regions = regions
        }

        if(vm.en === false) {
            moment.locale('fr-ca')
        }

        vm.month = moment().format('MMMM')
        vm.number_month = moment().format('MM')
        vm.year = moment().format('YYYY')

        var vm = this
        // if(!sessionStorage.getItem('user_location')) {
        //     if(!navigator.geolocation) {
        //         vm.$buefy.toast.open({
        //             message: 'Geolocation is not supported by your browser',
        //             type: 'is-success', // is-danger
        //             duration: 2000,
        //             queue: false
        //         })
        //     } else {
        //         // status.textContent = 'Locating…';
        //         navigator.geolocation.getCurrentPosition(vm.geoLocateSuccess, vm.geoLocateError);
        //     }
        // }

        if(window.innerWidth < 800) {
            vm.carouselSize = 1
		}


        axios.post('/api/users/tournament-calendar', {
            divisions: vm.divisions,
            rank: vm.rank,
            regions: vm.regions,
            month: vm.number_month,
            year: vm.year
        }).then(results => {
            if(results.data.success === true) {
                vm.tournaments = results.data.tournaments
            }
        })
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
        previousMonth: function() {
            var vm = this

            if(vm.en === false) {
                moment.locale('fr-ca')
            }

            var prev = moment(vm.month + '/01/' + vm.year, 'MMMM/DD/YYYY').subtract(1, 'months')

            return {
                month: prev.format('MMMM'),
                year: prev.format('YYYY')
            }
        },
        nextMonth: function() {
            var vm = this

            if(vm.en === false) {
                moment.locale('fr-ca')
            }

            var next = moment(vm.month + '/01/' + vm.year, 'MMMM/DD/YYYY').add(1, 'months')

            return {
                month: next.format('MMMM'),
                year: next.format('YYYY')
            }
        }
	},
	methods: {
        geoLocateSuccess(position) {
            this.user_coordinates.lat = position.coords.latitude;
            this.user_coordinates.long = position.coords.longitude;
            var pos = JSON.stringify(position)

            sessionStorage.setItem('user_location', pos)

            axios.post('/auth/update-location', {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                location_accuracy: position.coords.accuracy
            }).then(results => {

            })
            // store this in sessionStorage as well, and then don't fire the popup if it's there
        },
        geoLocateError() {
            var vm = this
            //  vm.$buefy.toast.open({
            //     message: 'There was an error getting your location',
            //     type: 'is-danger', // is-danger
            //     duration: 2000,
            //     queue: false
            // })
        },
        updateCalendar() {
            var vm = this
     
            
            axios.post('/api/users/tournament-calendar', {
                divisions: vm.divisions,
                rank: vm.rank,
                regions: vm.regions
            }).then(results => {
                if(results.data.success === true) {
                    vm.tournaments = results.data.tournaments
                }
            })
        },
        prevMonthButton: function() {
            var vm = this

             var current_month = vm.month

            if(vm.en === false) {
                moment.locale('fr-ca')
            }

            var next_month = moment(vm.year + ' ' + vm.month + ' 01', 'YYYY MMMM DD').subtract('1', 'months')

            

            vm.number_month = next_month.format("MM")
            vm.month = next_month.format('MMMM')
            vm.year = next_month.format('YYYY')
            vm.triggerCalendar()
        },
        nextMonthButton: function() {
            var vm = this
            var current_month = vm.month

            if(vm.en === false) {
                moment.locale('fr-ca')
            }

            var next_month = moment(vm.year + ' ' + vm.month + ' 01', 'YYYY MMMM DD').add('1', 'months')

            

            vm.number_month = next_month.format("MM")
            vm.month = next_month.format('MMMM')
            vm.year = next_month.format('YYYY')

            vm.triggerCalendar()
        },
        triggerCalendar: function() {
            var vm = this
            var string = JSON.stringify(vm.regions)
            localStorage.setItem('regions_selected', string)
            
            axios.post('/api/users/tournament-calendar', {
                divisions: vm.divisions,
                rank: vm.rank,
                regions: vm.regions,
                month: vm.number_month,
                year: vm.year
            }).then(results => {
                if(results.data.success === true) {
                    vm.tournaments = results.data.tournaments
                }
            })
        },
        moment: function(date, format = null) {
            if(vm.en === false) {
                moment.locale('fr-ca')
            }
            
            return moment(date, format)
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
