<template>
    <span>
        <h2 v-if="en">Umpire Cheat Sheet</h2>
		<h2 v-if="en === false">
			Notes pour Règles Local
		</h2>
		<a href="https://umpirecheatsheet.com?track=spn" target="_blank">
			<img src="https://cdn.blacktiecollab.com/umpirecheatsheet.com/umpirecheatsheetspn.png" style="width: 100%;" alt="Umpire Cheat Sheet">
		</a>
		<h3>Features List - All For Free</h3>
		<p><b>Built specifically for Slo-Pitch National League Executives & Tournament Co-ordinators</b></p>
		<p>- Umpire in Chiefs can upload rulesets and send specifically to their umpires</p>
		<p>- Umpires download an app and can see rulesets for all leagues you assign them to</p>
		<p>- No registration needed for umpires - all information is private to you and your umpires</p>
		<p>- If the League Executive or Tournament Coordinator you work with uses Slo-Pitch Central, import their schedule instantly and assign umpires to games and track payouts</p>
		<p>- Umpires can accept or decline assignments and UIC's get notified</p>
		<p>- And more features coming!</p>
		<a href="https://umpirecheatsheet.com" class="admin-button">Register for Umpire Cheat Sheet</a>
		<p><b>Only UIC's have to register! Umpires do not need an account! If you're an umpire hoping to use this, contact your UIC and encourage them to register!</b></p>
<!-- 
		<table class="table" style="width: 100%; margin-top: 20px;" v-if="selectedCheatSheet">
			<tr>
				<td><b>Innings</b></td>
				<td>
					{{ selectedCheatSheet.sheet.innings }}
				</td>
			</tr>
			<tr>
				<td><b>Call last inning at __ minutes</b></td>
				<td>
					{{ selectedCheatSheet.sheet.call_last_inning_after }}
				</td>
			</tr>
			<tr>
				<td><b>Minimum players on field</b></td>
				<td>
					{{ selectedCheatSheet.sheet.minimum_players }}
				</td>
			</tr>
			<tr>
				<td><b>HR Rule</b></td>
				<td>
					{{ selectedCheatSheet.sheet.hr_rule }}
				</td>
			</tr>
			<tr>
				<td><b>Anticipation</b></td>
				<td>
					{{ selectedCheatSheet.sheet.anticipation === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Ties</b></td>
				<td>
					{{ selectedCheatSheet.sheet.ties === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Courtesy Runners</b></td>
				<td>
					{{ selectedCheatSheet.sheet.throw_to_first_from_outfield === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Max runs per inning</b></td>
				<td>
					{{ selectedCheatSheet.sheet.max_runs_per_inning === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Mercy Rule</b></td>
				<td>
					{{ selectedCheatSheet.sheet.mercy_rule === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Tag at home</b></td>
				<td>
					{{ selectedCheatSheet.sheet.tag_at_home === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Throw to first from outfield</b></td>
				<td>
					{{ selectedCheatSheet.sheet.throw_to_first_from_outfield === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Additional Rules</b></td>
				<td>
					{{ selectedCheatSheet.sheet.additional_rules }}
				</td>
			</tr>
		</table> -->
    </span>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
export default {
	data() {
		return {
			cheat_sheets: null,
         	selectedCheatSheet: null
		};
	},
	components: {
		
	},
	mounted: function() {
		var vm = this
        axios.get('/api/umpires/get-your-cheat-sheets').then(results => {
			if(results.data.success === true) {
				vm.cheat_sheets = results.data.cheat_sheets
			}
		})
	},
	props: ['en'],
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
            return moment(date, format)
        }
	}
}
</script>

<style lang="scss">
    
</style>
