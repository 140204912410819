<template>
	<div>
		<Header></Header>
		
		<div class="content-area" v-if="page">
            <div style="text-align: center; padding: 20px;">
			    <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/GAMING-TOURNAMENT-LOGO-3500.png" style="max-width: 400px; width: 100%; display: inline-block;" alt="Gaming Tournament Logo">
            </div>
            <div class="frontend-content-wrapper">
				<b-tabs :multiline="true">
					<b-tab-item label="Register Now">
						<div class="section-spacing">
                            <p style="font-size: 20px;"><b>THIS EVENT IS FREE TO ENTER</b></p>
							<p style="font-size: 20px;">SPN is excited to announce our first eSports Tournament series, Legends of February, in partnership with Rawlings Canada. Registration opens January 19th at slo-pitch.com/legends-of-february. Qualifiers will take place February 19-20th before the Championship Tournament on February 26-27th, where the winner will be crowned SPN's first eNational Champion and be awarded a custom Rawlings glove - valued at $500!</p>
                            
                            <br>
                            
                            REGISTRATION IS CLOSED! SEE EVERYONE SATURDAY - IF YOU DID NOT RECEIVE AN EMAIL, PLEASE CONTACT shane@slo-ptich.com ASAP.
                            <br>
                            <p>Questions? Contact shane@slo-pitch.com</p>
						</div>
					</b-tab-item>
                    <b-tab-item label="Qualifier Info">
						<div class="section-spacing">
							<p><b>February 19th</b></p>
                            <p style="margin-left: 20px;">Open Round Robin Window from 12pm to 11:59pm</p>
                            <p><b>February 20th</b></p>
                            <p style="margin-left: 20px;">Open Round Robin Window from 12pm to 11:59pm</p>

                            <p><b>Qualifier Round Robin</b></p>
                            <p>Qualifiers are open from 12pm to 11:59pm</p>
                            <p>Number of qualified players moving on to the Finals Event from each of the two qualifiers will be the top 32 of each qualifier (64 total).</p>
                            <p>A channel in discord will be set up to create matches</p>
                            <p>- The competitor, while in the channel, will type !match</p>
                            <p>- When two users type !match, it will pair them and the chatbot will announce to both members that they have been matched, and also send them a private message showing the PSN or Xbox Gamertag of the two members</p>
                            <p>- It will not match you against yourself, if you have an open !match request it will not store your info again</p>
                            <p>- It will assign who is the home team at random</p>
                            <p><b>Wins are worth 3 points</b></p>
                            <p><b>Losses are worth 1 point</b></p>
                            <p><b>Early quit losses (rage quit) will be worth -3 points</b></p>
                            <p><b>Ties will be played to completion</b></p>
                            <p><b>Run differential will be tracked and be used for tiebreakers</b></p>
                            <p><b>You may play a maximum of 6 games</b></p>
                            <p><b>Only games listed in the match Discord will be considered official matches</b></p>
                            <p><b>Disconnected matches in qualifier will be decided in favour of the participant who did not disconnect.</b></p>
                            <p><b>Freezeoffs will be replayed from the situaton at the time of freezeoff. For example, if a game freezes off at a 5-2 score in the 4th inning, a 3 inning game where 4-2 is the simulated score will be played.</b></p>
						</div>
					</b-tab-item>
                    <b-tab-item label="Finals Info">
						<div class="section-spacing">
							<p><b>February 26th & February 27th</b></p>
                            <p>Top players from both qualifiers will be seeded in a single knockout, best of 3 per round tournament starting at 12pm on Saturday February 26th.</p>
                            <p>3 rounds of the bracketed event will be played each day starting at 12pm each day.</p>
                            <p>All games will be streamed on slo-pitch.com, Facebook and Twitch with commentators & prizes/giveaways for viewers!</p>
                            <p><b>Please continue to review this page closer to the event for more details.</b></p>
						</div>
					</b-tab-item>
                    <b-tab-item label="Roster Construction">
                        <p style="font-size:22px;"><b>Roster Construction</b></p>
                        <p>You may choose your players, however, there are limitations to your roster. They are listed below. If at any point during the tournament that you parallel a player, and that parallel moves them to a higher tier, they must be removed and replaced on your roster.</p>
                        <br>
                        <p><b>Position Players</b></p>
                        <p>- 3 - 95 to 99 overall ratings (or below)</p>
                        <p>- 3 - 85 to 94 overall ratings (or below)</p>
                        <p>- The remainder must be 84 or below overall rating</p>
                        <br>
                        <p><b>Starting Pitchers</b></p>
                        <p>- 2 - 95 to 99 overall ratings (or below)</p>
                        <p>- 1 - 85 to 94 overall rating (or below)</p>
                        <p>- The remaining 2 - 84 or below overall rating</p>
                        <br>
                        <p><b>Bullpen</b></p>
                        <p>- 2 - 95 to 99 overall ratings (or below)</p>
                        <p>- 2 - 85 to 94 overall ratings (or below)</p>
                        <p>- 4 - 84 or below overall ratings</p>
                    </b-tab-item>
                    <b-tab-item label="Rules">
						<div class="section-spacing">
							<p style="font-size: 22px;"><b>Logistical Rules</b></p>
                            <p>1. Must have a registered SPN Account (you may register for it while registering for the tournament). As a result, you must be 18 years of age to enter.</p>
                            <p>2. Must have a discord account and have notifications turned on. Failure to respond to tournament admin or moderator will factor into any rulings. After the start of the event, all conversations with moderators must be conducted through Discord.</p>
                            <p>3. Must have Twitch/Youtube set up on your Playstation/Xbox and all games must be streamed.</p>
                            <p>4. You must compete in a minimum of one qualifier to be eligible for the Finals Tournament. Finals Tournament invitations will be sent out a certain amount of people at the end of each qualifier event. If you did not qualify, you are able to sign up for remaining qualifiers to give yourself additional chances to join the Finals Tournament.</p>
                            <p>5. Players with inappropriate team names, logos or jersey designs will be removed from the event. This includes references or images relating to: alcohol, tobacco, drug paraphernalia, firearms, depictions of the human body or it’s anatomy, gambling, pornographic or adult material, or businesses or activities that could present reputational harm to Slo-Pitch National, MLB® The Show™, Playstation or Xbox. If you are unsure, please ask the admins beforehand. Decisions on this will be final.</p>
                            <hr>
                            <p style="font-size: 22px;"><b>Gameplay Rules</b></p>
                            <p>1. All games are 7 innings</p>
                            <p>2. You are in charge of adding your match competitor via MLB® The Show™ and challenging them to a Play vs Friends game.</p>
                            <p>3. The home team will send the game request in MLB® The Show™, with the following settings. Entrants may report non-compliant settings prior to the match.</p>
                                <p style="margin-left: 10px;">1. Crossplay: ON (Network Setting)</p>
                                <p style="margin-left: 10px;">2. Add friends: ON (Network Setting)</p>
                                <p style="margin-left: 10px;">3. Messaging: ON (Network Setting)</p>
                                <p style="margin-left: 10px;">4. Mode: Diamond Dynasty</p>
                                <p style="margin-left: 10px;">5. Hitting: All-Star</p>
                                <p style="margin-left: 10px;">6. Pitching: All-Star</p>
                                <p style="margin-left: 10px;">7. Guess Pitch: OFF</p>
                                <p style="margin-left: 10px;">8. Quick Counts: OFF</p>
                                <p style="margin-left: 10px;">9. Strike Zone: ON</p>
                                <p style="margin-left: 10px;">10. Hot Zone: OFF</p>
                                <p style="margin-left: 10px;">11. Balks: OFF</p>
                                <p style="margin-left: 10px;">12. Umpire Accuracy: Perfect</p>
                                <p style="margin-left: 10px;">13. Umpire Close Plays: OFF</p>
                                <p style="margin-left: 10px;">14. Ejections: OFF</p>
                                <p style="margin-left: 10px;">15. Injuries: OFF</p>
                                <p style="margin-left: 10px;">16. Presentation mode: Fast Play</p>
                                <p style="margin-left: 10px;">17. Created ballplayers are not permitted</p>
                            <p>4. All games will be played at a minor league stadium chosen by the admin team. This is to reduce parity and lag/framerate drops on lower systems. The stadium for this set of tournaments is: Main Street Field.</p>
                            <p>5. Win/Loss reporting: Both entrants must report results of each match in the reported-scores Discord channel. Entrants are advised to upload a screenshot of the final score and both Entrant’s IDs and keep all screenshots in case of dispute. Simulating or manipulating the game results images is forbidden and grounds for disqualification from all events.</p>
                            <p>6. Players can play a maximum of 6 round robin games. There is no minimum requirement of round robin games, however, points being the deciding factor on who qualifies, playing less games is only to your detriment. Round robin games are marked as 3 points for a win, and 1 point for a loss. Run differential and head to head will be the remaining tiebreakers. Round robins will be open for a certain window. Games finished after the allowed time window will not be counted.</p>
                            <hr>
                            <p style="font-size:22px;"><b>Code of Conduct</b></p>
                            <p>- Use of vulgar or offensive language is prohibited both on the stream and in the Discord server, or on any of Slo-Pitch National’s social media channels.</p>
                            <p>- Abusive behaviour, including harassment and threats, is prohibited both while streaming and in the Discord server, or on any of Slo-Pitch National’s social media channels.</p>
                            <p>- Physical abuse, fighting or any threatening action, directed at any Entrant, Administrator or any other person, is prohibited both while streaming and in the Discord server, or on any of Slo-Pitch National’s social media channels.</p>
                            <p>- Any action that interferes with play of a game, including breaking a console, interfering with power, and abuse of in-game pausing, is prohibited.</p>
                            <p>- Gambling, including betting on the outcome of games, is prohibited.</p>
                            <p>- The acceptance of any gifts, rewards, or compensation for services that relate to participation in the Promotion is prohibited.</p>
                            <p>- Giving and receiving bribes is illegal and prohibited.</p>
                            <p>- Collusion, defined as any agreement among two or more Entrants to disadvantage other Entrants in the Promotion, is strictly prohibited. This includes the following:</p>
                                <p style="margin-left: 10px;">- intentionally losing a match</p>
                                <p style="margin-left: 10px;">- playing on behalf of another Entrant</p>
                                <p style="margin-left: 10px;">- any form of match-fixing</p>
                                <p style="margin-left: 10px;">- soft play (an Entrant not trying their hardest in order to allow another Entrant to
                                increase their score)</p>
                                <p style="margin-left: 10px;">- agreeing to split prize money, cash or otherwise</p>
                            <p>- Entrants are prohibited from implying or representing that they have a commercial relationship with this Promotion, Sponsor, Administrator or the MLB Entities or the MLB Players, as well as Slo-Pitch National. Use of the intellectual property of the Sponsor, Administrator, or the MLB Entities or the MLB Players is strictly prohibited without written consent of the intellectual property owner.</p>
                            <p>- The recording or distribution of footage of this Promotion broadcast for commercial purposes without written permission from the Sponsor or Administrator is strictly prohibited.</p>
						</div>
					</b-tab-item>
                    <b-tab-item label="Prizing">
						<div class="section-spacing">
							<p style="font-size: 22px;"><b>Prizing</b></p>
                            <br>
                            <p style="font-size: 20px;"><b>1st Place - </b> A fully customized Rawlings baseball glove with your choice of colours & styling ($500 value)</p>
                            <br>
                            <p>Other amazing Rawlings prizing to be announced!</p>
                            <p>SPN Swag Prize Packs including products like hoodies, shirts, hats and more!</p>
                            <p>All participants will recieve an EXCLUSIVE SPN 20% off coupon to the SPN Store</p>
                            <p>All <b>viewers</b> will recieve a EXCLUSIVE SPN 10% off coupon to the SPN Store</p>
                            <br>
                            <p><b>Prizing is subject to change and grow dependent on the number of participants</b></p>
						</div>
					</b-tab-item>
					
				</b-tabs>
				
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'

export default {
	metaInfo() {
		if(!this.page) {
            return {}
        }
        return { 
            title: "Legends of February - Virtual National Championship - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Where memories and champions are made.' },
				{ property: 'og:title', content: "Legends of February - Virtual National Championship - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/legends-of-february' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
			page: null,
			winners: null
		};
	},
	components: {
		Header,
		Footer,
		Form,
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this
        vm.page_url = this.$route.params.page

        // axios
        axios.get('/api/pages/' + 'contests').then(results => {
			
            if(results.data) {
				
				vm.page = results.data
				vm.page.content = JSON.parse(vm.page.content)
            }
		})
		
		axios.get('/api/pages/' + 'winners').then(results => {
			
            if(results.data) {
				
				vm.winners = results.data
				vm.winners.content = JSON.parse(vm.winners.content)
            }
        })
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
