<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
	
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
					<div class="row">
						<div class="col-md-3">
							<div class="page-header-title">
								Manage Convenors
							</div>
						</div>
						<div class="col-md-9">
							<div class="padding">
			
							</div>
						</div>
					</div>
				</div>
				<div class="admin-area">
                    
					<b-tabs>
						<b-tab-item label="Application Submitted" v-if="applications">
                            
							<b-table
								:data="applications"
								>  
                                <b-table-column label="First Name">
                                    <template v-slot="props">
                                        {{ props.row.user.first_name }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Last Name">
                                    <template v-slot="props">
                                        {{ props.row.user.last_name }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Email">
                                    <template v-slot="props">
                                        {{ props.row.user.email }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Phone">
                                    <template v-slot="props">
                                        {{ props.row.user.phone }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Area To Convene">
                                    <template v-slot="props">
                                        {{ props.row.area_to_convene }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Best Skills">
                                    <template v-slot="props">
                                        {{ props.row.best_skills }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Biggest Weaknesses">
                                    <template v-slot="props">
                                        {{ props.row.biggest_weaknesses }}
                                    </template>
                                </b-table-column>
                                <b-table-column label="Referral">
                                    <template v-slot="props">
                                        {{ props.row.referral }}
                                    </template>
                                </b-table-column>
								<b-table-column label="">
                                    <template v-slot="props">
                                        <a v-on:click="addConvenorPermissions(props.row)" class="button is-success">Approve Convenor</a>
                                    </template>
                                </b-table-column>
								<b-table-column label="">
                                    <template v-slot="props">
                                        
										<a :href="'/superadmin/manage/users/' + props.row.user_id" class="button">View User</a>
                                    </template>
                                </b-table-column>
							</b-table>
						</b-tab-item>
						<b-tab-item label="Convenors" v-if="convenors">
							<div class="row">
                                <div class="col-md-12">
                                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                        <div
                                            slot="trigger" 
                                            slot-scope="props"
                                            class="card-header"
                                            role="button"
                                            aria-controls="contentIdForA11y3">
                                            <p class="card-header-title">
                                                Search & Control
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                        <div class="card-content">
                                            <div class="content">
                                                <b-field grouped group-multiline>
                                                    <div v-for="(column, index) in columnTemplate"
                                                        :key="index"
                                                        class="control">
                                                        <b-checkbox v-model="column.visible">
                                                            {{ column.title }}
                                                        </b-checkbox>
                                                    </div>
                                                </b-field>
                                                <hr>
                                                <b-field grouped group-multiline >
                                                    <b-field label="Convenor Approval">
                                                        <b-select v-model="searchables.approved">
                                                            <option value="*">All</option>
                                                            <option :value="1">Approved</option>
                                                            <option :value="null">Unapproved</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Region" v-if="specific_region === false">
                                                        <b-select v-model="searchables.region">
                                                            <option value="*">All</option>
                                                            <option :value="1">Alberta</option>
                                                            <option :value="2">British Columbia</option>
                                                            <option :value="3">Manitoba</option>
                                                            <option :value="4">Ontario</option>
                                                            <option :value="5">Maritimes</option>
                                                            <option :value="6">Quebec</option>
                                                            <option :value="7">Newfoundland & Labrador</option>
                                                            <option :value="8">Saskatchewan</option>
                                                        </b-select>
                                                    </b-field>
                                                    
                                                    <b-field label="Gender">
                                                        <b-select v-model="searchables.gender">
                                                            <option value="*">All</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="X">X</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Waiver Signed"  style="margin-right: 50px;">
                                                        <b-select v-model="searchables.waiver_signed">
                                                            <option value="*">All</option>
                                                            <option value="true">Signed</option>
                                                            <option value="false">Unsigned</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Number of Results">
                                                        <b-select v-model="searchables.limit">
                                                            <option :value="100">100</option>
                                                            <option :value="500">500</option>
                                                            <option :value="1000">1000</option>
                                                            <option :value="2500">2500</option>
                                                            <option :value="5000">5000</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Per Page">
                                                        <b-select v-model="perPage">
                                                            <option value="10">10 per page</option>
                                                            <option value="25">25 per page</option>
                                                            <option value="50">50 per page</option>
                                                            <option value="100">100 per page</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Show Deleted">
                                                        <b-switch v-model="searchables.deleted_at"></b-switch>
                                                    </b-field>
                                                </b-field>
                                                <b-field grouped group-multiline style="margin-top: 30px;" >
                                                    
                                                    <b-field label="ID" style="width: 60px;">
                                                        <b-input v-model="searchables.id"></b-input>
                                                    </b-field>
                                                    <b-field label="First Name" style="width: 150px;">
                                                        <b-input v-model="searchables.first_name"></b-input>
                                                    </b-field>
                                                    <b-field label="Last Name" style="width: 150px;">
                                                        <b-input v-model="searchables.last_name"></b-input>
                                                    </b-field>
                                                    <b-field label="Email">
                                                        <b-input v-model="searchables.email"></b-input>
                                                    </b-field>
                                                    <b-field label="Phone">
                                                        <b-input v-model="searchables.phone"></b-input>
                                                    </b-field>
                                                    <b-field label="City">
                                                        <b-input v-model="searchables.city"></b-input>
                                                    </b-field>
                                                    <b-field label="Date of Birth">
                                                        <b-datepicker
                                                            v-model="searchables.date_of_birth"
                                                            placeholder="Click to select..."
                                                            icon="calendar-today"
                                                            trap-focus>
                                                        </b-datepicker>
                                                    </b-field>
                                                </b-field>
                                                <hr>
                                                <div class="content">
                                                    <a class="button is-info" v-on:click="sendMessage = true"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp; Send Email To Selected Users</a>
                                                    <vue-excel-xlsx
                                                        :data="selectedUsers"
                                                        :columns="columnTemplate"
                                                        :filename="'filename'"
                                                        :sheetname="'sheetname'"
                                                        :class="'button is-success'"
                                                        >
                                                        <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
                                                    </vue-excel-xlsx>

         
                                                    

                                                    <div class="send-email" v-if="sendMessage" style="margin-top: 30px;">
                                                        <b-field label="Subject">
                                                            <b-input v-model="sendEmail.subject"></b-input>
                                                        </b-field>
                                                        <b-field label="Message">
                                                            <b-input v-model="sendEmail.message"
                                                            maxlength="2000" type="textarea"></b-input>
                                                        </b-field>

                                                        <a class="admin-button">Send Message to Selected Players</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            
                            

                            <b-table
                                :data="convenors"
                                ref="table"
                                :sticky-header="true"
                                :height="'100%'"
                                :paginated="true"
                                :current-page="1"
                                :per-page="perPage"
                                :pagination-simple="false"
                                :pagination-position="'bottom'"
                                :checked-rows.sync="selectedUsers"
                                checkable
                                detailed
                                detail-key="id"
                                
                                default-sort="user.first_name"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">
                                <template
                                    v-for="(column, index) in columnTemplate"
                                    >
                                    <b-table-column 
                                        v-bind="column"
                                        
                                        :key="index" 
                                        :sortable="true"
                                        :label="column.title"
                                        :visible="column.visible">

                                        <template v-slot="props" :width="props.column.width">
                                            <span v-if="props.column.field === 'gender'">
                                                <span v-if="props.row.gender === 'Male'">
                                                    <b-tag type="is-danger is-light">
                                                        <i class="fas fa-mars"></i> Male
                                                    </b-tag>
                                                    
                                                </span>
                                                <span v-if="props.row.gender === 'Female'">
                                                    <b-tag type="is-link is-danger">
                                                        <i class="fas fa-venus"></i> Female
                                                    </b-tag>
                                                </span>
                                            </span>
                                            <span v-else-if="props.column.field === 'current_rank_id'">
                                                <RankComponent :currentRank="props.row.current_rank_id"
                                                :previousRank="props.row.previous_rank_id"></RankComponent>
                                            </span>
                                            <span v-else-if="props.column.field === 'first_name'">
                                                <b :class="{ 'is-deleted': props.row.deleted_at !== null }">{{ props.row.first_name }}</b>
                                            </span>
                                            <span v-else-if="props.column.field === 'last_name'">
                                                <b :class="{ 'is-deleted': props.row.deleted_at !== null }">{{ props.row.last_name }}</b>
                                            </span>
                                            <span v-else-if="props.column.field === 'default_region_id'">
                                                {{ props.row.region.name }}
                                            </span>
                                            <span style="display: block; width: 100px" v-else-if="props.column.field === 'date_of_birth'">
                                                {{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}
                                            </span>
                                            <span v-else-if="props.column.field === 'last_login'">
                                                <span v-if="props.row.last_login">
                                                    {{ moment(props.row.last_login).utc().format('YYYY-MM-DD') }}
                                                </span>
                                            </span>
                                            <span v-else-if="props.column.field === 'latest_waiver_signed'">
                                                <span v-if="props.row.latest_waiver_signed === $latest_waiver">
                                                    <b-tag type="is-success">
                                                        <i class="fas fa-check"></i>
                                                    </b-tag>
                                                </span>
                                                <span v-else>
                                                    <b-tag type="is-danger">
                                                        <i class="fas fa-times"></i>
                                                    </b-tag>
                                                </span>
                                            </span>
                                            <span v-else>
                                                {{ props.row[column.field] }}
                                            </span>
                                        </template>
                                    </b-table-column>
                                </template>
                                <template slot="detail" slot-scope="props">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div>
                                                <a class="button" v-on:click="resetPassword(props.row)">Reset Password</a>
                                                <a class="button" :href="'/superadmin/manage/users/' + props.row.id">View Profile</a>
						
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <div class="panel is-primary">
                                                    <div class="panel-heading" style="font-size: 12px; color: #fff;">
                                                        Security
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Question:</b></td>
                                                                        <td>{{ props.row.security_question }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Answer:</b></td>
                                                                        <td>{{ props.row.security_answer }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="padding">
                                                <div class="panel is-danger">
                                                    <div class="panel-heading " style="font-size: 12px;">
                                                        Emergency
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Name:</b></td>
                                                                        <td>{{ props.row.emergency_contact }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Phone:</b></td>
                                                                        <td>{{ props.row.emergency_contact_phone }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <div class="panel is-info">
                                                    <div class="panel-heading" style="font-size: 12px;">
                                                        Address
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Address:</b></td>
                                                                        <td>{{ props.row.street_address }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>City:</b></td>
                                                                        <td>{{ props.row.city }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Province:</b></td>
                                                                        <td>{{ props.row.province }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Postal Code:</b></td>
                                                                        <td>{{ props.row.postal_code }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </template>
                            </b-table>
                        </b-tab-item>
					</b-tabs>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RankComponent from '../components/RankComponent.vue'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
export default {
	data() {
		return {
			isLoading: true,
			authUser: { first_name: null },
			questions: [],
            umpires: [],
            sendMessage: false,
            sendEmail: {
                subject: null,
                message: null
            },
            selectedUsers: [],
			specific_region: false,
			checkedRows: [],
			approvalType: 'awaiting',
			content_width: false,
			tabs: 'umpires',
			selectedUmpire: null,
            approveUmpireSwitch: false,
            perPage: 50,
			approvalTypes: [{
				slug: 'awaiting',
				name: 'Awaiting Approval',
			}, {
				slug: 'approved',
				name: 'Approved',
			}],
			newQuestion: {
				subject: null,
				fr_subject: null,
				text: null,
				fr_text: null,
				correct_answer: true,
				required: false
			},
			searchables: {
                id: null,
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                city: null,
                username: null,
                approved: '*',
                region: '*',
                gender: '*',
                date_of_birth: null,
                waiver_signed: '*',
                deleted_at: false,
                limit: 100,
			},
			columnTemplate: [
                { title: 'ID', label: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
                { title: 'Region', label: 'Region', field: 'default_region_id', visible: true, searchable: false },
                { title: 'Gender', label: 'Gender', field: 'gender', visible: true, searchable: false },
                { title: 'Rank', label: 'Rank', field: 'current_rank_id', visible: true, searchable: false, width: 50 },
                { title: 'First Name', label: 'First Name', field: 'first_name', visible: true, searchable: false },
                { title: 'Last Name', label: 'Last Name', field: 'last_name', visible: true, searchable: false },
                { title: 'Username', label: 'Username', field: 'username', visible: true, searchable: false },
                { title: 'Email', label: 'Email', field: 'email', visible: true, searchable: false },
                { title: 'Phone', label: 'Phone', field: 'phone', visible: true, searchable: false },
                { title: 'City', label: 'City', field: 'city', visible: false, searchable: false },
                { title: 'Date of Birth', label: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
                { title: 'Waiver', label: 'Waiver', field: 'latest_waiver_signed', visible: true, searchable: false },
                { title: 'Last Login', label: 'Last Login', field: 'last_login', visible: false },
            ],
            applications: null,
            convenors: null,
			editor: ClassicEditor,
            editorData: '<p>Rich-text editor content.</p>',
            editorConfig: {
                // The configuration of the rich-text editor.
            },
		};
	},
	components: {
		Header,
		Footer,
        RankComponent
	},
    created() {
        this.debouncedCheckingThis = _.debounce(this.checkingThis, 1000);
    },
	watch: {
		tabs: function() {
			if(this.tabs !== this.$route.params.slug) {
				this.$router.replace({ path: '/superadmin/manage/umpires/' + this.tabs })
			}
		},
		searchables: {
            handler(val) {
                this.debouncedCheckingThis()
            }, deep: true
        }
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

		if(this.$route.params.slug === 'exam') {
			vm.tabs = 'exam'	
        }

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
        
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_umpires !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }
                
				
				if(results.data.user_permissions.specific_region_only) {
                    
                    vm.searchables.region = results.data.user_permissions.specific_region_only
                    vm.specific_region = true
                }
                
                vm.isLoading = false
				axios.get('/api/superuser/convenors', { params: vm.searchables }).then(results2 => {
					if(results2.data.success === true) {
						
                        vm.applications = results2.data.applications
                        vm.convenors = results2.data.convenors
						
					}
				})
				

				
			}
        })
	},
	computed: {
		computedQuestionGroups: function() {
			var vm = this
			var groupBy = _.groupBy(vm.questions, 'subject')
			
			return groupBy
		}
	},
	methods: {
		moment:function(date, format = null) {
			return moment(date, format)
		},
		saveNewQuestion: function() {
			var vm = this
			axios.post('/api/superuser/save-umpire-question', {
				question: vm.newQuestion
			}).then(results => {
				if(results.data.success === true) {
					
					vm.questions = results.data.questions
				}
			})
		},
		editUmpire: function(umpire) {
			var vm = this
			vm.approveUmpireSwitch = true
			
			vm.selectedUmpire = Object.assign({}, umpire)
		},
		removeQuestion: function(question_id) {
			var vm = this
			var r = confirm('Are you sure you want to delete this question?')

            if(r === true) {
                axios.post('/api/superuser/remove-umpire-question', {
                    question_id: question_id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.questions = results.data.questions
                    }
                })
            }
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		approveUmpire: function() {
			var vm = this

			axios.post('/api/superuser/update-umpire', {
				order_id: vm.selectedUmpire.order_id,
				payment_type_id: vm.selectedUmpire.order.payment_type_id || null,
				payment_tracking_id: vm.selectedUmpire.order.payment_tracking_id || null,
				find_gigs: vm.selectedUmpire.find_gigs,
				cheat_sheet: vm.selectedUmpire.cheat_sheet,
				clinics: vm.selectedUmpire.clinics,
				selected_umpire_id: vm.selectedUmpire.id,
				approved: vm.selectedUmpire.approved
			}).then(results => {
				if(results.data.success === true) {
					vm.approveUmpireSwitch = false
					vm.selectedUmpire = null

					this.$buefy.snackbar.open({
						message: 'Umpire officially updated',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong when updating the umpire.',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
		addConvenorPermissions: function(row) {
			var vm = this
			axios.post('/api/superuser/add-convenor-permissions', {
                user_id: row.user_id
            }).then(results => {
                if(results.data.success === true) {

                    this.$buefy.snackbar.open({
                        message: 'Added their permissions profile.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    
                    this.$buefy.snackbar.open({
                        message: 'Did not add their permissions profile successfully.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
		},
		checkingThis: function() {
            var vm = this
            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                axios.get('/api/superuser/convenors', { params: vm.searchables }).then(results2 => {
                    if(results2.data.success === true) {						
                        vm.convenors = results2.data.convenors
                    }
                })
            }, 1000)

        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
