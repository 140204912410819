<template>
    <span>
        <h2>Round Robin Matchup Generator</h2>
		<p>Need help getting together a quick round robin schedule? Use this generator to help you set it up.</p>
		<p>Lay out all the team names in the divsion in the row below.</p>
		<p style="font-size: 18px;" class="note">This generator will give you <u>all</u> possible matchups in a round robin. It is your responsibility to turn this into a schedule with your promised number of games guaranteed.</p>
		<p>For odd # teams, please contact us using the chat for options.</p>
		<hr>
		<div class="row">
			<div class="col-md-6">
				<p><b>Teams</b></p>
				<div v-for="(team, key) in teams" :key="key" style="margin-top: 10px;">
					<div class="row">
						<div class="col-xs-9">
							<b-input v-model="team.name" placeholder="Team Name"></b-input>
						</div>
						<div class="col-xs-3">
							<a class="button is-danger" v-on:click="removeTeam(key, team)"><i class="fas fa-trash"></i>&nbsp;&nbsp;Remove</a>
						</div>
					</div>
				</div>			

				<a style="margin-top: 20px;" v-on:click="addTeam()" class="button"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add 2 More Teams to List</a>
				<br><br>
				<a class="button is-info" v-on:click="roundRobinResults()">Generate Round Robin Matchups</a>
			</div>
			<div class="col-md-6">
				<h3>Round Robin Matchup Results</h3>
				<div class="row">
					<div class="col-md-4" :key="key" v-for="(round, key) in robinGeneratorResults">
						<div class="matchup-padding">
							<h5>Round #{{ key +1 }}</h5>
							<div class="matchup" :key="gkey" v-for="(game, gkey) in round">
								<div class="matchup-team">
									{{ game[0].name }}
								</div>
								<div class="matchup-team">
									{{ game[1].name }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br><br>
			

				<vue-excel-xlsx
					:data="computedForExcel"
					:columns="columns"
					 v-if="robinGeneratorResults.length > 0"
					:filename="'filename'"
					:sheetname="'sheetname'"
					:class="'button is-success'"
					>
					<i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
				</vue-excel-xlsx>
			
			</div>
		</div>
		
		
    </span>
</template>

<script>

var robin = require('roundrobin')
export default {
	data() {
		return {
			numberOfGamesPerTeam: 3,
			columns: [
				{
					label: 'Game #',
					field: 'game'
				},
				{
					label: 'Team',
					field: 'away_team'
				},
				{
					label: 'Team 2',
					field: 'home_team'
				},
			],
			teams: [
				{
					name: ''
				},
				{
					name: ''
				},
				{
					name: ''
				},
				{
					name: ''
				},
			],
			robinGeneratorResults: []
		};
	},
	components: {
		
	},
	mounted: function() {
        
	},
	computed: {
		computedForExcel: function() {
			var vm = this
			var game_count = 1

			var array = [
				{
					game: 1,
					away_team: 'Test',
					home_team: 'Test 2'
				}
			]

			var matchups = []

			var robin = vm.robinGeneratorResults

			var i = 0

			for(i; i < robin.length; i++) {
				var round = robin[i]
				
				var s = 0

				for(s; s < round.length; s++) {
					var match = round[s]

					matchups.push({
						game: game_count,
						away_team: match[0].name,
						home_team: match[1].name
					})

					game_count++
				}
			}

			return matchups
		}
	},
	methods: {
		addTeam: function() {
			this.teams.push({
				name: ''
			})
			this.teams.push({
				name: ''
			})
		},
		roundRobinResults: function() {
			var teams = this.teams
			var numberOfGamesPerTeam = this.numberOfGamesPerTeam

			var roundResults = robin(teams.length, teams)
			
			this.robinGeneratorResults = roundResults
		},
		removeTeam: function(key, team) {
			this.teams.splice(key, 1)
		}
	}
}
</script>

<style lang="scss">
    
</style>
