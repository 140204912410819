<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				Membership By Region
			</div>
            <div class="frontend-content-wrapper">
				<div class="section-spacing">
                    <p><b>Select a region icon to see the region's information for memberships.</b></p>
                    <div class="provinces">
                        <div :class="['province', { selected: selectedRegion === 'bc' }]">
                            <a v-on:click="selectRegion('bc')">
                                <img src="../../images/header/crest/BCCrest.png" width="100%" alt="BC Crest">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 'ab' }]">
                            <a v-on:click="selectRegion('ab')">
                                <img src="../../images/header/crest/ABCrest.png" width="100%" alt="AB Crest">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 'sk' }]">
                            <a v-on:click="selectRegion('sk')">
                                <img src="../../images/header/crest/SKCrest.png" width="100%" alt="SK Crest">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 'mb' }]">
                            <a v-on:click="selectRegion('mb')">
                                <img src="../../images/header/crest/MBCrest.png" width="100%" alt="MB Crest">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 'on' }]">
                            <a v-on:click="selectRegion('on')">
                                <img src="../../images/header/crest/ONCrest.png" width="100%" alt="ON Crest">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 'qc' }]">
                            <a v-on:click="selectRegion('qc')">
                                <img src="../../images/header/crest/QCCrest.png" width="100%" alt="QC Crest">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 'mt' }]">
                            <a v-on:click="selectRegion('mt')">
                                <img src="../../images/header/crest/MTCrest.png" width="100%" alt="MT Crest">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 'nf' }]">
                            <a v-on:click="selectRegion('nf')">
                                <img src="../../images/header/crest/NLCrest.png" width="100%" alt="NL Crest">
                            </a>
                        </div>                
                    </div>

                    <div class="province-info-area" v-if="memberships">
                        <div v-if="selectedRegion === 'bc'">
                            <PageContentBox :content="parseMainContent(memberships[0].content)"></PageContentBox>
                        </div>
                        <div v-if="selectedRegion === 'ab'">
                            <PageContentBox :content="parseMainContent(memberships[1].content)"></PageContentBox>
                        </div>
                        <div v-if="selectedRegion === 'sk'">
                            <PageContentBox :content="parseMainContent(memberships[2].content)"></PageContentBox>
                        </div>
                        <div v-if="selectedRegion === 'mb'">
                            <PageContentBox :content="parseMainContent(memberships[3].content)"></PageContentBox>
                        </div>
                        <div v-show="selectedRegion === 'on'">
                            <PageContentBox :content="parseMainContent(memberships[4].content)"></PageContentBox>
                        </div>
                        <div v-if="selectedRegion === 'qc'">
                            <PageContentBox :content="parseMainContent(memberships[5].content)"></PageContentBox>
                        </div>
                        <div v-if="selectedRegion === 'mt'">
                            <PageContentBox :content="parseMainContent(memberships[6].content)"></PageContentBox>
                        </div>
                        <div v-if="selectedRegion === 'nf'">
                            <PageContentBox :content="parseMainContent(memberships[7].content)"></PageContentBox>
                        </div>
                    </div>


				</div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "Memberships By Region - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'All of the answers to our frequently asked questions go here.' },
                { property: 'og:title', content: "Memberships By Region - Slo-Pitch National"},
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:url', content:  'https://www.slo-pitch.com/memberships-by-region' },
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/membershipsbyregion.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            selectedRegion: 'bc',
            memberships: null,
            grandslam: null
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        // console.log()

        if(this.$route.params.page === 'alberta') {
            this.selectedRegion = 'ab'            
        }
        if(this.$route.params.page === 'british-columbia') {
            this.selectedRegion = 'bc'
        }
        if(this.$route.params.page === 'saskatchewan') {
            this.selectedRegion = 'sk'
        }
        if(this.$route.params.page === 'quebec') {
            this.selectedRegion = 'qc'
        }
        if(this.$route.params.page === 'manitoba') {
            this.selectedRegion = 'mb'
        }
        if(this.$route.params.page === 'ontario') {
            this.selectRegion('on')
            this.$nextTick(() => {
                const brampton = { lat: 43.7249125, lng:-79.8297545 }
                const map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 6,
                    center: brampton,
                });

                const marker5 = new google.maps.Marker({
                    position: brampton,
                    map: map,
                });
            })
        }
        if(this.$route.params.page === 'maritimes') {
            this.selectedRegion = 'mt'
        }
        if(this.$route.params.page === 'newfoundland') {
            this.selectedRegion = 'nf'
        }
        

        if(this.$route.params.region) {
            
            this.selectRegion(this.$route.params.region)
            localStorage.setItem('province', this.selectedRegion)  

        }

        var vm = this

        axios.get('/api/pages/membership-by-region').then(results => {
            if(results.data.success === true) {
                vm.memberships = results.data.memberships
                vm.grandslam = results.data.grandslam
            }
        })

       
     
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
    },
    
    watch: {
      selectedRegion: function(info) {
          window.history.pushState('state', 'Slo-Pitch National', '/memberships-by-region/' + info);

          if(info === 'on') {
              this.$nextTick(() => {
                const brampton = { lat: 43.7249125, lng:-79.8297545 }
                const map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 6,
                    center: brampton,
                });

                const marker5 = new google.maps.Marker({
                    position: brampton,
                    map: map,
                });
              })
          }
      }  
    },
	methods: {
        selectRegion: function (region) {
            this.selectedRegion = region
            if(region === 'on') {
                this.$nextTick(() => {
                    setTimeout(() => {
                        const brampton = { lat: 43.7249125, lng:-79.8297545 }
                        const map = new google.maps.Map(document.getElementById("map"), {
                            zoom: 6,
                            center: brampton,
                        });

                        const marker5 = new google.maps.Marker({
                            position: brampton,
                            map: map,
                        });
                    }, 2000)
                })
            }
            
        },
        parseMainContent: function(content) {
            return JSON.parse(content)
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
