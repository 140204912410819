import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import moment from 'moment'

extend('email', {
	...email,
	message: 'Invalid email format'
})

extend('required', {
  ...required,
  message: (_field_) => {
    switch (_field_) {
      case 'tournament_name':
        return 'No event name entered.'
      case 'headquarter_location':
        return 'No location entered. This is required to get your region.'
      case 'city':
        return 'No city entered.'
      case 'tournament_date':
        return 'Event needs an event start date.'
      case 'tournament_end_date':
        return 'Event needs an event end date.'
      case 'reg_date':
        return 'Event needs a registration start date.'
      case 'reg_end_date':
        return 'Event needs a registration end date.'
      case 'roster_lock_date':
        return 'Event needs a roster lock date.'
      case 'team_price':
        return 'Event needs a team price.'
      case 'payment_methods':
        return 'Please choose one payment method.'
      default:
        return 'This field is required'
    }
  }
})

extend('min', {
  validate: (value, args) => {
    return value.length >= args.length;
  },
  message: (_field_) => {
    switch (_field_) {
      case 'tournament_name':
        return 'Name must be more than four letters.'
    }
  },
  params: ['length']
})

extend('dateDiff', {
  params: ['target'],
  validate(value, { target }) {
    return moment(value).isBefore(target)
  },
  message: (_field_) => {
    switch (_field_) {
      case 'tournament_date':
        return 'Start date should be less than end date.'
      case 'reg_date':
        return 'Registration start date should be less than end date.'
    }
  }
})

extend('payment_methods_required', {
  params: ['length'],
  validate(value, args) {

    return true
  },
  message: () => { return 'dasdasd' } 
})