<template>
	<div>
		<b-modal
			v-model="addPlayerModal"
			trap-focus
			:destroy-on-hide="false"
			aria-role="dialog"
			aria-modal>
            <template #default="props">
				<AddPlayerToTeamModal
					:eventTeam="eventTeam"
					:regionId="tournament.region_id"
					v-on:addPlayersToRoster="addPlayersToRoster"></AddPlayerToTeamModal>
                <!-- <div class="modal-card" style="width: 350px">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Add Players To Team</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
                    </header>
                    <section class="modal-card-body">
                        <b-tabs>
							<b-tab-item label="Player Pool">
								<nav class="level" v-for="(user, key) in eventTeam.team.core_roster" :key="key">
									<div class="level-right" style="margin-right: 10px;">
										<a class="button is-small" v-on:click="addToPlayersSelected(user.user)"><i class="fas fa-plus"></i></a>
									</div>
									<div class="level-left">
										<p class="level-item"><b>{{ user.user.first_name }} {{ user.user.last_name }}</b></p>
										<p class="level-item">{{ user.user.city }}</p>
										<p class="level-item">{{ user.user.gender }}</p>
			
										<p class="level-item">
											<span v-if="user.user.latest_waiver_signed === 18">Signed</span>
										</p>
									</div>
									
								</nav>

							
							</b-tab-item>
							<b-tab-item label="Search for Player">
								<section class="modal-card-body" style="min-height: 140px; overflow: scroll;">
									
									<b-field 
										label="Search for a player"
										message="You can enter email, phone, username or first &amp; last name">
										
										<b-input v-on:input="updatePlayerSearch($event)" placeholder="Search"></b-input>
									
									</b-field>
								</section>
								<section class="modal-card-body">
									<nav class="level" v-for="(user, key) in searchList" :key="key">
										<div class="level-right">
											<a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
										</div>
										<div class="level-left">
											<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
											<p class="level-item">{{ user.city }}</p>
											<p class="level-item">{{ user.gender }}</p>
				
											<p class="level-item">
												<span v-if="user.latest_waiver_signed === 18">Signed</span>
											</p>
										</div>
										
									</nav>

								
								</section>
							</b-tab-item>
						</b-tabs>
                    </section>
                    <div class="adding">
                        <p><b>Adding:</b></p>
                    </div>
                    
                    <section class="modal-card-body">
                        <nav class="level" v-for="(user, key) in playersSelected" :key="key">
                            <div class="level-left">
                                <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                                <p class="level-item">{{ user.city }}</p>
                            </div>
                            <div class="level-right">
                                <a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
                            </div>
                        </nav>
                    </section>
                    <footer class="modal-card-foot">
                        <a class="admin-button" v-on:click="addToRoster()">Add Players To Roster</a>
                    </footer>
                </div> -->
            </template>
        </b-modal>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="tournament">
				<div :class="['page-header', { sidebarClosed: !content_width }]" >
                    <div class="row">
                        <div class="col-md-9">
                            <div class="page-header-title" v-if="eventTeam">
								<span v-if="eventTeam.team.logo !== 'null'">
									<img :src="eventTeam.team.logo" width="50px" style="display: inline; float: left; margin-top: 10px; margin-right: 10px;"  alt="Team Logo">
								</span>
                                Event Roster For <span :style="'color: ' + eventTeam.team.color_1">{{ eventTeam.team.name }}</span> in {{ tournament.name }} s
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a :href="'/superadmin/manage/leagues/' + tournament.id" class="button">Back to Edit League</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
					<div class="row" v-if="eventTeam">
						<div class="col-md-12">
							<h1 :style="'margin-top: 0px; color: ' + eventTeam.team.color_1">{{ eventTeam.team.name }}</h1>
							
							<p v-if="eventTeam.division"><b>Division Entered: </b> {{ eventTeam.division.gender_slug.charAt(0).toUpperCase() }}{{ eventTeam.division.gender_slug.slice(1) }} {{ eventTeam.division.rank_id }}</p>
							<p><b>Recommended Division: </b> {{ eventTeam.roster.roster_score }}</p> 
							<p><b>Event Team ID:</b> # {{ eventTeam.id }}</p>
							<p><b>Team ID:</b> # {{ eventTeam.team_id }}</p>
							<a :href="'/superadmin/manage/teams/' + eventTeam.team.slug" class="button is-info">Go to Team in Manage Teams</a>
						
							<h3 style="margin-top: 0px;">Team Roster Registered for {{ tournament.name }}</h3>
							<a class="button is-info" v-on:click="addPlayerModal = true"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add Player To Roster</a>
							<a :href="'/dashboard/convenors/leagues/' + tournament.slug + '/event-roster/' + eventTeam.id + '/printable'" style="float: right;" class="button is-success"><i class="fas fa-save"></i>&nbsp;&nbsp;Download Roster PDF</a>
							
							<div class="table-overflow">
								<b-table
									style="margin-top: 30px;"
									:data="eventTeam.roster.roster_players"
									:striped="true"
									:focusable="true"
								>
									<b-table-column
										field="waiver_signed"
										label="Waiver"
										:sortable="true"
									>
										<template slot="header" slot-scope="{ column }">
											<b-tooltip :label="column.label" dashed>
												{{ column.label }}
											</b-tooltip>
										</template>
										<template v-slot="props">
											<div style="text-align: center;">
												
												<span v-if="checkWaivers(props.row.user.waivers) === true">
													<b-tag type="is-success">
														<i class="fas fa-check"></i>
													</b-tag>
												</span>
												<span v-if="checkWaivers(props.row.user.waivers) === false">
													<b-tag type="is-danger">
														<i class="fas fa-times"></i>
													</b-tag>
												</span>
											</div>
										</template>
									</b-table-column>
									<b-table-column
										field="user.first_name"
										label="First Name"
										:searchable="true"
										:sortable="true"
									>
										<template slot="header" slot-scope="{ column }">
											<b-tooltip :label="column.label" dashed>
												{{ column.label }}
											</b-tooltip>
										</template>
										<template slot="searchable" slot-scope="props">
											<b-input
												v-model="props.filters[props.column.field]"
												placeholder="Search"
												size="is-small"
											></b-input>
										</template>
										<template v-slot="props">
											{{ props.row.user.first_name }}
										</template>
									</b-table-column>
									<b-table-column
										field="user.last_name"
										label="Last Name"
										:searchable="true"
										:sortable="true"
									>

										<template slot="header" slot-scope="{ column }">
											<b-tooltip :label="column.label" dashed>
												{{ column.label }}
											</b-tooltip>
										</template>
										<template slot="searchable" slot-scope="props">
											<b-input
												v-model="props.filters[props.column.field]"
												placeholder="Search"
												size="is-small"
											></b-input>
										</template>
										<template v-slot="props">
											<b>{{ props.row.user.last_name }}</b>
										</template>
									</b-table-column>
									<b-table-column
										field="user.current_rank_id"
										label="Level"
										:searchable="true"
										:sortable="true"
									>
										<template slot="searchable" slot-scope="props">
											<b-input
												v-model="props.filters[props.column.field]"
												size="is-small"
												style="width: 30px;"
											></b-input>
										</template>
										<template v-slot="props">
											<RankComponent :currentRank="props.row.user.current_rank_id"
											:previousRank="props.row.user.previous_rank_id"></RankComponent>
										</template>
									</b-table-column>
									<b-table-column field="role" label="Role">
										<template slot="header" slot-scope="{ column }">
											<b-tooltip :label="column.label" dashed>
												{{ column.label }}
											</b-tooltip>
										</template>
										<template v-slot="props">
											<span class="tag is-success" v-if="props.row.core_roster_player.role_on_team === 1">
												Coach
											</span>
											<span class="tag is-warning" v-if="props.row.core_roster_player.role_on_team === 2">
												Team Contact
											</span>
											<span class="tag is-danger" v-if="props.row.core_roster_player.role_on_team === 3">
												Player
											</span>
											<span class="tag is-info" v-if="props.row.core_roster_player.coach_only === 1">
												Coach Only
											</span>
										</template>
									</b-table-column>
				
									<b-table-column field="user.city" label="City" :sortable="true">
										<template slot="header" slot-scope="{ column }">
											<b-tooltip :label="column.label" dashed>
												{{ column.label }}
											</b-tooltip>
										</template>
										<template v-slot="props">
											{{ props.row.user.city }}
										</template>
									</b-table-column>
									<b-table-column field="user.gender" label="Gender" :sortable="true">
										<template slot="header" slot-scope="{ column }">
											<b-tooltip :label="column.label" dashed>
												{{ column.label }}
											</b-tooltip>
										</template>
										<template v-slot="props">
											<span class="tag is-light">
												<b>{{ props.row.user.gender }}</b>
											</span>
										</template>
									</b-table-column>
									<b-table-column field="user.date_of_birth" label="Date of Birth" :sortable="true">
										<template slot="header" slot-scope="{ column }">
											<b-tooltip :label="column.label" dashed>
												{{ column.label }}
											</b-tooltip>
										</template>
										<template v-slot="props">
											<span class="tag is-light">
												<b>{{ moment(props.row.user.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
											</span>
										</template>
									</b-table-column>
									<b-table-column>
										<template v-slot="props">
											<b-dropdown hoverable aria-role="list">
												<button class="button is-small is-info" slot="trigger">
													<span>Change Role</span>
													<b-icon icon="menu-down"></b-icon>
												</button>

												<b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 1)" v-if="props.row.role_on_team !== 1">Change To Coach</b-dropdown-item>
												<b-dropdown-item aria-role="listitem" v-on:click="changeToPrimaryCoach(props.row)" v-if="team.primary_coach_id !== props.row.user_id">Change To Primary Coach</b-dropdown-item>
												<b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 2)" v-if="props.row.role_on_team !== 2">Change To Team Contact</b-dropdown-item>
												<b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 3)"  v-if="props.row.role_on_team !== 3">Change to Player</b-dropdown-item>
												
												<b-dropdown-item v-if="props.row.role_on_team !== 1" aria-role="listitem" v-on:click="removeFromTeam(props.row)">Remove from Team</b-dropdown-item>
											</b-dropdown>
										</template>
									</b-table-column>
									<b-table-column>
										<template v-slot="props">
											<a :href="'/superadmin/manage/users/' + props.row.user_id" class="button is-small is-info">View Player</a>
											<a v-on:click="removePlayer(props.row)" class="button is-small is-danger">Remove Player</a>
											
										</template>
									</b-table-column>
									<b-table-column label="Added to Roster On">
										<template v-slot="props">
											
											<b-tag>{{ moment(props.row.created_at).format('YYYY-MM-DD h:mm a') }}</b-tag>
										</template>
									</b-table-column>
								</b-table>
							</div>
						</div>
					</div>
                
                </div>
				
            </div>
            
		</div>
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'

import RankComponent from '../../components/RankComponent.vue'
import AddPlayerToTeamModal from '../../components/AddPlayerToTeamModal.vue'

import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			page: null,
			addPlayerModal: false,
			tournament: null,
			previous: null,
			upcoming: null,
			timer: null,
			searchForTeam: null,
			addTeamToEventSwitch: false,
			umpSearch: null,
			registered_teams: [],
			content_width: false,
			mountCheckboxes: null,
			searchList: [],
			canvasAdditionalDetailsLeft: null,
			savedCheatSheet: false,
			umpireFound: null,
			addedToCheatSheet: null,
			searchResults: null,
			selectedTeam: null,
			selectRegion: false,
			eventTeam: null,
			playersSelected: [],
			registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
			cheatSheet: {
				innings: 7,
				call_last_inning_after: 75,
				minimum_players: 10,
				hr_rule: '1',
				anticipation: true,
				ties: true,
				courtesy_runners: true,
				max_runs_per_inning: 6,
				mercy_rule: true,
				tag_at_home: true,
				throw_to_first_from_outfield: true,
				additional_rules: null
			}
		};
	},
	components: {
		Header,
		Footer,
		AddPlayerToTeamModal,
		RankComponent
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page
		var slug = this.$route.params.slug
		var event_roster_id = this.$route.params.id
		axios.post('/api/tournaments/review-checklist').then(results => {
			if(results.data.success === true) {
				vm.mountCheckboxes = results.data.checklist
			}
		})

		axios.get(`/api/leagues/get-league/${slug}`).then(results => {
			if(results.data.success === true) {			
				vm.tournament = results.data.league
			}
		})

		axios.get(`/api/leagues/get-registered-team/${event_roster_id}`).then(results => {
			
			if(results.data.success === true) {
				vm.eventTeam = results.data.registrant
			}
		})
	},
	computed: {
		spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender

            
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender) {
                        return true
                    }
                })
                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                if(find_insurance) {

                    vm.currentInsurance = find_insurance
                    vm.triggerFinalize = true
                    return true
                }
                vm.triggerFinalize = false
                return false
            } else {
                vm.triggerFinalize = false
                return false
            }
        },
		totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournament.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournament.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournament.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
		filterGroup: function() {
			var vm = this
			var grouped = _.groupBy(vm.mountCheckboxes, 'group')

			return grouped
		}
	},
	methods: {
		filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
		switchWidth: function($event) {
			this.content_width = !$event
		},
		saveUmpireCheatSheet: function() {
			var vm = this

			axios.post('/api/umpires/save-cheat-sheet', {
				cheat_sheet: vm.cheatSheet,
				event_id: vm.tournament.id,
				type: 'tournament'
			}).then(results => {
				if(results.data.success === true) {
					
					vm.savedCheatSheet = true
					// then go over and make it so you can add umpires to a tournament
					// then go over to umpire cheat sheet and make it display the cheat sheet you just got added to

					// then go back and connect the event checklistbnmkj
				}
			})
		},
		umpireEmailSearch: function(text) {
			
			var vm = this
			axios.post('/api/umpires/search-for-umpire', {
				search: text
			}).then(results => {
				if(results.data.success === true) {
					
					vm.umpireFound = results.data.umpire
				}
			})
		},
		addUmpireToTournament: function() {
			
			var vm = this
			axios.post('/api/umpires/add-to-cheat-sheet', {
				umpire_found: vm.umpireFound,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					
					this.$buefy.snackbar.open({
                        message: 'Added user to the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not added to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		eventChecklistSave: function($event, box) {
			
			var vm = this
			axios.post('/api/tournaments/save-checklist', {
				box: box,
				event_id: vm.tournament.id,
				event_type: 'tournament',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
                        message: 'Saved checklist',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		saveEventNotes: function($event) {
			
			var vm = this
			
			axios.post('/api/tournaments/save-checklist-notes', {
				event_id: vm.tournament.id,
				event_type: 'tournament',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
                        message: 'Saved checklist notes',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		removeUmpire: function(user) {
			var vm = this
			axios.post('/api/umpires/remove-from-cheat-sheet', {
				user_id: user.user.id,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					
					this.$buefy.snackbar.open({
                        message: 'Removed user from the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not deleted to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		addTeamToEvent: function() {
			var vm = this

			vm.addTeamToEventSwitch = true
		},
		searchForTeamFunction: function($event = null) {
			var vm = this
			
			if($event) {
				axios.post('/api/tournaments/search-for-team', {
					tournament_id: vm.tournament.id,
					search: $event,
					region_on: vm.selectRegion,
					region_id: vm.tournament.region_id
				}).then(results => {
					if(results.data.success === true) {
						

						vm.searchResults = results.data.teams
					}
				})
			} else {
				axios.post('/api/tournaments/search-for-team', {
					tournament_id: vm.tournament.id,
					search: vm.searchForTeam,
					region_on: vm.selectRegion,
					region_id: vm.tournament.region_id
				}).then(results => {
					if(results.data.success === true) {
						

						vm.searchResults = results.data.teams
					}
				})
			}
		},
		checkIfPreviousDate: function(tournament) {
			
			var start_date = moment(tournament.event_start_date)

			var is_after = start_date.isAfter(moment())
			
			// var now = moment().format('YYYY-MM-DD')
			// var tournament_start_date = moment(tournament.event_start_date).format('YYYY-MM-DD')
			// console.log('isAfter', now.isAfter(tournament.event_start_date))
			return is_after
		},
		registerTeamToEvent: function() {
			var vm = this
			axios.post('/api/tournaments/add-team-to-event', {
				registration: vm.registerForTournament
			}).then(results => {
				this.$buefy.snackbar.open({
					message: 'Added Team to the vent',
					type: 'is-success', // is-danger
					duration: 4000,
					queue: false
				})
			})
		},
		removeTeam: function(team) {
			
			confirm("Are you sure you want to remove this team from your event?")

			axios.post('/api/tournaments/remove-team-from-event', {
				team_id: team.id
			}).then(results => {
				this.$buefy.snackbar.open({
					message: 'Removed team from the event',
					type: 'is-success', // is-danger
					duration: 4000,
					queue: false
				})
			})
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		updatePlayerSearch: function($event) {
            
            var vm = this

			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {

				axios.post(`/api/users/search/players`, {
					search_name: $event,
					region: vm.tournament.region_id
				}).then(results => {
					if(results.data.success === true) {
						vm.searchList = results.data.users
					}
				})
			}, 1000)
		},
		addPlayersToRoster: function(data) {
			this.addToRoster(data.playersSelected)
		},
		addToRoster: function(players) {
            
			var vm = this
		

            axios.post('/api/tournaments/add-to-event-roster', {
                roster_players: players,
				team_id: vm.eventTeam.team_id,
				team_roster_id: vm.eventTeam.team_roster_id
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.updateRoster()
					vm.playersSelected = []
					vm.addPlayerModal = false

					this.$buefy.snackbar.open({
						message: 'Players added to roster successfully.',
						type: 'is-danger' // is-danger
					})
                } else {
                    vm.error = results.data.error
                }
            })
		},
		updateRoster: function() {
            var vm = this
            axios.get(`/api/leagues/get-registered-team/${vm.eventTeam.id}`).then(results => {
				if(results.data.success === true) {
					vm.eventTeam = results.data.registrant
				}
			})
		},
		removePlayer: function(row) {
			var vm = this
			var r = confirm('Are you sure you want to remove a player?')
			if(r === true) {
				axios.post('/api/tournaments/remove-player-from-event-team', {
					player_id: row.id
				}).then(results => {
					if(results.data.success === true) {

						vm.updateRoster()

						this.$buefy.snackbar.open({
							message: 'Player removed from event roster.',
							type: 'is-success' // is-danger
						})
					}
				})
			}
		},
		checkWaivers: function(row) {
			var vm = this
			// console.log('$latest_waiver', vm.$latest_waiver)
			var filter = _.filter(row, function(waiver) {
				
				if(waiver.waiver_id == vm.$latest_waiver ) { return true }
				return false
			})

			return filter.length > 0
		},
		changePlayerRole: function(player, role) {
            var vm = this
            axios.post('/api/teams/update-role', {
                role: role,
                player_id: player.core_roster_player.id,
            }).then(results => {
                if(results.data.success === true) {
                   	vm.updateRoster()
				
					this.$buefy.snackbar.open({
						message: 'You have updated the role of a player on your team.',
						type: 'is-success' // is-danger
					})
				
				
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Updating the role of a player did not work. #002',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
		changeToPrimaryCoach: function(player) {
			var vm = this
            
            
            var r = confirm('Are you sure you want to set a new primary coach?')
            if(r === true) {
                axios.post('/api/teams/set-primary-coach', {
                    team_id: vm.eventTeam.team.id,
                    user_id: player.user_id,
					player_id: player.core_roster_player.id,
                }).then(results => {
                    if(results.data.success === true) {
                        
                        this.$buefy.snackbar.open({
                            message: 'New player set as primary coach',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
		}
	}
}
</script>

<style lang="scss">
    
</style>
