<template>
    <div>
        
        <b-carousel-list 
            v-model="upcomingTournamentsStep" 
            :data="upcomingTournaments" 
            :items-to-show="carouselSize">
            <template slot="item" slot-scope="list">
                <div class="custom-event-area" style="text-align: center;">
                    <div class="only-ad">
                        {{ list.distance }} away from you
                    </div>
                    
                    <div class="poster">
                        <img src="https://placehold.it/500x312" v-if="!list.poster_image_url" alt="Sample Poster Image">
                        <img :src="list.poster_image_url" v-if="list.poster_image_url" alt="Poster Image URL">
                    </div>
                    <div class="custom-event-box">
                        <div class="custom-tournament-name">
                            {{ list.name }}
                        </div>
                        <div class="custom-tournament-description">
                            {{ list.description }}
                        </div>
                        <div class="custom-tournament-divisions" v-if="list.divisions.length > 0">
                            <div class="custom-tournament-divisions-header">
                                Divisions Offered:
                            </div>
                            <div class="row" style="display: flex;">
                                <div class="col-md-4" style="flex: 1" v-if="filterDivision(list.divisions, 'mens').length > 0">
                                    <div class="custom-tournament-division-header">
                                        Men's
                                    </div>
                                    
                                    <b-tag
                                        type="is-danger"
                                        :key="key"
                                        v-for="(division, key) in filterDivision(list.divisions, 'mens')">
                                        {{ division.rank_id }}
                                    </b-tag>
                                
                                </div>
                                <div class="col-md-4" style="flex: 1" v-if="filterDivision(list.divisions, 'ladies').length > 0">
                                    <div class="custom-tournament-division-header">
                                        Ladies
                                    </div>
                                
                                    <b-tag
                                        type="is-danger"
                                        :key="key"
                                        v-for="(division, key) in filterDivision(list.divisions, 'ladies')">
                                        {{ division.rank_id }}
                                    </b-tag>
                                
                                </div>
                                <div class="col-md-4" style="flex: 1" v-if="filterDivision(list.divisions, 'coed').length > 0">
                                    <div class="custom-tournament-division-header">
                                        Coed
                                    </div>
                                    
                                    <b-tag
                                        type="is-danger"
                                        :key="key"
                                        v-for="(division, key) in filterDivision(list.divisions, 'coed')">
                                        {{ division.rank_id }}
                                    </b-tag>
                                
                                </div>
                            </div>
                        </div>
                        <div class="custom-tournament-entry-fees">
                            <div class="entry-fee-text">
                                Entry Fee:
                            </div>
                            <div class="entry-fee-team">
                                <i class="fas fa-users"></i> ${{ list.team_registration_fee }}
                            </div>
                            <div class="entry-fee-team" v-if="list.player_registration_fee">
                                <i class="fas fa-user"></i> ${{ list.player_registration_fee }}
                            </div>
                        </div>
                    </div>
                    
                    <a role="button" class="admin-button" style="width: 100%;">Register Now!</a>
                </div>
            </template>
        </b-carousel-list>
    </div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
            en: true,
            upcomingTournaments: [],
            upcomingTournamentsStep: 0
		};
	},
	components: {

	},
	mounted: function() {
        var vm = this

        var vm = this
        // if(!sessionStorage.getItem('user_location')) {
        //     if(!navigator.geolocation) {
        //         vm.$buefy.toast.open({
        //             message: 'Geolocation is not supported by your browser',
        //             type: 'is-success', // is-danger
        //             duration: 2000,
        //             queue: false
        //         })
        //     } else {
        //         // status.textContent = 'Locating…';
        //         navigator.geolocation.getCurrentPosition(vm.geoLocateSuccess, vm.geoLocateError);
        //     }
        // }

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                

                var location = null

                if(sessionStorage.getItem('user_location')) {
                    location = sessionStorage.getItem('user_location')
                }

                axios.get('/api/users/upcoming-leagues-near-you', {
                    params: {
                        geolocate: location
                    }
                }).then(results => {
                    if(results.data.success === true) {
                        vm.upcomingTournaments = results.data.tournaments
                    }
                })
            }
        })

        
    },
    props: ['carouselSize'],
	computed: {
	
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        }
		// submitPaymentAttempt: function() {
        //     var vm = this 
        //     vm.paymentSuccess = true
        //     vm.$emit('payment:successful', true)
        // }
	}
}
</script>