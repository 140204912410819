<template>
    <div>
        <b-modal
            v-model="addPlayerToRosterSwitch" :width="600">
            <template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Add Player To The Roster</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
                        <p class="note">Don't see a player you want to add here? You first have to add them to your player pool. Click the yellow button to send you player pool. Find the player you want in there, and then come back and add them here.</p>
                        <a v-if="myRole" style="margin-bottom: 30px" :href="'/dashboard/players/edit-team/' + tournament.event_roster_youre_on.roster.team.slug" class="button"><i class="fas fa-pencil-alt"></i>&nbsp;&nbsp;&nbsp;Edit Your Player Pool</a>
                        
                        <b-table 
                            :data="tournament.players_not_on_event_roster" 
                            :checked-rows.sync="selectedRosterPlayers"
                            
                            checkable
                        >
                             <b-table-column
                                field="gender"
                                label="Gender">
                                <template v-slot="props">
                                    <span v-if="props.row.user.gender === 'Male'">
                                        <i class="fas fa-mars"></i>
                                    </span>
                                    <span v-if="props.row.user.gender === 'Female'">
                                        <i class="fas fa-venus"></i>
                                    </span>
                                </template>
                            </b-table-column>

                            <b-table-column
                                field="first_name"
                                label="First Name">
                                <template v-slot="props">
                                    {{ props.row.user.first_name }}
                                </template>
                            </b-table-column>
                            
                            <b-table-column
                                field="last_name"
                                label="Last Name">
                                <template v-slot="props">
                                    {{ props.row.user.last_name }}
                                </template>
                            </b-table-column>
                            <b-table-column
                                field="city"
                                label="City">
                                <template v-slot="props">
                                    {{ props.row.user.city }}
                                </template>
                            </b-table-column>
                            <b-table-column
                                field="positions"
                                label="Positions">
                                <template v-slot="props">
                                    <span v-if="props.row.user.positions">
                                        <span class="tag is-light" :key="key" v-for="(position, key) in props.row.user.positions.split(',')">
                                            {{ position }}
                                        </span>
                                    </span>
                                </template>
                            </b-table-column>
                        </b-table>
                        <span v-if="tournament.max_roster_size >= (tournament.full_roster.length + selectedRosterPlayers.length)">
                            <a v-on:click="addToRoster()" class="admin-button">Add ({{ selectedRosterPlayers.length }}) Selected Players to Event Roster</a>    
                        </span>
                        <span v-else>
                            You must remove players from your roster before you can add more.
                        </span>
					</section>
                    
				</div>
            </template>  
        </b-modal>
        <Header v-on:switch:navigation="switchWidth($event)"></Header>
        <div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" v-if="tournament">
            <div class="content-wrapper">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="page-header-title">
                                <img v-if="tournament.event_roster_youre_on.roster.team.logo" :src="tournament.event_roster_youre_on.roster.team.logo" width="50px" style="display: inline; float: left; margin-top: 1px; margin-right: 10px;" alt="Event Roster Logo">
                                <span v-if="type === 'league'">
                                    League
                                </span>
                                <span v-if="type === 'tournament'">
                                    Tournament
                                </span>
                                 &nbsp;You're Entered In - {{ tournament.name }} -  {{ tournament.event_roster_youre_on.roster.team.name }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a href="/dashboard/players/events-registered-in" class="button">Back to My Events</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
     
                    <b-tabs>
                        
                        <b-tab-item label="Team Roster">
                            
                            <!-- <p>Your player pool is the roster you have all your players on - not just necessarily this league's players. In order to add someone to this league/tournament roster, they must <b>first</b> be in your player pool!</p> -->
                            <a v-if="myRole" style="margin-bottom: 30px" :href="'/dashboard/players/edit-team/' + tournament.event_roster_youre_on.roster.team.slug" class="button"><i class="fas fa-pencil-alt"></i>&nbsp;&nbsp;&nbsp;Edit Your Player Pool</a>

                            
                            
                            <div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="padding">
                                            <h2>This is your {{ type }} roster.</h2> 
                                            <!-- <div  v-if="!tournament.is_passed_roster_lock_date"> -->
                                                <div class="roster-info" style="margin-bottom: 10px;">
                                                    <p v-if="tournament.roster_lock_date"><b>Rosters Open Until:</b> {{ moment(tournament.roster_lock_date).format("YYYY-MM-DD") }}</p>
                                                    <p v-if="tournament.max_roster_size"><b>Max Roster Limit:</b> {{ tournament.max_roster_size }} players</p>
                                                    <p><b>On your roster:</b> {{ tournament.full_roster.length }} players</p>
                                               
                                                    <a v-if="myRole" v-on:click="addPlayerToRosterSwitch = !addPlayerToRosterSwitch" class="button is-info" style=" margin-bottom: 30px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Add Player To {{ type.toUpperCase() }} Roster</a>
                                                </div>
                                            <!-- </div> -->
                                            <a :href="`/dashboard/players/event/${tournament.slug}/${roster_id}/${type}/pdf`" class="button is-success" >Print PDF of Roster</a>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <!-- <div  v-if="!tournament.is_passed_roster_lock_date"> -->
                                            <div class="padding">
                                                <div class="invite-people" style="max-width: 600px;">
                                                    <h2>Invite Players to Join Your Roster</h2>
                                                    <p><b>New for 2024: </b> Share this URL to your players, they will be walked through signing up or logging in, accepting their waiver and will be added to your roster automatically at the end!</p>
                                                    <div
                                                        v-on:click="copyGeneratedLink()" >
                                                        <b-input v-model="generatedInviteLink" ></b-input>
                                                    </div>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                                    <!-- <span v-if="tournament.max_roster_size >= tournament.full_roster.length"> -->
                                       
                                    <!-- </span> -->
                            </div>
							<b-message type="is-danger" v-if="computedRosterPlayers.wrong_age === true">
								This team has players outside of the age range permitted for this event:

								<div v-for="player in computedRosterPlayers.wrong_age_array" :key="player.id">
									{{ player.user.first_name }} {{ player.user.last_name }} - {{ player.age }} years old
								</div>
							</b-message>
                            <b-table
                                :data="generatedRoster"
                                :striped="true"
                                :focusable="true"
                                paginated
                                searchable
                                :row-class="rowClass"
                                per-page="20"
                            >
                                <b-table-column
                                    field="waiver_signed"
                                    label="Waiver"
                                >
                                    <!-- <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template> -->
                                    <template v-slot="props">
                                        <div style="text-align: center;">
                                            <i v-if="props.row.waiver" class="fas fa-check-circle"></i>
                                        </div>
                                    </template>
                                </b-table-column>
                                <b-table-column
                                    field="first_name"
                                    label="First Name"
                                    :searchable="true"
                                >
                                    <template v-slot:searchable="props">
                                        <b-input
                                            v-model="props.filters[props.column.field]"
                                            placeholder="Search"
                                            size="is-small"
                                        ></b-input>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.first_name }}
                                    </template>
                                </b-table-column>

                                <b-table-column
                                    field="last_name"
                                    label="Last Name"
                                    :searchable="true"
                                >

                                    <!-- <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template> -->
                                    <template v-slot:searchable="props">
                                        <b-input
                                            v-model="props.filters[props.column.field]"
                                            placeholder="Search"
                                            size="is-small"
                                        ></b-input>
                                    </template>
                                    <template v-slot="props">
                                        <b>{{ props.row.last_name }}</b>
                                    </template>
                                </b-table-column>
                                <b-table-column
                                    field="level"
                                    label="Level"
                                    :searchable="true"
                                >
                                    <template v-slot:searchable="props">
                                        <b-input
                                            v-model="props.filters[props.column.field]"
                                            size="is-small"
                                            style="width: 30px;"
                                        ></b-input>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.level }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="role" label="Role">
                                    <template v-slot="props">
                                        <span class="tag is-success" v-if="props.row.role === 1">
                                            {{ props.row.core_roster_player.user_id === tournament.event_roster_youre_on.roster.team.primary_coach_id ? "Primary" : ""}} Coach
                                        </span>
                                        <span class="tag is-warning" v-if="props.row.role === 2">
                                            Team Contact
                                        </span>
                                        <span class="tag is-danger" v-if="props.row.role === 3">
                                            Player
                                        </span>
                                    </template>
                                </b-table-column>

                                <b-table-column field="city" label="City">
                                    <template v-slot="props">
                                        {{ props.row.city }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="gender" label="Gender">
                                    <template v-slot="props">
                                        <span class="tag is-light">
                                            <b>{{ props.row.gender }}</b>
                                        </span>
                                    </template>
                                </b-table-column>
                                <b-table-column field="date_of_birth" label="Date of Birth" v-if="myRole">
                                    <template v-slot="props">
                                        <span class="tag is-light">
                                            <b>{{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                        </span>
                                    </template>
                                </b-table-column>
                                <b-table-column
                                    field="positions"
                                    label="Positions"
                                >
                                    <template v-slot="props">
                                        <span v-if="props.row.positions.length > 0">
                                            <span class="tag is-light" :key="key" v-for="(position, key) in props.row.positions">
                                                {{ position }}
                                            </span>
                                        </span>
                                    </template>
                                </b-table-column>
                                <b-table-column field="actions" label="Actions" v-if="myRole">
                                    <template v-slot="props">
                                        <a v-on:click="removeFromEventRoster(props.row)" v-if="props.row.core_roster_player.role_on_team !== 1 && myRole" class="button is-small is-danger" style="text-transform: uppercase; height: 60px;"><b>Remove From <br>{{ type }} Roster</b></a>
                                      
                                    </template>
                                </b-table-column>
                            </b-table>
                            
                        </b-tab-item>
                        <b-tab-item label="Event Information">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="padding">
                                        <div class="custom-event-area" style="text-align: center;">
                                            
                                            <div class="poster">
                                                <img src="../../../images/dashboard/sanctionedtournament.png" v-if="!tournament.poster_image_url" alt="Poster Image">
                                                <img :src="tournament.poster_image_url" v-if="tournament.poster_image_url" alt="Poster Image">
                                            </div>
                                            <div class="custom-event-box">
                                                <div class="custom-tournament-name">
                                                    {{ tournament.name }}
                                                </div>
                                                <div class="custom-tournament-description">
                                                    {{ tournament.description }}
                                                </div>
                                                <div class="custom-tournament-entry-fees">
                                                    <div class="entry-fee-text">
                                                        Entry Fee:
                                                    </div>
                                                    <div class="entry-fee-team">
                                                        <i class="fas fa-users"></i> ${{ tournament.team_registration_fee }}
                                                    </div>
                                                    <div class="entry-fee-team" v-if="tournament.player_registration_fee">
                                                        <i class="fas fa-user"></i> ${{ tournament.player_registration_fee }}
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>    

</template>

<script>
import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
var getAge = require('get-age')
import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
            content_width: false,
            slug: null,
            tournament: null,
            overLimit: false,
			wrong_age: false,
			wrong_age_array: [],
            addPlayerToRosterSwitch: false,
            selectedRosterPlayers: [],
            eventType: null,
            user: null
		};
	},
	components: {
		Header,
		Footer,
		
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
        this.slug = this.$route.params.slug
        this.roster_id = this.$route.params.roster
        this.type = this.$route.params.type
        
        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.user = results.data.user
                
            
            }
        })
        
        if(this.type === 'tournament') {
            
            axios.get('/api/users/tournament-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                if(results.data.success === true) {
                    
                    vm.tournament = results.data.tournament       
                }    
            })
            
        } else {
            axios.get('/api/users/league-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                if(results.data.success === true) {
                    vm.tournament = results.data.tournament    
                }       
            })
        }

	},
	computed: {
        generatedRoster: function() {
            var vm = this

            var player = {
                waiver: true,
                first_name: null,
                last_name: null, 
                level: null,
                role: null,
                city: null,
                gender: null,
                positions: []
            }

            var roster = []

            var i = 0

            for(i; i < this.tournament.full_roster.length; i++) {
                var roster_item = this.tournament.full_roster[i]
                roster.push({
                    waiver: roster_item.user.latest_waiver_signed === this.$latest_waiver ? true : false,
                    first_name: roster_item.user.first_name,
                    last_name: roster_item.user.last_name,
                    level: roster_item.user.current_rank_id,
                    role: roster_item.core_roster_player.role_on_team,
                    city: roster_item.user.city,
                    id: roster_item.id,
                    gender: roster_item.user.gender,
                    date_of_birth: roster_item.user.date_of_birth,
                    positions: roster_item.user.positions ? JSON.parse(roster_item.user.positions) : [],
                    user_id: roster_item.user.id,
                    core_roster_player: roster_item.core_roster_player,
                    event_roster_youre_on: roster_item.event_roster_youre_on,
                })
            }

            return roster
        },
        computedRosterPlayers: function($event) {
            var vm = this
			// eventTeam.roster.roster_players
            
            var overLimit = false
                var wrong_age = false
                var wrong_age_array = []
            if(vm.tournament.division) {
                var min_age = vm.tournament.division.min_age
                
                var i = 0

                for(i; i < vm.tournament.full_roster.length; i++) {
                    var user = vm.tournament.full_roster[i].user
                    var date_of_birth = user.date_of_birth

                    var format_to_first_of_year = moment(date_of_birth).format('YYYY') + '-01-01'
                    var their_age = getAge(format_to_first_of_year)
                    if(their_age < min_age) {
                        wrong_age_array.push({ ...vm.tournament.full_roster[i], age: their_age })
                        wrong_age = true
                    }
                }
                
                if(wrong_age === true) {
                    vm.wrong_age = true
                    vm.wrong_age_array = wrong_age_array
                } else {
                    vm.wrong_age = false
                }
            }
            
			return {
				overLimit: overLimit,
				wrong_age: wrong_age,
				wrong_age_array: wrong_age_array
			}
        },
        generatedInviteLink: function() {
            return `https://slo-pitch.com/login?slug=${this.slug}&roster_id=${this.roster_id}&type=${this.type}`;
        },
		myRole: function() {
            var vm = this;
            var now = moment();
            var rosterLockDate = moment(vm.tournament.roster_lock_date);

            if (vm.tournament.event_roster_youre_on.core_roster_player.role_on_team === 3 || now.isAfter(rosterLockDate)) {
                return false;
            } else {
                return true;
            }
        }
	},
	methods: {
		switchWidth: function($event) {
			this.content_width = !$event
        },
        moment: function(date, format) {
            return moment(date, format)
        },
        addRosterPlayersToEventTeam: function() {
            
        },
        updateRoster: function() {
            var vm = this
            if(this.type === 'league') {
                axios.get('/api/users/league-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                    if(results.data.success === true) {
                        vm.tournament = results.data.tournament    
                    }       
                })
            } else {
                axios.get('/api/users/tournament-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                    if(results.data.success === true) {
                        vm.tournament = results.data.tournament
                    }
                })
            }
            
        },
        addToRoster: function() {
            
			var vm = this

            axios.post('/api/users/add-to-event-roster', {
                roster_players: vm.selectedRosterPlayers,
                team_id: vm.tournament.event_roster_youre_on.roster.team.id,
                team_roster_id: vm.tournament.event_roster_youre_on.team_roster_id,
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateRoster()
					vm.selectedRosterPlayers = []
					vm.addPlayerToRosterSwitch = false

					this.$buefy.snackbar.open({
						message: 'Players added to roster successfully.',
						type: 'is-success' // is-danger
					})
                } else {
                    vm.error = results.data.error
                }
            })
        },
        removeFromEventRoster: function(player) {

            var r = confirm('Are you sure you want to remove this player from the event roster?')
            
            var vm = this
            if(r === true) {
                axios.post('/api/users/remove-from-event-roster', {
                    id: player.id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.updateRoster()

                        this.$buefy.snackbar.open({
                            message: 'Players removed from roster successfully.',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        },
        copyGeneratedLink: function() {
            var vm = this
            
            var copyText = this.generatedInviteLink
            navigator.clipboard.writeText(copyText).then(function() {
                vm.$buefy.snackbar.open({
                    message: 'Link copied to clipboard.',
                    type: 'is-success' // is-danger
                })
            }, function(err) {
                console.error('Could not copy text: ', err);
            });
        },
        rowClass(row) {

            return this.user && row.user_id === this.user.id ? 'highlight-row' : '';
        },
	}
}
</script>

<style lang="scss">
    
</style>
