<template>
	<div>
		<b-modal 
            v-model="approveUmpireSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal>
			<template #default="props">
				<div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Edit Umpire</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body" v-if="selectedUmpire" style="padding-bottom: 30px;">
						<b-field label="Change Umpire Status">
							<b-select v-model="selectedUmpire.approved">
								<option :value="null">Awaiting Approval</option>
								<option :value="1">Approved</option>
								<option :value="0">Unapproval</option>
							</b-select>
						</b-field>
					
						<b-field 
							style="margin-top: 10px;"
							:grouped="true"
							label="Umpire Permissions (Check All That Apply)"
							:group-multiline="true">
							<b-checkbox :true-value="1" v-model="selectedUmpire.find_gigs">Find Gigs</b-checkbox>
							<b-checkbox :true-value="1" v-model="selectedUmpire.cheat_sheet">Cheat Sheet</b-checkbox>
							<b-checkbox :true-value="1" v-model="selectedUmpire.clinics">Clinics</b-checkbox>
						</b-field>
						
					</section>
					<footer class="modal-card-foot">
                        <!-- <button v-on:click="approveUmpire()" class="admin-button"><i class="fas fa-save"></i> Edit Umpire</button> -->
                    </footer>
				</div>
			</template>
		</b-modal>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper" >
				<div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Manage Permissions
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area" style="padding-top: 20px;">
                    <div class="row">
                        <div class="col-md-12">                            
                            <div class="row">
                                <div class="col-md-12">
                                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                        <div
                                            slot="trigger" 
                                            slot-scope="props"
                                            class="card-header"
                                            role="button"
                                            aria-controls="contentIdForA11y3">
                                            <p class="card-header-title">
                                                Search & Control
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                        <div class="card-content">
                                            <div class="content">
                                                <b-field grouped group-multiline>
                                                    <div v-for="(column, index) in columnTemplate"
                                                        :key="index"
                                                        class="control">
                                                        <b-checkbox v-model="column.visible">
                                                            {{ column.title }}
                                                        </b-checkbox>
                                                    </div>
                                                </b-field>
                                                <hr>
                                                <b-field grouped group-multiline >
                                                    <!-- <b-field label="Umpire Approval">
                                                        <b-select v-model="searchables.approved">
                                                            <option value="*">All</option>
                                                            <option :value="1">Approved</option>
                                                            <option :value="'null'">Unapproved</option>
                                                        </b-select>
                                                    </b-field> -->
                                                    <!-- <b-field label="Region" v-if="specific_region === false">
                                                        <b-select v-model="searchables.region">
                                                            <option value="*">All</option>
                                                            <option :value="1">Alberta</option>
                                                            <option :value="2">British Columbia</option>
                                                            <option :value="3">Manitoba</option>
                                                            <option :value="4">Ontario</option>
                                                            <option :value="5">Maritimes</option>
                                                            <option :value="6">Quebec</option>
                                                            <option :value="7">Newfoundland & Labrador</option>
                                                            <option :value="8">Saskatchewan</option>
                                                        </b-select>
                                                    </b-field> -->
                                                    
                                                    <!-- <b-field label="Gender">
                                                        <b-select v-model="searchables.gender">
                                                            <option value="*">All</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="X">X</option>
                                                        </b-select>
                                                    </b-field> -->
                                                    <!-- <b-field label="Waiver Signed"  style="margin-right: 50px;">
                                                        <b-select v-model="searchables.waiver_signed">
                                                            <option value="*">All</option>
                                                            <option value="true">Signed</option>
                                                            <option value="false">Unsigned</option>
                                                        </b-select>
                                                    </b-field> -->
                                                    <!-- <b-field label="Number of Results">
                                                        <b-select v-model="searchables.limit">
                                                            <option :value="100">100</option>
                                                            <option :value="500">500</option>
                                                            <option :value="1000">1000</option>
                                                            <option :value="2500">2500</option>
                                                            <option :value="5000">5000</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Per Page">
                                                        <b-select v-model="perPage">
                                                            <option value="10">10 per page</option>
                                                            <option value="25">25 per page</option>
                                                            <option value="50">50 per page</option>
                                                            <option value="100">100 per page</option>
                                                        </b-select>
                                                    </b-field> -->
                                                    <!-- <b-field label="Carded Year">
                                                        <b-select v-model="searchables.year">
                                                            
                                                            <option
                                                                v-for="option in computedYears"
                                                                :value="option"
                                                                :key="option">
                                                                {{ option }}
                                                            </option>
                                
                                                        </b-select>
                                                    </b-field> -->
                                                    <!-- <b-field label="Show Deleted">
                                                        <b-switch v-model="searchables.deleted_at"></b-switch>
                                                    </b-field> -->
                                                </b-field>
                                                <b-field grouped group-multiline style="margin-top: 30px;" >
                                                    
                                                    <!-- <b-field label="ID" style="width: 60px;">
                                                        <b-input v-model="searchables.id"></b-input>
                                                    </b-field>
                                                    <b-field label="First Name" style="width: 150px;">
                                                        <b-input v-model="searchables.first_name"></b-input>
                                                    </b-field>
                                                    <b-field label="Last Name" style="width: 150px;">
                                                        <b-input v-model="searchables.last_name"></b-input>
                                                    </b-field>
                                                    <b-field label="Email">
                                                        <b-input v-model="searchables.email"></b-input>
                                                    </b-field>
                                                    <b-field label="Phone">
                                                        <b-input v-model="searchables.phone"></b-input>
                                                    </b-field> -->
                                                    <!-- <b-field label="City">
                                                        <b-input v-model="searchables.city"></b-input>
                                                    </b-field>
                                                    <b-field label="Date of Birth">
                                                        <b-datepicker
                                                            v-model="searchables.date_of_birth"
                                                            placeholder="Click to select..."
                                                            icon="calendar-today"
                                                            trap-focus>
                                                        </b-datepicker>
                                                    </b-field> -->
                                                </b-field>
                                                <hr>
                                                <div class="content">
                                                    <a class="button is-info" v-on:click="sendMessage = true"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp; Send Email To Selected Users</a>
                                                    <vue-excel-xlsx
                                                        :data="selectedUsers"
                                                        :columns="columnTemplate"
                                                        :filename="'filename'"
                                                        :sheetname="'sheetname'"
                                                        :class="'button is-success'"
                                                        >
                                                        <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
                                                    </vue-excel-xlsx>
                                                    <a class="button is-warning" v-on:click="selectedUsers = umpires">Select all (Multipage) ({{ selectedUsers.length }})</a>
                                                    <div class="send-email" v-if="sendMessage" style="margin-top: 30px;">
                                                         <div class="contacts-selected" style="margin-top: 30px;">
                                                            <p><b>Sending To:</b></p>
                                                            <b-tag v-for="(email, index) in computedEmails" :key="index">{{ email }}</b-tag>
                                                        </div>
                                                        <b-field label="Subject">
                                                            <b-input v-model="sendEmail.subject"></b-input>
                                                        </b-field>
                                                        <b-field label="Message">
                                                            <b-input v-model="sendEmail.message"
                                                            maxlength="2000" type="textarea"></b-input>
                                                        </b-field>

                                                        <a class="admin-button" role="button" v-on:click="sendMessageToPlayers()">Send Message to Selected Players</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            
                            

                            <b-table
                                :data="umpires"
                                ref="table"
                                :sticky-header="true"
                                :height="'100%'"
                                :paginated="true"
                                :current-page="1"
                                :per-page="perPage"
                                :pagination-simple="false"
                                :pagination-position="'bottom'"
                                :checked-rows.sync="selectedUsers"
                                checkable
                                detailed
                                detail-key="id"
                                :show-detail-icon="showDetailIcon"
                                
                                default-sort="user.first_name"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">
                                <template
                                    v-for="(column, index) in columnTemplate"
                                    >
                                    <b-table-column 
                                        v-bind="column"
                                        
                                        :key="index" 
                                        :sortable="true"
                                        :label="column.title"
                                        :visible="column.visible">

                                        
                                        <template v-slot="props" :width="props.column.width">
                                            <span v-if="props.column.field === 'gender'">
                                                <span v-if="props.row.gender === 'Male'">
                                                    <b-tag type="is-danger is-light">
                                                        <i class="fas fa-mars"></i> Male
                                                    </b-tag>
                                                    
                                                </span>
                                                <span v-if="props.row.gender === 'Female'">
                                                    <b-tag type="is-link is-danger">
                                                        <i class="fas fa-venus"></i> Female
                                                    </b-tag>
                                                </span>
                                            </span>
                                            <span v-else-if="props.column.field === 'umpire_approved'">
                                                <b-tag v-if="props.row.umpire.approved === 1" type="is-success">
                                                    Approved
                                                </b-tag>
                                                <b-tag v-else type="is-danger">
                                                    Unapproved
                                                </b-tag>
                                                
                                            </span>
                                            <span v-else-if="props.column.field === 'first_name'">
                                                <b :class="{ 'is-deleted': props.row.deleted_at !== null }">{{ props.row.first_name }}</b>
                                            </span>
                                            <span v-else-if="props.column.field === 'last_name'">
                                                <b :class="{ 'is-deleted': props.row.deleted_at !== null }">{{ props.row.last_name }}</b>
                                            </span>
                                            <span v-else-if="props.column.field === 'default_region_id'">
                                                {{ props.row.region.name }}
                                            </span>
                                            <span style="display: block; width: 100px" v-else-if="props.column.field === 'date_of_birth'">
                                                {{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}
                                            </span>
                                            <span v-else-if="props.column.field === 'last_login'">
                                                <span v-if="props.row.last_login">
                                                    {{ moment(props.row.last_login).utc().format('YYYY-MM-DD') }}
                                                </span>
                                            </span>
                                            <span v-else-if="props.column.field === 'latest_waiver_signed'">
                                                <span v-if="props.row.latest_waiver_signed == true">
                                                    <b-tag type="is-success">
                                                        <i class="fas fa-check"></i>
                                                    </b-tag>
                                                </span>
                                                <span v-else>
                                                    <b-tag type="is-danger">
                                                        <i class="fas fa-times"></i>
                                                    </b-tag>
                                                </span>
                                            </span>
                                            <span v-else>
                                                {{ props.row[column.field] }}
                                            </span>
                                        </template>
                                        

                                    </b-table-column>
                                </template>
                                <template slot="detail" slot-scope="props">
                                    <div class="row">

                                        <div class="col-md-3">
                                            <div>
                                                <a class="button" v-on:click="resetPassword(props.row)">Reset Password</a>
                                                <a class="button" :href="'/superadmin/manage/users/' + props.row.user_id">View Profile</a>
												<!-- <button style="margin-top: 10px;" v-on:click="editUmpire(props.row)" class="button is-info">Edit Umpire</button> -->
            
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <div class="panel is-primary">
                                                    <div class="panel-heading" style="font-size: 12px; color: #fff;">
                                                        Security
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Question:</b></td>
                                                                        <td>{{ props.row.security_question }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Answer:</b></td>
                                                                        <td>{{ props.row.security_answer }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="padding">
                                                <div class="panel is-danger">
                                                    <div class="panel-heading " style="font-size: 12px;">
                                                        Emergency
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Name:</b></td>
                                                                        <td>{{ props.row.emergency_contact }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Phone:</b></td>
                                                                        <td>{{ props.row.emergency_contact_phone }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <div class="panel is-info">
                                                    <div class="panel-heading" style="font-size: 12px;">
                                                        Address
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Address:</b></td>
                                                                        <td>{{ props.row.street_address }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>City:</b></td>
                                                                        <td>{{ props.row.city }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Province:</b></td>
                                                                        <td>{{ props.row.province }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Postal Code:</b></td>
                                                                        <td>{{ props.row.postal_code }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from 'axios'
import moment from 'moment'
export default {
	data() {

		return {
            isLoading: true,
            authUser: { first_name: null },
            content_width: false,
            defaultOpenedDetails: [1],
            editUser: null,
            showDetailIcon: true,
			sendMessage: false,
			selectedUmpire: null,
			approveUmpireSwitch: false,
			
			approvalTypes: [{
				slug: 'awaiting',
				name: 'Awaiting Approval',
			}, {
				slug: 'approved',
				name: 'Approved',
			}, {
				slug: 'unapproved',
				name: 'Unapproved',
			}, {
				slug: 'all',
				name: 'All'
			}],
            sendEmail: {
                subject: null,
                message: null
            },
            // editor: ClassicEditor,
            perPage: 50,
            
            selectedUsers: [],
            // editorData: '<p>Rich-text editor content.</p>',
            // editorConfig: {
            //     // The configuration of the rich-text editor.
            // },
            deleted_at: false,
            specific_region: false,
            searchables: {
                id: null,
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                city: null,
                username: null,
                approved: '*',
                region: '*',
                gender: '*',
                date_of_birth: null,
                waiver_signed: '*',
                deleted_at: false,
                limit: 100,
                year: moment().format('YYYY')
			},
			umpires: [],
            searching: {
                'first_name': null,
                'last_name': null,
                'username': null,
                'email': null,
                'phone': null,
                'city': null,
                'date_of_birth': null,
                'latest_waiver_signed': null
            },
            users: [],
            regions: [],
            columnTemplate: [
                { title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
                { title: 'Specific Region Only', label: 'Region', field: 'specific_region_name', visible: true, searchable: false },
                // { title: 'Gender', label: 'Gender', field: 'gender', visible: true, searchable: false },
                // { title: 'Approved', label: 'Approved', field: 'umpire_approved', visible: true, searchable: false, width: 50 },
                { title: 'First Name', label: 'First Name', field: 'first_name', visible: true, searchable: false },
                { title: 'Last Name', label: 'Last Name', field: 'last_name', visible: true, searchable: false },
                // { title: 'Username', label: 'Username', field: 'username', visible: true, searchable: false },
                { title: 'Email', label: 'Email', field: 'email', visible: false, searchable: false },
                { title: 'Phone', label: 'Phone', field: 'phone', visible: false, searchable: false },
                { title: 'Superadmin Access', label: 'Superadmin Accesss', field: 'superadmin_access', visible: true, searchable: false },
                { title: 'Manage Site Pages', label: 'Manage Site Pages', field: 'manage_site_pages', visible: true, searchable: false },
                { title: 'Manage Blog Posts', label: 'Manage Blog Posts', field: 'manage_blog_posts', visible: true, searchable: false },
                { title: 'Manage Leagues', label: 'Manage Leagues', field: 'manage_leagues', visible: true, searchable: false },
                { title: 'Manage Tournaments', label: 'Manage Tournaments', field: 'manage_tournaments', visible: true, searchable: false },
                { title: 'Manage Teams', label: 'Manage Teams', field: 'manage_teams', visible: true, searchable: false },
                { title: 'Manage Umpires', label: 'Manage Umpires', field: 'manage_umpires', visible: true, searchable: false },
                { title: 'Manage Clinics', label: 'Manage Clinics', field: 'manage_clinics', visible: true, searchable: false },
                { title: 'Manage Players', label: 'Manage Players', field: 'manage_players', visible: true, searchable: false },
                { title: 'Manage Waivers', label: 'Manage Waivers', field: 'manage_waivers', visible: true, searchable: false },
                { title: 'Leagues Reporting', label: 'Leagues Reporting', field: 'leagues_reporting', visible: true, searchable: false },
                { title: 'Tournaments Reporting', label: 'Tournaments Reporting', field: 'tournaments_reporting', visible: true, searchable: false },
                { title: 'Payments Reporting', label: 'Payments Reporting', field: 'payments_reporting', visible: true, searchable: false },
                { title: 'Payments Reporting', label: 'Payments Reporting', field: 'payments_reporting', visible: true, searchable: false },
                { title: 'Manage Forms', label: 'Manage Forms', field: 'manage_forms', visible: true, searchable: false },
                { title: 'Manage Form Results', label: 'Manage Form Results', field: 'manage_form_results', visible: true, searchable: false },
                { title: 'Communication Log', label: 'Communication Log', field: 'communication_log', visible: true, searchable: false },
                { title: 'Merge Users', label: 'Merge Users', field: 'merge_users', visible: true, searchable: false },
                { title: 'Manage Umpire Exams', label: 'Manage Umpire Exams', field: 'manage_umpire_exams', visible: true, searchable: false },
                { title: 'Manage Convenors', label: 'Manage Convenors', field: 'manage_convenors', visible: true, searchable: false },
                { title: 'Manage Orders', label: 'Manage Orders', field: 'manage_orders', visible: true, searchable: false },
                { title: 'Manage Permissions', label: 'Manage Permissions', field: 'manage_permissions', visible: true, searchable: false },
                { title: 'Manage Region Rates', label: 'Manage Permissions', field: 'manage_region_rates', visible: true, searchable: false },
                
                // { title: 'City', label: 'City', field: 'city', visible: false, searchable: false },
                // { title: 'Date of Birth', label: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
                // { title: 'Waiver', label: 'Waiver', field: 'latest_waiver_signed', visible: true, searchable: false },
                // { title: 'Correct Answers', label: 'Correct Answers', field: 'correct_answers', visible: true, searchable: false },
                // { title: 'Completed On', label: 'Completed On', field: 'completed_on', visible: true, searchable: false },
                { title: 'Last Login', label: 'Last Login', field: 'last_login', visible: false },
            ]
		};
	},
	components: {
		Header,
		Footer,
    },
    watch: {
        deleted_at: function(test) {

        },
        searchables: {
            handler(val) {
                this.checkingThis()
            }, deep: true
        }
    },
	mounted: function() {
		// grab all the dashboard information now
        var vm = this
        
        axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})

        if(window.innerWidth < 800) {
            vm.content_width = true
		}        
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_umpires !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                if(results.data.user_permissions.specific_region_only) {
                    vm.searchables.region = results.data.user_permissions.specific_region_only
                    vm.specific_region = true
                }
                this.getUmpires()

                vm.isLoading = false
            }
        })
	},
	computed: {
        computedYears: function() {
			var years = []
			var lowest_year = 2019

			var currentDate = new Date();
			var current_year = currentDate.getFullYear();

			// If the current month is September (9) or later, increment the year
			if (currentDate.getMonth() >= 8) {
				current_year++;
			}

			years.push('All')

			var years_difference = current_year - lowest_year
			var i = 0
			for(i; i < years_difference; i++) {
				years.push(current_year - i)
			}

			return years
		},
        computedEmails: function() {
			var vm = this
			var array = []
            if(vm.selectedUsers.length > 0) {
                var i = 0
                for(i; i < vm.selectedUsers.length; i++) {
                    var user = vm.selectedUsers[i]
                    
                    array.push(user.email)
                }
            }
			return array
		}
	},
	methods: {
        getUmpires: function() {
            var vm = this
            var params = this.$route.query

			var keys = Object.keys(params)
			
			for(const key of keys) {
				vm.searchables[key] = params[key]
			}	
			
            axios.get('/api/superuser/permissions', { params: vm.searchables }).then(results2 => {
                if(results2.data.success === true) {			
                    vm.umpires = results2.data.permissions
                }
            })
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        isString: function($event) {
            
		},
		editUmpire: function(umpire) {
			var vm = this
			vm.approveUmpireSwitch = true
			vm.selectedUmpire = Object.assign({}, umpire)
		},
		approveUmpire: function() {
			var vm = this

			axios.post('/api/superuser/update-umpire', {
				find_gigs: vm.selectedUmpire.find_gigs,
				cheat_sheet: vm.selectedUmpire.cheat_sheet,
				clinics: vm.selectedUmpire.clinics,
				selected_umpire_id: vm.selectedUmpire.id,
				approved: vm.selectedUmpire.approved
			}).then(results => {
				if(results.data.success === true) {
					vm.approveUmpireSwitch = false
					vm.selectedUmpire = null

					this.$buefy.snackbar.open({
						message: 'Umpire officially updated',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong when updating the umpire.',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
        findRegion: function(row) {
            var vm = this
            var region = _.find(vm.regions, function(region) {
                return region.id === row
            })
            if(region) {
                return region.name
            }

            return null
        },
        resetPassword: function(user) {
            var r = confirm('Are you sure you want to reset their password? Remind them, they will be sent an email, which they must click the link and log in.')
            var vm = this
            if(r === true) {
                axios.post('/auth/reset-password', {
                    email: user.email
                }).then(results => {
                    if(results.data.success === true) {
                        vm.rememberEmail = true
                        this.$buefy.snackbar.open({
                            message: 'Password reset sent to ' + user.first_name + ' ' + user.last_name,
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    }
                })
            }
        },
        checkingThis: function() {
            var vm = this
            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                var params = new URLSearchParams()
				var keys = Object.keys(vm.searchables)
				for(const key of keys) {
					if(vm.searchables[key] !== null) {
						params.set(key, vm.searchables[key])
					}
				}

				window.history.replaceState(null, null, '?'+params.toString())

                axios.get('/api/superuser/permissions', { params: vm.searchables }).then(results2 => {
                    if(results2.data.success === true) {						
                        vm.umpires = results2.data.permissions
                    }
                })
            }, 1000)

        },
        sendMessageToPlayers: function() {
            var vm = this

            axios.post('/api/superuser/send-messages-to-players', {
                message: vm.sendEmail,
                emails: vm.computedEmails,
            }).then(results => {
                if(results.data.success === true) {
                    vm.sendEmail = {
                        subject: null,
                        message: null
                    }

                    vm.selectedUsers = []
                    this.$buefy.snackbar.open({
                        message: 'You have successfully sent your message!',
                        type: 'is-success' // is-danger
                    })
                }
            })
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
