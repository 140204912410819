<template>
    <div>
        <section class="modal-card-body" style="overflow: scroll;">
            <div class="row" v-if="registerForTournamentDetails.tournamentSelected">
              
                <div class="col-md-12">
                    <div class="padding">
                        <div  v-if="step === 0">
                            <div class="row" style="margin-top: 10px;">
                                <div class="col-md-4">
                                    <b-field label="Select Your Team">
                                        <b-select v-if="teams" v-model="selectedTeam" placeholder="Select your team">
                                            <option :value="team" :key="key" v-for="(team,key) in teams.coach">{{ team.team_name }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="col-md-6">
                                    <b-field label="Enter a Division" vertical>
                                        <b-select placeholder="Select Gender" v-model="registerForTournament.gender">
                                            <option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
                                            <option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
                                            <option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
                                        </b-select>
                                        
                                        <b-select style="margin-left: 10px;" placeholder="Select Division" v-model="registerForTournament.division">
                                            <option :value="division" :key="division.id" v-for="division in totalDivisions[registerForTournament.gender]">{{ division.rank_id }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                            <div v-if="team_roster">
                                <b-field label="Select Players on Your Roster for This Event" style="margin-top: 20px;"></b-field>
                                
                                <p class="note">Please check off all players on your team roster playing with you this event. Don't worry, you can change this until the roster lock date in the event after you've completed registration.</p>
                                <div class="players-selected">
                                    <p style="text-align: center;"><b>{{ selectedRosterPlayers.length }} Players Selected</b></p>
                                </div>
                                <b-table
                                    :data="filterRoster(team_roster)"
                                    checkable
                                    :checked-rows.sync="selectedRosterPlayers"
                                    ref="table">

                                    <b-table-column
                                        field="gender"
                                        label="Gender">
                                        <template v-slot="props">
                                            <span v-if="props.row.user.gender === 'Male'">
                                                <i class="fas fa-mars"></i>
                                            </span>
                                            <span v-if="props.row.user.gender === 'Female'">
                                                <i class="fas fa-venus"></i>
                                            </span>
                                        </template>
                                    </b-table-column>

                                    <b-table-column
                                        field="first_name"
                                        label="First Name">
                                        <template v-slot="props">
                                            {{ props.row.user.first_name }}
                                        </template>
                                    </b-table-column>
                                    
                                    <b-table-column
                                        field="last_name"
                                        label="Last Name">
                                        <template v-slot="props">
                                            {{ props.row.user.last_name }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="city"
                                        label="City">
                                        <template v-slot="props">
                                            {{ props.row.user.city }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="positions"
                                        label="Positions">
                                        <template v-slot="props">
                                            <span v-if="props.row.user.positions">
                                                <span class="tag is-light" :key="key" v-for="(position, key) in props.row.user.positions.split(',')">
                                                    {{ position }}
                                                </span>
                                            </span>
                                        </template>
                                    </b-table-column>
                                </b-table>
                                <div class="players-selected">
                                    <p style="  text-align: center;"><b>{{ selectedRosterPlayers.length }} Players Selected</b></p>
                                </div>

                                <div class="check-for-spn-registration" v-if="selectedRosterPlayers.length > 0">
                                    <div class="spn-number-box does-have-spn-number" v-if="spnRegistration === 'fully_insured'">
                                        Your team already has paid for SPN Insurance for this team in this division! 
                                    </div>
                                    <div class="spn-number-box does-not-have-spn-number" v-if="spnRegistration === 'upgrade'">
                                        <h4 style="margin-top: 0px; font-weight: 700;">Upgrade Your SPN4You Membership & Insurance</h4>
                                        <p>The last time that you purchased membership with this team, it was single event membership. To play in another event, you must upgrade:</p>
                                        <div class="row">
                                            <div class="col-md-6" :key="key" v-for="(option, key) in regionRateOptions">
                                                <div
                                                    v-if="paymentSuccess === false"
                                                    v-on:click="selectInsuranceOption(option)" :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                    <div class="select-box-header">{{ option.name }}</div>
                                                    <div class="select-box-price">${{ option.price }}</div>
                                                </div>
                                                <div
                                                    v-else
                                                    :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                    <div class="select-box-header">{{ option.name }}</div>
                                                    <div class="select-box-price">${{ option.price }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="selectedInsuranceOption">
                                      
                                            <NewPaymentProcessor
                                                :productId="selectedInsuranceOption.id"
                                                :regionId="tournamentSelected.region_id"
                                                v-on:payment:successful="paymentSuccessSwitch($event)"
                                                :teamName="tournamentSelected.name"
                                                >
                                            </NewPaymentProcessor>
                                        </div>
                                    </div>
                                    <div class="spn-number-box does-not-have-spn-number" v-if="spnRegistration === false">
                                        <h4 style="margin-top: 0px; font-weight: 700;">Your SPN4You Membership & Insurance</h4>
                                        <p>You must have an SPN4You Membership for each gendered division your team enters. So after this payment, you will be able to play any {{ registerForTournament.gender }} division in future tournaments this year.</p>
                                        <div class="row">
                                            <div class="col-md-4" :key="key" v-for="(option, key) in regionRateOptions">
                                                <div
                                                    v-if="paymentSuccess === false"
                                                    v-on:click="selectInsuranceOption(option)" :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                    <div class="select-box-header">{{ option.name }}</div>
                                                    <div class="select-box-price">${{ option.price }}</div>
                                                </div>
                                                <div
                                                    v-else
                                                    :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                    <div class="select-box-header">{{ option.name }}</div>
                                                    <div class="select-box-price">${{ option.price }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="note">If you select Single Use, the next time you sign up with this team you will be prompted to pay the difference.</p>
                                        <div v-if="selectedInsuranceOption">
                              
                                            <NewPaymentProcessor
                                                :productId="selectedInsuranceOption.id"
                                                :regionId="tournamentSelected.region_id"
                                                :tournamentName="tournamentSelected.name"
                                                v-on:payment:successful="paymentSuccessSwitch($event)"
                                                :teamName="newTournamement.name"
                                                >
                                            </NewPaymentProcessor>
                                        </div>
                                    </div>
                                </div>
                            
                                <button v-if="triggerFinalize && selectedRosterPlayers.length > 0" v-on:click="finalizeAndRegister()" class="admin-button" style="margin-top: 20px;">Finalize & Register</button>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

import NewPaymentProcessor from '../components/NewPaymentProcessor.vue'
export default {
	data() {
		return {
            en: true,
            upcomingTournaments: [],
            upcomingTournamentsStep: 0,
            tournamentSelected: null,
            step: 0,
            selectedTeam: null,
            selectedRosterPlayers: [],
            registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
            teams: null,
            team_roster: [],
            region_rate: null,
            selectedInsuranceOption: null,
            currentInsurance: null,
            paymentSuccess: false,
            triggerFinalize: false
		};
	},
	components: {
        // add multiple teams to the divisions, limit at one team per mens coed and ladies
        NewPaymentProcessor
    },
    watch: {
        selectedTeam: function($event) {
            var vm = this
            axios.get('/api/rosters/grab-roster/' + $event.team_slug).then(results => {
                if(results.data.success === true) {
                    vm.team_roster = results.data.team.core_roster
                }
            })
        },
        paymentSuccess: async function($event) {
            
            // now trigger automatically Submit & Receive Your Certificate

            await this.finalizeAndRegister()
        }
    },
    props: ['registerForTournamentDetails', 'tournamentId'],
	mounted: function() {
        var vm = this

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(vm.authUser.default_region_id === 4) {
                    alert('You cannot sign up for events in this region. We are working hard on the new website. Please be patient. You will be redirected back to the home page.')

                    vm.$router.push('/')
                }
                

                var location = null

                if(sessionStorage.getItem('user_location')) {
                    location = sessionStorage.getItem('user_location')
                }

                if(vm.tournamentId) {
                    // need to grab tournament, teams
                }
            }
        })

        axios.get('/api/users/teams').then(results => {
            if(results.data.type === 'success') {
                
                vm.teams = results.data.data.teams

            }
        })

        vm.tournamentSelected = vm.registerForTournamentDetails.tournamentSelected
        vm.region_rate = vm.registerForTournamentDetails.region_rate
    },
    
	computed: {
        totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.registerForTournamentDetails.tournamentSelected.divisions, 'mens')
            var ladies = vm.filterDivision(vm.registerForTournamentDetails.tournamentSelected.divisions, 'ladies')
            var coed = vm.filterDivision(vm.registerForTournamentDetails.tournamentSelected.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
        spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender

            
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender) {
                        return true
                    }
                })
                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                // code in the upgradable pricing

                if(find_insurance) {
                    
                    vm.currentInsurance = find_insurance
                    if(find_insurance.single_event !== 1) {
                        vm.triggerFinalize = true
                        return 'fully_insured'
                    } else {
                        return 'upgrade'
                    }
                }  else {
                    vm.triggerFinalize = false
                    return false
                }
                
            } else {
                vm.triggerFinalize = false
                return false
            }
        },
        regionRateOptions: function() {
            var vm = this

            var array = []
            if(vm.registerForTournamentDetails.region_rate) {
                if(vm.spnRegistration === false) {
                    if(vm.registerForTournamentDetails.region_rate.rate.single_use_tournament_team !== null) {
                        array.push({
                            name: 'Play One Time',
                            price: vm.registerForTournamentDetails.region_rate.rate.single_use_tournament_team,
                            id: 8
                        })
                    }
                    if(vm.registerForTournamentDetails.region_rate.rate.un_insured_tournament_team !== null) {
                        array.push({
                            name: 'Uninsured Tournament Team',
                            price: vm.registerForTournamentDetails.region_rate.rate.un_insured_tournament_team,
                            id: 7
                        })
                    }
                    if(vm.registerForTournamentDetails.region_rate.rate.fully_insured_tournament_team !== null) {
                        array.push({
                            name: 'Fully Insured Tournament Team',
                            price: vm.registerForTournamentDetails.region_rate.rate.fully_insured_tournament_team,
                            id: 6
                        })
                    }
                }
                if(vm.spnRegistration === 'upgrade') {
                    if(vm.registerForTournamentDetails.region_rate.rate.upgrade_to_un_insured_tournament_team !== null) {
                        array.push({
                            name: 'Upgrade - Uninsured Tournament Team',
                            price: vm.registerForTournamentDetails.region_rate.rate.upgrade_to_un_insured_tournament_team,
                            id: 11
                        })
                    }
                    if(vm.registerForTournamentDetails.region_rate.rate.upgrade_to_fully_insured_tournament_team !== null) {
                        array.push({
                            name: 'Upgrade - Fully Insured Tournament Team',
                            price: vm.registerForTournamentDetails.region_rate.rate.upgrade_to_fully_insured_tournament_team,
                            id: 10
                        })
                    }
                }
            }

            return array
        }
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
        filterRoster: function(roster) {
            // cannot have ladies in mens, cannot have mens in ladies

            var vm = this

            var roster = vm.team_roster

            if(vm.registerForTournament.gender === 'mens') {
                roster = _.filter(roster, function(player) {
                    if(player.user.gender === 'Male') {
                        return true
                    }
                })
            }
            if(vm.registerForTournament.gender === 'ladies') {
                roster = _.filter(roster, function(player) {
                    if(player.user.gender === 'Female') {
                        return true
                    }
                })
            }

            return roster
        },

        paymentSuccessSwitch: function($event) {
            var vm = this
            vm.paymentSuccess = $event
            this.triggerFinalize = true
        },
        finalizeAndRegister: function() {
            var vm = this
            // verify that players are selected, so is team, etc
            var order_id = null
            if(vm.paymentSuccess) {
                order_id = vm.paymentSuccess.order.id
            }

            var current_insurance_id = null
            if(vm.currentInsurance) {
                current_insurance_id = vm.currentInsurance.id
            }

            axios.post('/api/tournaments/register-for-tournament', {
                team_id: vm.selectedTeam.team_id,
                roster: vm.selectedRosterPlayers,
                gender_id: vm.registerForTournament.gender,
                division_id: vm.registerForTournament.division.id,
                tournament_id: vm.registerForTournamentDetails.tournamentSelected.id,
                insurance_id: vm.currentInsurance.id
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'You have successfully registered for this tournament!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    this.$emit('register:complete')
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Registration failed. Please contact us.',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        selectInsuranceOption: function(option) {
            var vm = this
            vm.selectedInsuranceOption = option

            
        }
	}
}
</script>