<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			
			<div class="content-wrapper" style="padding-top: 0px;" v-if="form_id">
				<div class="admin-area">
				<Form :form-id="form_id"></Form>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import axios from 'axios'
import Form from '../components/Form.vue'
export default {
	data() {
		return {
            en: true,
			authUser: { first_name: null },
            content_width: false,
            form_id: null
		};
	},
	components: {
		Header,
        Footer,
        Form
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
            }
        })

        vm.form_id = this.$route.params.slug
	},
	computed: {
	
	},
	methods: {
		switchWidth: function($event) {
			this.content_width = !$event
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
