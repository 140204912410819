<template>
	<span>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        <h2>{{ en === true ? 'My Leagues' : 'Mes ligues' }}</h2>

        <Accordion preOpen>
            <template v-slot:header>
                {{ en === true ? 'Your Upcoming Leagues' : 'Vos ligues à venir' }}
            </template>
            <template v-slot:content>
                <b-table 
                    :data="upcoming" 
                    :striped="true"
                    :focusable="true"   
                    paginated
                    per-page="10"
                >
                    <b-table-column field="league_name" :label="(en === true ? 'League Name' : 'Nom de la ligue')">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="season_id" :label="(en === true ? 'Season' : 'Saison')" style="text-align: center;">
                        <template slot="header" slot-scope="{ column }" >
                            <b-tooltip :label="column.label" dashed style="text-align: center; font-size: 12px;">
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.season_id }}
                        </template>
                    </b-table-column>
                    <b-table-column field="registration_end_date" :label="(en === true ? 'Registration End' : `Fin d'inscription`)">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                            
                        </template>
                        <template v-slot="props">
                            <div>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.registration_end_date).format('MMMM DD') }}</b>
                                </span>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.registration_end_date).format('YYYY') }}</b>
                                </span>
                            </div>
                        </template>
                    </b-table-column>
                    <b-table-column field="start_date" :label="(en === true ? 'Date of Event' : `Date de l'évènement`)" :header-class="'is-sticky-column-one'" :cell-class="'is-sticky-column-one'" :sticky="true" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <div>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('YYYY') }}</b>
                                </span>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('MMMM DD') }}</b>
                                </span>
                                
                            </div>
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="type" label="Type">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-danger" v-if="props.row.status === 'unapproved'">
                                Awaiting Approval
                            </span>
                            <span class="tag is-warning" v-if="props.row.status === 'approved'">
                                Approved But Unpaid
                            </span>
                            <span class="tag is-success" v-if="props.row.status === 'sanctioned'">
                                Sanctioned
                            </span>
                        </template>
                    </b-table-column>
            
                    <b-table-column field="teams_entered" :label="(en === true ? '# Registered' : 'Equipes inscrites')" style="text-align: center;">
                        <template slot="header" slot-scope="{ column }" >
                            <b-tooltip :label="column.label" dashed style="text-align: center; font-size: 12px;">
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.registrants.length }}
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="actions" label="Actions">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <a :href="'/dashboard/convenors/league/' + props.row.slug + '/dashboard'" class="button is-small is-info">{{ en === true ? 'View League Dashboard' : 'Voir le tableau de bord de la ligue' }}</a>
                            
                        </template>
                    </b-table-column>
                </b-table>
            </template>
        </Accordion>
        <Accordion>
            <template v-slot:header>
                {{ en === true ? 'Previous Leagues' : 'Ligues précédentes' }}
            </template>
            <template v-slot:content>
                <b-table 
                    :data="previous" 
                    :striped="true"
                    :focusable="true"
                    paginated
                    
                >
                    <b-table-column field="league_name" :label="(en === true ? 'League Name' : 'Nom de la ligue')">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="season_id" :label="(en === true ? 'Season' : 'Saison')" style="text-align: center;">
                        <template slot="header" slot-scope="{ column }" >
                            <b-tooltip :label="column.label" dashed style="text-align: center; font-size: 12px;">
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.season_id }}
                        </template>
                    </b-table-column>
                    <b-table-column field="registration_end_date" label="Registration End">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                            
                        </template>
                        <template v-slot="props">
                            <div>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.registration_end_date).format('MMMM DD') }}</b>
                                </span>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.registration_end_date).format('YYYY') }}</b>
                                </span>
                            </div>
                        </template>
                    </b-table-column>
                    <b-table-column field="start_date" :label="(en === true ? 'Date of Event' : `Date de l'évènement`)"  :header-class="'is-sticky-column-one'" :cell-class="'is-sticky-column-one'" :sticky="true" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <div>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('YYYY') }}</b>
                                </span>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('MMMM DD') }}</b>
                                </span>
                                
                            </div>
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="type" label="Type">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-danger" v-if="props.row.status === 'unapproved'">
                                Awaiting Approval
                            </span>
                            <span class="tag is-warning" v-if="props.row.status === 'approved'">
                                Approved But Unpaid
                            </span>
                            <span class="tag is-success" v-if="props.row.status === 'sanctioned'">
                                Sanctioned
                            </span>
                        </template>
                    </b-table-column>
            
                    <b-table-column field="teams_entered" :label="(en === true ? '# Registered' : 'Equipes inscrites')" style="text-align: center;">
                        <template slot="header" slot-scope="{ column }" >
                            <b-tooltip :label="column.label" dashed style="text-align: center; font-size: 12px;">
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.registrants.length }}
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="actions" label="Actions">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                                
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            
                            <b-dropdown :inline="true" position="is-right" v-if="user">
                                <button class="button is-info" slot="trigger">
                                    <span>Actions</span>
                                    <b-icon icon="menu-down"></b-icon>
                                </button>
                                <b-dropdown-item>
                                    <a :href="'/dashboard/convenors/league/' + props.row.slug + '/dashboard'">{{ en === true ? 'View League Dashboard' : 'Voir le tableau de bord de la ligue' }}</a>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="parseInt(props.row.season_id) >= 2021 && user.default_region_id !== 4">
                                    <a :href="'/dashboard/convenors/create-league?previous_league=' + props.row.slug">{{ en === true ? 'Duplicate League to ' + new Date().getFullYear() : `Ligues en double jusqu'en` }}</a>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table-column>
                </b-table>
            </template>
        </Accordion>

        <Accordion>
            <template v-slot:header>
                {{ en === true ? 'League Settings' : 'Paramètres du ligues' }}
            </template>
            <template v-slot:content>
                <p>Do you always use a particular location for <b>all or most</b> of your events? Or do you always invite the same three divisions?</p>
                <br>
                <p>You can use this league settings page to <b>pre-set</b> parts of the "Create a New League" page. You can always <b>override these for one-off events,</b> but this will allow you to get one step ahead.</p>
                <br>
                <p>Leave a box or area empty if you don't want it to pre-set anything. For example, if your price always changes, leave this empty, and it will remain empty when you go to create a league.</p>
                <div class="row">
                    <div class="col-md-12">
                        <h3>League Basics Presets</h3>
                        <div class="row row-margin">
                            <div class="col-md-6">
                                <b-field label="Team Price">
                                    <b-tooltip type="is-dark" label="This is the price for a fully-formed team to enter your event.">
                                        <b-input 
                                            v-model="newTournament.team_price"
                                            icon-pack="fas"
                                            icon="dollar-sign"
                                        ></b-input>    
                                    </b-tooltip>
                                </b-field>
                            </div>
                            <div class="col-md-6">
                                <b-field label="Individual Price">
                                    <b-tooltip type="is-dark" multilined label="This is the price for an individual to join a team. We will group these players seperate. You will be in charge of forming the teams. Make sure you factor our insurance into this price!">
                                        <b-input 
                                            v-model="newTournament.individual_price"
                                            icon-pack="fas"
                                            icon="dollar-sign">
                                        </b-input>
                                    </b-tooltip>
                                </b-field>
                            </div>
                        </div>
                        <br>
                        <b-field label="Your Website URL">
                            <b-tooltip multilined type="is-dark" label="This is the URL of your website! We will help you market it in the SPN Calendar Event.">
                                <b-input v-model="newTournament.website_url"></b-input>
                            </b-tooltip>
                        </b-field>

                        <b-field label="Your Facebook Group/Page URL">
                            <b-tooltip multilined type="is-dark" label="Do you have a Facebook group? It's great to grow your audience! Paste it here and people will be able to join when they register for the event.">
                                <b-input v-model="newTournament.facebook_group_url"></b-input>
                            </b-tooltip>
                        </b-field>

                        <b-field label="Do you require a deposit for team entry?">
                            
                            <div class="block">
                                <b-radio v-model="newTournament.deposit_required"
                                    name="deposit_required"
                                    native-value="true">
                                    Yes
                                </b-radio>
                                <b-radio v-model="newTournament.deposit_required"
                                    name="deposit_required"
                                    native-value="false">
                                    No
                                </b-radio>
                            </div>
                        
                        </b-field>
                        <div class="deposit-amount" v-if="newTournament.deposit_required">
                            <b-field label="Deposit Amount">
                                <b-tooltip type="is-dark" label="This is the amount you want to set the deposit at.">
                                    <b-input v-model="newTournament.deposit_amount"
                                            icon-pack="fas"
                                            icon="dollar-sign"></b-input>
                                </b-tooltip>
                            </b-field>
                        </div>
                        <b-field label="What methods of payment will you accept for events?">
                            
                            <div class="block">
                                <b-checkbox v-model="newTournament.registrants_pay_via_spn">
                                    Via SPN Registration System (Credit Card)
                                </b-checkbox>
                                <b-checkbox v-model="newTournament.registrants_pay_via_emt">
                                    Via EMT
                                </b-checkbox>
                                <b-checkbox v-model="newTournament.registrants_pay_via_cash">
                                    Cash on Event Day
                                </b-checkbox>
                            </div>
                        
                        </b-field>
                        <div class="via-emt" v-if="newTournament.registrants_pay_via_emt">
                            <p><b>Payment via EMT</b></p>
                            <b-field label="Your EMT Email">
                                <b-tooltip type="is-dark" multilined label="What email do you want EMTs to go to?">
                                    <b-input 
                                        v-model="newTournament.emt_email"
                                        icon-pack="fas"
                                        icon="at">
                                    </b-input>
                                </b-tooltip>
                            </b-field>
                            <b-field label="Your Preferred EMT Password">
                                <b-tooltip type="is-dark" multilined label="What password do you want EMTs to be set as?">
                                    <b-input 
                                        v-model="newTournament.emt_password"
                                        icon-pack="fas"
                                        icon="lock">
                                    </b-input>
                                </b-tooltip>
                            </b-field>
                        </div>
                    </div>
              
                </div>
                
<!--                 
                <h3>Tournament Divisions Presets</h3>
                <div class="selected-divisions">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="">Mens</h2>
                            <b-dropdown hoverable aria-role="list" v-model="newTournament.mens_divisions" multiple>
                                <button class="button is-info" slot="trigger">
                                    <span>
                                        Select Mens Divisions
                                    </span>
                                    <b-icon icon="menu-down"></b-icon>
                                </button>

                                <b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
                            </b-dropdown>
                            <nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.mens_divisions">
                                <div class="panel-heading">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <b>{{ division.name }} Division</b>
                                        </div>
                                        <div class="col-md-5">
                                            
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="panel-block" style="display: block;">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum # of Teams" custom-class="is-small">
                                                    <b-numberinput type="is-info" min="3" v-model="division.min_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Max # of Teams" custom-class="is-small">
                                                    <b-numberinput type="is-info" min="3" v-model="division.max_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum Age" custom-class="is-small">
                                                    <b-numberinput min="16" type="is-info" max="99" v-model="division.min_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Maximum Age" custom-class="is-small">
                                                    <b-numberinput min="16"  type="is-info" max="99" v-model="division.max_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="">Ladies</h2>
                            <b-dropdown hoverable aria-role="list" v-model="newTournament.ladies_divisions" multiple>
                                <button class="button is-primary" slot="trigger">
                                    <span>
                                        Select Ladies Divisions
                                    </span>
                                    <b-icon icon="menu-down"></b-icon>
                                </button>

                                <b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
                            </b-dropdown>
                            <nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.ladies_divisions">
                                <div class="panel-heading">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <b>{{ division.name }} Division</b>
                                        </div>
                                        <div class="col-md-5">
                                            
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="panel-block" style="display: block;">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum # of Teams" custom-class="is-small">
                                                    <b-numberinput v-model="division.min_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Max # of Teams" custom-class="is-small">
                                                    <b-numberinput v-model="division.max_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum Age" custom-class="is-small">
                                                    <b-numberinput min="16" v-model="division.min_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Maximum Age" custom-class="is-small">
                                                    <b-numberinput min="16" v-model="division.max_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="">Coed</h2>
                                <b-dropdown hoverable aria-role="list" v-model="newTournament.coed_divisions" multiple>
                                    <button class="button is-danger" slot="trigger">
                                        <span>
                                            Select Coed Divisions
                                        </span>
                                        <b-icon icon="menu-down"></b-icon>
                                    </button>

                                    <b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
                                </b-dropdown>
                                <nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.coed_divisions">
                                    <div class="panel-heading">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <b>{{ division.name }} Division</b>
                                            </div>
                                            <div class="col-md-5">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="panel-block" style="display: block;">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Minimum # of Teams" min="3" custom-class="is-small">
                                                        <b-numberinput type="is-danger" v-model="division.min_teams"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Max # of Teams" custom-class="is-small">
                                                        <b-numberinput type="is-danger" min="3" v-model="division.max_teams"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Minimum Age" custom-class="is-small">
                                                        <b-numberinput type="is-danger" min="16" max="99" v-model="division.min_age"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Maximum Age" custom-class="is-small">
                                                        <b-numberinput type="is-danger" min="16" max="99" v-model="division.max_age"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div> -->
                <a class="admin-button" style="margin-top: 30px;" v-on:click="saveLeagueSettings()"><i class="fas fa-save"></i>Save Your League Settings</a>
            </template>
        </Accordion>

        <!-- <b-tabs multiline v-model="activeTab">
            <b-tab-item  :label="(en === true ? 'Your Upcoming Leagues' : 'Vos ligues à venir')">
                
            </b-tab-item>

            <b-tab-item :label="(en === true ? 'Previous Leagues' : 'Ligues précédentes')">
                
            </b-tab-item>

            <b-tab-item :label="(en === true ? 'League Settings' : 'Paramètres du ligues')">
                
            </b-tab-item>
        </b-tabs> -->
        <a href="https://slopitchcentral.com/?track=spn"><img src="https://cdn.blacktiecollab.com/slopitchcentral/slopitchcentral1.png" style="width: 100%;" alt="Slo Pitch Central"></a>
    </span>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
            isLoading: true,
			page: null,
            activeTab: 0,
            upcoming: [],
            previous: [],    
            user: null,  
			newTournament: {			
				deposit_required: false,
				deposit_amount: null,
				team_price: null,
                individual_price: null,
				mens_divisions: [],
				ladies_divisions: [],
				coed_divisions: [],
				website_url: null,
				facebook_group_url: null,
				registrants_pay_via_spn: false,
				registrants_pay_via_emt: false,
				registrants_pay_via_cash: false,
				emt_email: null,
				emt_password: null
            },
            divisions: []
			
		};
	},
	components: {
		
	},
	mounted: function() {
        var vm = this
        axios.get('/api/leagues/your-leagues').then(results => {            
            vm.upcoming = results.data.upcoming
            vm.previous = results.data.previous
        })


		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
		

        axios.get('/api/leagues/default-league-settings').then(results => {
            var tourney_settings = results.data.league_settings
            if(results.data.success === true) {
                vm.newTournament.team_price = tourney_settings.team_registration_fee
                vm.newTournament.individual_price = tourney_settings.player_registration_fee
                vm.newTournament.emt_password = tourney_settings.emt_password
                vm.newTournament.emt_email = tourney_settings.emt_email
                vm.newTournament.website_url = tourney_settings.website_url
                vm.newTournament.facebook_group_url = tourney_settings.facebook_group_url
                vm.newTournament.deposit_amount = tourney_settings.deposit_amount
                vm.newTournament.deposit_required = tourney_settings.deposit_required === 1 ? true : false
                vm.newTournament.registrants_pay_via_cash = tourney_settings.registrants_pay_via_cash === 1 ? true : false
                vm.newTournament.registrants_pay_via_emt = tourney_settings.registrants_pay_via_emt === 1 ? true : false
                vm.newTournament.registrants_pay_via_spn = tourney_settings.registrants_pay_via_spn === 1 ? true : false
                vm.newTournament.mens_divisions = tourney_settings.mens_divisions
                vm.newTournament.ladies_divisions = tourney_settings.ladies_divisions
                vm.newTournament.coed_divisions = tourney_settings.coed_divisions
            }
        })

        axios.get('/api/tournaments/divisions').then(results => {
			vm.divisions = results.data.divisions
        })

        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                vm.user = results.data.user
                
                if(results.data.convenor_permissions) {
                    vm.convenor_permissions = results.data.convenor_permissions
                }
            }
		})

        vm.isLoading = false
	},
    props: ['en'],
	computed: {
	
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        saveLeagueSettings: function() {
            var vm = this

            axios.post('/api/leagues/update-default-settings', {
                newTournament: vm.newTournament
            }).then(results => {
                
            })
        }
	}
}
</script>

<style lang="scss">
    
</style>
