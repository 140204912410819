<template>
	<div>
		<Header></Header>
		
		<div class="content-area" v-if="roster_approved">
			<div class="standard-page-header">
				Thank you - Roster Has Been Approved
			</div>
            <div class="frontend-content-wrapper" style="text-align: center;">
				<p>Your submission has been recieved.</p>
				
			</div>
        </div>
        <div class="content-area" v-else>
			<div class="standard-page-header">
				One moment please..
			</div>
            <div class="frontend-content-wrapper" style="text-align: center;">

				
			</div>
        </div>
        

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'

export default {

	data() {
		return {
			en: true,
            page_url: null,
			page: null,
            roster_approved: false
		};
	},
	components: {
		Header,
		Footer,
		Form,
	},
	mounted: function() {
        var vm = this

        var registrant = this.$route.params.registrant
        var tournament = this.$route.params.tournament
        
        axios.post('/auth/approve-roster', {
            registrant: registrant,
            tournament: tournament
        }).then(results => {
            if(results.data.success === true) {
                vm.roster_approved = true
            }
        })
       
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
		
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
