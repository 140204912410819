<template>
    <span>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        
        <h2 v-if="en === true && user.default_region_id !== 4">My Player Pool Teams
            <a href="/dashboard/players/events-registered-in" class="button" style="margin-left: 50px;">View Leagues/Tournaments You've Entered</a>
        </h2>
        <h2 v-if="en === false && user.default_region_id !== 4">Mes Équipes
            <a href="/dashboard/players/events-registered-in" class="button" style="margin-left: 50px;">View Leagues/Tournaments You've Entered</a>
        </h2>
        <span v-if="en == true && user.default_region_id !== 4">
            <p>Player pool teams are essentially your "contact book" for players that you use for a specific team. These are NOT your event teams. To view your event teams, click the View Leagues/Tournaments You've Entered button above.</p>
            <p>This becomes incredibly handy if you're frequently registering for multiple tournaments and leagues with similar crews as you can quick-add these players to event rosters.</p>
        </span>
        <span v-if="en == false && user.default_region_id !== 4">
            <p>Les équipes du pool des joueurs sont essentiellement votre « carnet des contacts » pour les joueurs que vous utilisez pour une équipe spécifique. Ce ne sont PAS vos équipes d'événements. Pour afficher vos équipes d'événements, cliquez sur le bouton Afficher les ligues / tournois que vous avez entrés ci-dessus. Cela devient incroyablement pratique si vous vous inscrivez fréquemment à plusieurs tournois et ligues avec des équipes similaires, car vous pouvez ajouter rapidement ces joueurs aux listes d'événements.</p>
        </span>
        <div v-if="user && user.default_region_id === 4" style="text-align: center;">
            <img src="https://cdn.blacktiecollab.com/playslopitch.com/PLAYSLOPITCH%20Grunge%20hoodie%20logo%20with%20paint%20Left%20Chest%20copy.png" style="width: 300px; display: block; margin: 0 auto;" alt="Play Slopitch Hoodie">
            <a href="https://playslopitch.com/pages/login" class="admin-button">Login to the PlaySlopitch System</a>
            <!-- <b-message type="is-danger" has-icon aria-close-label="Close message" v-if="user.default_region_id === 4">
                Ontario Region Players will be able to access the new system by the middle of February. You are not able to create new teams until then. For more information, please click here: <a href="https://slo-pitch.com/news/united-in-building-a-stronger-game">United in Building a Stronger Game</a>
            </b-message> -->
        </div>
        <div v-if="teams && user.default_region_id !== 4">
            <!-- <b-message type="is-danger" has-icon aria-close-label="Close message">
                <span v-if="en === true">
                    <p><b>Help shorten your team list in the future!</b></p>

                    <p><b>Teams are no longer year-specific and do not need to be re-created every year. Furthermore, if you are a coach of an "organization" - aka, a mens/ladies co-teams, or mens/coed with the same name, you do not need to create two teams anymore. Create one team, add all your rosters, and track each roster for events. Build your dynasty!</b></p>

                    <p><b>If you want your teams consolidated you can message us using the chat in the bottom right and we can help you out.</b></p>
                </span>
                <span v-if="en === false">
                    <p><b>Aidez à raccourcir votre liste d’équipe à l’avenir!</b></p>

                    <p><b>Les équipes ne sont plus spécifiques à l’année et n’ont pas besoin d’être re-créées à chaque année. De plus, si vous êtes entraîneur de plusieurs équipes,  hommes mixtes femmes du même nom, vous n’avez plus besoin de créer plusieurs équipes. Créez une équipe, ajoutez toutes vos listes et suivez chaque alignement pour les événements. Construisez votre dynastie!</b></p>

                    <p><b>Si vous voulez que vos équipes consolidées, vous pouvez nous envoyer un message en utilisant le chat en bas à droite et nous pouvons vous aider.</b></p>
                </span>
            </b-message> -->
            <Accordion>
                <template v-slot:header>
                    {{ en === true ? 'Teams You Coach' : 'Équipes ou vous êtes entraineur' }}
                </template>
                <template v-slot:content>
                    <div v-if="teams.coach.length === 0">
                        <p>This box will display all the teams you're a Coach on, unless you're a Player or Team Contact.</p>
                    </div>
                    <div v-if="teams.coach.length > 0">
                        <b-table 
                            :data="teams.coach" 
                            :striped="true"
                            :focusable="true"
                            paginated
                            per-page="15"
                        >

                        <b-table-column field="color" :label="(en === true ? 'Color' : 'Couleur')">
                            <template slot="header" slot-scope="{ column }">
                                <b-tooltip :label="column.label" dashed>
                                    {{ column.label }}
                                </b-tooltip>
                            </template>
                            <template v-slot="props">
                                <div class="color-box" :style="'background: ' + props.row.color">
                                    
                                </div>
                            </template>
                        </b-table-column>
                        <b-table-column field="logo" :label="(en === true ? 'Logo' : 'Logo')">
                            <template v-slot="props">
                                <img :src="props.row.logo" style="max-height: 36px;" alt="Team Logo">
                            </template>
                        </b-table-column>
                        <b-table-column field="name" :label="(en === true ? 'Season Created' : 'Nom')">
                            <template slot="header" slot-scope="{ column }">
                                <b-tooltip :label="column.label" dashed>
                                    {{ column.label }}
                                </b-tooltip>
                            </template>
                            <template v-slot="props">
                                <b>{{ props.row.season }}</b>
                            </template>
                        </b-table-column>
                        <b-table-column field="name" :label="(en === true ? 'Name' : 'Nom')">
                            <template slot="header" slot-scope="{ column }">
                                <b-tooltip :label="column.label" dashed>
                                    {{ column.label }}
                                </b-tooltip>
                            </template>
                            <template v-slot="props">
                                <b>{{ props.row.team_name }}</b>
                            </template>
                        </b-table-column>
                        <b-table-column field="coach" :label="(en === true ? 'Primary Coach' : 'Instructeur')">
                            <template slot="header" slot-scope="{ column }">
                                <b-tooltip :label="column.label" dashed>
                                    {{ column.label }}
                                </b-tooltip>
                            </template>
                            <template v-slot="props">
                                {{ props.row.coach_first_name }} {{ props.row.coach_last_name }}
                            </template>
                        </b-table-column>
                        <b-table-column field="coach_phone_number" :label="(en === true ? 'Phone #'
                         : 'Numéro de Tél')">
                            <template slot="header" slot-scope="{ column }">
                                <b-tooltip :label="column.label" dashed>
                                    {{ column.label }}
                                </b-tooltip>
                            </template>
                            <template v-slot="props">
                                <a :href="'tel:' + props.row.coach_phone_number">{{ props.row.coach_phone_number }}</a>
                            </template>
                        </b-table-column>

                        <b-table-column field="insurance" :label="(en === true ? 'Insurance' : 'Assurance')">
                            <template slot="header" slot-scope="{ column }">
                                <b-tooltip :label="column.label" dashed>
                                    {{ column.label }}
                                </b-tooltip>
                            </template>
                            <template v-slot="props">
                                <b-tag style="text-transform: uppercase;" type="is-danger" v-for="insurance in showOnlyThisYear(props.row.insurance)" :key="insurance.id">
                                    <b>{{ insurance.gender }} - {{ insurance.season }}</b>
                                </b-tag>
                            </template>
                        </b-table-column>
            
                        <b-table-column field="actions" label="Actions">
                            <template slot="header" slot-scope="{ column }">
                                <b-tooltip :label="column.label" dashed>
                                    {{ column.label }}
                                </b-tooltip>
                            </template>
                            <template v-slot="props">
                                <a v-on:click="editTeam(props.row)" class="button is-small is-info">{{ en === true ? 'View Team' : 'Visionner L’Équipe' }}</a>
                                <a href="/dashboard/players/events-registered-in" class="button is-small">{{ en === true ? 'View League/Tournament Rosters' : 'View League/Tournament Rosters' }}</a>
                            </template>
                        </b-table-column>
                        
                        </b-table>
                    </div>
                </template>
            </Accordion>

          
            <Accordion>
                <template v-slot:header>
                    {{ en === true ? `Teams You're a Team Contact On` : 'Les équipes ou vous êtes le contact d’équipe' }}
                </template>
                <template v-slot:content>
                    <div v-if="teams.team_contact.length === 0">
                        <p>This box will display all the teams you're a Team Contact on, unless you're a Player or Coach.</p>
                    </div>
                    <div v-if="teams.team_contact.length > 0">
                        <b-table 
                            :data="teams.team_contact" 
                            checkable
                            :sticky-header="true"
                            :striped="true"
                            :focusable="true"
                            paginated
                            per-page="15"
                        >
                            
                            <b-table-column field="color" label="Color">
                                <template slot="header" slot-scope="{ column }">
                                    <b-tooltip :label="column.label" dashed>
                                        {{ column.label }}
                                    </b-tooltip>
                                </template>
                                <template v-slot="props">
                                    <div class="color-box" :style="'background: ' + props.row.color">
                                        
                                    </div>
                                </template>
                            </b-table-column>
                            <b-table-column field="name" label="Name">
                                <template slot="header" slot-scope="{ column }">
                                    <b-tooltip :label="column.label" dashed>
                                        {{ column.label }}
                                    </b-tooltip>
                                </template>
                                <template v-slot="props">
                                    <b>{{ props.row.team_name }}</b>
                                </template>
                            </b-table-column>
                            <b-table-column field="coach" label="Coach">
                                <template slot="header" slot-scope="{ column }">
                                    <b-tooltip :label="column.label" dashed>
                                        {{ column.label }}
                                    </b-tooltip>
                                </template>
                                <template v-slot="props">
                                    <span class="tag is-success">
                                        {{ props.row.coach_first_name }} {{ props.row.coach_last_name }}
                                    </span>
                                </template>
                            </b-table-column>
                
                            <b-table-column field="actions" label="Actions">
                                <template v-slot="props">
                                    <a v-on:click="editTeam(props.row)" class="button is-info is-small">View Team</a>
                                    
                                </template>
                            </b-table-column>
                        
                        </b-table>
                    </div>
                </template>
            </Accordion>

            <Accordion>
                <template v-slot:header>
                    {{ en === true ? `Teams You Play On` : 'Équipes ou vous êtes joueur' }}
                </template>
                <template v-slot:content>
                    <div v-if="teams.player.length === 0">
                        <p>This box will display all the teams you play on, unless you're a Team Contact or Coach.</p>
                    </div>
                    <div v-if="teams.player.length > 0">
                        <b-table 
                            :data="teams.player" 
                            checkable
                            :sticky-header="true"
                            :striped="true"
                            :focusable="true"
                            paginated
                            per-page="15"
                        >
                            
                            <b-table-column field="color" label="Color">
                                <template slot="header" slot-scope="{ column }">
                                    <b-tooltip :label="column.label" dashed>
                                        {{ column.label }}
                                    </b-tooltip>
                                </template>
                                <template v-slot="props">
                                    <div class="color-box" :style="'background: ' + props.row.color">
                                        
                                    </div>
                                </template>
                            </b-table-column>
                            <b-table-column field="name" label="Name">
                                <template slot="header" slot-scope="{ column }">
                                    <b-tooltip :label="column.label" dashed>
                                        {{ column.label }}
                                    </b-tooltip>
                                </template>
                                <template v-slot="props">
                                    <b>{{ props.row.team_name }}</b>
                                </template>
                            </b-table-column>
                            <b-table-column field="coach" label="Coach">
                                <template slot="header" slot-scope="{ column }">
                                    <b-tooltip :label="column.label" dashed>
                                        {{ column.label }}
                                    </b-tooltip>
                                </template>
                                <template v-slot="props">
                                    <span class="tag is-success">
                                        {{ props.row.coach_first_name }} {{ props.row.coach_last_name }}
                                    </span>
                                </template>
                            </b-table-column>
                
                            <b-table-column field="actions" label="Actions">
                                <template v-slot="props">
                                    
                                    <a v-on:click="leaveTeam(props.row)" class="button is-small">Leave Team</a>
                                </template>
                            </b-table-column>
                        
                        </b-table>
                    </div>
                </template>
            </Accordion>

            <Accordion>
                <template v-slot:header>
                    How to Use This Page
                </template>
                <template v-slot:content>
                    <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/2022 Bases Instructions.png" width="100%" alt="Bases Instructions">
                </template>
            </Accordion>
<!-- 
            <b-tabs v-model="activeTab">
                
                <b-tab-item :label="(en === true ? 'Teams You Coach' : 'Équipes ou vous êtes entraineur')">
                    
                </b-tab-item>
                <b-tab-item :label="(en === true ? `Teams You're a Team Contact On` : 'Les équipes ou vous êtes le contact d’équipe')">
                    
                </b-tab-item>
                <b-tab-item :label="(en === true ? `Teams You Play On` : 'Équipes ou vous êtes joueur')">
                    
                </b-tab-item>
            </b-tabs> -->
            
        </div>
    </span>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
// import Accordion from '../../../components/Accordion.vue'

export default {
	data() {
		return {
            activeTab: 0,
            teams: null,
            isLoading: true,
            en: true,
            user: null
		};
	},
	components: {
		// Accordion
	},
	mounted: function() {
        var vm = this

        axios.get('/api/users/teams').then(results => {
            if(results.data.type === 'success') {
                // console.log(results.data.data.teams)
                vm.teams = results.data.data.teams
                vm.isLoading = false
            }
        })

        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.user = results.data.user
            }
        })

       
	},
	computed: {
	
	},
	methods: {
        editTeam: function(team) {
            this.$router.push({ name: 'EditTeam', params: { slug: team.team_slug } })
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        showOnlyThisYear: function(insurances) {
            
            return insurances.filter(insurance => {
                return parseInt(insurance.season) == moment().format('YYYY')
            })
        },
        leaveTeam: function(team) {
            var r = confirm('Are you sure you want to leave your team?')

            if(r === true) {
                
                axios.post('/api/users/leave-team', {
                    team: team.team_id
                }).then(results => {
                    if(results.data.success === true) {
                        this.$buefy.snackbar.open({
                            message: 'You have left this team.',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }


        },
        saveTeam: function() {
            // first, do validation (look up on buefy)
            var newTeam = this.newTeam
            var user = localStorage.getItem('auth')
            if(newTeam.name === null || '') {
                // validation error
                return false
            }
            if(newTeam.primary_location === null || '') {
                // validation error
                return false
            }

            

            axios.post('/api/teams/save', {
                newTeam: newTeam,
                user: user
            }).then(results => {
                if(results.data.success === true) {
                    // now you want to redirect to the edit page on success

                    // redirect to

                    this.$router.push({ name: 'EditTeam', params: { slug:  results.data.data.team.slug } })


                    return true
                }
            }).catch(err => {
                console.log('err', err)
            })
            // then create the axios

            // then create the router in the backend

            // then save the info

            // then write the code that actually displays your teams

        },
	}
}
</script>

<style lang="scss">
    
</style>
