<template>
<span>
    <div class="advertising-bar">

    </div>
   
    <div class="footer">
        <div class="footer-wrapper">
            
            <div class="footer-line"></div>
            <div class="footer-info">
                <div class="row">
                    <div class="col-md-3">
                        <a href="#">
                            <img src="../../../images/header/SPNLogo2019-White.png" style="max-width: 100px; margin: 20px;" alt="SPN Logo">
                        </a>
                    </div>
                    <div class="col-md-6">
                        <div class="footer-main-text">
                            © 2024 Slo-Pitch National All rights reserved.
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="footer-blacktie">
                            Designed, Developed & Maintained by Blacktie Collaborative
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            user_coordinates: {
                lat: null,
                long: null
            },
            submenu_filtered: [],
            menu: [],
            submenus: []
        };
    },
    components: {
        
    },
    computed: {
        
    },
    mounted: function() {
        var vm = this
        axios.get('/api/navigation/footer').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            console.log(err)
        })
    },
    methods: {

        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            }
            if(item.href) {
                // redirect
                router.push({ path: '/content/' + item.href })
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Footer.scss';
</style>
