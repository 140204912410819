<template>
	<div>
        <nav class="panel">
            <p class="panel-heading">
                <b>Umpire Tools</b>
            </p>
            <a class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Injury Report
            </a>
            <a class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Protest Form
            </a>
            <a class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                Insurance Program
            </a>
            <a class="panel-block">
                <span class="panel-icon">
                <i class="fas fa-book" aria-hidden="true"></i>
                </span>
                SPN Rulebook
            </a>
        </nav>
	</div>
</template>

<script>

export default {
	data() {
		return {

		};
	},
	components: {

	},
	mounted: function() {
        // grab all the dashboard information now
	},
	computed: {
	
	},
	methods: {
	
	}
}
</script>

<style lang="scss">
    
</style>
