<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div :class="['page-header', { sidebarClosed: !content_width }]">
				<div class="row">
					<div class="col-md-3">
						<div class="page-header-title">
							Manage Leagues
						</div>
					</div>
					<div class="col-md-9">
						<div class="padding">
						<b-field  grouped position="is-right">
							<a v-on:click="createNewProvincialEvent = true" class="button">Create New League</a>
						</b-field>
						</div>
					</div>
				</div>
			</div>
			<div class="admin-area">
				<b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
					<div
						slot="trigger" 
						slot-scope="props"
						class="card-header"
						role="button"
						aria-controls="contentIdForA11y3">
						<p class="card-header-title">
							Search & Control
						</p>
						<a class="card-header-icon">
							<b-icon
								:icon="props.open ? 'menu-down' : 'menu-up'">
							</b-icon>
						</a>
					</div>
					<div class="card-content">
						<div class="content">
							<b-field grouped group-multiline>
								<div v-for="(column, index) in columnTemplate"
									:key="index"
									class="control">
									<b-checkbox v-model="column.visible">
										{{ column.title }}
									</b-checkbox>
								</div>
							</b-field>
							<hr>
							<b-field grouped group-multiline >
								<b-field label="Region" v-if="specific_region === false">
									<b-select v-model="searchables.region">
										<option value="*">All</option>
										<option :value="1">Alberta</option>
										<option :value="2">British Columbia</option>
										<option :value="3">Manitoba</option>
										<option :value="4">Ontario</option>
										<option :value="5">Maritimes</option>
										<option :value="6">Quebec</option>
										<option :value="7">Newfoundland & Labrador</option>
										<option :value="8">Saskatchewan</option>
									</b-select>
								</b-field>

								<b-field label="Number of Results">
									<b-select v-model="searchables.limit">
										<option :value="100">100</option>
										<option :value="500">500</option>
										<option :value="1000">1000</option>
										<option :value="2500">2500</option>
										<option :value="5000">5000</option>
									</b-select>
								</b-field>
								<b-field label="Per Page">
									<b-select v-model="perPage">
										<option value="10">10 per page</option>
										<option value="25">25 per page</option>
										<option value="50">50 per page</option>
										<option value="100">100 per page</option>
									</b-select>
								</b-field>
								<b-field label="Show Deleted">
									<b-switch v-model="searchables.deleted_at"></b-switch>
								</b-field>
								<b-field label="Filter By Year">
									<b-select v-model="searchables.season" placeholder="Select a year">
										<option
											v-for="option in computedYears"
											:value="option"
											:key="option">
											{{ option }}
										</option>
									</b-select>
								</b-field>
							</b-field>
							<b-field grouped group-multiline style="margin-top: 30px;" >
								
								<b-field label="ID" style="width: 60px;">
									<b-input v-model="searchables.id"></b-input>
								</b-field>
								<b-field label="Type">
									<b-select v-model="searchables.type">
										<option value="*">All</option>
										<option value="1">Fully Insured</option>
										<option value="2">Uninsured</option>
									</b-select>
								</b-field>
								<b-field label="Status">
									<b-select v-model="searchables.status">
										<option value="*">All</option>
										<option value="sanctioned">Sanctioned</option>
										<option value="unapproved">Unapproved</option>
										<option value="rejected">Rejected</option>
										<option value="nationals">Nationals</option>
										<option value="provincials">Provincials</option>
									</b-select>
								</b-field>
								<b-field label="Name" style="width: 150px;">
									<b-input v-model="searchables.tournament_name"></b-input>
								</b-field>
								<b-field label="City" style="width: 150px;">
									<b-input v-model="searchables.city"></b-input>
								</b-field>
								<b-field label="Convenor's First Name">
									<b-input v-model="searchables.convenor_first_name"></b-input>
								</b-field>
								<b-field label="Convenor's Last Name">
									<b-input v-model="searchables.convenor_last_name"></b-input>
								</b-field>
								<b-field label="Convenor's Email">
									<b-input v-model="searchables.convenor_email"></b-input>
								</b-field>
								<b-field label="Convenor Phone">
									<b-input v-model="searchables.convenor_phone"></b-input>
								</b-field>
								<b-field label="Event Start Date">
									<b-datepicker v-model="searchables.event_start_date"></b-datepicker>
								</b-field>
								<b-field label="Event End Date">
									<b-datepicker v-model="searchables.event_end_date"></b-datepicker>
								</b-field>
							</b-field>
							<hr>
							<div class="content">
								<a class="button is-info" v-on:click="sendMessage = true"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp; Send Email To Selected League</a>
								<vue-excel-xlsx
									:data="computedTournaments"
									:columns="columnTemplateXslx"
									:filename="'filename'"
									:sheetname="'sheetname'"
									:class="'button is-success'"
									>
									<i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
								</vue-excel-xlsx>
								<a class="button is-warning" v-on:click="selectedTournaments = allTournaments">Select all (Multipage) ({{ selectedTournaments.length }})</a>
								<div class="send-email" v-if="sendMessage" style="margin-top: 30px;">
									
									<div class="selectable-contacts">
										<b-field grouped group-multiline>
											<b-field label="Convenors">
												<b-switch v-model="emailSelectables.convenors"></b-switch>
											</b-field>
											<b-field label="All Team Coaches">
												<b-switch v-model="emailSelectables.all_coaches"></b-switch>
											</b-field>
											
										</b-field>
									</div>
									<div class="contacts-selected" style="margin-top: 30px;">
										<p><b>Sending To:</b></p>
										<b-tag v-for="(email, index) in computedEmails" :key="index">{{ email }}</b-tag>
									</div>
									<b-field label="Subject" style="margin-top: 30px;">
										<b-input v-model="sendEmail.subject"></b-input>
									</b-field>
									<b-field label="Message">
										<b-input v-model="sendEmail.message"
										maxlength="2000" type="textarea"></b-input>
									</b-field>

									<a class="admin-button" v-on:click="sendMessageToPlayers()">Send Message to Selected Players</a>
								</div>
							</div>
						</div>
					</div>
				</b-collapse>

				<b-table
					:data="allTournaments"
					ref="table"
					:sticky-header="true"
					:height="'100%'"
					style="margin-top: 30px;"
					:paginated="true"
					:current-page="1"
					:per-page="perPage"
					:pagination-simple="false"
					:pagination-position="'both'"
					:checked-rows.sync="selectedTournaments"
					checkable
					detailed
					detail-key="id"
					:show-detail-icon="true"
					
					default-sort="user.first_name"
					aria-next-label="Next page"
					aria-previous-label="Previous page"
					aria-page-label="Page"
					aria-current-label="Current page">
					<template
						v-for="(column, index) in columnTemplate"
						>
						<b-table-column 
							v-bind="column"
							
							:key="index" 
							:sortable="true"
							:label="column.title"
							:visible="column.visible">

							
							<template v-slot="props" :width="props.column.width">
								<span v-if="column.field === 'region.short_name'">
									{{ props.row.region.short_name }}
								</span>
								<span v-else-if="column.field === 'status'">
									<b-tag type="is-warning" v-if="props.row.status === 'unapproved'">{{ props.row.status.toUpperCase() }}</b-tag>
									<b-tag type="is-danger" v-else-if="props.row.status === 'rejected'">{{ props.row.status.toUpperCase() }}</b-tag>
									<b-tag type="is-success" v-else-if="props.row.status === 'sanctioned'">{{ props.row.status.toUpperCase() }}</b-tag>
								</span>
								<span v-else-if="column.field === 'type'">
									<span v-if="props.row.type_id === 1">Fully Insured</span>
									<span v-if="props.row.type_id === 2">Uninsured</span>
									<span v-if="props.row.type_id === 3">League Tournament</span>
								</span>
								<span v-else-if="column.field === 'convenor.first_name'">
									{{ props.row.convenor.first_name }}
								</span>
								<span v-else-if="column.field === 'convenor.last_name'">
									{{ props.row.convenor.last_name }}
								</span>
								<span v-else-if="column.field === 'convenor.email'">
									{{ props.row.convenor.email }}
								</span>
								<span v-else-if="column.field === 'convenor.phone'">
									{{ props.row.convenor.phone }}
								</span>
								<span v-else-if="column.field === 'divisions'">
									<b-tag v-for="division in props.row.divisions" :key="division.id">
										{{ division.gender_slug.toUpperCase() }} {{ division.rank_id }}
									</b-tag>
								</span>
								<span v-else-if="column.field === 'event_start_date'">
									{{ moment(props.row.event_start_date).format('YYYY-MM-DD') }}
								</span>
								<span v-else-if="column.field === 'event_end_date'">
									{{ moment(props.row.event_end_date).format('YYYY-MM-DD') }}
								</span>
								<span v-else>
									{{ props.row[column.field] }}
								</span>
							</template>
						</b-table-column>
						
					</template>
					<template slot="detail" slot-scope="props">
						<b-field  grouped>
							<a :href="'/superadmin/manage/leagues/' + props.row.id" class="button margin-right">View League Profile</a>
							
							<a :href="'/superadmin/manage/users/' + props.row.convenor_user_id"  class="button is-info margin-right">View League Convenor Profile</a>

							<a v-if="props.row.status !== 'sanctioned'" v-on:click="approveLeague(props.row)" class="button is-success margin-right">Approve League</a>

							<a v-on:click="rejectLeague(props.row)" class="button is-warning margin-right">Reject League</a>

							<a v-on:click="deleteLeague(props.row)" class="button is-danger">Delete League</a>
						</b-field>
						<div class="row">
							<div class="col-md-4">
								<div class="padding">
									<div class="panel is-primary">
										<div class="panel-heading" style="font-size: 12px; color: #fff;">
											Divisions
										</div>
										<div class="panel-content">
											<table class="table">
												<tr>
													<td><b>Division</b></td>
													<td><b>Min/Max</b></td>
													<td><b>Registered</b></td>
												</tr>
												<tr :key="key" v-for="(division, key) in props.row.divisions">
													<td>
														<b>{{ division.gender_slug.slice(0,1).toUpperCase() + division.gender_slug.slice(1) }} {{ division.rank_id }}</b>
													</td>
													<td>
														{{ division.min_number_of_teams }}/{{ division.max_number_of_teams }}
													</td>
													<td>
														<b>{{ division.registrants.length }}</b>
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>

								<div class="padding" style="padding: 20px 0p;">
									<div class="panel is-info" >
										<div class="panel-heading" style="font-size: 12px; color: #fff;">
											Notes
										</div>
										<div class="panel-content" v-if="props.row.notes.length > 0">
											<div class="user-note " :key="key" v-for="(note, key) in props.row.notes">
												<div class="user-note-note" style="padding-bottom: 10px;">{{ note.notes }}</div>
												<div class="note">{{ moment(note.created_at).format('YYYY-MM-DD h:mmA') }} by {{ note.written.first_name }} {{ note.written.last_name }}</div>
											</div>


										</div>
										<b-field>
											<b-input type="text" v-model="note"></b-input>
										</b-field>
										<div style="text-align: center; margin-top: 10px; padding-bottom: 10px;">
											<a class="admin-button" v-on:click="addNote(props.row)">Add Note</a>
										</div>
									</div>
								</div>
							
							</div>
							
							<div class="col-md-8">
								<div class="padding">
									<div class="panel is-info">
										<div class="panel-heading" style="font-size: 12px; color: #fff;">
											Registrants
										</div>
										<div class="panel-content">
											<b-pagination
												:total="props.row.registrants.length"
												:per-page="perPageDropdown"
												v-model="currentPageDropdown[props.row.id]"
												aria-next-label="Next page"
												aria-previous-label="Previous page"
												aria-page-label="Page"
												aria-current-label="Current page">
											</b-pagination>
											<table class="table">
												<tr>
													<td><b>Colour</b></td>
													<td><b>Team Name</b></td>
													<td><b>City</b></td>
													<td></td>
												</tr>
												<tr :key="key" v-for="(registrant, key) in paginatedRegistrants(props.row.registrants, props.row.id)">
													<td>
														<div class="color-box" :style="'background: ' + registrant.team.color_1">
												
														</div>
													</td>
													<td>
														<b>{{ registrant.team.name }}</b>
													</td>
													<td>
														<b>{{ registrant.team.city }}</b>
														
													</td>
													<td>
														<a :href="'/superadmin/manage/teams/' + registrant.team.slug" class="button is-info is-small">View Team Profile</a>
														<a :href="'/superadmin/manage/leagues/' + props.row.slug  + '/event-roster/' + registrant.id" class="button is-warning is-small">Event Roster</a>
													</td>
												</tr>
											</table>
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</b-table>

				
			</div>
		</div>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
export default {
	data() {
		return {
			authUser: { first_name: null },
			isLoading: true,
			content_width: false,
			allTournaments: [],
			selectedTournaments: [],
			
			years: ['All', 2024,2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010],
			selectedTeams: [],
			sendMessage: false,
			specific_region: false,
			perPage: 50,
			sendEmail: {
                subject: null,
                message: null
			},
			emailSelectables: {
				convenors: false,
				all_coaches: false,
				team_contacts: false,
				rostered_players: false,
			},
			perPageDropdown: 6,
			currentPageDropdown: 1,

			timer: null,
			searchables: {
				id: null,
				region: '*',
				type: '*',
				status: '*',
				season: moment().format('YYYY'),
				tournament_name: null,
				city: null,
				convenor_email: null,
				convenor_phone: null,
				convenor_first_name: null,
				convenor_last_name: null,
				event_start_date: null,
				event_end_date: null,
				deleted_at: false,
                limit: 100,
			},
			columnTemplate: [
				{ title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Status', label: 'Status', field: 'status', visible: true, searchable: false },
				{ title: 'Region', label: 'Region', field: 'region.short_name', visible: true, searchable: false, width: 40 },
				{ title: 'Season', label: 'Season', field: 'season_id', visible: true, searchable: false },
				{ title: 'Name', label: 'Name', field: 'name', visible: true, searchable: false },
				{ title: 'Type', label: 'Type', field: 'type.name', visible: false, searchable: false },
				{ title: 'Start Date', label: 'Start Date', field: 'event_start_date', visible: false, searchable: false },
				{ title: 'End Date', label: 'End Date', field: 'event_end_date', visible: false, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Divisions', label: 'Divisions', field: 'divisions', visible: false, searchable: false },
				{ title: 'Convenor First', label: 'Convenor First', field: 'convenor.first_name', visible: true, searchable: false },
				{ title: 'Convenor Last', label: 'Convenor Last', field: 'convenor.last_name', visible: true, searchable: false },
				{ title: 'Convenor Email', label: 'Convenor Email', field: 'convenor.email', visible: false, searchable: false },
				{ title: 'Convenor Phone', label: 'Convenor Phone', field: 'convenor.phone', visible: false, searchable: false },
			],
			columnTemplateXslx: [
				{ title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Status', label: 'Status', field: 'status', visible: true, searchable: false },
				{ title: 'Region', label: 'Region', field: 'region_shortname', visible: true, searchable: false, width: 40 },
				{ title: 'Season', label: 'Season', field: 'season_id', visible: true, searchable: false },
				{ title: 'Name', label: 'Name', field: 'name', visible: true, searchable: false },
				{ title: 'Type', label: 'Type', field: 'type_name', visible: false, searchable: false },
				{ title: 'Start Date', label: 'Start Date', field: 'event_start_date', visible: false, searchable: false },
				{ title: 'End Date', label: 'End Date', field: 'event_end_date', visible: false, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Divisions', label: 'Divisions', field: 'divisions_text', visible: false, searchable: false },
				{ title: 'Convenor First', label: 'Convenor First', field: 'convenor_first_name', visible: true, searchable: false },
				{ title: 'Convenor Last', label: 'Convenor Last', field: 'convenor_last_name', visible: true, searchable: false },
				{ title: 'Convenor Email', label: 'Convenor Email', field: 'convenor_email', visible: false, searchable: false },
				{ title: 'Convenor Phone', label: 'Convenor Phone', field: 'convenor_phone', visible: false, searchable: false },
				{ title: 'Registered Teams', label: 'Registered Teams', field: 'registered_teams', visible: false, searchable: false },
			]
		};
	},
	components: {
		Header,
		Footer,
	},
	created() {
        this.debouncedCheckingThis = _.debounce(this.checkingThis, 1000);
    },
	watch: {
		yearSelected: function($event) {
			this.getLeagues()
		},
		searchables: {
            handler(val) {
                this.debouncedCheckingThis()
            }, deep: true
        }
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

		if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user


                if(results.data.user_permissions.manage_leagues !== 1) {
                    this.$router.push({ name: 'Dashboard' })
				}
				if(results.data.user_permissions.specific_region_only) {
                    vm.searchables.region = results.data.user_permissions.specific_region_only
                    vm.specific_region = true
                }
			}
			
			vm.getLeagues()
			vm.isLoading = false
        })
	},
	computed: {
		
		computedTournaments: function() {
			var vm = this

			var selectedTournaments = this.selectedTournaments
			
			selectedTournaments = selectedTournaments.map(tournament => {
				tournament.convenor_first_name = tournament.convenor.first_name
				tournament.convenor_last_name = tournament.convenor.last_name
				tournament.convenor_email = tournament.convenor.email
				tournament.convenor_phone = tournament.convenor.phone
				tournament.registered_teams = tournament.registrants.length
				tournament.divisions_text = ''
				tournament.region_shortname = tournament.region.short_name
				if(tournament.type_id == 1) {
					tournament.type_name = 'Sanctioned Qualifier'
				}
				if(tournament.type_id == 2) {
					tournament.type_name = 'Sanctioned Non-Qualifier'
				}
				if(tournament.type_id == 3) {
					tournament.type_name = 'Sanctioned Provincial Event'
				}
				if(tournament.type_id == 4) {
					tournament.type_name = 'Sanctioned National Event'
				}
				if(tournament.type_id == 5) {
					tournament.type_name = 'League Tournament'
				}


				var i = 0
				for(const division of tournament.divisions) {
					tournament.divisions_text += division.gender_slug.toUpperCase() + ' ' + division.rank_id + (tournament.divisions.length === i  ? '' : ', ')

					i++
				}

				return tournament
			})
			
			return selectedTournaments
		},
		computedYears: function() {
			var years = []
			var lowest_year = 2009

			var currentDate = new Date();
			var current_year = currentDate.getFullYear();

			// If the current month is September (9) or later, increment the year
			if (currentDate.getMonth() >= 8) {
				current_year++;
			}

			years.push('All')

			var years_difference = current_year - lowest_year
			var i = 0
			for(i; i < years_difference; i++) {
				years.push(current_year - i)
			}

			return years
		},
		computedEmails: function() {
			var vm = this
			
			var i = 0

			var emails = []

			for(i; i < vm.selectedTournaments.length; i++) {
				var tournament = vm.selectedTournaments[i]
				var permissions = tournament.permissions
				var registrants = tournament.registrants

				if(vm.emailSelectables.convenors) {
					var s = 0
					for(s; s < permissions.length; s++) {
						var permission = permissions[s]

						emails.push(permission.user.email)
					}
				}

				if(vm.emailSelectables.all_coaches) {
					var w = 0
					for(w; w < registrants.length; w++) {
						var registrant = registrants[w]
						if(registrant.team.primary_coach) {
							emails.push(registrant.team.primary_coach.email)
						}
					}
				}
			}

			return emails
		}
	},
	methods: {
		paginatedRegistrants: function(registrants, league) {
			var vm = this
	
			var start = (vm.currentPageDropdown[league] - 1) * vm.perPageDropdown
			var end = start + vm.perPageDropdown

			return registrants.slice(start, end)
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		getLeagues: function() {
			var vm = this
			var params = this.$route.query

			var keys = Object.keys(params)
			
			for(const key of keys) {
				vm.searchables[key] = params[key]
			}	
			axios.get('/api/superuser/leagues', { params: vm.searchables }).then(results => {
				if(results.data.success === true) {
					vm.allTournaments = results.data.leagues

					let leagueIdMap = {};

					// Iterate over all leagues and populate the object
					results.data.leagues.forEach(league => {
						leagueIdMap[league.id] = 1;
					});

					// If you need to use this map elsewhere in your Vue component, you might want to store it in your data
					vm.currentPageDropdown = leagueIdMap;
				}
			})
		},
		approveLeague: function(league) {
			var vm = this

			axios.post('/api/leagues/approve-league', {
				league_id: league.id
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'League is approved and convenor has been notified',
						type: 'is-success' // is-danger
					})
				}
			})
		},
		rejectLeague: function(league) {
			var vm = this

			axios.post('/api/leagues/reject-league', {
				league_id: league.id
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'League is declined and will not be used',
						type: 'is-success' // is-danger
					})
				}
			})
		},
		deleteLeague: function(league) {
			var vm = this
			var r = confirm('Are you sure you want to do this?')

			if(r === true) {

				axios.post('/api/leagues/delete-league', {
					league_id: league.id
				}).then(results => {
					if(results.data.success === true) {
						this.$buefy.snackbar.open({
							message: 'League is delete and will not be used',
							type: 'is-success' // is-danger
						})
					}
				})
			}
		},
		
		checkingThis: function() {
			var vm = this
			
			var params = new URLSearchParams()
			var keys = Object.keys(vm.searchables)
			for(const key of keys) {
				if(vm.searchables[key] !== null) {
					params.set(key, vm.searchables[key])
				}
			}
			
			window.history.replaceState(null, null, '?'+params.toString())
			
			axios.get('/api/superuser/leagues', { params: vm.searchables }).then(results => {
				if(results.data.success === true) {
					vm.allTournaments = results.data.leagues
				}
			})
		
		},
		addNote: function(league) {
			var vm = this

			axios.post('/api/superuser/add-note-to-league', {
				league: league,
				note: vm.note
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Note is added to the league!',
						type: 'is-success' // is-danger
					})
				}
			})
		},
		sendMessageToPlayers: function() {
            var vm = this

            axios.post('/api/superuser/send-messages-to-players', {
                message: vm.sendEmail,
                emails: vm.computedEmails,
            }).then(results => {
                if(results.data.success === true) {
                    vm.sendEmail = {
                        subject: null,
                        message: null
                    }

                    vm.selectedUsers = []
                    this.$buefy.snackbar.open({
                        message: 'You have successfully sent your message!',
                        type: 'is-success' // is-danger
                    })
                }
            })
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
