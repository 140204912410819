<template>
    <span>
        <h2 v-if="en === true">Exam</h2>
        <h2 v-if="en === false">Examen</h2>
        <div class="padding" style="padding-left: 0px;" v-if="results">
            <b-table 
                :data="results" 
                :striped="true"
                :focusable="true"
            >
                <b-table-column field="created_at" :label="en === true ? 'Completed' : 'Terminé'">
        
                    <template v-slot="props">
                        <span class="tag is-light">
                            <b>{{ moment(props.row.created_at).format('MMMM DD, YYYY') }}</b>
                        </span>
                    </template>
                </b-table-column>
                <b-table-column field="passed" :label="en === true ? 'Result' : 'Résultats'">
                
                    <template v-slot="props">
                        <span class="tag is-success">
                            Passed
                        </span>
                    </template>
                </b-table-column>
                <b-table-column field="correct_answers" :label="en === true ? 'Correct' : 'Correcte'">
            
                    <template v-slot="props">
                        {{ props.row.correct_answers }} <b>({{ ((props.row.correct_answers/25) * 100).toFixed(0) }}%)</b>
                    </template>
                </b-table-column>

                <b-table-column field="valid_until" :label="en === true ? 'Valid Until' : 'Valide jusqu’à'">
                    
                    <template v-slot="props">
                        <span class="tag is-light">
                            <b>{{ moment(props.row.valid_until).format('MMMM DD, YYYY') }}</b>
                        </span>
                    </template>
                </b-table-column>
                
            </b-table>
        </div>
    </span>
</template>

<script>

import moment from 'moment'
import axios from 'axios'
export default {
	data() {
		return {
			
            activeTab: 0,
            results: null
		};
	},
	components: {
		
	},
	mounted: function() {
        // NEXT - grab the umpire results that you've received
        // remove previous years, do it all in one page
        var vm = this
        // then complete clinics because that should be easy too - both frontend and backend
        // at this point you're finished umpires area for now - find gigs and cheat sheet will come later
        axios.get('/api/umpires/personal-results').then(results => {
            if(results.data.success === true) {
                vm.results = results.data.results
            }
        })
    },
    props: ['en'],
	computed: {
	
	},
	methods: {
	 	moment: function(date, format = null) {
            return moment(date, format)
        }
	}
}
</script>

<style lang="scss">
    
</style>
