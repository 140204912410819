<template>
	<div class="login-bg">
		<Header></Header>
		<div class="login-area">
            <div class="login-area-wrapper">
                <div class="login-header">
                    Recover Your Account
                </div>
                <div style="background: #fff; padding: 10px;">
                    <p>It's now easier than ever to log into SPN. You can log in using your email, primary phone number, or username.</p>
                </div>
                
                <div class="error" v-if="error">
                    {{ error }}
                </div>
                <div style="text-align: center;">

                    <a class="login-button" v-on:click="registerMyAccount()">Register My Account</a>
                </div>
            
            </div>
        </div>
		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/SecondaryHeader.vue'
import Footer from './components/SecondaryFooter.vue'
import axios from 'axios'

export default {
	data() {
		return {
            registerInfo: {
                first_name: null,
                last_name: null,
                email: null,
                retype_email: null,
                password: null,
                confirm_password: null,
                date_of_birth: null
            }
		};
	},
	components: {
		Header,
		Footer
	},
	mounted: function() {       
       
	},
	computed: {
        reviewForDuplicates: function() {
            // run an axios that validates all the information

        }
	}, 
	methods: {
        registerMyAccount: function() {
            
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
