<template>
  <div class="page-not-found">
    <h1>404: Page not found.</h1>
    <router-link to="/">
      Home
    </router-link>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: 'Page Not Found'
    };
  }
};
</script>

<style>

</style>
