<template>
    <div class="edit-tournament-box">
        <div class="content-area">
            <div class="row">
                <div class="col-md-6">
                    <h2 style="margin-top: 0px;">Umpire Cheat Sheet</h2>
                    <p>Fill out this umpire cheat sheet to help them see and make sure they can umpire according to your event.</p>
                    
                    <table class="table" style="width: 100%; margin-top: 20px;">
                        <tr>
                            <td><b>Innings</b></td>
                            <td>
                                <b-numberinput
                                    v-model="cheatSheet.innings">
                                </b-numberinput>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Call last inning at __ minutes</b></td>
                            <td>
                                <b-numberinput
                                    v-model="cheatSheet.call_last_inning_after">
                                </b-numberinput>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Minimum players on field</b></td>
                            <td>
                                <b-numberinput
                                    v-model="cheatSheet.minimum_players">
                                </b-numberinput>
                            </td>
                        </tr>
                        <tr>
                            <td><b>HR Rule</b></td>
                            <td>
                                <b-input
                                    v-model="cheatSheet.hr_rule">
                                </b-input>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Anticipation</b></td>
                            <td>
                                <b-switch :true-value="1" :false-value="0" v-model="cheatSheet.anticipation"></b-switch>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Ties</b></td>
                            <td>
                                <b-switch :true-value="1" :false-value="0" v-model="cheatSheet.ties"></b-switch>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Courtesy Runners</b></td>
                            <td>
                                <b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.courtesy_runners"></b-switch>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Max runs per inning</b></td>
                            <td>
                                <b-input
                                    v-model="cheatSheet.max_runs_per_inning">
                                </b-input>
                            </td>
                        </tr>
                        <tr>
                            <td><b>HR Rule</b></td>
                            <td>
                                <b-input
                                    v-model="cheatSheet.hr_rule">
                                </b-input>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Mercy Rule</b></td>
                            <td>
                                <b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.mercy_rule"></b-switch>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Tag at home</b></td>
                            <td>
                                <b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.tag_at_home"></b-switch>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Throw to first from outfield</b></td>
                            <td>
                                <b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.throw_to_first_from_outfield"></b-switch>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Additional Rules</b></td>
                            <td>
                                <b-input type="textarea" v-model="cheatSheet.additional_rules"></b-input>
                            </td>
                        </tr>
                    </table>
                    <a v-if="savedCheatSheet === false" v-on:click="saveUmpireCheatSheet()" role="button" class="admin-button"><i class="fas fa-save"></i> Create Umpire Cheat Sheet</a>
                    <a v-if="savedCheatSheet === true" v-on:click="saveUmpireCheatSheet()" role="button" class="admin-button"><i class="fas fa-save"></i> Update Umpire Cheat Sheet</a>
                    
                </div>
                <div class="col-md-6">
                    <div class="padding" v-if="savedCheatSheet">
                        <h2 style="margin-top: 0px;">Umpires Hired</h2>
                    
                        <p>Add your umpires to this list via their email address to add them to the tournament. For security reasons, their email has to be a complete match.</p>

                        <b-input style="margin-top: 10px;" placeholder="Search for a carded umpire by email"
                            type="search"
                            icon="magnify"
                            icon-clickable
                            v-model="umpSearch"
                            v-on:input="umpireEmailSearch($event)"
                        >
                        </b-input>
                        <div style="text-align: center; margin-top: 10px;">
                            <p class="note" style="color: red;" v-if="umpireFound === null && umpSearch !== null">Umpire Not Found</p>
                            <p class="note" style="color: green;" v-if="umpireFound && umpSearch !== null">Umpire Found!!</p>

                            <a role="button" v-on:click="addUmpireToTournament()" v-if="umpireFound && umpSearch !== null" class="admin-button"><i class="fas fa-plus"></i> Add Umpire to Tournament</a>
                        </div>
                        <div>
                            <h2>Umpires Added</h2>
                            <table class="table">
                                <tr :key="user.id" v-for="user in addedToCheatSheet">
                                    <td>{{ user.user.email }}</td>
                                    <td><a style="margin-left: 10px;" class="button is-small is-danger" v-on:click="removeUmpire(user)">Remove</a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
    data() {
        return {
            cheatSheet: {
				innings: 7,
				call_last_inning_after: 75,
				minimum_players: 10,
				hr_rule: '1',
				anticipation: true,
				ties: true,
				courtesy_runners: true,
				max_runs_per_inning: 6,
				mercy_rule: true,
				tag_at_home: true,
				throw_to_first_from_outfield: true,
				additional_rules: null
			},
            savedCheatSheet: false,
            addedToCheatSheet: null,
            umpireFound: null,
            umpSearch: null,
        };
    },
    computed: {
        
    },
    props: ['eventId', 'eventType'],
    mounted: function() {
        var self = this

    },
    methods: {
        grabCheatSheet: function() {
           var vm = this
           axios.get('/api/umpires/cheat-sheet/' + vm.eventId + '/tournament').then(results => {
                if(results.data.success === true) {
                    if(results.data.cheat_sheet) {
                        vm.cheatSheet = results.data.cheat_sheet
                        vm.savedCheatSheet = true
                    
                        axios.get('/api/umpires/added-to-cheat-sheet/' + vm.cheatSheet.id + '/tournament').then(results => {
                            if(results.data.success === true) {
                                vm.addedToCheatSheet = results.data.added_to_cheat_sheet
                            }
                        })
                    }
                }
            })
        },
        addUmpireToTournament: function() {
			
			var vm = this
			axios.post('/api/umpires/add-to-cheat-sheet', {
				umpire_found: vm.umpireFound,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					vm.grabCheatSheet()
					
					this.$buefy.snackbar.open({
                        message: 'Added user to the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not added to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
        umpireEmailSearch: function(text) {
			var vm = this
			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				axios.post('/api/umpires/search-for-umpire', {
					search: text
				}).then(results => {
					if(results.data.success === true) {
						
						vm.umpireFound = results.data.umpire
						
					}
				})
			}, 1000)
		},
        saveUmpireCheatSheet: function() {
			var vm = this

			axios.post('/api/umpires/save-cheat-sheet', {
				cheat_sheet: vm.cheatSheet,
				event_id: vm.eventId,
				type: 'tournament'
			}).then(results => {
				if(results.data.success === true) {
					
					vm.savedCheatSheet = true
					vm.grabCheatSheet()
					
				}
			})
		},

    }
}
</script>
