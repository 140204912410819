<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="tournament">
				<div :class="['page-header', { sidebarClosed: !content_width }]" >
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
								Manage {{ tournament.name }} Paperwork
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                
                                <b-field  grouped position="is-right">
                                    
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
                    <h1 style="margin-top: 0px;">Tournament Results Form</h1>
					<h4>Tournament Information - For Reference</h4>
                    <p><b>Name:</b> {{ tournament.name }}</p>
					<p><b>Start Date:</b> {{ moment(tournament.event_start_date).format('YYYY-MM-DD') }}</p>
					<p><b>End Date:</b> {{ moment(tournament.event_end_date).format('YYYY-MM-DD') }}</p>
					<p>All tournament info will be submitted automatically.</p>


					<div v-if="tournament.region_id === 4">
						<h4>Prize Packages</h4>
						<table class="table">
							<tr>
								<td><b>Type of Prize Pack</b></td>
								<td><b>Qty</b></td>
								<td><b>Total Due</b></td>
							</tr>
							<tr v-for="(prize, key) in prize_pack" :key="key">
								<td>{{ prize.name }}</td>
								<td>
									<b-input v-model="prize.qty"></b-input>
								</td>
								<td>
									${{ prize.qty * prize.price_per }}
								</td>
							</tr>
	
						</table>
					</div>

                    <h4>Tournament Results</h4>
					
					<div class="division" :key="key" v-for="(registrant, key) in registrantsInDivisions">
						
						<h5>{{ registrant.gender_slug }} {{ registrant.rank_id }}</h5>
						<table class="table">
							<tr>
								<td><b>Placed</b></td>
								<td><b>Qualify</b></td>
								<td><b>Team Name</b></td>
								<td><b>Wins</b></td>
								<td><b>Losses</b></td>
								<td><b>Higher Division?</b></td>
								<td><b>Lower Division?</b></td>
							</tr>
							<tr :key="key2" v-for="(team, key2) in registrant.registrants">
								<td>
									<b-input v-model="team.placed"></b-input>
								</td>
								<td>
									<b-checkbox v-model="team.qualify"></b-checkbox>
								</td>
								<td>{{ team.team.name }}</td>
								<td>
									<b-input v-model="team.wins"></b-input>
								</td>
								<td>
									<b-input v-model="team.losses"></b-input>
								</td>
								<td>
									<b-checkbox v-model="team.higher_division"></b-checkbox>
								</td>
								<td>
									<b-checkbox v-model="team.lower_division"></b-checkbox>
								</td>
							</tr>
						</table>
					</div>				
                    
                    <h4>Convenor Comments/Recommendations</h4>
					<b-input type="textarea" v-model="comments">

					</b-input>
                    
                    <h4>Injuries</h4>
					<table class="table">
						<tr>
							<td><b>Player Name</b></td>
							<td style="width: 80%;"><b>Description</b></td>
						</tr>
						<tr v-for="(injury, key) in injuries" :key="key">
							<td><b-input v-model="injury.name"></b-input></td>
							<td><b-input v-model="injury.description"></b-input></td>
						</tr>
					</table>
					<a v-on:click="addInjury()" class="button is-danger">+ Add Injury</a>

                    <h4>Officiating (Umpires Used)</h4>
					<table class="table">
						<tr>
							<td><b>Umpire Name</b></td>
							<td><b>Umpire Email</b></td>
						</tr>
						<tr :key="key" v-for="(umpire, key) in umpires">
							<td>
								<b-input v-model="umpire.name"></b-input>
							</td>
							<td>
								<b-input v-model="umpire.email"></b-input>
							</td>
						</tr>
					</table>
					<a v-on:click="addUmpire()" class="button is-danger">+ Add Umpire</a>
					<hr>
					<a v-on:click="saveTournamentResults()" v-if="saving === false" role="button" class="admin-button"><i class="fas fa-save"></i>&nbsp;&nbsp; Submit Tournament Results</a>
					<a class="admin-button" role="button" v-if="saving === true"><i class="fas fa-save"></i>&nbsp;&nbsp; Saving please wait</a>
                </div>
				
            </div>
            
		</div>
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
export default {
	data() {
		return {
			page: null,
			addPlayerModal: false,
			tournament: null,
			previous: null,
			upcoming: null,
			searchForTeam: null,
			addTeamToEventSwitch: false,
			umpSearch: null,
			registered_teams: [],
			content_width: false,
			mountCheckboxes: null,
			searchList: [],
			canvasAdditionalDetailsLeft: null,
			savedCheatSheet: false,
			umpireFound: null,
			addedToCheatSheet: null,
			searchResults: null,
			selectedTeam: null,
			selectRegion: false,
			eventTeam: null,
			saving: false,
			registrants: [],
			divisions: [],
			umpires: [
				{
					name: null,
					email: null,
				}
			],
			injuries: [
				{
					name: null,
					description: null
				}
			],
			comments: null,
			prize_pack: [
				{ 
					name: 'Basic/Provincial Qualifying',
					qty: 0,
					price_per: 195
				},
				{ 
					name: 'Deluxe/National Qualifying',
					qty: 0,
					price_per: 265
				},
				{ 
					name: 'Supplementary 2nd/3rd place awards',
					qty: 0,
					price_per: 170
				}
			]
			
		};
	},
	components: {
		Header,
		Footer,
		
	},
	
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page
		var slug = this.$route.params.slug

		axios.get(`/api/tournaments/get-tournament/${slug}`).then(results => {
			if(results.data.success === true) {				
				vm.tournament = results.data.tournament
				vm.registrants = results.data.tournament.registrants
				vm.divisions = results.data.tournament.divisions
			}
		})

	},
	computed: {
		registrantsInDivisions: function() {
			var vm = this

			var finished_divisions = []
			for(let division of vm.divisions) {
				var registrants_in_division = _.filter(vm.registrants, function(registrant) {
					return registrant.tournament_division_id === division.id
				})

				var i = 0
				for(i; i < registrants_in_division.length; i++) {
					registrants_in_division[i].placed = 1
					registrants_in_division[i].qualify = false
					registrants_in_division[i].wins = 0
					registrants_in_division[i].losses = 0
					registrants_in_division[i].higher_division = false
					registrants_in_division[i].lower_division = false
				}
				
				division.registrants = registrants_in_division
			
				
				finished_divisions.push(division)
			}

			return finished_divisions
		}
	},
	methods: {

		switchWidth: function($event) {
			this.content_width = !$event
		},
	
		moment: function(date, format = null) {
			return moment(date, format)
		},
		addInjury: function() {
			var vm = this
			vm.injuries.push({
				name: null,
				description: null
			})
		},
		addUmpire: function() {
			var vm = this
			vm.umpires.push({
				name: null,
				email: null
			})
		},
		saveTournamentResults: function() {
			var vm = this
			this.saving = true
			axios.post('/api/tournaments/submit-tournament-results', {
				tournament: vm.tournament,
				injuries: vm.injuries,
				prize_pack: vm.prize_pack,
				umpires: vm.umpires,
				registrants: vm.registrants,
				comments: vm.comments
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						// redirect back to last page
						
                        message: 'Submitted tournament results',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
					})  

					this.saving = false
					
					this.$router.push({ path: '/dashboard/convenors/my-tournaments', query: null })
				}
			})
		}
	}
}
</script>

<style lang="scss">
    
</style>
