<template>
    <span>
        <h2>Playoff Bracket Generator</h2>
		<p>Need help getting together a quick playoff bracket schedule? Use this generator to set it up.</p>
		<p>Lay out all the team names in the division below.</p>
		<hr>
		<div class="row">
			<div class="col-md-6">

				<p><b>Teams</b></p>
				<div v-for="(team, key) in teams" :key="key" style="margin-top: 10px;">
					<div class="row">
						<div class="col-xs-9">
							
								<b-input v-model="team.name" placeholder="Team Name"></b-input>
						
						</div>
						<div class="col-xs-3">
							<a class="button is-danger"><i class="fas fa-trash"></i>&nbsp;&nbsp;Remove</a>
						</div>
					</div>
				</div>			

				<a style="margin-top: 20px;" v-on:click="addTeam()" class="button"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add Team to List</a>
				<br><br>
				<a class="button is-info" v-on:click="roundRobinResults()">Generate Playoff Schedule</a>
			</div>
			<div class="col-md-6">
				<h3>Round Robin Generator Results</h3>
				<div class="row">
					<div class="col-md-4" :key="key" v-for="(round, key) in robinGeneratorResults">
						<div class="matchup-padding">
							<h5>Round #{{ key +1 }}</h5>
							<div class="matchup" :key="gkey" v-for="(game, gkey) in round">
								<div class="matchup-team">
									{{ game[0].name }}
								</div>
								<div class="matchup-team">
									{{ game[1].name }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br><br>
				<a class="button is-info" v-if="robinGeneratorResults.length > 0">Export to Excel</a>
			</div>
		</div>
		
		
    </span>
</template>

<script>

var robin = require('roundrobin')
export default {
	data() {
		return {
			numberOfGamesPerTeam: 3,
			teams: [
				{
					name: ''
				},
				{
					name: ''
				},
				{
					name: ''
				},
			],
			robinGeneratorResults: []
		};
	},
	components: {
		
	},
	mounted: function() {
        
	},
	computed: {
		
	},
	methods: {
		addTeam: function() {
			this.teams.push({
				name: ''
			})
		},
		roundRobinResults: function() {
			var teams = this.teams
			var numberOfGamesPerTeam = this.numberOfGamesPerTeam

			var roundResults = robin(teams.length, teams)

			var roundSpliced = roundResults.splice(0, numberOfGamesPerTeam)
			
			this.robinGeneratorResults = roundSpliced
		}
	}
}
</script>

<style lang="scss">
    
</style>
