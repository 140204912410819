<template>
    <div>
        
        <b-carousel-list 
            v-model="upcomingTournamentsStep" 
            :data="upcomingTournaments" 
            :arrow-hover="false"
            :items-to-show="carouselSize">
            <template slot="item" slot-scope="list">
                
                <div class="custom-event-area" style="text-align: center;">
                    <div class="only-ad" v-if="list.distance">
                        {{ list.distance }} away from you
                    </div>
                    
                    <div class="poster">
                        <img src="../../images/dashboard/sanctionedtournament.png" v-if="!list.poster_image_url" alt="Poster Image Url">
                        <img :src="list.poster_image_url" v-if="list.poster_image_url" alt="Poster Image Url">
                    </div>
                    <div class="custom-event-box">
                        <div class="custom-tournament-name">
                            {{ list.name }}
                        </div>
                        <div class="custom-tournament-date">
                            Starts {{ moment(list.event_start_date).format('dddd MMMM DD, YYYY')}}
                        </div>
                        <div class="custom-tournament-description">
                            {{ list.description }}
                        </div>
                        <div class="share-buttons">
                            <ul>
                                <li>
                                    <a href="#"><i class="fab fa-facebook" role="presentation"></i></a>
                                    <a href="#"><i class="fab fa-twitter" role="presentation"></i></a>
                                    <a href="#"><i class="fab fa-instagram" role="presentation"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="custom-tournament-divisions">
                            <div v-if="list.divisions.length > 0">
                                <div class="custom-tournament-divisions-header">
                                    Divisions Offered:
                                </div>
                                <div class="row" style="display: flex;">
                                    <div class="col-md-4" style="flex: 1" v-if="filterDivision(list.divisions, 'mens').length > 0">
                                        <div class="custom-tournament-division-header">
                                            Men's
                                        </div>
                                        
                                        <b-tag
                                            type="is-danger"
                                            :key="key"
                                            v-for="(division, key) in filterDivision(list.divisions, 'mens')">
                                            {{ division.rank_id }}
                                        </b-tag>
                                    
                                    </div>
                                    <div class="col-md-4" style="flex: 1"  v-if="filterDivision(list.divisions, 'ladies').length > 0">
                                        <div class="custom-tournament-division-header">
                                            Ladies
                                        </div>
                                        
                                        <div class="custom-tournament-division" 
                                            :key="key"
                                            v-for="(division, key) in filterDivision(list.divisions, 'ladies')">
                                            {{ division.rank_id }}
                                        </div>
                                    
                                    </div>
                                    <div class="col-md-4" style="flex: 1"  v-if="filterDivision(list.divisions, 'coed').length > 0">
                                        <div class="custom-tournament-division-header">
                                            Coed
                                        </div>
                                        
                                        <div class="custom-tournament-division" 
                                            :key="key"
                                            v-for="(division, key) in filterDivision(list.divisions, 'coed')">
                                            {{ division.rank_id }}
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="custom-tournament-entry-fees">
                            <div class="entry-fee-text">
                                Entry Fee:
                            </div>
                            <div class="entry-fee-team">
                                <i class="fas fa-users"></i> ${{ list.team_registration_fee }}
                            </div>
                            <div class="entry-fee-team" v-if="list.player_registration_fee">
                                <i class="fas fa-user"></i> ${{ list.player_registration_fee }}
                            </div>
                        </div>
                    </div>
                    <b-tabs expanded>
                        <button v-if="authUser" role="button"  class="admin-button" style="width: 100%;" v-on:click="emitRegisterNow(list)">Register Now!</button>
                        <div v-if="list.you_are_entered && authUser">
                            <div class="already-registered"  style="margin-top: 20px;">
                                <b>You have already entered/are on a team (or multiple teams) for this event!</b>
                            </div>
                        </div>
                        <a v-if="!authUser" v-on:click="emitUnloggedRegister(list.id)" :href="'/login?q=tournament&r=' + list.slug" role="button" class="admin-button" style="width: 100%;">Login Now!</a>
                        
                        
                    </b-tabs>
                    
                </div>
            </template>
        </b-carousel-list>
        
    </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import PaymentProcessor from '../components/PaymentProcessor.vue'
export default {
	data() {
		return {
            en: true,
            upcomingTournaments: [],
            upcomingTournamentsStep: 0,
            tournamentSelected: null,
            step: 0,
            authUser: null,
            selectedTeam: null,
            selectedRosterPlayers: [],
            registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
            teams: null,
            team_roster: [],
            region_rate: null,
            selectedInsuranceOption: null,
            paymentSuccess: false,
            triggerFinalize: false
		};
	},
	components: {
        // add multiple teams to the divisions, limit at one team per mens coed and ladies
        PaymentProcessor
    },
    watch: {
        selectedTeam: function($event) {
            var vm = this
            axios.get('/api/rosters/grab-roster/' + $event.team_slug).then(results => {
                if(results.data.success === true) {
                    vm.team_roster = results.data.team.core_roster
                }
            })
        },
        paymentSuccess: async function($event) {
            
            // now trigger automatically Submit & Receive Your Certificate

            await this.finalizeAndRegister()
        }
    },
	mounted: function() {
        var vm = this

        var vm = this
        // if(!sessionStorage.getItem('user_location')) {
        //     if(!navigator.geolocation) {
        //         vm.$buefy.toast.open({
        //             message: 'Geolocation is not supported by your browser',
        //             type: 'is-success', // is-danger
        //             duration: 2000,
        //             queue: false
        //         })
        //     } else {
        //         // status.textContent = 'Locating…';
        //         navigator.geolocation.getCurrentPosition(vm.geoLocateSuccess, vm.geoLocateError);
        //     }
        // }

        axios.get('/auth/check').then(results => {  
            var location = null
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                
                if(sessionStorage.getItem('user_location')) {
                    location = sessionStorage.getItem('user_location')
                }
            }
            axios.get('/api/users/upcoming-tournaments-near-you', {
                params: {
                    geolocate: location
                }
            }).then(results => {
                if(results.data.success === true) {
                    vm.upcomingTournaments = results.data.tournaments
                    
                }
            })
        })

       

        axios.get('/api/users/teams').then(results => {
            if(results.data.type === 'success') {
                
                vm.teams = results.data.data.teams

            }
        })

        

        
    },
    props: ['carouselSize'],
	computed: {
        totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournamentSelected.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournamentSelected.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournamentSelected.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
        spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender

            
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender) {
                        return true
                    }
                })

                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                if(find_insurance) {
                    vm.triggerFinalize = true
                    return true
                }
                vm.triggerFinalize = false
                return false
            } else {
                vm.triggerFinalize = false
                return false
            }
        },
        regionRateOptions: function() {
            var vm = this

            var array = []
            if(vm.region_rate) {
                if(vm.region_rate.rate.single_use_tournament_team !== null) {
                    array.push({
                        name: 'Play One Time',
                        price: vm.region_rate.rate.single_use_tournament_team,
                        price_id: vm.region_rate.rate.single_use_tournament_team_price_id,
                        id: 8
                    })
                }
                if(vm.region_rate.rate.un_insured_tournament_team !== null) {
                    array.push({
                        name: 'Uninsured Tournament Team',
                        price: vm.region_rate.rate.un_insured_tournament_team,
                        price_id: vm.region_rate.rate.un_insured_tournament_team_price_id,
                        id: 7
                    })
                }
                if(vm.region_rate.rate.fully_insured_tournament_team !== null) {
                    array.push({
                        name: 'Fully Insured Tournament Team',
                        price: vm.region_rate.rate.fully_insured_tournament_team,
                        price_id: vm.region_rate.rate.fully_insured_tournament_team_price_id,
                        id: 6
                    })
                }
            }

            return array
        }
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
        filterRoster: function(roster) {
            // cannot have ladies in mens, cannot have mens in ladies

            var vm = this

            var roster = vm.team_roster

            if(vm.registerForTournament.gender === 'mens') {
                roster = _.filter(roster, function(player) {
                    if(player.user.gender === 'Male') {
                        return true
                    }
                })
            }
            if(vm.registerForTournament.gender === 'ladies') {
                roster = _.filter(roster, function(player) {
                    if(player.user.gender === 'Female') {
                        return true
                    }
                })
            }

            return roster
        },
		// submitPaymentAttempt: function() {
        //     var vm = this 
        //     vm.paymentSuccess = true
        //     vm.$emit('payment:successful', true)
        // }
        emitRegisterNow: function(list) {
            var vm = this
            vm.tournamentSelected = list
            
            
			axios.post('/api/tournaments/region-rate', {
				region: vm.tournamentSelected.region_id
			}).then(results => {
				if(results.data.success === true) {
                    
                    vm.region_rate = results.data.region_rate
                    vm.emitUnloggedRegister(list.id)
                    vm.$emit('register:now', {
                        tournamentSelected: list,
                        region_rate: results.data.region_rate
                    })
				}
            })
        },
        paymentSuccessSwitch: function($event) {
            
            var vm = this
            vm.paymentSuccess = $event
            this.triggerFinalize = true
        },
        finalizeAndRegister: function() {
            
            var vm = this
            // verify that players are selected, so is team, etc
            axios.post('/api/users/register-for-tournament', {
                team_id: vm.selectedTeam.id,
                roster: vm.selectedRosterPlayers,
                gender_id: vm.registerForTournament.gender,
                division_id: vm.registerForTournament.division.id,
                tournament_id: vm.tournamentSelected.id,
                order_id: vm.paymentSuccess.id
            }).then(results => {
                // TO DO
            })
        },
        selectInsuranceOption: function(option) {
            var vm = this
            vm.selectedInsuranceOption = option

            
        },
        emitUnloggedRegister: function(id) {
            // create axios to add a view click
            
            axios.post('/api/tournaments/track-click', {
                tournament_id: id
            }).then(results => {
                if(results.data.success === true) {
                    
                }
            })
        },
        moment: function(date, format = null) {
            return moment(date, format)
        }
	}
}
</script>