<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<h1>Payments Reporting</h1>
				
			</div>
		</div>
		
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import axios from 'axios'
export default {
	data() {
		return {
			authUser: { first_name: null },
			content_width: false
		};
	},
	components: {
		Header,
		Footer,
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this
		
		axios.get('/auth/check').then(results => {  

            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.payments_reporting !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }
			}
        })
	},
	computed: {
	
	},
	methods: {
		switchWidth: function($event) {
			this.content_width = !$event
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
