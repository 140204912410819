<template>
	<div>
		<Header></Header>
		
        <div class="content-area">
			<div class="standard-page-header">
				Frequently Asked Questions
			</div>
            <div class="frontend-content-wrapper">
                <div style="text-align: center; padding: 10px;">
                    <a :class="['lang', { selected: en === true }]" v-on:click="selectLang(true)">EN</a>|<a :class="['lang', { selected: en === false }]" v-on:click="selectLang(false)">FR</a>
                </div>
				<b-tabs>
					<b-tab-item label="For Convenors">
						<div class="panel"  :key="key" v-for="(question, key) in faq.for_convenors">
							<div class="panel-question" v-on:click="question.hidden = !question.hidden">
								<div class="row">
									<div class="col-xs-11">
										{{ en === true ? question.question : question.question_fr }}
									</div>
									<div class="col-xs-1">
										<div style="float: right;">
											<i class="fas fa-caret-down"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="panel-answer" 
								v-if="question.hidden === false" 
								v-html="(en === true ? question.answer : question.answer_fr)">
							</div>
						</div>
					</b-tab-item>
					<b-tab-item label="For Umpires">
						<div class="panel"  :key="key" v-for="(question, key) in faq.for_umpires">
							<div class="panel-question" v-on:click="question.hidden = !question.hidden">
								<div class="row">
									<div class="col-xs-11">
										{{ en === true ? question.question : question.question_fr }}
									</div>
									<div class="col-xs-1">
										<div style="float: right;">
											<i class="fas fa-caret-down"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="panel-answer" 
								v-if="question.hidden === false" 
								v-html="(en === true ? question.answer : question.answer_fr)">
							</div>
						</div>
					</b-tab-item>
					<b-tab-item label="For Coaches/Players">
						<div class="panel"  :key="key" v-for="(question, key) in faq.for_players">
							<div class="panel-question" v-on:click="question.hidden = !question.hidden">
								<div class="row">
									<div class="col-xs-11">
										{{ en === true ? question.question : question.question_fr }}
									</div>
									<div class="col-xs-1">
										<div style="float: right;">
											<i class="fas fa-caret-down"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="panel-answer" 
								v-if="question.hidden === false" 
								v-html="(en === true ? question.answer : question.answer_fr)">
							</div>
						</div>
					</b-tab-item>
					<b-tab-item label="For Insurance">
						<div class="panel"  :key="key" v-for="(question, key) in faq.for_insurance">
							<div class="panel-question" v-on:click="question.hidden = !question.hidden">
								<div class="row">
									<div class="col-xs-11">
										{{ en === true ? question.question : question.question_fr }}
									</div>
									<div class="col-xs-1">
										<div style="float: right;">
											<i class="fas fa-caret-down"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="panel-answer" 
								v-if="question.hidden === false" 
								v-html="(en === true ? question.answer : question.answer_fr)">
							</div>
						</div>
					</b-tab-item>
				</b-tabs>
				
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import axios from 'axios'

export default {
	metaInfo() {
        return { 
            title: "Frequently Asked Questions - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'All of the answers to our frequently asked questions go here.' },
                { property: 'og:title', content: "Frequently Asked Questions - Slo-Pitch National"},
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/frequently-asked-questions' },
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/faq.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			faq: null,
			en: true
		};
	},
	components: {
		Header,
		Footer
	},
	mounted: function() {
		
		var vm = this
		axios.get('/api/pages/faq').then(results => {
			if(results.data.success === true) {	
				vm.faq = results.data.faq
			}
		}).catch(err => {
			console.log(err)
		})	
	},
	computed: {
	
	},
	methods: {
		selectLang: function(lang) {
            this.en = lang

            sessionStorage.setItem('lang', lang)
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
