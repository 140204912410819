<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="editUser !== null">
                <a class="admin-button" v-on:click="editUser = null"><i class="fas fa-caret-left"></i> Back</a>
                <h1>Manage {{ editUser.first_name }} {{ editUser.last_name }}</h1>
                <b-tabs>
                    <b-tab-item label="User Details">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            User Details
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>First Name:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p :class="{ 'is-deleted': editUser.deleted_at !== null }">{{ editUser.first_name }}</p>

                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Middle Name:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p :class="{ 'is-deleted': deleted_at !== null }">{{ editUser.middle_name }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Last Name:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.last_name }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Date of Birth:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ moment(editUser.date_of_birth).utc().format('YYYY-MM-DD') }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Gender:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.gender }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Email:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.email }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Phone:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.phone }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Username:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.username }}</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Emergency Contact Details
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Name:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.emergency_contact }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Phone #:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.emergency_contact_phone }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Security Question & Answer
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Q:</b></p>
                                            </div>
                                            <div style="flex: 5;">
                                                <p>{{ editUser.security_question }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>A:</b></p>
                                            </div>
                                            <div style="flex: 5;">
                                                <p>{{ editUser.security_answer }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Account Details
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Last Login:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ moment(editUser.last_login).format('YYYY-MM-DD h:mma') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Address Details
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Street Address:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.street_address }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>City:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.city }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Province:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.province }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Postal Code:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.postal_code }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Player Details
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Current Rank:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.current_rank_id }}</p>
                                            </div>
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;">
                                            <div style="flex: 1;">
                                                <p><b>Positions:</b></p>
                                            </div>
                                            <div style="flex: 2;">
                                                <p>{{ editUser.positions }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="padding">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            Waivers Signed
                                        </div>
                                        <div class="panel-block" style="padding: 10px 20px;" v-for="(waiver, key) in editUser.waivers" :key="key">
                                            <div style="flex: 2;">
                                                <p><b>Signed {{ moment(waiver.created_at).format('YYYY-MM-DD') }}</b></p>
                                            </div>
                                            <div style="flex: 1;">
                                                <button class="button is-small">View Waiver Iteration</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="User Permissions">
                        
                    </b-tab-item>
                    <b-tab-item label="Payment History">
                        
                    </b-tab-item>
                    <b-tab-item label="Teams">
                        
                    </b-tab-item>
                    <b-tab-item label="Notes">
                        <div class="row">
                            <div class="col-md-6">
                                <b-field label="Admin Notes">
                                    <b-input type="textarea"></b-input>
                                </b-field>
                                <a class="admin-button"><i class="fas fa-save"></i> Save Notes</a>
                            </div>
                            <div class="col-md-6">

                            </div>
                        </div>
                        
                    </b-tab-item>
                </b-tabs>
                
            </div>
			<div class="content-wrapper" v-if="editUser === null">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
                                Manage Banned Players
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area" style="padding-top: 20px;">
                    <div class="row">
                        <div class="col-md-12">                            
                            <div class="row">
                                <div class="col-md-12">
                                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                        <div
                                            slot="trigger" 
                                            slot-scope="props"
                                            class="card-header"
                                            role="button"
                                            aria-controls="contentIdForA11y3">
                                            <p class="card-header-title">
                                                Search & Control
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                        <div class="card-content">
                                            <div class="content">
                                                <b-field grouped group-multiline>
                                                    <div v-for="(column, index) in columnTemplate"
                                                        :key="index"
                                                        class="control">
                                                        <b-checkbox v-model="column.visible">
                                                            {{ column.title }}
                                                        </b-checkbox>
                                                    </div>
                                                </b-field>
                                                <hr>
                                                <b-field grouped group-multiline >
                                                    <b-field label="Rank">
                                                        <b-select v-model="searchables.rank">
                                                            <option value="*">All</option>
                                                            <option value="A">A</option>
                                                            <option value="B">B</option>
                                                            <option value="C">C</option>
                                                            <option value="D">D</option>
                                                            <option value="E">E</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Region" v-if="specific_region === false">
                                                        <b-select v-model="searchables.region">
                                                            <option value="*">All</option>
                                                            <option :value="1">Alberta</option>
                                                            <option :value="2">British Columbia</option>
                                                            <option :value="3">Manitoba</option>
                                                            <option :value="4">Ontario</option>
                                                            <option :value="5">Maritimes</option>
                                                            <option :value="6">Quebec</option>
                                                            <option :value="7">Newfoundland & Labrador</option>
                                                            <option :value="8">Saskatchewan</option>
                                                        </b-select>
                                                    </b-field>
                                                    
                                                    <b-field label="Gender">
                                                        <b-select v-model="searchables.gender">
                                                            <option value="*">All</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="X">X</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Waiver Signed"  style="margin-right: 50px;">
                                                        <b-select v-model="searchables.waiver_signed">
                                                            <option value="*">All</option>
                                                            <option value="true">Signed</option>
                                                            <option value="false">Unsigned</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Number of Results">
                                                        <b-select v-model="searchables.limit">
                                                            <option :value="100">100</option>
                                                            <option :value="500">500</option>
                                                            <option :value="1000">1000</option>
                                                            <option :value="2500">2500</option>
                                                            <option :value="5000">5000</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Per Page">
                                                        <b-select v-model="perPage">
                                                            <option value="10">10 per page</option>
                                                            <option value="25">25 per page</option>
                                                            <option value="50">50 per page</option>
                                                            <option value="100">100 per page</option>
                                                        </b-select>
                                                    </b-field>
                                                    <b-field label="Show Deleted">
                                                        <b-switch v-model="searchables.deleted_at"></b-switch>
                                                    </b-field>
                                                </b-field>
                                                <b-field grouped group-multiline style="margin-top: 30px;" >
                                                    
                                                    <b-field label="ID" style="width: 60px;">
                                                        <b-input v-model="searchables.id"></b-input>
                                                    </b-field>
                                                    <b-field label="First Name" style="width: 150px;">
                                                        <b-input v-model="searchables.first_name"></b-input>
                                                    </b-field>
                                                    <b-field label="Last Name" style="width: 150px;">
                                                        <b-input v-model="searchables.last_name"></b-input>
                                                    </b-field>
                                                    <b-field label="Email">
                                                        <b-input v-model="searchables.email"></b-input>
                                                    </b-field>
                                                    <b-field label="Phone">
                                                        <b-input v-model="searchables.phone"></b-input>
                                                    </b-field>
                                                    <b-field label="City">
                                                        <b-input v-model="searchables.city"></b-input>
                                                    </b-field>
                                                    <b-field label="Date of Birth">
                                                        <b-datepicker
                                                            v-model="searchables.date_of_birth"
                                                            placeholder="Start date..."
                                                            icon="calendar-today"
                                                            trap-focus>
                                                        </b-datepicker>
                                                        <b-datepicker
                                                            v-model="searchables.end_date_of_birth"
                                                            placeholder="End date..."
                                                            icon="calendar-today"
                                                            trap-focus>
                                                        </b-datepicker>
                                                    </b-field>
                                                </b-field>
                                                <hr>
                                                <div class="content">
                                                    <a class="button is-info" v-on:click="sendMessage = true"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp; Send Email To Selected Users</a>
                                                    <vue-excel-xlsx
                                                        :data="selectedUsers"
                                                        :columns="columnTemplate"
                                                        :filename="'filename'"
                                                        :sheetname="'sheetname'"
                                                        :class="'button is-success'"
                                                        >
                                                        <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
                                                    </vue-excel-xlsx>

                                                    <div class="send-email" v-if="sendMessage" style="margin-top: 30px;">
                                                        <div class="contacts-selected" style="margin-top: 30px;">
                                                            <p><b>Sending To:</b></p>
                                                            <b-tag v-for="(email, index) in computedEmails" :key="index">{{ email }}</b-tag>
                                                        </div>
                                                        

                                                        <b-field label="Subject" style="margin-top: 20px;">
                                                            <b-input v-model="sendEmail.subject"></b-input>
                                                        </b-field>
                                                        <b-field label="Message">
                                                            <b-input v-model="sendEmail.message"
                                                            maxlength="2000" type="textarea"></b-input>
                                                        </b-field>

                                                        <a class="admin-button" v-on:click="sendMessageToPlayers()">Send Message to Selected Players</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            
                            

                            <b-table
                                :data="users"
                                ref="table"
                                :sticky-header="true"
                                :height="'100%'"
                                :paginated="true"
                                :current-page="1"
                                :per-page="perPage"
                                :pagination-simple="false"
                                :pagination-position="'bottom'"
                                :checked-rows.sync="selectedUsers"
                                checkable
                                detailed
                                detail-key="id"
                                :show-detail-icon="showDetailIcon"
                                
                                default-sort="user.first_name"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">
                                <template
                                    v-for="(column, index) in columnTemplate"
                                    >
                                    <b-table-column 
                                        v-bind="column"
                                        
                                        :key="index" 
                                        :sortable="true"
                                        :label="column.title"
                                        :visible="column.visible">

                                        
                                        <template v-slot="props" :width="props.column.width">
                                            <span v-if="props.column.field === 'gender'">
                                                <span v-if="props.row.gender === 'Male'">
                                                    <b-tag type="is-danger is-light">
                                                        <i class="fas fa-mars"></i> Male
                                                    </b-tag>
                                                    
                                                </span>
                                                <span v-if="props.row.gender === 'Female'">
                                                    <b-tag type="is-link is-danger">
                                                        <i class="fas fa-venus"></i> Female
                                                    </b-tag>
                                                </span>
                                            </span>
                                            <span v-else-if="props.column.field === 'current_rank_id'">
                                                <RankComponent :currentRank="props.row.current_rank_id"
                                                :previousRank="props.row.previous_rank_id"></RankComponent>
                                            </span>
                                            <span v-else-if="props.column.field === 'first_name'">
                                                <b :class="{ 'is-deleted': props.row.deleted_at !== null }">
                                                    {{ props.row.first_name.replace(' ', '_') }}
                                                </b>
                                            </span>
                                            <span v-else-if="props.column.field === 'last_name'">
                                                <b :class="{ 'is-deleted': props.row.deleted_at !== null }">{{ props.row.last_name.replace(' ', '_') }}</b>
                                            </span>
                                            <span v-else-if="props.column.field === 'email'">
                                                <b :class="{ 'is-deleted': props.row.deleted_at !== null }">{{ props.row.email.replace(' ', '_') }}</b>
                                                <br v-if=" props.row.banned_until">
                                                <b-tag type="is-danger" v-if=" props.row.banned_until" size="small" >Banned until: {{ moment(props.row.banned_until).format('YYYY-MM-DD')}}</b-tag>
                                            </span>
                                            <span v-else-if="props.column.field === 'default_region_id'">
                                                <span v-if="props.row.region">
                                                    {{ props.row.region.name }}
                                                </span>
                                            </span>
                                            <span style="display: block; width: 100px" v-else-if="props.column.field === 'date_of_birth'">
                                                {{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}
                                            </span>
                                            <span v-else-if="props.column.field === 'last_login'">
                                                <span v-if="props.row.last_login">
                                                    {{ moment(props.row.last_login).utc().format('YYYY-MM-DD') }}
                                                </span>
                                            </span>
                                            <span v-else-if="props.column.field === 'latest_waiver_signed'">
                                                <span v-if="props.row.latest_waiver_signed == true">
                                                    <b-tag type="is-success">
                                                        <i class="fas fa-check"></i>
                                                    </b-tag>
                                                </span>
                                                <span v-else>
                                                    <b-tag type="is-danger">
                                                        <i class="fas fa-times"></i>
                                                    </b-tag>
                                                </span>
                                            </span>
                                            <span v-else>
                                                {{ props.row[column.field] }}
                                            </span>
                                        </template>
                                    </b-table-column>
                                </template>
                                <template slot="detail" slot-scope="props">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div style="text-align: center;">
                                                <a class="button" v-on:click="resetPassword(props.row)">Reset Password</a>
                                                <span v-if="props.row.confirmed === null">
                                                    <br>
                                                    <a class="button" v-on:click="resendConfirmAccount(props.row)">Resend Confirm Account</a>
                                                </span>
                                                <span v-if="props.row.failed_login_attempts > 0">
                                                <br>
                                                <a class="button" v-on:click="resetFailedAttempts(props.row)">Reset Failed Login Attempts ({{ props.row.failed_login_attempts }})</a>
                                                <br>
                                                
                                                </span>
                                                <br>
                                                <span>
                                                   <a class="button" v-on:click="sendWaiverReminder(props.row)">Resend Waiver</a>
                                                </span>
                                                <a class="button" :href="'/superadmin/manage/users/' + props.row.id">View Profile</a>
                                                
                                                <div class="panel is-success" style="margin-top: 30px;" v-if="props.row.notes.length > 0">
                                                    <div class="panel-heading" style="font-size: 12px;">
                                                        Notes
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="user-note " :key="key" v-for="(note, key) in props.row.notes">
                                                        <div class="user-note-note" style="padding-bottom: 10px;">{{ note.notes }}</div>
                                                        <div class="note">{{ moment(note.created_at).format('YYYY-MM-DD h:mmA') }} by {{ note.written.first_name }} {{ note.written.last_name }}</div>
                                                    </div>
                                                    <b-field>
                                                        <b-input type="text" v-model="note"></b-input>
                                                    </b-field>
                                                    <div style="text-align: center; margin-top: 10px; padding-bottom: 10px;">
                                                        <a class="admin-button" v-on:click="addNote(props.row)">Add Note</a>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <div class="panel is-primary">
                                                    <div class="panel-heading" style="font-size: 12px; color: #fff;">
                                                        Security
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Question:</b></td>
                                                                        <td>{{ props.row.security_question }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Answer:</b></td>
                                                                        <td>{{ props.row.security_answer }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="padding">
                                                <div class="panel is-danger">
                                                    <div class="panel-heading " style="font-size: 12px;">
                                                        Emergency
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Name:</b></td>
                                                                        <td>{{ props.row.emergency_contact }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Phone:</b></td>
                                                                        <td>{{ props.row.emergency_contact_phone }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <div class="panel is-info">
                                                    <div class="panel-heading" style="font-size: 12px;">
                                                        Address
                                                    </div>
                                                    <div class="panel-content">
                                                        <div class="padding">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><b>Address:</b></td>
                                                                        <td>{{ props.row.street_address }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>City:</b></td>
                                                                        <td>{{ props.row.city }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Province:</b></td>
                                                                        <td>{{ props.row.province }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Postal Code:</b></td>
                                                                        <td>{{ props.row.postal_code }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <div class="panel is-success">
                                                    <div class="panel-heading" style="font-size: 12px;">
                                                        Permissions
                                                    </div>
                                                    <div class="panel-content">
                                                        <table class="table">
                                                            <tr v-if="props.row.umpire_permissions">
                                                                <td style="width: 100px;" >
                                                                    <b>Umpire</b>
                                                                </td>
                                                                <td>
                                                                    {{ props.row.umpire_permissions.cert_number }}
                                                                </td>
                                                            </tr>
                                                            <tr  v-if="props.row.convenor_permissions">
                                                                <td style="width: 100px;">
                                                                    <b>Convenor</b>
                                                                </td>
                                                                <td>
                                                                    {{ props.row.convenor_permissions ? 'Yes' : 'No' }}
                                                                </td>
                                                            </tr>
                                                            <tr  v-if="props.row.user_permissions">
                                                                <td style="width: 100px;">
                                                                    <b>Superadmin</b>
                                                                </td>
                                                                <td v-if="props.row.user_permissions">
                                                                    {{ props.row.user_permissions ? 'Yes' : 'No' }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RankComponent from '../components/RankComponent.vue'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
export default {
	data() {

		return {
            isLoading: true,
            authUser: { first_name: null },
            content_width: false,
            defaultOpenedDetails: [1],
            editUser: null,
            showDetailIcon: true,
            sendMessage: false,
            timer: null,
            sendEmail: {
                subject: null,
                message: null
            },
            note: null,
            perPage: 50,
            selectedUsers: [],
            deleted_at: false,
            specific_region: false,
            searchables: {
                id: null,
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                city: null,
                username: null,
                rank: '*',
                region: '*',
                gender: '*',
                date_of_birth: null,
                end_date_of_birth: null,
                waiver_signed: '*',
                deleted_at: false,
                limit: 100,
            },
            searching: {
                'first_name': null,
                'last_name': null,
                'username': null,
                'email': null,
                'phone': null,
                'city': null,
                'date_of_birth': null,
                'latest_waiver_signed': null
            },
            users: [],
            regions: [],
            columnTemplate: [
                { title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
                { title: 'Region', label: 'Region', field: 'default_region_id', visible: true, searchable: false },
                { title: 'Gender', label: 'Gender', field: 'gender', visible: true, searchable: false },
                { title: 'Rank', label: 'Rank', field: 'current_rank_id', visible: true, searchable: false, width: 50 },
                { title: 'First Name', label: 'First Name', field: 'first_name', visible: true, searchable: false },
                { title: 'Last Name', label: 'Last Name', field: 'last_name', visible: true, searchable: false },
                { title: 'Username', label: 'Username', field: 'username', visible: true, searchable: false },
                { title: 'Email', label: 'Email', field: 'email', visible: true, searchable: false },
                { title: 'Phone', label: 'Phone', field: 'phone', visible: true, searchable: false },
                { title: 'City', label: 'City', field: 'city', visible: false, searchable: false },
                { title: 'Full Address', label: 'Full Address', field: 'full_written_address', visible: false, searchable: false },
                { title: 'Date of Birth', label: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
                { title: 'Waiver', label: 'Waiver', field: 'latest_waiver_signed', visible: true, searchable: false },
                { title: 'Last Login', label: 'Last Login', field: 'last_login', visible: false },
            ]
		};
	},
	components: {
		Header,
		Footer,
        RankComponent
    },
    created() {
        this.debouncedCheckingThis = _.debounce(this.checkingThis, 1000);
    },
    watch: {
        deleted_at: function(test) {
        },
        searchables: {
            handler(val) {
                this.checkingThis()
            }, deep: true
        }
    },
	mounted: function() {
		// grab all the dashboard information now
        var vm = this
        
         axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
        
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_players !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                if(results.data.user_permissions.specific_region_only) {
                    vm.searchables.region = results.data.user_permissions.specific_region_only
                    vm.specific_region = true
                }

                axios.get('/api/superuser/banned', {
                    params: vm.searchables
                }).then(results => {
                    if(results.data.success === true) {
                        vm.users = results.data.users
                    }
                })

                vm.isLoading = false
            }
        })
	},
	computed: {
        computedEmails: function() {
			var vm = this
			var array = []
            if(vm.selectedUsers.length > 0) {
                var i = 0
                for(i; i < vm.selectedUsers.length; i++) {
                    var user = vm.selectedUsers[i]
                    
                    array.push(user.email)
                }
            }
			return array
		}
	},
	methods: {
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        isString: function($event) {
            
        },
        findRegion: function(row) {
            var vm = this
            var region = _.find(vm.regions, function(region) {
                return region.id === row
            })
            if(region) {
                return region.name
            }

            return null
        },
        resendConfirmAccount: function(user) {
            var vm = this


            axios.post('/auth/resend-confirm-account', {
                email: user.email
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Confirm email resent to ' + user.first_name + ' ' + user.last_name,
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Confirm email failed ' + user.first_name + ' ' + user.last_name,
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        resetPassword: function(user) {
            var r = confirm('Are you sure you want to reset their password? Remind them, they will be sent an email, which they must click the link and log in.')
            var vm = this
            if(r === true) {
                axios.post('/auth/reset-password', {
                    email: user.email
                }).then(results => {
                    if(results.data.success === true) {
                        vm.rememberEmail = true
                        this.$buefy.snackbar.open({
                            message: 'Password reset sent to ' + user.first_name + ' ' + user.last_name,
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                    }
                })
            }
        },
        resetFailedAttempts: function(user) {
            var vm = this

            axios.post('/auth/reset-failed-attempts', {
                email: user.email
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Failed attempts reset for ' + user.first_name + ' ' + user.last_name,
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        checkingThis: function() {
            var vm = this

        
            axios.get('/api/superuser/banned', {
                params: vm.searchables
            }).then(results => {
                if(results.data.success === true) {
                    vm.users = results.data.users
                }
            })
        

            
        },
        sendMessageToPlayers: function() {
            var vm = this

            axios.post('/api/superuser/send-messages-to-players', {
                message: vm.sendEmail,
                emails: vm.computedEmails,
            }).then(results => {
                if(results.data.success === true) {
                    vm.sendEmail = {
                        subject: null,
                        message: null
                    }

                    vm.selectedUsers = []
                    this.$buefy.snackbar.open({
                        message: 'You have successfully sent your message!',
                        type: 'is-success' // is-danger
                    })
                }
            })
        },
        sendWaiverReminder: function(user) {
            var vm = this
            axios.post('/api/users/send-waiver-reminder', {
                user: user
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Waiver reminder has been sent!',
                        type: 'is-success' // is-danger
                    })
                }
            })
        },
        addNote: function(user) {
            var vm = this
            axios.post('/api/superuser/save-note', {
                user_id: user.id,
                note: vm.note
            }).then(results => {
                if(results.data.success === true) {
                    vm.notes = results.data.notes
                    vm.note = null

                    this.$buefy.snackbar.open({
                        message: 'Successfully added a note to their file.',
                        type: 'is-success' // is-danger
                    })

                } else {

                    this.$buefy.snackbar.open({
                        message: 'Failed to add a note to their file.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
