<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>


		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
                                View Waivers By Region (as of Today)
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
             
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row" v-if="waivers">
                    <div class="col-md-12">
                        <br><br>
                        <p><b>Total: </b> {{ waivers.total }}</p>
                        <p><b>Alberta: </b> {{ waivers.ab }}</p>
                        <p><b>British Columbia: </b> {{ waivers.bc }}</p>
                        <p><b>Manitoba: </b> {{ waivers.mb }}</p>
                        <p><b>Ontario: </b> {{ waivers.on }}</p>
                        <p><b>Maritimes: </b> {{ waivers.mt }}</p>
                        <p><b>Quebec: </b> {{ waivers.qc }}</p>
                        <p><b>Newfoundland & Labrador: </b> {{ waivers.nl }}</p>
                        <p><b>Saskatchewan: </b> {{ waivers.sk }}</p>
                        <p><b>No Region: </b> {{ waivers.null }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

        
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import moment from 'moment'

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from 'axios'
export default {
	data() {

		return {
            content_width: false,
            isLoading: false,
            waivers: null
		};
	},
	components: {
		Header,
        Footer,
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			 
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.merge_users !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/view-waivers-by-region').then(results => {
                    if(results.data.success === true) {
                        
                        vm.waivers = results.data.waivers
                        // vm.waivier = results.data.logs
                        // format the content
                    }
                })

                vm.isLoading = false
            }
        })
	},
	computed: {
        
    },
    watch: {
    
    },
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
       
	},
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
