<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        <b-modal 
            v-model="createNewFormSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            full-screen
            aria-modal>
            <template #default="props">
                <div class="modal-card">
                     <header class="modal-card-head">
                        <p class="modal-card-title">Create a New Form</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
                    </header>
                    <section class="modal-card-body">
                        <b-tabs>
                            <b-tab-item label="Create Form">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="note">
                                            <span>Please note:</span> selecting "Email, First Name or Last Name" will create a short text box with autofill OF the person who is filling out the form. If you are having the customer fill out info for someone else - DO NOT USE THIS.
                                            <br>
                                            Hidden fields will allow you to pass on information to the completed form without showing the customer.
                                        </p>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Form Name (EN)">
                                                        <b-input v-model="form.name"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Form Name (FR)">
                                                        <b-input v-model="form.fr_name"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Submit to">
                                                        <b-select v-model="form.collection">
                                                            <option value="form_collection">Form Collector</option>

                                                        </b-select>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Region">
                                                        <b-select v-model="form.region">
                                                            <option value="-1">All</option>
                                                            <option value="1">Alberta</option>
                                                            <option value="2">British Columbia</option>
                                                            <option value="3">Manitoba</option>
                                                            <option value="4">Ontario</option>
                                                            <option value="5">Maritimes</option>
                                                            <option value="6">Quebec</option>
                                                            <option value="7">Newfoundland Labrador</option>
                                                            <option value="8">Saskatchewan</option>
                                                        </b-select>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-question-box" :key="key" v-for="(question, key) in form_questions">
                                            <div v-if="question.type === 'section_break'">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Section Header">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Section Header">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div class="padding">
                                                            <b-field label="Section Description">
                                                                <b-input type="textarea" v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Section Description">
                                                                <b-input type="textarea" v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>     
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type === 'short_text'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>  
                                            </div>
                                            <div v-if="question.type === 'long_text'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type === 'true_or_false'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type === 'select'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="padding">
                                                    <p><b>Options</b></p>
                                                    <div class="row">
                                                        <div class="col-md-4" :key="key2" v-for="(option, key2) in question.options">
                                                            <div class="padding" style="border: 1px dashed #ccc; margin-right: 5px;">
                                                                <b-field :label="'EN Option ' + (key2 + 1)">
                                                                    <b-input v-model="option.name"></b-input>

                                                                
                                                                </b-field>
                                                                <b-field :label="'FR Option ' + (key2 + 1)">
                                                                    <b-input v-model="option.fr_name"></b-input>
                                                                </b-field>
                                                                <div style="text-align: right;">
                                                                    <a v-on:click="removeOption(question, key2)" class="button is-small is-danger"><i class="fas fa-trash"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <a class="button" style="margin-top: 20px;" v-on:click="addFormOption(question)">Add Option</a>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type === 'date'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type === 'time'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type === 'checkbox'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type === 'first_name'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>   
                                            </div>
                                            <div v-if="question.type === 'last_name'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>   
                                            </div>
                                            <div v-if="question.type === 'email'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>     
                                            </div>
                                            <div v-if="question.type === 'hidden'">
                                                <div class="row">
                                                
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Value">
                                                                <b-input v-model="question.value"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>    
                                            </div>
                                        </div>
                                        <a class="button button-primary" v-on:click="addSectionBreak()" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add Section Break</a>    
                                        <a class="button button-primary" v-on:click="addFormQuestion()" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add Form Question</a>                       
                                    </div>
                                </div>
                            </b-tab-item>
                            <b-tab-item label="Preview">
                                <div class="form-question-preview">
                                    <div class="form-question-header">Preview</div>
                                    <h3 style="margin-top: 10px;"> {{ form.name }} - {{ form.fr_name }}</h3>
                                    <div :key="key" v-for="(question,key) in form_questions">
                                        <div v-if="question.type === 'section_break'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                {{ question.description }} - {{ question.fr_description }}
                                            </b-field>
                                        </div>
                                        <div v-if="question.type === 'short_text'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }}</p>
                                        </div>
                                        <div v-if="question.type === 'long_text'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-input type="textarea" :placeholder="question.placeholder" :disabled="true"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type === 'true_or_false'">
                                            
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-radio
                                                    size="is-large"
                                                    :native-value="true">
                                                    True
                                                </b-radio>
                                                <b-radio
                                                    size="is-large"
                                                    :native-value="false">
                                                    False
                                                </b-radio>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                            <div v-if="question.type === 'checkbox'">
                                            
                                                <div class="field">
                                                    <b-checkbox>
                                                        {{ question.label }}
                                                    </b-checkbox>
                                                </div>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type === 'select'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-select> 
                                                    <option :key="key3" v-for="(option, key3) in question.options">
                                                        {{ option.name }} - {{ option.fr_name }}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type === 'date'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-datepicker
                                                    placeholder="Click to select..."
                                                    icon="calendar-today"
                                                    trap-focus>
                                                </b-datepicker>

                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type === 'time'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-timepicker
                                                    
                                                    placeholder="Click to select..."
                                                    icon="clock"
                                                    :hour-format="'12'">
                                                </b-timepicker>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type === 'first_name'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true" value="**First Name of Logged In User**"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type === 'last_name'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true" value="**Last Name of Logged In User**"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type === 'email'">
                                            <b-field :label="question.label + '-' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true" value="email@loggedinuser.com"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }}  - {{ question.fr_description }} </p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </b-tab-item>
                        </b-tabs>
                    </section>
                    <footer class="modal-card-foot">
                        <button v-on:click="saveForm()" class="admin-button"><i class="fas fa-save"></i> Save New Form</button>
                    </footer>
                </div>
            </template>
        </b-modal>

        <b-modal 
            v-model="editFormSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            full-screen
            aria-modal>
            <template #default="props">
                <div class="modal-card">
                     <header class="modal-card-head">
                        <p class="modal-card-title">Edit Form - {{ selectedForm.name }}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
                    </header>
                    <section class="modal-card-body">
                        <b-tabs>
                            <b-tab-item label="Create Form">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="note">
                                            <span>Please note:</span> selecting "Email, First Name or Last Name" will create a short text box with autofill OF the person who is filling out the form. If you are having the customer fill out info for someone else - DO NOT USE THIS.
                                            <br>
                                            Hidden fields will allow you to pass on information to the completed form without showing the customer.
                                        </p>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Form Name (EN)">
                                                        <b-input v-model="selectedForm.name"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Form Name (FR)">
                                                        <b-input v-model="selectedForm.fr_name"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Submit to">
                                                        <b-select v-model="selectedForm.path_to_form_save">
                                                            <option value="form_collection">Form Collector</option>

                                                        </b-select>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Region">
                                                        <b-select v-model="selectedForm.region_id">
                                                            <option value="-1">All</option>
                                                            <option value="1">Alberta</option>
                                                            <option value="2">British Columbia</option>
                                                            <option value="3">Manitoba</option>
                                                            <option value="4">Ontario</option>
                                                            <option value="5">Maritimes</option>
                                                            <option value="6">Quebec</option>
                                                            <option value="7">Newfoundland Labrador</option>
                                                            <option value="8">Saskatchewan</option>
                                                        </b-select>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-question-box" :key="key" v-for="(question, key) in selectedForm.questions">
                                            
                                            <div v-if="question.type_id === 'section_break'">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Section Header">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Section Header">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div class="padding">
                                                            <b-field label="Section Description">
                                                                <b-input type="textarea" v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Section Description">
                                                                <b-input type="textarea" v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>     
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type_id === 'short_text'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>  
                                            </div>
                                            <div v-if="question.type_id === 'long_text'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type_id === 'true_or_false'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type_id === 'select'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="padding">
                                                    <p><b>Options</b></p>
                                                    <div class="row">
                                                        <div class="col-md-4" :key="key2" v-for="(option, key2) in question.options">
                                                            <div class="padding" style="border: 1px dashed #ccc; margin-right: 5px;">
                                                                <b-field :label="'EN Option ' + (key2 + 1)">
                                                                    <b-input v-model="option.name"></b-input>

                                                                
                                                                </b-field>
                                                                <b-field :label="'FR Option ' + (key2 + 1)">
                                                                    <b-input v-model="option.fr_name"></b-input>
                                                                </b-field>
                                                                <div style="text-align: right;">
                                                                    <a v-on:click="removeOption(question, key2)" class="button is-small is-danger"><i class="fas fa-trash"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <a class="button" style="margin-top: 20px;" v-on:click="addFormOption(question)">Add Option</a>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type_id === 'date'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type_id === 'time'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type_id === 'checkbox'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="question.type_id === 'first_name'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>   
                                            </div>
                                            <div v-if="question.type_id === 'last_name'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>   
                                            </div>
                                            <div v-if="question.type_id === 'email'">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <div class="padding">
                                                            <b-field label="Req">
                                                                <b-checkbox class="is-large" v-model="question.required"></b-checkbox>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Description">
                                                                <b-input v-model="question.description"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Description">
                                                                <b-input v-model="question.fr_description"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="padding">
                                                            <b-field label="Placeholder">
                                                                <b-input v-model="question.placeholder"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Placeholder">
                                                                <b-input v-model="question.fr_placeholder"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>     
                                            </div>
                                            <div v-if="question.type_id === 'hidden'">
                                                <div class="row">
                                                
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Type">
                                                                <b-select v-model="question.type_id">
                                                                    <option v-for="(option, key) in questionTypes" :key="key" :value="option.value">{{ option.name }}</option>
                                                                </b-select>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Label">
                                                                <b-input v-model="question.label"></b-input>
                                                            </b-field>
                                                            <b-field label="FR Label">
                                                                <b-input v-model="question.fr_label"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="padding">
                                                            <b-field label="Value">
                                                                <b-input v-model="question.value"></b-input>
                                                            </b-field>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div style="text-align: right;">
                                                    <a class="button is-danger" v-on:click="removeEditRow(key)"><i class="fas fa-trash"></i></a>
                                                </div>    
                                            </div>
                                        </div>
                                        <a class="button button-primary" v-on:click="editAddSectionBreak()" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add Section Break</a>    
                                        <a class="button button-primary" v-on:click="editAddFormQuestion()" style="margin-top: 10px;"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add Form Question</a>                       
                                    </div>
                                </div>
                            </b-tab-item>
                            <b-tab-item label="Preview">
                                <div class="form-question-preview">
                                    <div class="form-question-header">Preview</div>
                                    <h3 style="margin-top: 10px;"> {{ selectedForm.name }} - {{ selectedForm.fr_name }}</h3>
                                    <div :key="key" v-for="(question,key) in selectedForm.questions">
                                        <div v-if="question.type_id === 'section_break'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                {{ question.description }} - {{ question.fr_description }}
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'short_text'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type_id === 'long_text'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-input type="textarea" :placeholder="question.placeholder" :disabled="true"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type_id === 'true_or_false'">
                                            
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-radio
                                                    size="is-large"
                                                    :native-value="true">
                                                    True
                                                </b-radio>
                                                <b-radio
                                                    size="is-large"
                                                    :native-value="false">
                                                    False
                                                </b-radio>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                            <div v-if="question.type_id === 'checkbox'">
                                            
                                                <div class="field">
                                                    <b-checkbox>
                                                        {{ question.label }}
                                                    </b-checkbox>
                                                </div>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type_id === 'select'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-select> 
                                                    <option :key="key3" v-for="(option, key3) in question.options">
                                                        {{ option.name }}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type_id === 'date'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-datepicker
                                                    placeholder="Click to select..."
                                                    icon="calendar-today"
                                                    trap-focus>
                                                </b-datepicker>

                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type_id === 'time'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-timepicker
                                                    
                                                    placeholder="Click to select..."
                                                    icon="clock"
                                                    :hour-format="'12'">
                                                </b-timepicker>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type_id === 'first_name'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true" value="**First Name of Logged In User**"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                        <div v-if="question.type_id === 'last_name'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true" value="**Last Name of Logged In User**"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }}</p>
                                        </div>
                                        <div v-if="question.type_id === 'email'">
                                            <b-field :label="question.label + ' - ' + question.fr_label">
                                                <b-input :placeholder="question.placeholder" :disabled="true" value="email@loggedinuser.com"></b-input>
                                            </b-field>
                                            <p class="note">{{ question.description }} - {{ question.fr_description }} </p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </b-tab-item>
                        </b-tabs>
                    </section>
                    <footer class="modal-card-foot">
                        <button v-on:click="updateForm()" class="admin-button"><i class="fas fa-save"></i> Update Form</button>
                    </footer>
                </div>
            </template>
        </b-modal>

		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div :class="['page-header', { sidebarClosed: !content_width }]">
                <div class="row">
                    <div class="col-md-3">
                        <div class="page-header-title">
                            Manage Forms
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="padding">
                        <b-field  grouped position="is-right">
                            <a v-on:click="createNewFormSwitch = true" class="button">Create New Form</a>
                        </b-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="admin-area">
                <div class="row">
                    <div class="col-md-12">
                        <div class="padding" v-if="!selectedForm">
             
                            <b-table
                                :data="forms"
                                ref="table"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">

                            

                                <b-table-column field="name" label="Form Name" sortable centered v-slot="props">
                                    {{ props.row.name }}
                                </b-table-column>
                                <b-table-column field="name" label="French Name" sortable centered v-slot="props">
                                    {{ props.row.fr_name }}
                                </b-table-column>
                                <b-table-column field="name" label="Region" sortable centered v-slot="props">
                                    <span v-if="props.row.region_id === '-1'">All</span>
                                    <span v-if="props.row.region_id === '1'">Alberta</span>
                                    <span v-if="props.row.region_id === '2'">British Columbia</span>
                                    <span v-if="props.row.region_id === '3'">Manitoba</span>
                                    <span v-if="props.row.region_id === '4'">Ontario</span>
                                    <span v-if="props.row.region_id === '5'">Maritimes</span>
                                    <span v-if="props.row.region_id === '6'">Quebec</span>
                                    <span v-if="props.row.region_id === '7'">Newfoundland Labrador</span>
                                    <span v-if="props.row.region_id === '8'">Saskatchewan</span>
                                </b-table-column>
                                <b-table-column field="edit" label="Edit" sortable centered v-slot="props">
                                    <a class="button is-small" v-on:click="editForm(props.row)">Edit Form</a>
                                    <a class="button is-small" :href="'/dashboard/form/' + props.row.slug">View Form</a>
                                </b-table-column>
                            

                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import moment from 'moment'
import axios from 'axios'
export default {
	data() {
		return {
            isLoading: true,
            createNewFormSwitch: false,
            authUser: { first_name: null },
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            waivers: [],
            questionTypes: [
                {
                    value: 'short_text',
                    name: 'Short Text'
                },
                {
                    value: 'long_text',
                    name: 'Long Text'
                },
                {
                    value: 'true_or_false',
                    name: 'True or False'
                },
                {
                    value: 'select',
                    name: 'Select'
                },
                {
                    value: 'checkbox',
                    name: 'Checkbox'
                },
                {
                    value: 'date',
                    name: 'Date'
                },
                {
                    value: 'time',
                    name: 'Time'
                },
                {
                    value: 'first_name',
                    name: 'First Name'
                },
                {
                    value: 'last_name',
                    name: 'Last Name'
                },
                {
                    value: 'email',
                    name: 'Email'
                },
                {
                    value: 'hidden',
                    name: 'Hidden'
                },
                
            ],
            form: {
                name: null,
                fr_name: null,
                collection: 'form_collection',
                region: '-1'
            },
            form_questions: [
                {
                    type: 'section_break', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Section 1 - ',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                },
                {
                    type: 'short_text', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Label',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                }
            ],
            confirmSaveWaiver: false,
            content_width: false,
            forms: [],
            submitted_forms: [],
            selectedForm: null,
            editFormSwitch: null
		};
	},
	components: {
		Header,
		Footer,
    },
    computed: {
        computedWaiverSubmission: function() {
            
        }
    },
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_waivers !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }
			
                axios.get('/api/superuser/forms').then(results => {
                    
                    if(results.data.success === true) {
                        vm.forms = results.data.forms
                        vm.isLoading = false
                    }
                })
            }
        })
	},
	computed: {
	
	},
	methods: {
        removeOption: function(question, key) {
            
            question.options.splice(key, 1)
        },
        removeEditRow: function(index) {
            this.selectedForm.questions.splice(index, 1);
        },
        removeRow: function(index) {
            this.form_questions.splice(index, 1);
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addFormQuestion: function() {
			this.form_questions.push({
                type: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addSectionBreak: function() {
            this.form_questions.push({
                type: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddFormQuestion: function() {
            this.selectedForm.questions.push({
                type_id: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddSectionBreak: function() {
            
            this.selectedForm.questions.push({
                type_id: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addFormOption: function(question) {
            question.options.push({
                name: '',
                fr_name: ''
            })
        },
        editForm: function(form) {
            var vm = this
            vm.selectedForm = form
            vm.editFormSwitch = true
        },
        saveForm: function() {
            var vm = this
            axios.post('/api/superuser/save-new-form', {
                form: vm.form,
                form_questions: vm.form_questions
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.createNewFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        updateForm: function() {
            var vm = this
            axios.post('/api/superuser/update-form', {
                selected_form: vm.selectedForm,
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.editFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        viewFormResults: function(selectedForm) {
            var vm = this

            vm.selectedForm = selectedForm
        },
        moment: function(date, format = null) {
            return moment(date, format)
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
