<template>
    <span>

        <h2>Budget Calculator</h2>

		<h3>Calculate what your event might cost and figure out a price to charge before running!</h3>
		<div class="row">
			<div class="col-md-6">
				<b-field label="Number of Teams">
					<b-tooltip multilined type="is-dark" label="This is the number of teams you're hoping to host.">
						<b-input v-model="calculator.team_count"></b-input>
					</b-tooltip>
				</b-field>

				<b-field label="Number of Umpires Per Round Robin Game">
					<b-tooltip multilined type="is-dark" label="This is the number of umpires per round robin game (most go with 1, special events usually run with 2 per diamond).">
						<b-input v-model="calculator.number_of_umps_rr"></b-input>
					</b-tooltip>
				</b-field>

				<b-field label="Round Robin Games Per Team">
					<b-tooltip multilined type="is-dark" label="This is the number of round robin games each team will be playing (typically 2 or 3)">
						<b-input v-model="calculator.number_of_rr_games"></b-input>
					</b-tooltip>
				</b-field>

				<b-field label="Number of Umpires Per Playoff Game">
					<b-tooltip multilined type="is-dark" label="This is the number of round robin games each team will be playing (typically 2 or 3)">
						<b-input v-model="calculator.number_of_umps_playoffs"></b-input>
					</b-tooltip>
				</b-field>

				<b-field label="Playoff Style">
					<b-tooltip multilined type="is-dark" label="This is the format of playoffs (single or double knockout)">
						<b-select v-model="calculator.playoff_style">
							<option value="single">Single Knockout</option>
							<option value="double">Double Knockout</option>
						</b-select>

					</b-tooltip>
				</b-field>

				<b-field label="SPN Tournament Fee">
					<b-tooltip multilined type="is-dark" label="This is the cost of registering your event">
						<b-input v-model="calculator.spn_tournament_fee"></b-input>
					</b-tooltip>
				</b-field>

				<b-field label="Umpire Cost Per Game ($)">
					<b-tooltip multilined type="is-dark" label="This is the cost of paying an umpire per game">
						<b-input v-model="calculator.per_game_ump_fee"></b-input>
					</b-tooltip>
				</b-field>

				<b-switch v-model="hourlyOrFlat">Hourly or Flat Rate</b-switch>
				<div v-if="hourlyOrFlat === false">
					<b-field label="Hourly Price of Diamonds">
						<b-tooltip multilined type="is-dark" label="This is the price you're renting the diamond at per hour">
							<b-input v-model="calculator.price_of_diamonds_hourly"></b-input>
						</b-tooltip>
					</b-field>

					<b-field label="Minutes Per Game">
						<b-tooltip multilined type="is-dark" label="This is the number of minutes you're assigning to each game slot (ex: 90 minutes)">
							<b-input v-model="calculator.minutes_per_game"></b-input>
						</b-tooltip>
					</b-field>
				</div>
				<div v-if="hourlyOrFlat === true">
					<b-field label="Diamond Flat Rate">
						<b-tooltip multilined type="is-dark" label="This is the price you're renting the diamond at per hour">
							<b-input v-model="calculator.diamond_flat_rate"></b-input>
						</b-tooltip>
					</b-field>
				</div>
				<b-field label="Prizing Allotted (Cash & Gear)">
					<b-tooltip multilined type="is-dark" label="If you're offering cash prizes, enter them here">
						<b-input v-model="calculator.cash_prizing_offered"></b-input>
					</b-tooltip>
				</b-field>

				<b-field label="Entry Fee (Income)">
					<b-tooltip multilined type="is-dark" label="Enter a number here and see if your plans fall in the green!">
						<b-input v-model="calculator.event_fee"></b-input>
					</b-tooltip>
				</b-field>

			</div>
			<div class="col-md-6">
				<h4>Calculator Results</h4>
				<b-field label="Number of Games">
					{{ basicEventCalculator.number_of_games }}
				</b-field>
				<b-field label="Diamond Cost Estimate">
					${{ basicEventCalculator.diamond_costs }}
				</b-field>
				<b-field label="Umpire Cost Estimate">
					${{ basicEventCalculator.umpire_costs }}
				</b-field>
				<b-field label="Ball Cost Estimate">
					${{ basicEventCalculator.ball_costs }}
				</b-field>
				<b-field label="SPN Insurance (assumes all teams do not have)">
					${{ basicEventCalculator.spn_insurance }}
				</b-field>
				<b-field label="SPN Prizing Total Cost">
					${{ basicEventCalculator.spn_prizing }}
				</b-field>
				<b-field label="Cash Prizing">
					${{ basicEventCalculator.cash_prizing }}
				</b-field>
				<b-field label="SPN Host Liquor"  v-if="calculator.serving_liquor === 'yes'">
					${{ basicEventCalculator.liquor_insurance }}
				</b-field>
				<hr>
				<b-field label="Total Expenses Per Team/Overall">
					${{ basicEventCalculator.total_expenses/calculator.team_count }} / ${{ basicEventCalculator.total_expenses }}
				</b-field>
				<b-field label="Total Profits">
					${{ basicEventCalculator.profits }}
				</b-field>

				<b-field label="Estimated Net Profits">
					${{ basicEventCalculator.net_profit }}
				</b-field>
			</div>
		</div>
		


    </span>
</template>

<script>

import moment from 'moment'

export default {
	data() {
		return {
         	calculator: {
				team_count: 0,
				playoff_style: 'single',
				number_of_rr_games: 0,
				number_of_umps_rr: 0,
				number_of_umps_playoffs: 0,
				per_game_ump_fee: 0,
				price_of_diamonds_hourly: 0,
				minutes_per_game: 0,
				event_fee: 0,
				spn_tournament_fee: 0,
				diamond_flat_rate: 0,
				basic_prize_packs_needed: 0,
				deluxe_prize_packs_needed: 0,
				cash_prizing_offered: 0,
				serving_liquor: 'no',
			},
			hourlyOrFlat: false,
		};
	},
	components: {
		
	},
	mounted: function() {
        
	},
	computed: {
		basicEventCalculator: function() {
			var calculator = this.calculator
			var vm = this

			var calculated = {
				number_of_games: 0,
				diamond_costs: 0,
				umpire_costs: 0,
				ball_costs: 0,
				spn_insurance: vm.calculator.spn_tournament_fee,
				spn_prizing: 0,
				cash_prizing: 0,
				points_delegated: 0,
				cost_of_points: 0,
				acgo: 0,
				liquor_insurance: 0,
				total_expenses: 0,
				profits: 0,
			}

			

			// figure out number of games
			var round_robin_games = (calculator.team_count * calculator.number_of_rr_games)/2
			var number_of_playoff_games = 0
			

			if(calculator.playoff_style === 'single' || calculator.playoff_style === 'best-of-3' || calculator.playoff_style === 'best-of-5' || calculator.playoff_style === 'best-of-7') {

				number_of_playoff_games = calculator.team_count - 1

				if(calculator.playoff_style === 'best-of-3') {
					number_of_playoff_games = number_of_playoff_games * 3
				}

				if(calculator.playoff_style === 'best-of-5') {
					number_of_playoff_games = number_of_playoff_games * 5
				}

				if(calculator.playoff_style === 'best-of-7') {
					number_of_playoff_games = number_of_playoff_games * 7
				}
			}

			if(calculator.playoff_style === 'double') {
				number_of_playoff_games = (calculator.team_count * 2) - 1
			}
			

			calculated.number_of_games = round_robin_games + number_of_playoff_games

			
			// number of games * length of game
			var length_of_booking_hours = (calculated.number_of_games * calculator.minutes_per_game)/60
			// roudn up to next hour

			if(vm.hourlyOrFlat === false) {
				calculated.diamond_costs = length_of_booking_hours * calculator.price_of_diamonds_hourly
			} else {
				calculated.diamond_costs = calculator.diamond_flat_rate	
			}
			
			// umpire_costs
			var round_robin_price = calculator.number_of_umps_rr * round_robin_games * calculator.per_game_ump_fee

			var playoffs_price = calculator.number_of_umps_playoffs * number_of_playoff_games * calculator.per_game_ump_fee
			
			calculated.umpire_costs = round_robin_price + playoffs_price

			// ball costs = 5 a ball
			calculated.ball_costs = calculated.number_of_games * 5

			calculated.profits = calculator.team_count * calculator.event_fee

			// liquor
			if(calculator.serving_liquor === 'yes') {
				calculated.acgo = 150
				calculated.liquor_insurance = 175
			}

			if(calculator.serving_liquor === 'no') {
				calculated.acgo = 0
				calculated.liquor_insurance = 0
			}

			calculated.cash_prizing = parseFloat(calculator.cash_prizing_offered)

			calculated.spn_prizing = parseFloat(parseFloat(calculator.basic_prize_packs_needed) * 170) + parseFloat(parseFloat(calculator.deluxe_prize_packs_needed) * 240)
				
			calculated.total_expenses = parseFloat(calculated.diamond_costs) + parseFloat(calculated.umpire_costs) + parseFloat(calculated.ball_costs) + parseFloat(calculated.spn_insurance) + parseFloat(calculated.acgo) + parseFloat(calculated.liquor_insurance) + parseFloat(calculated.spn_prizing) + parseFloat(calculated.cost_of_points)

			calculated.net_profit = parseFloat(calculated.profits) - parseFloat(calculated.total_expenses)

			return calculated

		}
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		},
	}
}
</script>

<style lang="scss">
    
</style>
