<template>
	<div>
		<Header></Header>
		
		<div class="content-area" v-if="page">
			<div class="standard-page-header">
				{{ page.name }}
			</div>
            <div class="frontend-content-wrapper">
				<b-tabs>
					<b-tab-item label="Current Contest">
						<div class="section-spacing" v-for="(info, key) in page.content" :key="key">
							<div class="textarea-content-block" v-if="info.type === 'textarea'">
								<div class="row">
									<div :class="'col-md-' + column.width" :key="column.id" v-for="column in info.columns" >
										<div class="padding" v-html="(en === true ? column.content : column.fr_content)">

										</div>
									</div>
								</div>
							</div>
							<div class="widget-box" v-if="info.type === 'widget'">
								<div v-if="info.widget_type === 'social'">
									<div class="social-box">
										<div class="social-header">Follow & Stay Updated!</div>
										<div class="social-content">
											<ul>
												<li>
													<a role="button" href="https://www.facebook.com/SloPitchNational" aria-label="Facebook" target="_blank" class="lang"><i class="fab fa-facebook" role="presentation"></i></a>
												</li>
												<li>
													<a role="button" href="https://twitter.com/spnslopitch" aria-label="Twitter" target="_blank" class="lang"><i class="fab fa-twitter" role="presentation"></i></a>
												</li>
												<li>
													<a role="button" href="https://www.instagram.com/spnslopitch/" aria-label="Instagram" target="_blank" class="lang"><i class="fab fa-instagram" role="presentation"></i></a>
												</li>
												<li>
													<a role="button" href="https://www.pinterest.com/spnslopitch/" aria-label="Pinterest" target="_blank" class="lang"><i class="fab fa-pinterest" role="presentation"></i></a>
												</li>
												<li>
													<a role="button" href="https://www.youtube.com/channel/UCAH0gIUTWZSObWcY8EZ0xBA" target="_blank" aria-label="Youtube" class="lang"><i class="fab fa-youtube" role="presentation"></i></a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div v-if="info.widget_type === 'form'">
									<Form :form-id="info.form_id"></Form>
								</div>
								<div v-if="info.widget_type === 'table'">
									<b-table
										paginated
										per-page="20"
										:columns="createProperTableFunction(info.table).columns"
										:data="createProperTableFunction(info.table).data">							
									</b-table>
								</div>
								<div v-if="info.widget_type === 'custom_html'" v-html="info.custom_html">
								
								</div>
								<div v-if="info.widget_type === 'panels'" >
									
									<div class="panel">
										
										<div class="panel-heading" v-on:click="openPanel(info.panels[0])">{{ info.panels[0].header }} <i class="fas fa-caret-down" style="float: right;"></i></div>
										<div class="panel-content" v-if="info.panels[0].switched === 'true'" v-html="info.panels[0].content"></div>
									</div>
								</div>
							</div>
						</div>
					</b-tab-item>
					<b-tab-item label="Previous Contest Winners">
						<div class="section-spacing" v-for="(info, key) in winners.content" :key="key">
							<div class="textarea-content-block" v-if="info.type === 'textarea'">
								<div class="row">
									<div :class="'col-md-' + column.width" :key="column.id" v-for="column in info.columns" >
										<div class="padding" v-html="(en === true ? column.content : column.fr_content)">

										</div>
									</div>
								</div>
							</div>
							<div class="widget-box" v-if="info.type === 'widget'">
								<div v-if="info.widget_type === 'social'">
									<div class="social-box">
										<div class="social-header">Follow & Stay Updated!</div>
										<div class="social-content">
											<ul>
												<li>
													<a href="https://www.facebook.com/SloPitchNational" aria-label="Facebook" role="button" target="_blank" class="lang"><i class="fab fa-facebook" icon="presentation"></i></a>
												</li>
												<li>
													<a href="https://twitter.com/spnslopitch" role="button" aria-label="Twitter" target="_blank" class="lang"><i class="fab fa-twitter" icon="presentation"></i></a>
												</li>
												<li>
													<a href="https://www.instagram.com/spnslopitch/" role="button" aria-label="Instagram" target="_blank" class="lang"><i class="fab fa-instagram" icon="presentation"></i></a>
												</li>
												<li>
													<a href="https://www.pinterest.com/spnslopitch/" target="_blank" class="lang" role="button" aria-label="Pinterest"><i class="fab fa-pinterest" icon="presentation"></i></a>
												</li>
												<li>
													<a href="https://www.youtube.com/channel/UCAH0gIUTWZSObWcY8EZ0xBA" target="_blank" class="lang" role="button" aria-label="Youtube"><i class="fab fa-youtube" icon="presentation"></i></a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div v-if="info.widget_type === 'form'">
									<Form :form-id="info.form_id"></Form>
								</div>
								<div v-if="info.widget_type === 'table'">
									<b-table
										paginated
										per-page="20"
										:columns="createProperTableFunction(info.table).columns"
										:data="createProperTableFunction(info.table).data">							
									</b-table>
								</div>
								<div v-if="info.widget_type === 'custom_html'" v-html="info.custom_html">
								
								</div>
								<div v-if="info.widget_type === 'panels'" >
									
									<div class="panel">
										
										<div class="panel-heading" v-on:click="openPanel(info.panels[0])">{{ info.panels[0].header }} <i class="fas fa-caret-down" style="float: right;"></i></div>
										<div class="panel-content" v-if="info.panels[0].switched === 'true'" v-html="info.panels[0].content"></div>
									</div>
								</div>
							</div>
						</div>
					</b-tab-item>
				</b-tabs>
				
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'

export default {
	metaInfo() {
		if(!this.page) {
            return {}
        }
        return { 
            title: this.page.name + " - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Where memories and champions are made.' },
				{ property: 'og:title', content: this.page.name + " - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/contests' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
			page: null,
			winners: null
		};
	},
	components: {
		Header,
		Footer,
		Form,
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this
        vm.page_url = this.$route.params.page

        // axios
        axios.get('/api/pages/' + 'contests').then(results => {
			
            if(results.data) {
				
				vm.page = results.data
				vm.page.content = JSON.parse(vm.page.content)
            }
		})
		
		axios.get('/api/pages/' + 'winners').then(results => {
			
            if(results.data) {
				
				vm.winners = results.data
				vm.winners.content = JSON.parse(vm.winners.content)
            }
        })
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
