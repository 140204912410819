<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Create a League
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a href="/dashboard/convenors/my-leagues" class="button">Back to My Leagues</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>				
				<div class="admin-area">
					<ValidationObserver v-slot="{ handleSubmit }" ref="form">
						<form @submit.prevent="handleSubmit(submitYourTournament)">
							<b-steps
								:animated="true"
								type="is-danger"
								:rounded="true"
								v-model="registrationStep"
								:has-navigation="true">
						
								<b-step-item step="1" label="Basics" :clickable="false">
									<h2 class="title">League Basics <span v-if="duplicating">- Duplicating Previous League</span></h2>
									<div class="row">
										<div class="col-md-6">
											<!-- <b-field label="Main Diamond Complex" >
												<ValidationProvider v-slot="{ errors }" rules="min:4" name="headquarter_location"> 
													<b-input icon-pack="fas"
														icon="street-view"
														expanded
														v-model="newTournament.headquarter_location"></b-input>
													<span class="help is-danger">{{ errors[0] }}</span>
													
												</ValidationProvider>
											</b-field> -->
											<b-field label="City" >
												<ValidationProvider v-slot="{ errors }" rules="required|min:4" name="city"> 
													<b-input icon-pack="fas"
														icon="street-view"
														expanded
														v-model="newTournament.city"></b-input>
													<span class="help is-danger">{{ errors[0] }}</span>
													
												</ValidationProvider>
											</b-field>
										</div>
										<div class="col-md-6">
											<b-field label="Region">
												<b-tooltip type="is-dark" label="This is the region your league is hosted in.">
													<b-dropdown  hoverable aria-role="list" v-model="newTournamentRegion">
														<div class="button is-info" slot="trigger">
															<span v-if="newTournamentRegion">
																{{ newTournamentRegion.name }}
															</span>
															<span v-if="!newTournamentRegion">
																Select your HQ below
															</span>
															<b-icon icon="menu-down"></b-icon>
														</div>

														<b-dropdown-item :key="key" :value="region" v-for="(region, key) in regions" aria-role="listitem">{{ region.name }}</b-dropdown-item>
													</b-dropdown>	
												</b-tooltip>
											</b-field>
										
										</div>
									</div>
									<div class="row row-margin">
										<div class="col-md-3">
											<b-field label="League Name" expanded>
												
												<ValidationProvider v-slot="{ errors }" rules="required|min:4" name="tournament_name"> 
													<b-input v-model="newTournament.name"></b-input>
													<span class="help is-danger">{{ errors[0] }}</span>
												</ValidationProvider>
											
											</b-field>
										</div>
										<div class="col-md-3">
											<b-field label="League Name (FR)" expanded>
												<b-input v-model="newTournament.fr_name"></b-input>
											</b-field>
										</div>
										<div class="col-md-3">
											<b-field label="League Type">
												<b-select v-model="newTournament.qualifier">
													<option value="1">Fully Insured League</option>
													<option value="2" v-if="newTournamentRegion.id === 4">Uninsured League</option>
												</b-select>
											</b-field>
										</div>
									
									</div>

									<div class="row row-margin">
										<div class="col-md-4">
											<b-field label="League Start Date">
											
												<ValidationProvider rules="required|dateDiff:@tournament_end_date" name="tournament_date" v-slot="{ errors }">
													<b-datetimepicker
													 	
														vertical
														placeholder="Click to select..."
														icon="calendar-today"
														v-model="newTournament.event_start_date"
														:min-datetime="minDate"
														>
													</b-datetimepicker>
													<span class="help is-danger">{{ errors[0] }}</span>
												</ValidationProvider>
											
											</b-field>
										</div>
										<div class="col-md-4">
											<b-field label="League End Date">
												
												<ValidationProvider name="tournament_end_date" rules="required" v-slot="{ errors }">
													<b-datetimepicker
														placeholder="Click to select..."
														icon="calendar-today"
														v-model="newTournament.event_end_date"
														:min-datetime="minDate"
														>
													</b-datetimepicker>
													<span class="help is-danger">{{ errors[0] }}</span>
												</ValidationProvider>
											
											</b-field>
										</div>
									</div>

									<div class="row row-margin">
										<div class="col-md-4">
											<b-field label="Registration Start Date">
												
												<ValidationProvider rules="required|dateDiff:@reg_end_date" name="reg_date" v-slot="{ errors }">
													<b-datetimepicker
														placeholder="Click to select..."
														icon="calendar-today"
														v-model="newTournament.registration_start_date"
														:min-datetime="minDate"
														>
													</b-datetimepicker>
													<span class="help is-danger">{{ errors[0] }}</span>
												</ValidationProvider>
												
											</b-field>
							
										</div>
										<div class="col-md-4">
											<b-field label="Registration End Date">
												<ValidationProvider name="reg_end_date" rules="required" v-slot="{ errors }">
													<b-datetimepicker
														placeholder="Click to select..."
														icon="calendar-today"
														v-model="newTournament.registration_end_date"
														:min-datetime="minDate"
														>
													</b-datetimepicker>
													<span class="help is-danger">{{ errors[0] }}</span>
												</ValidationProvider>
											</b-field>
										</div>
									</div>

									<div class="row row-margin">
										<div class="col-md-4">
											<b-field label="Roster Lock Date">
												<b-datetimepicker
													placeholder="Click to select..."
													icon="calendar-today"
													v-model="newTournament.roster_lock_date"
													:min-datetime="minDate"
													>
												</b-datetimepicker>
											</b-field>
										</div>
									</div>

									<div class="row row-margin">
										<div class="col-md-6">
											<b-field label="Team Price">
												
													<ValidationProvider name="team_price" rules="required" v-slot="{ errors }">
														<b-input 
															v-model="newTournament.team_price"
															icon-pack="fas"
															icon="dollar-sign"
														></b-input>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												
											</b-field>
										</div>
									</div>
									<div class="row row-margin">
										<div class="col-md-6">
											<b-field extended label="League Description" style="margin-right: 10px;"> 
												<b-input style="width: 100%;" v-model="newTournament.description" maxlength="2000" type="textarea"></b-input>
											</b-field>
										</div>
										<div class="col-md-6">
											
										</div>
									</div>
					
									<div class="row">
										<div class="col-md-4">
											<b-field label="Max Roster Size per team (default is 16)">
												<div class="block">
													<b-numberinput v-model="newTournament.max_roster_size"></b-numberinput>
												</div>
											</b-field>
										</div>
									</div>
									
										<b-field label="What methods of payment will you accept for this event?">
											<div class="block">
												<ValidationProvider name="payment_methods" rules="required" v-slot="{ errors }">
													
													<b-checkbox name="emt" v-model="newTournament.reg_payment" 
													native-value="emt">
														Via EMT
													</b-checkbox>
													<b-checkbox name="cash" v-model="newTournament.reg_payment" 
													native-value="cash">
														Cash Due By League Start
													</b-checkbox>
													<span class="help is-danger">{{ errors[0] }}</span>
												</ValidationProvider>
											</div>
										</b-field>
									<div class="via-emt" v-if="newTournament.registrants_pay_via_emt">
										<p style="font-size: 20px;"><b>Payment via EMT</b></p>
										<b-field label="Your EMT Email" style="margin-top: 10px;">
											<b-tooltip type="is-dark" multilined label="What email do you want EMTs to go to?">
												<b-input 
													v-model="newTournament.emt_email"
													icon-pack="fas"
													icon="at">
												</b-input>
											</b-tooltip>
										</b-field>
										<b-field label="Your Preferred EMT Password">
											<b-tooltip type="is-dark" multilined label="What password do you want EMTs to be set as?">
												<b-input 
													v-model="newTournament.emt_password"
													icon-pack="fas"
													icon="lock">
												</b-input>
											</b-tooltip>
										</b-field>
									</div>
									
									<b-field label="Do you require a deposit for team registration?">
									
										<div class="block">
											<b-radio v-model="newTournament.deposit_required"
												name="deposit_required"
												:native-value="true">
												Yes
											</b-radio>
											<b-radio v-model="newTournament.deposit_required"
												name="deposit_required"
												:native-value="false">
												No
											</b-radio>
										</div>
									</b-field>
									<div class="deposit-amount" v-if="newTournament.deposit_required">
										<b-field label="Deposit Amount">
											<b-tooltip type="is-dark" label="This is the amount you want to set the deposit at.">
												<b-input v-model="newTournament.deposit_amount"
														icon-pack="fas"
														icon="dollar-sign"></b-input>
											</b-tooltip>
										</b-field>
									</div>
									
									<div class="row row-margin">
										<div class="col-md-12">
											
										</div>
									</div>
														
								</b-step-item>
							
								<b-step-item step="2" label="Divisions" :clickable="false">
									<h1 class="title has-text-centered">Select Your Divisions</h1>
									<div class="selected-divisions">
										<div class="row">
											<div class="col-md-12">
												<h2 class="">Mens</h2>

									

												<b-dropdown hoverable aria-role="list" v-model="newTournament.mens_divisions" multiple>
													<div class="button is-info" slot="trigger">
														<span>
															Select Men's Division
														</span>
														<b-icon icon="menu-down"></b-icon>
													</div>

													<b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
												</b-dropdown>
												<nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.mens_divisions">
													<div class="panel-heading">
														<div class="row">
															<div class="col-md-7">
																<b>{{ division.name }} Division</b>
															</div>
															<div class="col-md-5">
																
															</div>
														</div>
													</div>
													

													<div class="panel-block" style="display: block;">
														<div class="row">
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum # of Teams" custom-class="is-small">
																		<b-numberinput type="is-info" min="3" v-model="division.min_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Max # of Teams" custom-class="is-small">
																		<b-numberinput type="is-info" min="3" v-model="division.max_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum Age" custom-class="is-small">
																		<b-numberinput min="16" type="is-info" max="99" v-model="division.min_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Maximum Age" custom-class="is-small">
																		<b-numberinput min="16"  type="is-info" max="99" v-model="division.max_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
														</div>
													</div>
												</nav>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<h2 class="">Ladies</h2>
												
												<b-dropdown hoverable aria-role="list" v-model="newTournament.ladies_divisions" multiple>
													<div class="button is-primary" slot="trigger">
														<span>
															Select Ladies Divisions
														</span>
														<b-icon icon="menu-down"></b-icon>
													</div>

													<b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
												</b-dropdown>
												<nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.ladies_divisions">
													<div class="panel-heading">
														<div class="row">
															<div class="col-md-7">
																<b>{{ division.name }} Division</b>
															</div>
															<div class="col-md-5">
																
															</div>
														</div>
													</div>
													

													<div class="panel-block" style="display: block;">
														<div class="row">
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum # of Teams" custom-class="is-small">
																		<b-numberinput v-model="division.min_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Max # of Teams" custom-class="is-small">
																		<b-numberinput v-model="division.max_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum Age" custom-class="is-small">
																		<b-numberinput min="16" v-model="division.min_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Maximum Age" custom-class="is-small">
																		<b-numberinput min="16" v-model="division.max_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
														</div>
													</div>
												</nav>
											</div>
											<div class="row">
												<div class="col-md-12">
													<h2 class="">Coed</h2>
													<b-dropdown hoverable aria-role="list" v-model="newTournament.coed_divisions" multiple>
														<div class="button is-danger" slot="trigger">
															<span>
																Select Coed Divisions
															</span>
															<b-icon icon="menu-down"></b-icon>
														</div>

														<b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
													</b-dropdown>
													<nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.coed_divisions">
														<div class="panel-heading">
															<div class="row">
																<div class="col-md-7">
																	<b>{{ division.name }} Division</b>
																</div>
																<div class="col-md-5">
																	
																</div>
															</div>
														</div>
														

														<div class="panel-block" style="display: block;">
															<div class="row">
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Minimum # of Teams" min="3" custom-class="is-small">
																			<b-numberinput type="is-danger" v-model="division.min_teams"></b-numberinput>
																		</b-field>
																	</div>
																</div>
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Max # of Teams" custom-class="is-small">
																			<b-numberinput type="is-danger" min="3" v-model="division.max_teams"></b-numberinput>
																		</b-field>
																	</div>
																</div>
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Minimum Age" custom-class="is-small">
																			<b-numberinput type="is-danger" min="16" max="99" v-model="division.min_age"></b-numberinput>
																		</b-field>
																	</div>
																</div>
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Maximum Age" custom-class="is-small">
																			<b-numberinput type="is-danger" min="16" max="99" v-model="division.max_age"></b-numberinput>
																		</b-field>
																	</div>
																</div>
															</div>
														</div>
													</nav>
												</div>
											</div>
										</div>
									</div>
								</b-step-item>
							
								<!-- <b-step-item step="6" :visible="false" label="Marketing" :clickable="false">
									<div class="row">
										<div class="col-md-6">
											<h2>Marketing</h2> -->
											<!-- <b-field label="Your Website URL">
												<b-tooltip multilined type="is-dark" label="This is the URL of your website! We will help you market it in the SPN Calendar Event.">
													<b-input v-model="newTournament.website_url"></b-input>
												</b-tooltip>
											</b-field>
											<b-field label="Your Facebook Event URL">
												<b-tooltip multilined type="is-dark" label="This is the URL of your Facebook Event! It's incredibly vital for you to use social media to promote your event. When you're finished creating your tournament, we will show you how!">
													<b-input v-model="newTournament.facebook_event_url"></b-input>
												</b-tooltip>
											</b-field>

											<b-field label="Your Facebook Group/Page URL">
												<b-tooltip multilined type="is-dark" label="Do you have a Facebook group? It's great to grow your audience! Paste it here and people will be able to join when they register for the event.">
													<b-input v-model="newTournament.facebook_group_url"></b-input>
												</b-tooltip>
											</b-field> -->
											<!-- <h3>League Features</h3>
											<div class="row" style="margin-left: -10px;">
												<div class="col-md-6">
													<div class="padding">
														<b-field label="Games Guaranteed">
															<b-numberinput v-model="newTournament.games_guaranteed"></b-numberinput>
														</b-field>
													</div>
												</div>
												<div class="col-md-6">
													<div class="padding">
														<b-field label="How are games scheduled?">
															<b-select v-model="newTournament.games_scheduled_format">
																<option 
																	:value="'single'"
																	>
																		Single Scheduled Game
																</option>
																<option :value="'double'">Double Scheduled Game</option>
															</b-select>
														</b-field>
													</div>
												</div>
											</div>
											<div class="row" style="margin-left: -10px;">
												<div class="col-md-4">
													<div class="padding">
														<b-field>
															<b-checkbox v-model="newTournament.preseason_meeting">Preseason Meeting</b-checkbox>
														</b-field>
													</div>
												</div>
												<div class="col-md-4">
													<div class="padding">
														<b-field>
															<b-checkbox
																v-model="newTournament.in_league_tournaments">
																	In-League Tournaments
															</b-checkbox>
														</b-field>
													</div>
												</div>
												<div class="col-md-4">
													<div class="padding">
														<b-field>
															<b-checkbox
																v-model="newTournament.carded_umpires">
																	Carded Umpires
															</b-checkbox>
														</b-field>
													</div>
												</div>
											</div>
											<div class="row" style="margin-left: -10px;">
												<div class="col-md-4">
													<div class="padding">
														<b-field>
															<b-checkbox
																v-model="newTournament.year_end_banquet">
																	Year-End Banquet
															</b-checkbox>
														</b-field>
													</div>
												</div>
												<div class="col-md-4">
													<div class="padding">
														<b-field>
															<b-checkbox v-model="newTournament.year_end_awards"
															>Year-End Awards</b-checkbox>
														</b-field>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6" style="text-align: center;">
											<h2>Your SPN Event Preview</h2>	
											<div class="custom-event-area" style="text-align: center;">
												<b-field>
													<b-upload
														multiple
														expanded
														drag-drop>
														<section class="section">
															<div class="content has-text-centered">
																<p>
																	<b-icon
																		icon="upload"
																		size="is-large">
																	</b-icon>
																</p>
																<p>Upload a poster here if you have one! If you don't, you can use the poster generator after you finish creating your event.</p>
															</div>
														</section>
													</b-upload>
												</b-field>
												<div class="custom-event-box">
													<div class="custom-tournament-name">
														{{ newTournament.name }}
													</div>
													<div class="custom-tournament-description">
														{{ newTournament.description }}
													</div>
													<div class="custom-tournament-divisions">
														<div class="custom-tournament-divisions-header">
															Divisions Offered:
														</div>
														<div class="row">
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Men's
																</div>
																<b-tag
																	type="is-danger"  :key="key" v-for="(division, key) in newTournament.mens_divisions">
																
																		{{ division.name }}
																
																</b-tag>
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Ladies
																</div>
																<b-tag
                                                    				type="is-danger" :key="key" v-for="(division, key) in newTournament.ladies_divisions">
																	
																	{{ division.name }}
																	
																</b-tag>
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Coed
																</div>
																<b-tag
																type="is-danger"  :key="key" v-for="(division, key) in newTournament.coed_divisions">
																
																		{{ division.name }}
																	
																</b-tag>
															</div>
														</div>
													</div>
													<div class="custom-tournament-entry-fees">
														<div class="entry-fee-text">
															Entry Fee:
														</div>
														<div class="entry-fee-team">
															<i class="fas fa-users"></i> ${{ newTournament.team_price }}
														</div>
														<div class="entry-fee-team" 		v-if="newTournament.individual_price">
															<i class="fas fa-user"></i> ${{ newTournament.individual_price }}
														</div>
													</div>
												</div>
												
											</div>
										</div>
									</div>
									
									
								</b-step-item> -->
								
								<b-step-item step="3" :visible="true" label="Finish &amp; Confirm" :clickable="false">
									<h1 class="title has-text-centered">Finish &amp; Confirm</h1>
									<div class="row">
										<div class="col-md-7">
											
											<div class="payment-box" v-if="newTournamentRegion">
												<span v-if="newTournamentRegion.id == 6">
													<NewPaymentProcessor
														v-if="authUser !== null"
														:productId="computedProduct"
														:regionId="newTournamentRegion.id"
														v-on:payment:successful="paymentSuccessSwitch($event)"
														:teamName="newTournament.name"
														>
													</NewPaymentProcessor>
													
													<b-message type="is-danger" has-icon aria-close-label="Close message">
														<b>After submitting your payment, please press the "FINAL STEP: Submit Your League For Approval" button!</b>
													</b-message>
												</span>

												<a class="admin-button" role="button" v-if="paymentSuccess" v-on:click="completeTournamentSubmission()"><i class="fas fa-save"></i>FINAL STEP: Submit Your League For Approval</a>
											</div>
											
										</div>
										<div class="col-md-5">
											<div class="custom-event-area" style="text-align: center;">
												<div class="custom-event-box">
													<div class="custom-tournament-name">
														{{ newTournament.name }}
													</div>
													<div class="custom-tournament-description">
														{{ newTournament.description }}
													</div>
													<div class="custom-tournament-divisions">
														<div class="custom-tournament-divisions-header">
															Divisions Offered:
														</div>
														<div class="row">
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Men's
																</div>
																<b-tag
																type="is-danger" :key="key" v-for="(division, key) in newTournament.mens_divisions">
																
																		{{ division.name }}
																
																</b-tag>
																
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Ladies
																</div>
																<b-tag
																type="is-danger"  :key="key" v-for="(division, key) in newTournament.ladies_divisions">
																	{{ division.name }}
																</b-tag>
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Coed
																</div>
																<b-tooltip type="is-dark" label="3 spots available in this division!"  :key="key" v-for="(division, key) in newTournament.coed_divisions">
																	<div class="custom-tournament-division">
																		{{ division.name }}
																	</div>
																</b-tooltip>
															</div>
														</div>
													</div>
													<div class="custom-tournament-entry-fees">
														<div class="entry-fee-text">
															Entry Fee:
														</div>
														<div class="entry-fee-team">
															<i class="fas fa-users"></i> ${{ newTournament.team_price }}
														</div>
														<div class="entry-fee-team" v-if="newTournament.individual_price">
															<i class="fas fa-user"></i> ${{ newTournament.individual_price }}
														</div>
													</div>
												</div>
									
											</div>
										</div>
									</div>
								</b-step-item>	

								<template
									slot="navigation"
									slot-scope="{previous, next}">
									<b-button
										outlined
										v-if="registrationStep !== 0"
										type="is-danger"
										icon-pack="fas"
										icon-left="backward"
										:disabled="previous.disabled"
										@click.prevent="previous.action">
										Previous Step
									</b-button>
									<b-button
										outlined
										v-if="registrationStep !== 3"
										type="is-success"
										icon-pack="fas"
										icon-right="forward"
										:disabled="next.disabled"
										native-type="submit"
										>
										Next Step
									</b-button>
								</template>
							</b-steps>
						</form>
					</ValidationObserver>
				</div>
				<div class="page-errors" v-if="validation_errors.length > 0">
					<div class="page-error-header">Please fix these errors before going to the next step!</div>
					<div class="page-error" v-for="(validation_error, key) in validation_errors" :key="key">
						{{ validation_error.reason }}
					</div>
				</div>	

			</div>
		</div>
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import validation from './../../../validation'

import PaymentProcessor from '../../components/PaymentProcessor.vue'
import NewPaymentProcessor from '../../components/NewPaymentProcessor.vue'

import axios from 'axios'
import moment from 'moment'

export default {
	
	data() {
		const today = new Date()
		return {
			
			page: null,
			content_width: false,
			autocompleteAddress: null,
			newTournamentRegion: null,
			duplicating: false,
			authUser: null,
			newTournament: {
				max_roster_size: 16,
				invitation_only: false,
				name: null,
				fr_name: null,
				qualifier: 1,
				event_start_date: null,
				event_end_date: null,
				deposit_required: false,
				deposit_amount: null,
				registration_start_date: null,
				registration_end_date: null,
				roster_lock_date: null,
				description: '',
				headquarter_location: null,
				headquarter_google_address: null,
				team_price: null,
				individual_price: null,
				selling_alcohol: false,
				mens_divisions: [],
				ladies_divisions: [],
				coed_divisions: [],
				website_url: null,
				facebook_event_url: null,
				facebook_group_url: null,
				method_of_payment: 'card',
				registrants_pay_via_spn: false,
				registrants_pay_via_emt: false,
				registrants_pay_via_cash: false,
				emt_email: null,
				emt_password: null,
				city: null,
				games_guaranteed: 0,
				games_scheduled_format: 'single',
				preseason_meeting: false,
				in_league_tournaments: false,
				carded_umpires: false,
				year_end_banquet: false,
				year_end_awards: false,
				reg_payment: []
			},
			validation_errors: [],
			regions: [],
			region_rate: null,
			divisions: [],
			paymentSuccess: false,
			registrationStep: 0,
			minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
			en: true
		};
	},
	components: {
		Header,
		Footer,
		ValidationProvider,
		ValidationObserver,
		PaymentProcessor,
		NewPaymentProcessor
	},
	watch: {
		newTournamentRegion: function() {
			this.getRegionRate()
		}
	},
	mounted: function() {
		var vm = this
		this.page = this.$route.params.page

		

		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
				vm.authUser = results.data.user

				axios.get('/api/tournaments/regions').then(results => {
					vm.regions = results.data.regions
					

					var region_data = _.find(vm.regions, function(region) {
						if(region.id === vm.authUser.default_region_id) {
							return true
						}
					})

					vm.newTournamentRegion = region_data
				})

				
				
			}
		})

		
			
		axios.get('/api/tournaments/regions').then(results => {
			vm.regions = results.data.regions
		})

		axios.get('/api/tournaments/divisions').then(results => {
			vm.divisions = results.data.divisions
		})

		axios.get('/api/leagues/default-league-settings').then(results => {
			var tourney_settings = results.data.league_settings
			
			if(tourney_settings) {
				vm.newTournament.team_price = tourney_settings.team_registration_fee
				vm.newTournament.individual_price = tourney_settings.player_registration_fee
				vm.newTournament.emt_password = tourney_settings.emt_password
				vm.newTournament.emt_email = tourney_settings.emt_email
				vm.newTournament.website_url = tourney_settings.website_url
				vm.newTournament.facebook_group_url = tourney_settings.facebook_group_url
				vm.newTournament.deposit_amount = tourney_settings.deposit_amount
				vm.newTournament.deposit_required = tourney_settings.deposit_required === 1 ? true : false
				vm.newTournament.registrants_pay_via_cash = tourney_settings.registrants_pay_via_cash === 1 ? true : false
				vm.newTournament.registrants_pay_via_emt = tourney_settings.registrants_pay_via_emt === 1 ? true : false
				vm.newTournament.registrants_pay_via_spn = tourney_settings.registrants_pay_via_spn === 1 ? true : false
				vm.newTournament.mens_divisions = tourney_settings.mens_divisions
				vm.newTournament.ladies_divisions = tourney_settings.ladies_divisions
				vm.newTournament.coed_divisions = tourney_settings.coed_divisions
			}
		})
		


		if(this.$route.query.previous_league) {
			var previous_league = this.$route.query.previous_league
			
			
			axios.get(`/api/leagues/get-league/${previous_league}`).then(results => {
				
				if(results.data.success === true) {		
					
					var league = results.data.league
					vm.duplicating = league.id
					vm.newTournament.name = league.name
					vm.newTournament.description = league.description
					vm.newTournament.max_roster_size = league.max_roster_size
					vm.newTournament.requested_method_of_payment = league.requested_method_of_payment
					vm.newTournament.deposit_required = league.deposit_required === 1 ? true : false
					vm.newTournament.city = league.city
					vm.newTournament.deposit_amount = league.deposit_amount
					var divisions = results.data.league.divisions
					if(divisions) {
						if(divisions.length > 0) {
							divisions.map(division => {
								division.name = division.rank_id
								division.min_teams = division.min_number_of_teams
								division.max_teams = division.max_number_of_teams
								return
							})

							var coed_divisions = divisions.filter(division => {
								return division.gender_slug === 'coed'
							})
							vm.newTournament.coed_divisions = coed_divisions

							var mens_divisions = divisions.filter(division => {
								return division.gender_slug === 'mens'
							})
							vm.newTournament.mens_divisions = mens_divisions

							var ladies_divisions = divisions.filter(division => {
								return division.gender_slug === 'ladies'
							})
							vm.newTournament.ladies_divisions = ladies_divisions

							// console.log('filter', filter)
						}
					}
					

					// vm.newTournament = results.data.tournament
					// var tournament = results.data.tournament
					// vm.newTournament.name = tournament.name
					// // vm.newTournament.event_start_date = moment(tournament.event_start_date)
					// vm.newTournament.team_price = tournament.team_price
					// vm.newTournament.individual_price = tournament.individual_price
					// vm.newTournament.event_start_date = new Date(tournament.event_start_date)
					// vm.newTournament.event_end_date = new Date(tournament.event_end_date)
					// vm.newTournament.registration_start_date = new Date(tournament.registration_start_date)
					// vm.newTournament.registration_end_date = new Date(tournament.registration_end_date)
					// vm.newTournament.roster_lock_date = new Date(tournament.roster_lock_date)
					// vm.newTournament.headquarter_location = tournament.written_address
					// var tournament_divisions = tournament.divisions
					
					// var i = 0

					// var mens_divisions = []
					// var ladies_divisions = []
					// var coed_divisions = []

					// for(i; i < tournament_divisions.length; i++) {
					// 	var tournament_division = tournament_divisions[i]
					// 	if(tournament_division.gender_slug === 'mens') {
					// 		tournament_division.original_division.qualifier = tournament_division.qualifier
					// 		mens_divisions.push(tournament_division.original_division)
					// 	}
					// 	if(tournament_division.gender_slug === 'ladies') {
					// 		tournament_division.original_division.qualifier = tournament_division.qualifier
					// 		ladies_divisions.push(tournament_division.original_division)
					// 	}
					// 	if(tournament_division.gender_slug === 'coed') {
					// 		tournament_division.original_division.qualifier = tournament_division.qualifier
					// 		coed_divisions.push(tournament_division.original_division)
					// 	}
					// }

					



					// vm.newTournament.mens_divisions = mens_divisions
					// vm.newTournament.ladies_divisions = ladies_divisions
					// vm.newTournament.coed_divisions = coed_divisions
					// vm.newTournamentRegion = tournament.region
					
					// axios.post('/api/tournaments/region-rate', {
					// 	region: tournament.region_id
					// }).then(results => {
					// 	if(results.data.success === true) {
					// 		vm.region_rate = results.data.region_rate
					// 	}
					// })

					
					// finish this a little later
				}
				// more needs to be loaded... propbaby region_rate
			})
		}

	},
	computed: {
		computedProduct: function() {
			var vm = this
			
			return 1
        }
	},
	methods: {
		getRegionRate: function() {
			var vm = this
			axios.post('/api/tournaments/region-rate', {
				region: vm.newTournamentRegion.id
			}).then(results => {
				if(results.data.success === true) {
					vm.region_rate = results.data.region_rate
				}
			})
		},		
		switchWidth: function($event) {
			this.content_width = !$event
		},
		completeTournamentSubmission: function() {
			var vm = this
			var failed = false

			var order_id = null
			
			if(vm.paymentSuccess) {
				if(vm.paymentSuccess.order) {
					order_id = vm.paymentSuccess.order.id
				}
				
			}

			axios.post('/api/leagues/save-new-league', {
				tournament: vm.newTournament,
				duplicating: vm.duplicating,
				region: vm.newTournamentRegion,
				order_id: order_id
			}).then(results => {
				if(results.data.success === true) {
					if(sessionStorage.getItem('order-league')) {
						sessionStorage.removeItem('order-league')
					}
					if(vm.duplicating) {
						vm.$router.push({ path: `/dashboard/convenors/league/${results.data.league.slug}/dashboard?nav=import`  })
					} else {
						vm.$router.push({ path: '/dashboard/convenors/my-leagues'  })
					}
					
					this.$buefy.snackbar.open({
                        message: 'Sent the league for approval! You will receive an email soon.',
                        type: 'is-success', // is-danger
                        duration: 5000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Something went wrong when submitting your league! Contact us.',
                        type: 'is-danger', // is-danger
                        duration: 5000,
                        queue: false
                    })
				}
			}).catch(err => {
				this.$buefy.snackbar.open({
					message: 'Something went wrong when submitting your league! Message us if all info is submitted properly.',
					type: 'is-danger', // is-danger
					duration: 5000,
					queue: false
				})
			})
		},
		submitYourTournament: function() {
			var vm = this
			vm.$refs.form.validate().then(success => {
				if (!success) {
					return;
				}
				vm.registrationStep++
				

				if(vm.registrationStep === 2) {
					// check and go back if wrong
					
					var divisions = 0

					divisions = divisions + vm.newTournament.mens_divisions.length
					divisions = divisions + vm.newTournament.ladies_divisions.length
					divisions = divisions + vm.newTournament.coed_divisions.length
					
					if(vm.newTournamentRegion.id != 6) {
						vm.paymentSuccess = true
					}
					
					if(divisions === 0) {
						vm.registrationStep = 1

						this.$buefy.snackbar.open({
							message: 'You need to add at least one division',
							type: 'is-danger', // is-danger
							duration: 5000,
							queue: false
						})
					}
				}
				
				if(vm.registrationStep === 3) {
					
				}
			})			
		},
		checkValidation: function() {
			var vm = this
			var validation_errors = []
			vm.validation_errors = []

			if(vm.registrationStep === 0) {
				if(vm.newTournament.name === null) {
					validation_errors.push({
						reason: 'No tournament name entered.'
					})
				} else {
					if(vm.newTournament.name.length < 4) {
						validation_errors.push({
							reason: 'Tournament name must be more than four letters.'
						})
					}
				}
				if(vm.newTournament.registration_start_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a registration start date.'
					})
				}
				if(vm.newTournament.registration_end_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a registration end date.'
					})
				}
				if(vm.newTournament.event_start_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a event start date.'
					})
				}
				if(vm.newTournament.event_end_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a event end date.'
					})
				}
				// if(vm.newTournament.headquarter_location === null) {
				// 	validation_errors.push({
				// 		reason: 'Tournament needs a headquarter location.'
				// 	})
				// }
				if(vm.newTournament.team_price === null) {
					validation_errors.push({
						reason: 'Tournament needs a team price.'
					})
				}
				if(vm.newTournament.team_price === '') {
					validation_errors.push({
						reason: 'Tournament needs a team price.'
					})
				}
				// todo - check for one payment method selected
			}

			if(vm.registrationStep === 1) {
				var mens_divisions_count = vm.newTournament.mens_divisions.length
				var ladies_divisions_count = vm.newTournament.ladies_divisions.length
				var coed_divisions_count = vm.newTournament.coed_divisions.length
				var total_divisions = mens_divisions_count + ladies_divisions_count + coed_divisions_count
				if(total_divisions === 0) {
					validation_errors.push({
						reason: 'Tournament needs at least one division.'
					})
				}
			}

			vm.validation_errors = validation_errors

			return validation_errors
		},
		pressedNext: function(action) {
			var vm = this
			var checkedVal = vm.checkValidation()	
			if(checkedVal.length === 0) {
				vm.registrationStep++
			} else {
				
			}
			
		},
		paymentSuccessSwitch: function($event) {

			var vm = this
			
			var json = JSON.stringify($event)
			sessionStorage.setItem('order-league', json);

            vm.paymentSuccess = $event
        },
	}
}
</script>

<style lang="scss">
    
</style>
