import { createVueApp } from './App'
import io from 'socket.io-client';
import { Table, Input } from 'buefy'


import axios from 'axios'

// const socket = io('wss://mlbtheshowtourneys.com');

// const socket = io('ws://localhost:3124');

// window.socket = socket


const { app, router } = createVueApp()
app.$mount('#app')

if (module.hot) {
	module.hot.accept()
}




