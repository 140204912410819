<template>
    <div>
        <Header v-on:switch:navigation="switchWidth($event)"></Header>
        <div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper">
                <h1>Statistics Center</h1>
                
                <h2>Age Demographic By Region</h2>

                <apexchart ref="chart" type="bar" :options="chartOptions" :series="chartSeries"></apexchart>

                <button 
                    @click="selectRegion(null)" 
                    :class="{ 'selected-region': selectedRegion === null }"
                >
                    All Regions
                </button>
                <button 
                    v-for="region in regions" 
                    :key="region" 
                    @click="selectRegion(region)"
                    :class="{ 'selected-region': selectedRegion === region }"
                >
                    {{ region }}
                </button>
                <button 
                    @click="selectGender(null)" 
                    :class="{ 'selected-region': selectedGender === null }"
                >
                    Both Genders
                </button>
                <button 
                    @click="selectGender('Male')" 
                    :class="{ 'selected-region': selectedGender === 'Male' }"
                >
                    Males Only
                </button>
                <button 
                    @click="selectGender('Female')" 
                    :class="{ 'selected-region': selectedGender === 'Female' }"
                >
                    Females Only
                </button>
                <button 
                    @click="selectActiveStatus(true)" 
                    :class="{ 'selected-region': selectedActiveStatus === true }"
                >
                    Active This Year
                </button>
                <button 
                    @click="selectActiveStatus(false)" 
                    :class="{ 'selected-region': selectedActiveStatus === false }"
                >
                    All Accounts
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Header from './components/PrimaryHeader.vue'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'

export default {
    data() {
        return {
            content_width: false,
            ageGroups: null,
            selectedGender: null,
            selectedActiveStatus: null,
            selectedRegion: null,
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: ['16-17', '18-19', '20-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70-74', '75-79', '80-84', '85-89', '90-94', '95-99'],
                    position: 'top',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false
                    }
                },
                title: {
                    text: 'Age Demographic By Region',
                    floating: true,
                    offsetY: 0,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
            chartSeries: [{
                name: 'Number of Users',
                data: [] // This will be populated in updateChartData method
            }]
        }
    },
    components: {
        Header,
        apexchart: VueApexCharts
    },
    computed: {
        regions() {
            if (!this.ageGroups) {
                return []
            }

            return Object.keys(this.ageGroups[0].regions)
        }
    },
    methods: {
        selectRegion(region) {
            this.selectedRegion = region
            this.updateChartData()
        },
        updateChartData() {
            this.chartOptions.xaxis.categories = this.ageGroups.map(group => group.ageRange)
            const data = this.ageGroups.map(group => {
                if (this.selectedRegion && group.regions[this.selectedRegion] !== undefined) {
                    return group.regions[this.selectedRegion]
                } else {
                    return group.count
                }
            })
            // console.log('data', data)
            // console.log('categories', this.ageGroups.map(group => group.ageRange))
            this.chartSeries = [{
                name: 'Number of Users',
                data: data || [] // Ensure data is always an array
            }]
            this.$refs.chart.refresh();
        },
        selectGender(gender) {
            this.selectedGender = gender
            this.fetchData()
        },
        selectActiveStatus(isActive) {
            this.selectedActiveStatus = isActive
            this.fetchData()
        },
        fetchData() {
            const params = {}
            if (this.selectedGender) {
                params.gender = this.selectedGender
            }
            if (this.selectedActiveStatus !== null) {
                params.isActive = this.selectedActiveStatus
            }
            axios.get('/api/superuser/age-demographics', { params }).then(results => {
                this.ageGroups = results.data.ageGroups
                this.updateChartData()
            })
        },
    },
    mounted: function() {
        this.fetchData()
        // axios.get('/api/superuser/age-demographics').then(results => {
        //     console.log('results', results)
        //     this.ageGroups = results.data.ageGroups
        //     this.updateChartData()
        // })
    }
}
</script>

<style scoped>
.selected-region {
    background-color: red; /* Change this to the desired highlight color */
}
</style>