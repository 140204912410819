<template>
	<span>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        <h2>{{ en === true ? 'My Tournaments' : 'Mes tournois' }}</h2>
        
        <Accordion preOpen>
            <template v-slot:header>
                {{ en === true ? 'Your Upcoming Tournaments' : 'Vos prochains tournois' }}
            </template>
            <template v-slot:content>
                <b-field grouped position="is-right">
                    <vue-excel-xlsx
                        :data="current_tournaments"
                        :columns="excelColumns"
                        :filename="'filename'"
                        :sheetname="'sheetname'"
                        :class="'button is-success'"
                        >
                        <i class="fas fa-file-export"></i>&nbsp;&nbsp; {{ en === true ? 'Export to Excel' : 'Exporter vers Excel' }}
                    </vue-excel-xlsx>
                </b-field>
                
                <b-table 
                    v-if="upcoming"
                    :data="upcoming" 
                    :striped="true"
                    :focusable="true"
                >
                    <b-table-column field="tournament_name" :label="(en === true ? 'Tournament Name' : 'Nom du tournoi')">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="registration_end_date" :label="(en === true ? 'Registration End' : `Fin d'inscription`)">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                            
                        </template>
                        <template v-slot="props">
                            <div>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.registration_end_date).format('MMMM DD') }}</b>
                                </span>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.registration_end_date).format('YYYY') }}</b>
                                </span>
                            </div>
                        </template>
                    </b-table-column>
                    <b-table-column field="start_date" :label="(en === true ? 'Date of Event' : `Date de l'évènement`)"  :header-class="'is-sticky-column-one'" :cell-class="'is-sticky-column-one'" :sticky="true" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <div>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('YYYY') }}</b>
                                </span>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('MMMM DD') }}</b>
                                </span>
                                
                            </div>
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="type" label="Type">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-danger" v-if="props.row.status === 'unapproved'">
                                Awaiting Approval
                            </span>
                            <span class="tag is-warning" v-if="props.row.status === 'approved'">
                                Approved But Unpaid
                            </span>
                            <span class="tag is-success" v-if="props.row.status === 'sanctioned'">
                                Sanctioned
                            </span>
                        </template>
                    </b-table-column>
            
                    <b-table-column field="teams_entered" :label="(en === true ? '# Registered' : 'Equipes inscrites')" style="text-align: center;">
                        <template slot="header" slot-scope="{ column }" >
                            <b-tooltip :label="column.label" dashed style="text-align: center; font-size: 12px;">
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.registrants.length }}
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="actions" label="Actions">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <a :href="'/dashboard/convenors/tournament/' + props.row.slug + '/dashboard'" class="button is-small is-info">{{ en === true ? 'View Tournament Dashboard' : 'Voir le tableau de bord du tournoi' }}</a>
                        </template>
                    </b-table-column>
                
                </b-table>
            </template>
        </Accordion>
        <Accordion>
            <template v-slot:header>
                {{ en === true ? 'Previous Tournaments' : 'Tournois précédents' }}
            </template>
            <template v-slot:content>
                <b-table 
                    v-if="previous"
                    :data="previous" 
                    :striped="true"
                    :focusable="true"
                    paginated
                    per-page="10"
                >
                    <b-table-column field="tournament_name"  :label="(en === true ? 'Tournament Name' : 'Nom du tournoi')">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                   
                    <b-table-column field="start_date" :label="(en === true ? 'Date of Event' : `Date de l'évènement`)"  :header-class="'is-sticky-column-one'" :cell-class="'is-sticky-column-one'" :sticky="true" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <div>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('YYYY') }}</b>
                                </span>
                                <span class="tag is-light">
                                    <b>{{ moment(props.row.event_start_date).format('MMMM DD') }}</b>
                                </span>
                                
                            </div>
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="type" label="Type">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-danger" v-if="props.row.status === 'unapproved'">
                                Awaiting Approval
                            </span>
                            <span class="tag is-warning" v-if="props.row.status === 'approved'">
                                Approved But Unpaid
                            </span>
                            <span class="tag is-success" v-if="props.row.status === 'sanctioned'">
                                Sanctioned
                            </span>
                        </template>
                    </b-table-column>
            
                    <b-table-column field="teams_entered" :label="(en === true ? '# Registered' : 'Equipes inscrites')" style="text-align: center;">
                        <template slot="header" slot-scope="{ column }" >
                            <b-tooltip :label="column.label" dashed style="text-align: center; font-size: 12px;">
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.registrants.length }}
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="actions" label="Actions">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <b-dropdown :inline="true" position="is-right">
                                <button class="button is-info" slot="trigger">
                                    <span>Actions</span>
                                    <b-icon icon="menu-down"></b-icon>
                                </button>
                                <b-dropdown-item>
                                    <a :href="'/dashboard/convenors/tournament/' + props.row.slug + '/dashboard'">{{ en === true ? 'View Tournament Dashboard' : 'Voir le tableau de bord du tournoi' }}</a>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="props.row.paperwork_completed === null">
                                    <a :href="'/dashboard/convenors/tournament/' + props.row.slug + '/paperwork'" >{{ en === true ? 'COMPLETE YOUR PAPERWORK' : 'Complétez votre paperasse' }}</a>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="user.default_region_id !== 4">
                                    <a :href="'/dashboard/convenors/create-tournament?d=' + props.row.slug">{{ en === true ? 'Duplicate Tournament to ' + new Date().getFullYear() : `Tournoi en double jusqu'en` }}</a>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <a :href="'/dashboard/convenors/tournament/' + props.row.slug + '/dashboard'" class="button is-small is-info">{{ en === true ? 'View Tournament Dashboard' : 'Voir le tableau de bord du tournoi' }}</a>
                            <a :href="'/dashboard/convenors/tournament/' + props.row.slug + '/paperwork'" class="button is-small is-danger" v-if="props.row.paperwork_completed === null">{{ en === true ? 'COMPLETE YOUR PAPERWORK' : 'Complétez votre paperasse' }}</a>
                            <a :href="'/dashboard/convenors/create-tournament?d=' + props.row.slug" class="button is-warning is-small">{{ en === true ? 'Duplicate Tournament to next year': `Tournoi en double jusqu'en` }} </a> -->
                        </template>
                    </b-table-column>
                
                </b-table>
            </template>
        </Accordion>
        <Accordion>
            <template v-slot:header>
                {{ en === true ? 'Tournament Settings' : 'Paramètres du tournoi' }}
            </template>
            <template v-slot:content>
                <p>Do you always use a particular headquarters for <b>all or most</b> of your events? Or do you always invite the same three divisions?</p>
                <br>
                <p>You can use this tournament settings page to <b>pre-set</b> parts of the "Create a New Tournament" or "Create a New League" page. You can always <b>override these for one-off events,</b> but this will allow you to get one step ahead.</p>
                <br>
                <p>Leave a box or area empty if you don't want it to pre-set anything. For example, if your price always changes, leave this empty, and it will remain empty when you go to create a tournament.</p>

                <h3>Tournament Basics Presets</h3>
                <div class="row row-margin">
                    <div class="col-md-6">
                        <b-field label="Team Price">
                            <b-tooltip type="is-dark" label="This is the price for a fully-formed team to enter your event.">
                                <b-input 
                                    v-model="newTournament.team_price"
                                    icon-pack="fas"
                                    icon="dollar-sign"
                                ></b-input>
                            </b-tooltip>
                        </b-field>
                    </div>
                    <div class="col-md-6">
                        <b-field label="Individual Price">
                            <b-tooltip type="is-dark" multilined label="This is the price for an individual to join a team. We will group these players seperate. You will be in charge of forming the teams. Make sure you factor our insurance into this price!">
                                <b-input 
                                    v-model="newTournament.individual_price"
                                    icon-pack="fas"
                                    icon="dollar-sign">
                                </b-input>
                            </b-tooltip>
                        </b-field>
                    </div>
                </div>
                <br>
                <b-field label="Your Website URL">
                    <b-tooltip multilined type="is-dark" label="This is the URL of your website! We will help you market it in the SPN Calendar Event.">
                        <b-input v-model="newTournament.website_url"></b-input>
                    </b-tooltip>
                </b-field>

                <b-field label="Your Facebook Group/Page URL">
                    <b-tooltip multilined type="is-dark" label="Do you have a Facebook group? It's great to grow your audience! Paste it here and people will be able to join when they register for the event.">
                        <b-input v-model="newTournament.facebook_group_url"></b-input>
                    </b-tooltip>
                </b-field>

                <b-field label="Do you require a deposit for team entry?">
                    <b-tooltip type="is-dark" label="If you require a deposit, mark yes and fill in the new box with the amount of deposit required.">
                        <div class="block">
                            <b-radio v-model="newTournament.deposit_required"
                                name="deposit_required"
                                native-value="true">
                                Yes
                            </b-radio>
                            <b-radio v-model="newTournament.deposit_required"
                                name="deposit_required"
                                native-value="false">
                                No
                            </b-radio>
                        </div>
                    </b-tooltip>
                </b-field>
                <div class="deposit-amount" v-if="newTournament.deposit_required">
                    <b-field label="Deposit Amount">
                        <b-tooltip type="is-dark" label="This is the amount you want to set the deposit at.">
                            <b-input v-model="newTournament.deposit_amount"
                                    icon-pack="fas"
                                    icon="dollar-sign"></b-input>
                        </b-tooltip>
                    </b-field>
                </div>
                <b-field label="What methods of payment will you accept for events?">
                    <b-tooltip type="is-dark" multilined label="This allows us to give options right away to your registrants.">
                        <div class="block">
                            <b-checkbox v-model="newTournament.registrants_pay_via_spn">
                                Via SPN Registration System (Credit Card)
                            </b-checkbox>
                            <b-checkbox v-model="newTournament.registrants_pay_via_emt">
                                Via EMT
                            </b-checkbox>
                            <b-checkbox v-model="newTournament.registrants_pay_via_cash">
                                Cash on Event Day
                            </b-checkbox>
                        </div>
                    </b-tooltip>
                </b-field>
                <div class="via-emt" v-if="newTournament.registrants_pay_via_emt">
                    <p><b>Payment via EMT</b></p>
                    <b-field label="Your EMT Email">
                        <b-tooltip type="is-dark" multilined label="What email do you want EMTs to go to?">
                            <b-input 
                                v-model="newTournament.emt_email"
                                icon-pack="fas"
                                icon="at">
                            </b-input>
                        </b-tooltip>
                    </b-field>
                    <b-field label="Your Preferred EMT Password">
                        <b-tooltip type="is-dark" multilined label="What password do you want EMTs to be set as?">
                            <b-input 
                                v-model="newTournament.emt_password"
                                icon-pack="fas"
                                icon="lock">
                            </b-input>
                        </b-tooltip>
                    </b-field>
                </div>
                
                <h3>Tournament Divisions Presets</h3>
                <div class="selected-divisions">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="">Mens</h2>
                            <b-dropdown hoverable aria-role="list" v-model="newTournament.mens_divisions" multiple>
                                <button class="button is-info" slot="trigger">
                                    <span>
                                        Select Mens Divisions
                                    </span>
                                    <b-icon icon="menu-down"></b-icon>
                                </button>

                                <b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
                            </b-dropdown>
                            <nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.mens_divisions">
                                <div class="panel-heading">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <b>{{ division.name }} Division</b>
                                        </div>
                                        <div class="col-md-5">
                                            <b-field label="Qualifier Type" horizontal custom-class="is-small">
                                                <b-tooltip type="is-dark" label="Mark the qualifier for this division.">
                                                    <b-dropdown hoverable aria-role="list" v-model="division.qualifier">
                                                        <button class="button is-info" slot="trigger">
                                                            <span>
                                                                {{ division.qualifier }}
                                                            </span>
                                                            <b-icon icon="menu-down"></b-icon>
                                                        </button>

                                                        <b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
                                                            Provincial Qualifer
                                                        </b-dropdown-item>
                                                        <b-dropdown-item value="National Qualifer" aria-role="listitem">
                                                            National Qualifer
                                                        </b-dropdown-item>
                                                        <b-dropdown-item value="Non-Qualifer" aria-role="listitem">
                                                            Non-Qualifer
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </b-tooltip>
                                            </b-field>
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="panel-block" style="display: block;">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum # of Teams" custom-class="is-small">
                                                    <b-numberinput type="is-info" min="3" v-model="division.min_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Max # of Teams" custom-class="is-small">
                                                    <b-numberinput type="is-info" min="3" v-model="division.max_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum Age" custom-class="is-small">
                                                    <b-numberinput min="16" type="is-info" max="99" v-model="division.min_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Maximum Age" custom-class="is-small">
                                                    <b-numberinput min="16"  type="is-info" max="99" v-model="division.max_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="">Ladies</h2>
                            <b-dropdown hoverable aria-role="list" v-model="newTournament.ladies_divisions" multiple>
                                <button class="button is-primary" slot="trigger">
                                    <span>
                                        Select Ladies Divisions
                                    </span>
                                    <b-icon icon="menu-down"></b-icon>
                                </button>

                                <b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
                            </b-dropdown>
                            <nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.ladies_divisions">
                                <div class="panel-heading">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <b>{{ division.name }} Division</b>
                                        </div>
                                        <div class="col-md-5">
                                            <b-field label="Qualifier Type" horizontal custom-class="is-small">
                                                <b-tooltip type="is-dark" label="Mark the qualifier for this division.">
                                                    <b-dropdown hoverable aria-role="list" v-model="division.qualifier">
                                                        <button class="button is-primary" slot="trigger">
                                                            <span>
                                                                {{ division.qualifier }}
                                                            </span>
                                                            <b-icon icon="menu-down"></b-icon>
                                                        </button>

                                                        <b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
                                                            Provincial Qualifer
                                                        </b-dropdown-item>
                                                        <b-dropdown-item value="National Qualifer" aria-role="listitem">
                                                            National Qualifer
                                                        </b-dropdown-item>
                                                        <b-dropdown-item value="Non-Qualifer" aria-role="listitem">
                                                            Non-Qualifer
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </b-tooltip>
                                            </b-field>
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="panel-block" style="display: block;">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum # of Teams" custom-class="is-small">
                                                    <b-numberinput v-model="division.min_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Max # of Teams" custom-class="is-small">
                                                    <b-numberinput v-model="division.max_teams"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Minimum Age" custom-class="is-small">
                                                    <b-numberinput min="16" v-model="division.min_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="padding">
                                                <b-field label="Maximum Age" custom-class="is-small">
                                                    <b-numberinput min="16" v-model="division.max_age"></b-numberinput>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="">Coed</h2>
                                <b-dropdown hoverable aria-role="list" v-model="newTournament.coed_divisions" multiple>
                                    <button class="button is-danger" slot="trigger">
                                        <span>
                                            Select Coed Divisions
                                        </span>
                                        <b-icon icon="menu-down"></b-icon>
                                    </button>

                                    <b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
                                </b-dropdown>
                                <nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.coed_divisions">
                                    <div class="panel-heading">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <b>{{ division.name }} Division</b>
                                            </div>
                                            <div class="col-md-5">
                                                <b-field label="Qualifier Type" horizontal custom-class="is-small">
                                                    <b-tooltip type="is-dark" label="Mark the qualifier for this division.">
                                                        <b-dropdown hoverable aria-role="list" v-model="division.qualifier">
                                                            <button class="button is-danger" slot="trigger">
                                                                <span>
                                                                    {{ division.qualifier }}
                                                                </span>
                                                                <b-icon icon="menu-down"></b-icon>
                                                            </button>

                                                            <b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
                                                                Provincial Qualifer
                                                            </b-dropdown-item>
                                                            <b-dropdown-item value="National Qualifer" aria-role="listitem">
                                                                National Qualifer
                                                            </b-dropdown-item>
                                                            <b-dropdown-item value="Non-Qualifer" aria-role="listitem">
                                                                Non-Qualifer
                                                            </b-dropdown-item>
                                                        </b-dropdown>
                                                    </b-tooltip>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="panel-block" style="display: block;">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Minimum # of Teams" min="3" custom-class="is-small">
                                                        <b-numberinput type="is-danger" v-model="division.min_teams"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Max # of Teams" custom-class="is-small">
                                                        <b-numberinput type="is-danger" min="3" v-model="division.max_teams"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Minimum Age" custom-class="is-small">
                                                        <b-numberinput type="is-danger" min="16" max="99" v-model="division.min_age"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="padding">
                                                    <b-field label="Maximum Age" custom-class="is-small">
                                                        <b-numberinput type="is-danger" min="16" max="99" v-model="division.max_age"></b-numberinput>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="admin-button" style="margin-top: 30px;" v-on:click="saveTournamentSettings()"><i class="fas fa-save"></i>Save Your Tournament Settings</a>
            </template>
        </Accordion>
<!-- 

        <b-tabs v-model="activeTab" multiline>
            <b-tab-item :label="(en === true ? 'Your Upcoming Events' : 'Vos prochains événements')">
                
                
            </b-tab-item>

            <b-tab-item :label="(en === true ? 'Previous Events' : 'Evénements précédents')">
                
            </b-tab-item>
            
            <b-tab-item :label="(en === true ? 'Tournament Settings' : 'Paramètres du tournoi')">
                
            </b-tab-item>
        </b-tabs> -->
        <a href="https://slopitchcentral.com/?track=spn"><img src="https://cdn.blacktiecollab.com/slopitchcentral/slopitchcentral1.png" style="width: 100%;" alt="Slo-Pitch Central"></a>
    </span>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
	data() {
		return {
            isLoading: true,
			page: null,
			activeTab: 0,
            current_tournaments: [],
            previous: null,
            upcoming: null,
            previous_events: [],
            user: null,
            excelColumns: [
                {
                    label: 'Status',
                    field: 'status'
                },
                {
                    label: 'Tournament Name',
                    field: 'name'
                },
                {
                    label: 'Event Start Date',
                    field: 'event_start_date'
                },
                {
                    label: 'Event End Date',
                    field: 'event_end_date'
                },
                {
                    label: 'Registration Start Date',
                    field: 'registration_start_date'
                },
                {
                    label: 'Registration End Date',
                    field: 'registration_end_date'
                },
                {
                    label: 'Team Registration Fee',
                    field: 'team_registration_fee'
                },
                {
                    label: 'Address',
                    field: 'street_address'
                },
                {
                    label: 'City',
                    field: 'city'
                },
                {
                    label: 'Province',
                    field: 'province'
                },
                {
                    label: 'Postal Code',
                    field: 'postal_code'
                },
            ],
            newTournament: {			
				deposit_required: false,
				deposit_amount: null,
				team_price: null,
                individual_price: null,
				mens_divisions: [],
				ladies_divisions: [],
				coed_divisions: [],
				website_url: null,
				facebook_group_url: null,
				registrants_pay_via_spn: false,
				registrants_pay_via_emt: false,
				registrants_pay_via_cash: false,
				emt_email: null,
				emt_password: null
            },
            divisions: []
		};
	},
	components: {
		
	},
	mounted: function() {
        var vm = this

        axios.get('/api/tournaments/divisions').then(results => {
			vm.divisions = results.data.divisions
        })

        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        
        axios.get('/api/tournaments/default-tournament-settings').then(results => {
            var tourney_settings = results.data.tournament_settings
            vm.newTournament.team_price = tourney_settings.team_registration_fee
            vm.newTournament.individual_price = tourney_settings.player_registration_fee
            vm.newTournament.emt_password = tourney_settings.emt_password
            vm.newTournament.emt_email = tourney_settings.emt_email
            vm.newTournament.website_url = tourney_settings.website_url
            vm.newTournament.facebook_group_url = tourney_settings.facebook_group_url
            vm.newTournament.deposit_amount = tourney_settings.deposit_amount
            vm.newTournament.deposit_required = tourney_settings.deposit_required === 1 ? true : false
            vm.newTournament.registrants_pay_via_cash = tourney_settings.registrants_pay_via_cash === 1 ? true : false
            vm.newTournament.registrants_pay_via_emt = tourney_settings.registrants_pay_via_emt === 1 ? true : false
            vm.newTournament.registrants_pay_via_spn = tourney_settings.registrants_pay_via_spn === 1 ? true : false
            vm.newTournament.mens_divisions = tourney_settings.mens_divisions
            vm.newTournament.ladies_divisions = tourney_settings.ladies_divisions
            vm.newTournament.coed_divisions = tourney_settings.coed_divisions
        })
        
        axios.get('/api/tournaments/your-tournaments').then(results => {
            vm.current_tournaments = results.data.tournaments
            vm.previous = results.data.previous
            vm.upcoming = results.data.upcoming
        })

        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                vm.user = results.data.user
                
                if(results.data.convenor_permissions) {
                    vm.convenor_permissions = results.data.convenor_permissions
                }
            }
		})

        vm.isLoading = false
	},
	computed: {
	
	},
    props: ['en'],
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        viewTournamentDashboard: function(tournament) {
            this.$router.push({ name: 'TournamentDashboard', params: { slug: tournament.slug, page: 'dashboard' } })
        },
        orderSPNPrizing: function(tournament) {
            this.$router.push({ name: 'TournamentDashboard', params: { slug: tournament.slug, page: 'prizing' } })
        },
        assignUmpires: function(tournament) {
            this.$router.push({ name: 'TournamentDashboard', params: { slug: tournament.slug, page: 'umpires' } })
        },
        viewRegisteredTeams: function(tournament) {
            this.$router.push({ name: 'TournamentDashboard', params: { slug: tournament.slug, page: 'teams' } })
        },
        saveTournamentSettings: function() {
            var vm = this

            axios.post('/api/tournaments/update-default-settings', {
                newTournament: vm.newTournament
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Saved your league setup settings',
                        type: 'is-success', // is-danger
                        duration: 5000,
                        queue: false
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Unable to save your tournament settings',
                        type: 'is-danger', // is-danger
                        duration: 5000,
                        queue: false
                    })
                }
            })
        }
	}
}
</script>

<style lang="scss">
    
</style>
