<template>
	<div class="login-bg">
		<Header></Header>
		<div class="login-area">
            <div class="login-area-wrapper" v-if="nowRegistered === false">
                <div class="login-header">
                    Register to Join SPN4You
                </div>
                <div style="background: #fff; padding: 10px;">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="First Name">
                                    <b-input v-model="registerInfo.first_name"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Last Name">
                                    <b-input v-model="registerInfo.last_name"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Email Address">
                                    <b-input v-model="registerInfo.email"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Confirm Email">
                                    <b-input v-model="registerInfo.confirm_email"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Password">
                                    <b-input v-model="registerInfo.password"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Confirm Password">
                                    <b-input v-model="registerInfo.confirm_password"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Date of Birth">
                                    <b-datepicker v-model="registerInfo.date_of_birth"></b-datepicker>
                                    
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Gender">
                                    <b-select v-model="registerInfo.gender">
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </b-select>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                
                <a class="login-button" v-on:click="registerMyAccount()">Register My Account</a>
                <p style="margin-top: 20px;"><b>Forgot your <a href="#">username</a> or <a href="#">password</a>?</b></p>
                  
            </div>
            <div class="login-area-wrapper" v-if="nowRegistered === true">
                <div class="login-header">
                    Complete your registration!
                </div>
                <div style="background: #fff; padding: 10px;">
                    <p>Your account has been registered! Check your inbox and spam for a confirmation email so we can prove your email is valid. <b>Your account is considered pending until accepting this email.</b> If you did not receive an email, please contact us.</p>
                    <br>
                    <div style="text-align: center;">
                        <h3 style="font-size: 22px; font-weight: 700; color: #da0000;">While you wait...</h3>
                        <p>Let's finish up the rest of your profile.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Primary Phone #">
                                    <b-input v-model="registerConfirm.primary_phone"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Secondary Phone #">
                                    <b-input v-model="registerConfirm.secondary_phone"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="padding">
                                <b-field label="Security Question">
                                    <b-select v-model="registerConfirm.security_question">
                                        <option :value="option" :key="key" v-for="(option, key) in securityQuestionOptions">
                                            {{ option }}
                                        </option>
                                    </b-select>

                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="padding">
                                <b-field label="Security Answer">
                                    <b-input v-model="registerConfirm.secondary_phone"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Emergency Contact Name">
                                    <b-input v-model="registerConfirm.emergency_contact_name"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Emergency Contact Phone #">
                                    <b-input v-model="registerConfirm.emergency_contact_phone"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Current Player Rank">
                                    <b-select v-model="registerConfirm.current_rank">
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="E">E</option>
                                    </b-select>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Positions You Play">
                                     <b-dropdown
                                        v-model="registerConfirm.positions"
                                        multiple
                                        aria-role="list">
                                        <button class="button is-secondary" type="button" slot="trigger">
                                            <span>Selected ({{ registerConfirm.positions.length }})</span>
                                            <b-icon icon="menu-down"></b-icon>
                                        </button>

                                        <b-dropdown-item value="P" aria-role="listitem">
                                            <span>Pitcher</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="C" aria-role="listitem">
                                            <span>Catcher</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="1B" aria-role="listitem">
                                            <span>First Base</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="2B" aria-role="listitem">
                                            <span>Second Base</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="SS" aria-role="listitem">
                                            <span>Shortstop</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="3B" aria-role="listitem">
                                            <span>Third Base</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="LF" aria-role="listitem">
                                            <span>Left Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="LCF" aria-role="listitem">
                                            <span>Left Center Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="RCF" aria-role="listitem">
                                            <span>Right Center Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="RF" aria-role="listitem">
                                            <span>Right Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="Ro" aria-role="listitem">
                                            <span>Rover</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="login-button" v-on:click="completeMyAccount()">Complete My Account</a>
            </div>
        </div>
		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/SecondaryHeader.vue'
import Footer from './components/SecondaryFooter.vue'
import axios from 'axios'
var getAge = require('get-age')
export default {
	data() {
		return {
            registerInfo: {
                first_name: null,
                last_name: null,
                email: null,
                retype_email: null,
                password: null,
                confirm_password: null,
                date_of_birth: null
            },
            registerConfirm: {
                primary_phone: null,
                secondary_phone: null,
                emergency_contact_name: null,
                emergency_contact_phone: null,
                current_rank: 'E',
                positions: []
            },
            error: null,
            nowRegistered: true,
            securityQuestionOptions: [
                'What was the house number and street name you lived in as a child?',
                'What were the last four digits of your childhood telephone number?',
                'What primary school did you attend?',
                'In what town or city was your first full time job?',
                'In what town or city did you meet your spouse or partner?',
                'What is the middle name of your oldest child?',
                "What are the last five digits of your driver's license number?",
                "What is your spouse or partner's mother's maiden name?",
                "What is your grandmother's (on your mother's side) maiden name?",
                "In what town or city did your parents meet?"
            ]
		};
	},
	components: {
		Header,
		Footer
	},
	mounted: function() {
       
	},
	computed: {
        reviewForDuplicates: function() {
            // run an axios that validates all the information

        }
	}, 
	methods: {
        registerMyAccount: function() {
            var vm = this

            // double check that passwords match

            // double check that emails match
            
            // double check that email doesn't already exist
            // check date of birth
            
            // if(getAge(this.registerInfo.date_of_birth) >= 16) {
            //     console.log('true')
            // }


            axios.post('/api/users/create', {
                register: vm.registerInfo
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Your account has been created! Check your email!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })

                    // redirect to second page of registration (can do this in the same page, as a secondary popup/hide current content)
                    
                }
            })   
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
