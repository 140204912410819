<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div :class="['page-header', { sidebarClosed: !content_width }]">
                <div class="row">
                    <div class="col-md-3">
                        <div class="page-header-title">
                            Tournament Results
                        </div>
                    </div>
                </div>
            </div>
            <div class="admin-area">
                <div class="row">
                    <div class="col-md-12">
                        <div class="padding" v-if="!selectedForm">
                            <b-table
                                :data="tournament_results"
                                ref="table"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">

                            

                                <b-table-column field="tournament.name" label="Tournament Name" sortable centered v-slot="props">
                                    {{ props.row.tournament.name }}
                                </b-table-column>
                                <b-table-column field="tournament.region.short_name" label="Region" sortable centered v-slot="props">
                                    <span>{{ props.row.tournament.region.short_name }}</span>
                                </b-table-column>
                                <b-table-column field="tournament.name" label="Tournament Date" sortable centered v-slot="props">
                                    {{ moment(props.row.tournament.event_start_date).format('YYYY-MM-DD') }}
                                </b-table-column>
                                <b-table-column field="created_at" label="Submitted On" sortable centered v-slot="props">
                                    {{ props.row.tournament.convenor.first_name }} {{ props.row.tournament.convenor.last_name }}
                                </b-table-column>
                                
                                <b-table-column field="created_at" label="Submitted On" sortable centered v-slot="props">
                                    {{ moment(props.row.created_at).format('YYYY-MM-DD') }}
                                </b-table-column>
                                <b-table-column field="edit" label="Edit" sortable centered v-slot="props">
                                    <a class="button is-small" v-on:click="viewFormResults(props.row)">View Tourney Results</a>
                                </b-table-column>
                            </b-table>
                        </div>

                        <div class="selected-form" v-if="selectedForm">
                            <h1>{{ selectedForm.tournament.name }}</h1>

                            
                            <div v-if="selectedForm.tournament.region_id === 4">
                                <h4>Prize Packages</h4>
                                <table class="table">
                                    <tr>
                                        <td><b>Type of Prize Pack</b></td>
                                        <td><b>Qty</b></td>
                                        <td><b>Total Due</b></td>
                                    </tr>
                                    <tr>
                                        <td>Basic Prize Pack</td>
                                        <td>
                                            <b-input v-model="selectedForm.basic_prize_pack_qty"></b-input>
                                        </td>
                                        <td>${{ 195 * selectedForm.basic_prize_pack_qty }}</td>
                                    </tr>
                                    <tr>
                                        <td>Deluxe Prize Pack</td>
                                        <td>
                                            <b-input v-model="selectedForm.deluxe_prize_pack_qty"></b-input>
                                        </td>
                                        <td>${{ 265 * selectedForm.deluxe_prize_pack_qty }}</td>
                                    </tr>
                                    <tr>
                                        <td>Supplementary Prize Pack</td>
                                        <td>
                                            <b-input v-model="selectedForm.supplementary_prize_pack_qty"></b-input>
                                        </td>
                                        <td>${{ 170 * selectedForm.supplementary_prize_pack_qty }}</td>
                                    </tr>
                                </table>
                            </div>

                            <h4>Teams</h4>
                            <table class="table">
                                <tr>
                                    <td><b>Division</b></td>
                                    <td><b>Placed</b></td>
                                    <td><b>Team Name</b></td>
                                    <td><b>Wins</b></td>
                                    <td><b>Losses</b></td>
                                    <td><b>Higher Division</b></td>
                                    <td><b>Lower Division</b></td>
                                    <td><b>Qualify</b></td>
                                </tr>
                                <tr :key="team.id" v-for="team in sortByPlaced(selectedForm.teams)">
                                    <td><span v-if="team.division">{{ team.division.gender_slug }} {{ team.division.rank_id }}</span></td>
                                    <td><b-input v-model="team.placed"></b-input></td>
                                    <td><b>{{ team.registrant.team.name }}</b></td>
                                    <td><b-input v-model="team.wins"></b-input></td>
                                    <td><b-input v-model="team.losses"></b-input></td>
                                    <td>
                                        <b-select v-model="team.higher_division">
                                            <option :value="1">
                                                Yes
                                            </option>
                                            <option :value="0">
                                                No
                                            </option>
                                        </b-select>
                                    </td>
                                    <td>
                                        <b-select v-model="team.lower_division">
                                            <option :value="1">
                                                Yes
                                            </option>
                                            <option :value="0">
                                                No
                                            </option>
                                        </b-select>
                                    </td>
                                    <td>
                                        <b-select v-model="team.qualify">
                                            <option :value="1">
                                                Yes
                                            </option>
                                            <option :value="0">
                                                No
                                            </option>
                                        </b-select>
                                    </td>
                                </tr>

                                
                            </table>
                    
                            
                            <h4>Injuries</h4>
                            <table class="table">
                                <tr>
                                    <td><b>Name</b></td>
                                    <td><b>Description</b></td>
                                </tr>
                                <tr :key="injury.id" v-for="injury in selectedForm.injuries">
                                    <td><b-input v-model="injury.name"></b-input></td>
                                    <td><b-input v-model="injury.description"></b-input></td>
                                </tr>
                            </table>
                            <h4>Umpires</h4>
                            <table class="table">
                                <tr>
                                    <td><b>Name</b></td>
                                    <td><b>Email</b></td>
                                </tr>
                                <tr :key="umpire.id" v-for="umpire in selectedForm.umpires">
                                    <td>
                                        <b-input v-model="umpire.name"></b-input>
                                    </td>
                                    <td>
                                        <b-input v-model="umpire.email"></b-input>
                                    </td>
                                </tr>
                            </table>

                            <h4>Comments</h4>
                            <b-input type="textarea" v-model="selectedForm.comments"></b-input>                            
                            
                            <br><br><br><br>
                            <a class="admin-button" role="button" v-on:click="updateTournamentResults()">Update Tournament Results</a>
                            <br><br><br><br>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
export default {
	data() {
		return {
            isLoading: true,
            createNewFormSwitch: false,
            authUser: { first_name: null },
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            tournament_results: [],
            waivers: [],
            questionTypes: [
                {
                    value: 'short_text',
                    name: 'Short Text'
                },
                {
                    value: 'long_text',
                    name: 'Long Text'
                },
                {
                    value: 'true_or_false',
                    name: 'True or False'
                },
                {
                    value: 'select',
                    name: 'Select'
                },
                {
                    value: 'checkbox',
                    name: 'Checkbox'
                },
                {
                    value: 'date',
                    name: 'Date'
                },
                {
                    value: 'time',
                    name: 'Time'
                },
                {
                    value: 'first_name',
                    name: 'First Name'
                },
                {
                    value: 'last_name',
                    name: 'Last Name'
                },
                {
                    value: 'email',
                    name: 'Email'
                },
                {
                    value: 'hidden',
                    name: 'Hidden'
                },
                
            ],
            form: {
                name: null,
                fr_name: null,
                collection: 'form_collection',
                region: '-1'
            },
            form_questions: [
                {
                    type: 'section_break', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Section 1 - ',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                },
                {
                    type: 'short_text', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Label',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                }
            ],
            confirmSaveWaiver: false,
            content_width: false,
            forms: [],
            submitted_forms: [],
            selectedForm: null,
            editFormSwitch: null,
            user_perms: null
		};
	},
	components: {
		Header,
		Footer,
    },
    computed: {
        computedWaiverSubmission: function() {
            
        },
        computedFormResults: function() {
            var vm = this
            
            if(vm.selectedForm) {
                var formResults = vm.selectedForm.responses
                if(vm.user_perms) {
                    if(vm.user_perms.specific_region_only) {
                        formResults = _.filter(formResults, function(result) {
                            return result.region_id === vm.user_perms.specific_region_only
                        })
                    }
                }
            
                return formResults
            }

            return []
        }
    },
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.user_perms = results.data.user_permissions
                if(results.data.user_permissions.tournaments_reporting !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/tournament-results').then(results => {
                    if(results.data.success === true) {
                        var data = results.data.tournament_results
                        if(vm.user_perms.specific_region_only) {
                            data = _.filter(data, function(result) {
                                return result.tournament.region_id === vm.user_perms.specific_region_only
                            })
                        }
                        vm.tournament_results = data
                        vm.isLoading = false
                    }
                })
            }
        })
	},
	methods: {
        removeOption: function(question, key) {
            question.options.splice(key, 1)
        },
        removeEditRow: function(index) {
            this.selectedForm.questions.splice(index, 1);
        },
        removeRow: function(index) {
            this.form_questions.splice(index, 1);
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addFormQuestion: function() {
			this.form_questions.push({
                type: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addSectionBreak: function() {
            this.form_questions.push({
                type: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddFormQuestion: function() {
            this.selectedForm.questions.push({
                type_id: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddSectionBreak: function() {
            
            this.selectedForm.questions.push({
                type_id: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addFormOption: function(question) {
            question.options.push({
                name: '',
                fr_name: ''
            })
        },
        editForm: function(form) {
            var vm = this
            vm.selectedForm = form
            vm.editFormSwitch = true
        },
        saveForm: function() {
            var vm = this
            axios.post('/api/superuser/save-new-form', {
                form: vm.form,
                form_questions: vm.form_questions
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.createNewFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        updateForm: function() {
            var vm = this
            axios.post('/api/superuser/update-form', {
                selected_form: vm.selectedForm,
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.editFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        viewFormResults: function(selectedForm) {
            var vm = this

            vm.selectedForm = selectedForm
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        sortByPlaced: function(placed) {
            return _.orderBy(placed, ['division.gender_slug', 'division.rank_id', 'placed'], 'asc')
        },
        updateTournamentResults: function() {
            var vm = this
            
            axios.post('/api/superuser/update-tournament-results', {
                tournament_results: vm.selectedForm
            }).then(results => {
                if(results.data.success === true) {
                     vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
