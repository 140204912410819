<template>
    <span v-if="authUser">
		<UmpCertificate :en="en" :userId="userId"></UmpCertificate>
    </span>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

import UmpCertificate from './components/UmpCertificate.vue'
export default {
	data() {
		return {
            authUser: null,
            userId: null,
            umpire_permissions: null,
            en: true
		};
	},
	components: {
		UmpCertificate
	},
	
	mounted: function() {
        var vm = this
        vm.userId = this.$route.params.userId
        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
				vm.umpire_permissions = results.data.umpire_permissions
            }
        })
	},
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		}
	}
}
</script>

<style lang="scss">
    
</style>
