<template>
	<div>

		<Header v-if="hasHeader"></Header>


		<div class="content-area" v-if="tournament">
			<div class="standard-page-header" style="padding: 50px 0;">
				{{ tournament.name }}
                <h3 style="margin-top: 0px; font-family: 'Fjalla One', sans-serif; color: #ddd !important; font-weight: 400; text-transform: uppercase;">Starts on {{ moment(tournament.event_start_date).format('dddd MMMM DD YYYY') }}!</h3>
			</div>
			<div class="event-description" style="text-align: center;" v-if="tournament.description">
				<div class="event-description-header">{{ tournament.name }}'s description:</div>
				<div class="event-description-content" v-html="tournament.description"></div>
			</div>

            <div class="row">
                <div class="col-md-4">
                    <img src="../../images/dashboard/sanctionedtournament.png" v-if="!tournament.poster_image_url" alt="Tournament Poster">
                    <img :src="tournament.poster_image_url" v-if="tournament.poster_image_url" alt="Tournament Poster">

                    
                </div>
                <div class="col-md-8">
					<!-- <iframe

                        width="100%"
                        height="273"
                        frameborder="0" style="border:0; "
                        :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyCAnO39NnB7qlv22s3nm-mM7QOCqYFsTI8&maptype=satellite&q=' + tournament.written_address" allowfullscreen>
                    </iframe> -->
				</div>
            </div>
			<div class="row">
				<div class="col-md-12">    
                    <div class="remainder-tournament-content">
						<div style="margin-top: 30px;" >
							<span v-if="isInFuture">
								<a :class="['admin-button', { gray: buttonSelected === 'convenor' }]" :href="`/tournament/${tournament.slug}/register`" >Register For  {{ tournament.name }}</a>
							</span>
							<!-- <a :class="['admin-button', { gray: buttonSelected === 'register' }]" v-on:click="buttonSelected = 'convenor'">Contact 
								<span style="display: inline; text-transform: inherit; font-size: 20px;" v-if="tournament.convenor">{{ tournament.convenor.first_name + ' ' + tournament.convenor.last_name }}</span><span style="display: inline; text-transform: inherit; font-size: 20px;" v-else>The Convenor</span></a> -->
							<a class="admin-button" :href="`mailto:${tournament.preferred_email}`" v-if="tournament.name_advertised === 1 && tournament.preferred_email">
								Email: {{ tournament.preferred_email }}
							</a>
							<a class="admin-button" :href="`tel:${tournament.phone}`" v-if="tournament.name_advertised === 1 && tournament.preferred_phone">
								{{ tournament.convenor.first_name }} {{ tournament.convenor.last_name }}
								<br>Call: {{tournament.preferred_phone }}
							</a>
						</div>
						
						<div class="register-to-tournament" v-if="buttonSelected ==='register'">
							<div v-if="provincialsOrNationals">
								<div class="check-for-spn-registration">
									<div class="spn-number-box does-not-have-spn-number">
										<h4 style="margin-top: 0;">Pay For Event</h4>
										<p>This event requires you to make payment online in order to register. Start by paying for the event, and then selecting your roster and paying your tournament insurance (if necessary).</p>

										<NewTournamentPayment
											:tournamentId="tournament.id"
											:regionId="tournament.region_id"
											v-on:payment:successful="paidForProvincialEvent($event)"
											>
										</NewTournamentPayment>
									</div>
								</div>
								<div v-if="eventPaymentMade">
									<div v-if="authUser">
										<NewRegisterToTournament
											v-on:register:complete="registrationComplete($event)"
											:tournamentId="tournament.id"
										>
										</NewRegisterToTournament>
									</div>
									<div v-else>
										<LoginRegister
											v-if="authUser === null"
											:redirect="false"
											:redirect-to="false"
											v-on:logged:in="triggerAuth($event)">
										</LoginRegister>
									</div>
								</div>
							</div>
							<div v-else>
								<div v-if="authUser">
									<NewRegisterToTournament
										v-on:register:complete="registrationComplete($event)"
										:tournamentId="tournament.id"
									>
									</NewRegisterToTournament>
								</div>
								<div v-else>
									<LoginRegister
										v-if="authUser === null"
										:redirect="false"
										:redirect-to="false"
										v-on:logged:in="triggerAuth($event)">
									</LoginRegister>
								</div>
							</div>
						</div>
						<div class="send-convenor-question" v-if="buttonSelected === 'convenor'">
							<div class="send-a-message">
								<div class="send-a-message-header">
									Send the convenor a question
								</div>

								<div class="padding">
									<div class="row">
										<div class="col-md-12">
											<b-field label="Your name">
												<b-input v-model="sendMessage.your_name"></b-input>
											</b-field>
										</div>
									</div>
									<div class="row" style="margin-top: 10px;">
										<div class="col-md-6">
											<b-field label="Your email">
												<b-input v-model="sendMessage.your_email"></b-input>
											</b-field>
										</div>
										<div class="col-md-6">
											<b-field label="Your phone number">
												<b-input v-model="sendMessage.your_phone"></b-input>
											</b-field>
										</div>
									</div>
									<div class="row" style="margin-top: 10px;">
										<div class="col-md-12">
											<b-field label="Write your message">
												<b-input type="textarea" v-model="sendMessage.your_message"></b-input>
											</b-field>
										</div>
									</div>
									<a v-on:click="sendMessageNow()" style="margin-top: 10px;" class="button is-danger">Send Message</a>
								</div>
							</div>
						</div>
						<hr>
						<div class="event-info-box">
							<div class="event-info-box-header">
								When is {{ tournament.name }}?
							</div>
							<div class="event-info-box-content">
								{{ moment(tournament.event_start_date).format('dddd MMMM DD, YYYY h:mm a') }} until {{ moment(tournament.event_end_date).format('dddd MMMM DD, YYYY h:mm a') }}
							</div>
						</div>
						<div class="event-info-box">
							<div class="event-info-box-header">
								Where is {{ tournament.name }}?
							</div>
							<div class="event-info-box-content">
								{{ tournament.written_address }}
							</div>
						</div>
						<div class="event-info-box">
							<div class="event-info-box-header">
								Share This Event:
							</div>
							<div class="event-info-box-content">
								<div class="share-buttons" style=" margin-left: -10px;">
									<ul>
										<li>
											<a role="button" :href="'https://www.facebook.com/sharer/sharer.php?u=https://slo-pitch.com/tournament/' + tournament.slug"><i class="fab fa-facebook" icon="presentation"></i></a>
											<a role="button" :href="'https://twitter.com/intent/tweet?text=Check+out+this+Slo-Pitch+National+Sanctioned+Tournament%21+https%3A%2F%2Fslo-pitch.com%2Ftournament%2F' + tournament.slug"><i class="fab fa-twitter" icon="presentation"></i></a>
											
										</li>
									</ul>
								</div>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-6">
								<div class="event-info-box">
									<div class="event-info-box-header">
										Team Entry Fee <span v-if="tournament.status === 'nationals' || tournament.status === 'provincials'">+ Tax</span> (+ Applicable SPN Membership)
									</div>
									<div class="event-info-box-content">
										<div class="entry-fee-team">
											<i class="fas fa-users"></i> ${{ tournament.team_registration_fee }}
										</div>
										<div class="entry-fee-team" v-if="tournament.individual_registration_fee">
											<i class="fas fa-user"></i> ${{ tournament.individual_registration_fee }}
										</div>
									</div>
								</div>
								<div class="event-info-box">
									<div class="event-info-box-header">
										Registration opens at:
									</div>
									<div class="event-info-box-content">
										{{ moment(tournament.registration_start_date).format('dddd MMMM DD, YYYY h:mm a') }} 
									</div>
								</div>
								<div class="event-info-box">
									<div class="event-info-box-header">
										Registration closes at:
									</div>
									<div class="event-info-box-content">
										{{ moment(tournament.registration_end_date).format('dddd MMMM DD, YYYY h:mm a') }} 
									</div>
								</div>
								<div class="event-info-box" v-if="!(tournament.region_id === 4 && (tournament.status === 'provincials' || tournament.status === 'nationals'))">
									<div class="event-info-box-header">
										Roster Locks On:
									</div>
									<div class="event-info-box-content">
										{{ moment(tournament.roster_lock_date).format('dddd MMMM DD, YYYY h:mm a') }} 
									</div>
								</div>
								<div class="event-info-box">
									<div class="event-info-box-header">
										Accepts Payment Via:
									</div>
									<div class="event-info-box-content">
										<b-tag size="is-info is-large" v-if="tournament.registrants_pay_via_spn === 1">
											Credit Card
										</b-tag>
										<b-tag size="is-info is-large" v-if="tournament.registrants_pay_via_emt === 1">
											EMT
										</b-tag>
										<b-tag size="is-warning is-large" v-if="tournament.registrants_pay_via_cash === 1">
											Cash
										</b-tag>
									</div>
								</div>
								
							</div>
							<div class="col-md-6">
								<div class="event-info-box">
									<div class="event-info-box-header">
										Divisions Offered
									</div>
									<div class="event-info-box-content">
										<div class="divisions-content">
											<table class="table" style="font-size: 18px;">
												<tr style="background: #eee;">
													<td style="text-transform: uppercase; font-size: 14px;"><b>Gender</b></td>
													<td style="text-transform: uppercase; font-size: 14px;"><b>Qualifier</b></td>
													<td style="text-transform: uppercase; font-size: 14px;"><b>Age</b></td>
													<td style="text-transform: uppercase; font-size: 14px;"><b>Max Teams</b></td>
												</tr>
												<tbody v-if="filterDivision(tournament.divisions, 'mens').length > 0">
													<tr :key="key" v-for="(division, key) in filterDivision(tournament.divisions, 'mens')">
														<td><b-tag type="is-info is-medium">Mens {{ division.rank_id }}</b-tag></td>
														<td>{{ division.qualifier }}</td>
													
													</tr>
												</tbody>
												<tbody v-if="filterDivision(tournament.divisions, 'ladies').length > 0">
													<tr :key="key" v-for="(division, key) in filterDivision(tournament.divisions, 'ladies')">
														<td><b-tag type="is-danger is-medium">Ladies {{ division.rank_id }}</b-tag></td>
														<td>{{ division.qualifier }}</td>
												
													</tr>
												</tbody>
												<tbody v-if="filterDivision(tournament.divisions, 'coed').length > 0">
													<tr :key="key" v-for="(division, key) in filterDivision(tournament.divisions, 'coed')">
														<td><b-tag type="is-warning is-medium">Coed {{ division.rank_id }}</b-tag></td>
														<td>{{ division.qualifier }}</td>
												
													</tr>
												</tbody>
												
											</table>

										</div>
									</div>
								</div>
							</div>	
						</div>
					</div>
                </div>
            </div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import moment from 'moment'
import NewRegisterToTournament from '../components/NewRegisterToTournament.vue'

import LoginRegister from '../components/LoginRegister.vue'
import NewTournamentPayment from '../components/NewTournamentPayment.vue'

import _ from 'lodash'
export default {
    metaInfo() {
        if(!this.tournament) {
            return {}
        }
		var tournament_poster_image = 'https://slo-pitch.com/uploads/sanctionedtournament.png'
		if(this.tournament.poster_image_url) {
			tournament_poster_image = 'https://slo-pitch.com/' + (this.tournament.poster_image_url)
		}

        return {
			title: this.tournament.name + " Tournament - Register Now! Limited Spots! - Slo-Pitch National",
			meta: [
				{ name: 'description', content:  this.tournament.description },
				{ property: 'og:url', content:  'https://www.slo-pitch.com/tournament/' + this.tournament.slug },
				{ property: 'og:title', content: this.tournament.name + " Tournament - Register Now! Limited Spots! - Slo-Pitch National"},
				{ name: 'og:description', content:  this.tournament.description },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: tournament_poster_image },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			sendMessage: {
				your_name: null,
				your_email: null,
				your_phone: null,
				your_message: null
			},
			buttonSelected: null,
			en: true,
            page_url: null,
            page: null,
			tournament: null,
			authUser: null,
			provincialsOrNationals: false,
			eventPaymentMade: false,
			hasHeader: true
		};
	},
	components: {
		Header,
		Footer,
		Form,
		LoginRegister,
		NewRegisterToTournament,
		NewTournamentPayment
	},
	mounted: function() {
		var fb = this.$route.query.fb
		
		if(fb === "true") {
			this.hasHeader = false
		}
		if(sessionStorage.getItem('lang')) {

            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this
		vm.page_url = this.$route.params.page
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
            }
        })


        axios.get('/api/pages/grab-tournament/' + vm.page_url).then(results => {
            if(results.data.success === true) {

				vm.tournament = results.data.tournament

				if(vm.tournament.status === 'provincials') {
					vm.provincialsOrNationals = true

				}
				if(vm.tournament.status === 'nationals') {
					vm.provincialsOrNationals = true
				}

				if(vm.tournament.status === 'rejected') {
					this.$buefy.snackbar.open({
						message: 'This tournament is not approved yet.',
						type: 'is-danger' // is-danger
					})
					window.location.href = '/'
				}
				if(vm.tournament.status === 'unapproved') {
					this.$buefy.snackbar.open({
						message: 'This tournament is not approved yet.',
						type: 'is-danger' // is-danger
					})
					window.location.href = '/'
				}
            } else {
				this.$buefy.snackbar.open({
					message: 'This tournament does not exist.',
					type: 'is-danger' // is-danger
				})
				window.location.href = '/'
				
			}
        })
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
		isInFuture: function() {
			var vm = this
			if(vm.tournament) {

				return moment(vm.tournament.registration_end_date).isAfter(moment()) && moment().isAfter(moment(vm.tournament.registration_start_date))
			}

			return false
		}
	},
	methods: {
		sendMessageNow: function() {
			var vm = this
			var validate = this.validateEmail(vm.sendMessage.your_email)

			if(validate !== true) {
				this.$buefy.snackbar.open({
					message: 'Your email is invalid',
					type: 'is-success' // is-danger
				})
			} else {
				axios.post('/api/users/send-message', {
					sendMessage: vm.sendMessage,
					id: vm.tournament.id,
					leaguetourney: 'tournament'
				}).then(results => {
					if(results.data.success === true) {
						this.$buefy.snackbar.open({
							message: 'Your message was successfully sent!',
							type: 'is-success' // is-danger
						})
					}
				})
			}
		},
        moment: function(date, format = null) {
            return moment(date, format)
		},
		validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
		filterDivision: function(divisions, filter) {
			var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
		},
		triggerAuth: function(event) {
			var vm = this

			axios.get('/auth/check').then(results => {  
				if(results.data.type === "success") {
					vm.authUser = results.data.user
              

                    // get the region rate for the umpire

                }
            })
		},
		registrationComplete: function($event) {
			var vm = this
			vm.buttonSelected = null
		}
		// createProperTableFunction(table) {
		// 	// first row is your columns
		// 	var first_row = table[0]
		// 	var all_rows = table
		// 	var columns = []
		// 	var data = []

		// 	var i = 0
		// 	for(i; i < all_rows.length; i++) {
		// 		console.log('row', all_rows[i])
		// 		var row = all_rows[i]
		// 		if(i === 0) {
		// 			// this is the first row, need to for loop again though
		// 			var s = 0

		// 			for(s; s < row.row.length; s++) {
		// 				var column = row.row[s]

		// 				columns.push({
		// 					field: column.name,
		// 					label: column.name
		// 				})
		// 			}
		// 		} else {
		// 			// this is the rest of the rows, need to loop and then call via the loop to the first row
		// 			var s = 0

		// 			var object = {}

		// 			for(s; s < row.row.length; s++) {
		// 				var column = row.row[s]

		// 				var col_name = all_rows[0].row[s].name

		// 				object[col_name] = column.name
		// 			}
		// 			data.push(object)
		// 		}
		// 	}

		// 	// every row after is your data



		// 	return {
		// 		columns: columns,
		// 		data: data,
		// 		table: table
		// 	}
		// }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
