<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        <b-modal 
            v-model="createNewPageSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            full-screen
            aria-modal>
            <template #default="props">
                <div class="modal-card">
                     <header class="modal-card-head">
                        <p class="modal-card-title">Create a New Blog Post</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
                    </header>
                
                    <section class="modal-card-body">
                        <div class="row">
                            <div class="col-md-12">
                                
                            
                                <div class="row">
                                    <div class="col-md-3">
                                        <b-field label="Post Name">
                                            <b-tooltip type="is-dark" label="This is the title for the blog post.">
                                                <b-input v-model="newPage.title"></b-input>
                                            </b-tooltip>
                                        </b-field>
                                    </div>
                                    <div class="col-md-3">
                                        <b-field label="Post Url">
                                            <b-tooltip type="is-dark" label="This is the url for the blog post.">
                                                <div class="row">
                                                    <div class="col-xs-3">
                                                        <div style="margin-top: 8px;">/blog/</div>
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <b-input v-model="newPage.slug"></b-input>
                                                    </div>
                                                </div>
                                                <p class="note">This will be automatically assigned if left blank.</p>
                                            </b-tooltip>
                                        </b-field>
                                    </div>
                                    <div class="col-md-3">
                                        <b-field label="Post Tags">
                                            <b-tooltip type="is-dark" label="This is the tags for the blog post.">
                                                <b-input v-model="newPage.tags" placeholder="news,announcements"></b-input>
                                            </b-tooltip>
                                        </b-field>
                                    </div>
                                    <div class="col-md-3">
                                        <b-field label="Post Categories">
                                            <b-tooltip type="is-dark" label="This is the categories for the blog post.">
                                                <b-input v-model="newPage.categories" placeholder="News,Press Releases"></b-input>
                                            </b-tooltip>
                                        </b-field>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="padding">
                                            <p style="margin-top: 20px;"><b>English Content:</b></p>
                                            <ckeditor :editor="editor" v-model="newPage.content" :config="editorConfig"></ckeditor>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="padding">
                                            <p style="margin-top: 20px;"><b>French Content:</b></p>
                                            <ckeditor :editor="editor" v-model="newPage.fr_content" :config="editorConfig"></ckeditor>
                                        </div>
                                    </div>
                                </div>
                                
                                

                                <button v-on:click="saveNewPost()" class="admin-button" style="margin-top: 20px;"><i class="fas fa-save"></i> Save New Post</button>
                            </div>
                        </div>
                    </section>
                </div>
            </template>
        </b-modal>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper" v-if="postSelectedForEditing === null">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Manage Blog Posts
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
                            <b-field  grouped position="is-right">
                                <a v-on:click="createNewPageSwitch = true" class="button">Create New Blog Post</a>
                            </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
                    <div class="row">
                        <div class="col-md-12">
                            
            
                            <b-table
                                :data="posts"
                                ref="table"
                                :paginated="true"
                                :current-page="1"
                                per-page="10"
                                :pagination-simple="false"
                                :pagination-position="'bottom'"
                                :opened-detailed="defaultOpenedDetails"
                                detailed
                                detail-key="id"
                                @details-open="(row) => $buefy.toast.open(`Expanded ${row.user.first_name}`)"
                                :show-detail-icon="showDetailIcon"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">

                                <b-table-column field="title" label="Post Title" sortable centered v-slot="props">
                                    {{ props.row.title }}
                                </b-table-column>
                                <b-table-column field="url" label="Post Url" sortable centered v-slot="props">
                                    /blog/{{ props.row.slug }}
                                </b-table-column>

                                <b-table-column label="Edit Post" v-slot="props">
                                    <button class="button" v-on:click="postSelectedForEditing = props.row">Edit</button>
                                </b-table-column>

                                <template slot="detail" slot-scope="props">
                                    <article class="media">
                                    
                                        <div class="media-content">
                                            <div class="content">
                                                <p>
                                                    <strong>English Preview:</strong>
                                                </p>
                                                <div v-html="props.row.content"></div>
                                                <hr>
                                                <p>
                                                    <strong>French Preview:</strong>
                                                </p>
                                                <div v-html="props.row.fr_content"></div>
                                            </div>
                                        </div>
                                    </article>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
			</div>
            <div class="content-wrapper" v-if="postSelectedForEditing">
                <h1>Edit {{ postSelectedForEditing.title }}</h1>
				<div class="row">
                    <div class="col-md-12">
  
                        <div class="row">
                            <div class="col-md-6">
                                <b-field label="Post Name">
                                    <b-tooltip type="is-dark" label="This is the title for the blog post.">
                                        <b-input v-model="postSelectedForEditing.title"></b-input>
                                    </b-tooltip>
                                </b-field>
                            </div>
                            <div class="col-md-6">
                                <b-field label="Post Url">
                                    <b-tooltip type="is-dark" label="This is the url for the blog post.">
                                        <div class="row">
                                            <div class="col-xs-3">
                                                <div style="margin-top: 8px;">/content/</div>
                                            </div>
                                            <div class="col-xs-6">
                                                <b-input v-model="postSelectedForEditing.slug"></b-input>
                                            </div>
                                        </div>
                                        
                                    </b-tooltip>
                                </b-field>
                            </div>
                        </div>
                        
                        
                        <p style="margin-top: 20px;"><b>English Content:</b></p>
                        <ckeditor :editor="editor" v-model="postSelectedForEditing.content" :config="editorConfig"></ckeditor>
                        <p style="margin-top: 20px;"><b>French Content:</b></p>
                        <ckeditor :editor="editor" v-model="postSelectedForEditing.fr_content" :config="editorConfig"></ckeditor>

                        <button class="admin-button" v-on:click="updatePost()" style="margin-top: 20px;"><i class="fas fa-save"></i> Save Post Updates</button>
                        <hr>
                        <button class="admin-button" v-on:click="goBack()"><i class="fas fa-caret-left"></i> Go Back (Unsaved)</button>
                    </div>
                </div>
            </div>
		</div>
		
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from 'axios'
export default {
	data() {

		return {
            isLoading: true,
            createNewPageSwitch: false,
            authUser: { first_name: null },
            newPage: {
                title: null,
                slug: null,
                content: null,
                fr_content: null,
                tags: null,
                categories: null
            },
            content_width: false,
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            editor: ClassicEditor,
            editorData: '<p>Rich-text editor content.</p>',
            editorConfig: {
                // The configuration of the rich-text editor.
            },
            postSelectedForEditing: null,
            posts: []
		};
	},
	components: {
		Header,
		Footer,
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_blog_posts !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }
			
                axios.get('/api/superuser/posts').then(results => {
                    if(results.data.success === true) {
                        vm.posts = results.data.posts

                        vm.isLoading = false
                    }
                })
            }
        })
	},
	computed: {
	
	},
	methods: {
        goBack: function(data){
            var r = confirm('Are you sure you want to do this? You will lose this work.')

            if(r === true) {
                this.postSelectedForEditing = null
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        saveNewPost: function() {
            var vm = this
            
            axios.post('/api/superuser/save-new-post', {
                post: vm.newPage
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.posts = results.data.posts
                    vm.$buefy.snackbar.open({
                        message: 'Saved blog post successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.createNewPageSwitch = false

                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Blog post did not save properly - please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        updatePost: function() {
            var vm = this
            
            axios.post('/api/superuser/update-post', {
                post: vm.postSelectedForEditing
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.posts = results.data.posts
                    vm.postSelectedForEditing = null
                    vm.$buefy.snackbar.open({
                        message: 'Updated blog post successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Blog post did not save properly - please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        switchWidth: function($event) {
			this.content_width = !$event
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
