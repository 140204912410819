<template>
    <span>
		<a href="/dashboard/convenors/my-tournaments" role="button" class="admin-button">
			<i class="fas fa-caret-left"></i>&nbsp;Back
		</a>
        <h2>Event Checklist</h2>
		<p>Use this checklist to make sure you're ready to go for your next tournament! Notes area can be found at the bottom! <b>Checklists will autosave when an event is selected.</b></p>
		<br><br>
		<b-field label="Select Tournament to View Checklist">
			<b-dropdown v-model="tournamentSelected" aria-role="list">
				<button class="button" slot="trigger" slot-scope="{ active }">
					<span v-if="tournamentSelected">{{ tournamentSelected.name }}</span>
					<span v-if="tournamentSelected === null">Select a Tournament</span>
					<b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
				</button>

				<b-dropdown-item :value="tournament" :key="key" v-for="(tournament, key) in tournaments" aria-role="listitem">
					<span>{{ tournament.name }}</span>
				</b-dropdown-item>
			</b-dropdown>
		</b-field>

		<div class="row" v-if="tournamentSelected">
			<div class="col-md-6" :key="key" v-for="(group, key) in filterGroup">
				<h3>{{ key }}</h3>
				<div class="field" :key="box" v-for="box in group">
					<b-checkbox size="is-medium">{{ box.name }}</b-checkbox>
				</div>
			</div>
		</div>
		<div class="row" style="margin-top: 50px;"  v-if="tournamentSelected">
			<b-field label="Notes/Personal Reminders">
				<b-input v-model="canvasAdditionalDetailsLeft"
					maxlength="200" type="textarea"></b-input>
			</b-field>
		</div>
    </span>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
	data() {
		return {
			mountCheckboxes: [],
			tournamentSelected: null,
			tournaments: []
		};
	},
	components: {
		
	},
	mounted: function() {
		var vm = this 
        axios.post('/api/tournaments/review-checklist').then(results => {
			if(results.data.success === true) {
				vm.mountCheckboxes = results.data.checklist
			}
		})

		axios.get('/api/tournaments/your-tournaments').then(results => {
            if(results.data.success === true) {
                vm.tournaments = results.data.tournaments
            }
        })
	},
	computed: {
		filterGroup: function() {
			var vm = this
			var grouped = _.groupBy(vm.mountCheckboxes, 'group')

			return grouped
		}
	},
	methods: {
		// on click of a checkb ox, autosave
		// on typing of notes, autosave
		// on first open, one may not exist, check and create one
	}
}
</script>

<style lang="scss">
    
</style>
