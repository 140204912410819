<template>
    <div>
        <div v-if="!paymentSuccess" style="max-width: 600px; margin: 0 auto;">
            <h3 style="font-size: 20px; font-weight: 700;">Your Receipt</h3>
            <div class="full-payment-box" v-if="cart">
                <div class="full-payment-reciept-area">
                    <div class="row" style="margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px solid #ddd;">
                        <div class="col-md-12" style="text-align: center;" v-if="authUser">
                            <b>{{ cart.item }} {{ authUser.region.name }}</b>
                        </div>
                    </div>
                    <div class="row" style="padding: 0 10px;">
                        <div class="col-md-6">
                            <b>Subtotal:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            $
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.subtotal }}
                        </div>
                    </div>
                    <div class="row" v-if="cart.hst_amount" style="padding: 0 10px;">
                        <div class="col-md-6">
                            <b>Tax Amount:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            $
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.hst_amount }}
                        </div>
                    </div>
                    <div class="row" v-if="cart.gst_amount" style="padding: 0 10px;">
                        <div class="col-md-6">
                            <b>GST Amount:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            $
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.gst_amount }}
                        </div>
                    </div>
                    <div class="row" v-if="cart.pst_amount" style="padding: 0 10px;">
                        <div class="col-md-6">
                            <b>PST Amount:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            $
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.pst_amount }}
                        </div>
                    </div>
                    <div class="row" style="padding: 0 10px;"  v-if="step === 0">
                        <div class="col-md-6">
                            <b>Processing Fees:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            $
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.stripe_fee_coverage }}
                        </div>
                    </div>
                    <div class="row" style="background: #eee; padding: 0 10px;" v-if="step === 0">
                        <div class="col-md-6">
                            <b>You owe:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            <b>$</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.total_after_stripe }}
                        </div>
                    </div>
                    <div class="row" style="background: #eee; padding: 0 10px;" v-if="step !== 0">
                        <div class="col-md-6">
                            <b>You owe:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            <b>$</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.total_prior_to_stripe }}
                        </div>
                    </div>
                    <div class="row"  style="background: #eee; padding: 0 10px;" v-if="cart.amount_paid">
                        <div class="col-md-6">
                            <b>You already paid:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            <b>$</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            {{ cart.amount_paid }}
                        </div>
                    </div>
                    <div class="row"  style="background: #eee; padding: 0 10px;" v-if="cart.amount_paid">
                        <div class="col-md-6">
                            <b>Total due today:</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">
                            <b>$</b>
                        </div>
                        <div class="col-md-3" style="text-align: right;">  
                            {{ cart.total_after_stripe - cart.amount_paid }}
                        </div>
                    </div>
                </div>
            </div>
            <h3 style="font-size: 20px; font-weight: 700; margin-top: 20px;">Process Your Payment</h3>
            <div class="full-payment-box">
                <b-tabs v-model="step">
                    <b-tab-item label="Pay via Credit Card" >
                        <div class="payment-processor-box">
                            <div class="row">
                                <div :class="[{ 'col-md-7': cards.length > 0 }, { 'col-md-12': cards.length === 0}]" v-if="cardSelected === null">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-field label="Card Number (16 or 17 digits)">
                                                <b-input v-model="card_number"></b-input>
                                            </b-field>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col-md-6">
                                            <b-field label="Month" :expanded="true">	
                                                <b-dropdown :expanded="true" hoverable aria-role="list" v-model="month">
                                                    <button :expanded="true" class="button is-info" slot="trigger">
                                                        <span v-if="month">
                                                            {{ month.name }}
                                                        </span>
                                                        <b-icon icon="menu-down"></b-icon>
                                                    </button>
                                                    <b-dropdown-item 
                                                        v-for="(month, key) in months"
                                                        :key="key"
                                                        :value="month" aria-role="listitem">{{ month.name }} - {{ month.value }}</b-dropdown-item>
                                                </b-dropdown>	
                                            </b-field>
                                        </div>
                                        <div class="col-md-4">
                                            <b-field label="Year">                             
                                                <b-dropdown hoverable aria-role="list" v-model="year">
                                                    <button  class="button is-info" slot="trigger">
                                                        <span>
                                                            {{ year }}
                                                        </span>
                                                        <b-icon icon="menu-down"></b-icon>
                                                    </button>
                                                    <b-dropdown-item 
                                                        v-for="(year, key) in years"
                                                        :key="key"
                                                        :value="year" aria-role="listitem">{{ year }}</b-dropdown-item>
                                                    
                                                    
                                                </b-dropdown>	
                                            </b-field>
                                        </div>
                                        <div class="col-md-2">
                                            <b-field label="CVC">
                                                <b-input v-model="cvc"></b-input>
                                            </b-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5" v-if="cards.length > 0">
                                    <div class="previously-saved-cards" >
                                        <p style="font-size: 10px; margin-bottom: 10px; border-bottom: 1px solid #ddd;"><b>PREVIOUSLY USED</b></p>
                                        <b-field :key="key" v-for="(card, key) in cards">
                                            <b-radio 
                                                v-model="cardSelected"
                                                
                                                :native-value="card"
                                                
                                                >
                                                {{ card.brand }} <span style="font-size: 10px;"><b>ends with {{ card.last4 }}</b></span>
                                            </b-radio>
                                        </b-field>
                                        <b-field>
                                            <b-radio 
                                                v-model="cardSelected"
                                                :native-value="null"
                                                >
                                                New Card</span>
                                            </b-radio>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Pay by EMT">
                        <p>Please save the below details and EMT us as soon as possible. We cannot fully process this until funds are received.</p>
                        <p><b>Please send payment to: </b>payspn@slo-pitch.com</p>
                        
                    </b-tab-item>
                    <b-tab-item label="Pay by Cheque">
                        <p>Please save the below details and send us the cheque us as soon as possible. We cannot fully process this until funds are received.</p>
                        <p><b>Please make all cheques out to: Slo-Pitch National</b></p>
                        <p>Please mail or deliver your cheque to: 9-20 Lightbeam Terrace, Brampton ON L6Y 0E3</p>
                    </b-tab-item>
                </b-tabs>        
            </div> 
            <a role="button" class="admin-button gray" v-if="submittedPayment === true" style="margin-top: 20px;"><i class="fas fa-save" ></i> Processing... please wait.</a>       
            <a role="button" class="admin-button" style="margin-top: 20px;" v-on:click="submitPaymentAttempt()" v-if="submittedPayment === false"><i class="fas fa-save" ></i> Submit Payment Info</a>       
        </div>
        <div v-if="paymentSuccess">
            <div class="payment-complete">
                <div class="icon-row">
                    <i class="far fa-check-circle"></i>
                </div>
                <div class="complete-text">
                    <div v-if="nocharge === false">
                        <div v-if="step === 0">
                            <p><b>Payment Successful.</b></p> 
                            <p class="smal">Please check your email for an invoice.</p>
                        </div>
                        <div v-if="step !== 0">
                            <p><b>Awaiting your payment.</b></p>
                            <p class="smal">Submit the form below and we will approve it as soon as funds are received.</p>
                        </div>
                    </div>
                    <div v-else>
                        <p><b>No Charge</b></p> 
                        <p class="smal">Submit the form below and we will approve it as soon as we can.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
            en: true,
            submittedPayment: false,
            step: 0,
            card_selected_id: null,
            previous_order: false,
            months: [
                { name: 'January', value: 1 },
                { name: 'February', value: 2 },
                { name: 'March', value: 3 },
                { name: 'April', value: 4 },
                { name: 'May', value: 5 },
                { name: 'June', value: 6 },
                { name: 'July', value: 7 },
                { name: 'August', value: 8 },
                { name: 'September', value: 9 },
                { name: 'October', value: 10 },
                { name: 'November', value: 11 },
                { name: 'December', value: 12 },
            ],
            month: null,
            years: [2021,2022,2023,2024,2025,2026,2027,2028,2029],
            card_number: null,
            cardSelected: null,
            cards: [],
            year: null,
            nocharge: false,
            cvc: null,
            cart: null,
            authUser: null
		};
	},
	components: {

	},
	mounted: function() {
        var vm = this

        

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                
                

                axios.get('/api/payments/previously-used-cards').then(results => {
                    if(results.data.success === true) {
                        vm.cards = results.data.cards
                    } else {

                    }
                })
            }
        })

        axios.post('/api/payments/get-product/', {
            product_id: vm.productId,
            region_id: vm.regionId
        }).then(results => {
            if(results.data.success === true) {
                vm.cart = results.data.cart
                
                // if there's a previous order
                if(vm.orderId) {
                    vm.previous_order = true
                    
                    axios.get('/api/payments/get-previous-order/'+ vm.orderId).then(results => {
                        if(results.data.success === true) {

                            vm.order = results.data.order

                            vm.cart = { 
                                item: vm.order.order_item.product.name,
                                team_registration_membership: vm.order.membership,
                                subtotal: vm.order.subtotal,
                                hst_amount: vm.order.hst_amount,
                                gst_amount: vm.order.gst_amount,
                                pst_amount: vm.order.pst_amount,
                                total_prior_to_stripe: vm.order.total_prior_to_stripe,
                                stripe_fee_coverage: vm.order.stripe_fee_coverage,
                                total_after_stripe: vm.order.total_after_stripe,
                                amount_paid: vm.order.amount_paid
                            }
                        } else {
                            // TO DO
                        }
                    })

                    // cart = {

                    // }
                }

                if(vm.cart.total_prior_to_stripe === '0.00') {
                    vm.nocharge = true

                    // need to trigger it to finalize the form and everything like if i clicked process payment
                    //needs to put the order thru without me touching anything, awaiting submission for the tournament
                    
                    if(vm.processorLocation) {
                        if(!sessionStorage.getItem('order-' + vm.processorLocation)) {
                            vm.submitPaymentAttempt(true)
                        }
                    } else {
                        if(!sessionStorage.getItem('order')) {
                            vm.submitPaymentAttempt(true)
                        }
                    }   
                }
            }
        })

        
    },
    props: ['productId', 'regionId','paymentSuccess','team','gender','processorLocation','orderId'],
    watch: {
        productId: function() {
            var vm = this
            
            axios.post('/api/payments/get-product/', {
                product_id: vm.productId,
                region_id: vm.regionId
            }).then(results => {
                if(results.data.success === true) {
                    vm.cart = results.data.cart
                }
            })
        }
    },
	computed: {
	
	},
	methods: {
		submitPaymentAttempt: function(nocharge = false) {
            var vm = this 
            vm.submittedPayment = true
            // submit the payment attempt

            // when successful send back to whatever form you're already on that it's good to go
            
            // axios post to get the card etc etc
            
            var team_id = null

            if(vm.team) {
                team_id = vm.team.team_id
            }

            var order_id = null

            if(vm.order) {
                order_id = vm.order.id
            }
            // store this temporarily in localstorage so that when they load the page again it doesn't try to charge them again
            axios.post('/api/payments/attempt-payment', {
                card_selected: vm.cardSelected,
                card_number: vm.card_number,
                month: vm.month,
                year: vm.year,
                cvc: vm.cvc,
                cart: vm.cart,
                step: vm.step,  // 0 is CC, 1 is EMT, 2 is Cheque
                product_id: vm.productId,
                region_id: vm.regionId,
                team: team_id,
                gender: vm.gender,
                nocharge: nocharge,
                user_id: null,
                previous_order: vm.previous_order,
                order_id: order_id
            }).then(results => {
                
                if(results.data.success === true) {
                    // if payment attempt comes back successful for any given reason, move forward
                    vm.paymentSuccess = true
                    vm.$emit('payment:successful', { 
                        order: results.data.order,
                    })

                    vm.$buefy.snackbar.open({
                        message: 'Payment submission was successful!',
                        type: 'is-success' // is-danger
                    })
                } else {
                    vm.submittedPayment = false
                    vm.$buefy.snackbar.open({
                        message: 'Payment submission failed. Please make sure your card is accurate.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>