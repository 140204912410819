<template>
    <span>
		<b-tabs v-model="activeTab">
            <b-tab-item label="Events You're Umpiring">
				<h2>Events You're Umpiring</h2>
                
                <p>These are events that the convenor or umpire in chief have marked you as part of the umpiring team.</p>
				<b-table 
					:data="sample_table_data" 
					checkable
					:sticky-header="true"
					:striped="true"
					:focusable="true"
					paginated
					per-page="3"
				>
					<template slot-scope="props">
						<b-table-column field="start_date" label="Start Date"  :header-class="'is-sticky-column-one'" :cell-class="'is-sticky-column-one'" :sticky="true" >
							<template slot="header" slot-scope="{ column }">
								<b-tooltip :label="column.label" dashed>
									{{ column.label }}
								</b-tooltip>
							</template>
							<span class="tag is-light">
								<b>{{ moment(props.row.start_date).format('MMMM DD, YYYY') }}</b>
							</span>
						</b-table-column>
						<b-table-column field="city" label="City">
							<template slot="header" slot-scope="{ column }">
								<b-tooltip :label="column.label" dashed>
									{{ column.label }}
								</b-tooltip>
							</template>
							{{ props.row.city }}
						</b-table-column>
						<b-table-column field="actions" label="Actions">
							<template slot="header" slot-scope="{ column }">
								<b-tooltip :label="column.label" dashed>
									{{ column.label }}
								</b-tooltip>
							</template>
							<b-dropdown hoverable aria-role="list">
								<button class="button is-info" slot="trigger">
									<span>Actions</span>
									<b-icon icon="menu-down"></b-icon>
								</button>

								<b-dropdown-item aria-role="listitem">Register For Clinic</b-dropdown-item>
								
							</b-dropdown>
						</b-table-column>
		
					</template>
				</b-table>
            </b-tab-item>
			<b-tab-item label="Find Gigs">
				<h2>Find Gigs Near You</h2>
				<p>This is a list of upcoming tournaments and leagues near you.</p>
            </b-tab-item>
		</b-tabs>
    </span>
</template>

<script>

import moment from 'moment'
export default {
	data() {
		return {
			sample_table_data: [
				{"id":1,"city": "Hamilton", "start_date":"2016/10/15 13:43:27"},
				{"id":1,"city": "Hamilton","start_date":"2016/10/15 13:43:27"},
			],
		};
	},
	components: {
		
	},
	mounted: function() {
        
	},
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
            return moment(date, format)
        }
	}
}
</script>

<style lang="scss">
    
</style>
