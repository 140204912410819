<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Create a Tournament
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a href="/dashboard/convenors/my-tournaments" class="button">Back to My Tournaments</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>				
				<div class="admin-area">
					<ValidationObserver v-slot="{ handleSubmit }" ref="form">
						<form @submit.prevent="handleSubmit(submitYourTournament)">
							<b-steps
								:animated="true"
								type="is-danger"
								:rounded="true"
								v-model="registrationStep"
								:has-navigation="true">
						
								<b-step-item step="1" label="Basics" :clickable="false">
									<h2 class="title">Tournament Basics <span v-if="duplicating">- Duplicating Previous Event</span></h2>
									<div class="row">
										<div class="col-md-6">
											<div class="padding">
												
												<b-field label="Headquarter Location">
													<ValidationProvider v-slot="{ errors }" rules="required|min:4" name="headquarter_location"> 
														<b-input icon-pack="fas"
															icon="street-view"
															expanded
															v-model="newTournament.headquarter_location"></b-input>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
													
												</b-field>
												<b-field label="City" >
												<ValidationProvider v-slot="{ errors }" rules="required|min:4" name="city"> 
													<b-input icon-pack="fas"
														icon="street-view"
														expanded
														v-model="newTournament.city"></b-input>
													<span class="help is-danger">{{ errors[0] }}</span>
													
												</ValidationProvider>
											</b-field>
										
											</div>
										</div>
										<div class="col-md-6">
											<div class="padding">
												<b-field label="Region">
													<b-dropdown disabled hoverable aria-role="list" v-model="newTournamentRegion">
														<div class="button is-info" slot="trigger">
															<span v-if="newTournamentRegion">
																{{ newTournamentRegion.name }}
															</span>
															<span v-if="!newTournamentRegion">
																Select your Headquarters
															</span>
															<b-icon icon="menu-down"></b-icon>
														</div>

														<b-dropdown-item :key="key" :value="region" v-for="(region, key) in regions" aria-role="listitem">{{ region.name }}</b-dropdown-item>
													</b-dropdown>	
												</b-field>
											
											</div>
										</div>
									</div>
									<div class="row row-margin">
										<div class="col-md-3">
											<div class="padding">
												<b-field label="Tournament Name">
													<ValidationProvider v-slot="{ errors }" rules="required|min:4" name="tournament_name"> 
														<b-input v-model="newTournament.name"></b-input>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>
										<div class="col-md-3">
											<div class="padding">
												<b-field label="Tournament Name (FR)">
													<b-input v-model="newTournament.fr_name"></b-input>
												</b-field>
											</div>
										</div>
										<div class="col-md-3">
											<div class="padding">
												<b-field label="Qualifier">
													<b-select v-model="newTournament.qualifier">
														<option value="1">Sanctioned Qualifier</option>
														<option value="2">Sanctioned Non-Qualifier</option>
														<option value="5">League Tournament</option>
													</b-select>
												</b-field>
											</div>
										</div>
										<div class="col-md-3">
											
										</div>
									</div>

									<div class="row row-margin">
										<div class="col-md-4">
											<div class="padding">
												<b-field label="Tournament Start Date">
													<ValidationProvider rules="required|dateDiff:@tournament_end_date" name="tournament_date" v-slot="{ errors }">
														<b-datetimepicker
															placeholder="Click to select..."
															icon="calendar-today"
															v-model="newTournament.event_start_date"
															
															:min-datetime="minDate"
															
														>
														</b-datetimepicker>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												</b-field>
												<b-checkbox v-model="newTournament.overnight"><b>Overnight Tournament</b></b-checkbox>
											</div>
										</div>
										<div class="col-md-4">
											<div class="padding">
												<b-field label="Tournament End Date">
													<ValidationProvider name="tournament_end_date" rules="required" v-slot="{ errors }">
														<b-datetimepicker
															placeholder="Click to select..."
															icon="calendar-today"
															v-model="newTournament.event_end_date"
															:min-datetime="minDate"
														>
														</b-datetimepicker>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>
							
									</div>

									<div class="row row-margin">
										<div class="col-md-4">
											<div class="padding">
												<b-field label="Registration Start Date">
													<ValidationProvider rules="required|dateDiff:@reg_end_date" name="reg_date" v-slot="{ errors }">
														<b-datetimepicker
															placeholder="Click to select..."
															icon="calendar-today"
															v-model="newTournament.registration_start_date"
															:min-datetime="minDate"
														>
														</b-datetimepicker>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												</b-field>
												<b-field label="Roster Lock Date">
													<ValidationProvider rules="required" name="roster_lock_date" v-slot="{ errors }">
														<b-datetimepicker
															placeholder="Click to select..."
															icon="calendar-today"
															v-model="newTournament.roster_lock_date"
															:min-datetime="minDate"
														>
														</b-datetimepicker>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>
										<div class="col-md-4">
											<div class="padding">
												<b-field label="Registration End Date">
													<ValidationProvider name="reg_end_date" rules="required" v-slot="{ errors }">
														<b-datetimepicker
															placeholder="Click to select..."
															icon="calendar-today"
															v-model="newTournament.registration_end_date"
															:min-datetime="minDate"
														>
														</b-datetimepicker>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>
									</div>

									<div class="row row-margin">
										<div class="col-md-6">
											<div class="padding">
												<b-field label="Team Price">
													<ValidationProvider name="team_price" rules="required" v-slot="{ errors }">
														<b-input 
															v-model="newTournament.team_price"
															icon-pack="fas"
															icon="dollar-sign"
														></b-input>
														<span class="help is-danger">{{ errors[0] }}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>
										<div class="col-md-6">
									
										</div>
									</div>
									<div class="row row-margin">
										<div class="col-md-6">
											<div class="padding">
												<b-field extended label="Tournament Description">
													<b-input v-model="newTournament.description" maxlength="2000" style="width: 100%;" type="textarea"></b-input>
												</b-field>
											</div>
										</div>
									</div>
									<b-field label="Will you be selling alcohol?">
										<div class="block">
											<b-radio v-model="newTournament.selling_alcohol"
												name="selling_alcohol"
												native-value="true">
												Yes
											</b-radio>
											<b-radio v-model="newTournament.selling_alcohol"
												name="selling_alcohol"
												native-value="false">
												No
											</b-radio>
										</div>
									</b-field>
									<div class="row">
										<div class="col-md-4">
											<b-field label="Max Roster Size per team (default is 16)">
												<div class="block">
													<b-numberinput v-model="newTournament.max_roster_size"></b-numberinput>
												</div>
											</b-field>
										</div>
									</div>
									<b-field label="What methods of payment will you accept for this event?">
										<div class="block">
											<ValidationProvider name="payment_methods" rules="required" v-slot="{ errors }">
												
												<b-checkbox name="emt" v-model="newTournament.reg_payment" 
												native-value="emt">
													Via EMT
												</b-checkbox>
												<b-checkbox name="cash" v-model="newTournament.reg_payment" 
												native-value="cash">
													Cash on Event Day
												</b-checkbox>
												<span class="help is-danger">{{ errors[0] }}</span>
											</ValidationProvider>
										</div>
									</b-field>
									<b-field label="Is this event invitation-only? Clicking yes will hide this event from our Tournament Calendar">
										<div class="block">
											<b-radio v-model="newTournament.invitation_only"
												name="invitation_only"
												native-value="true">
												Yes
											</b-radio>
											<b-radio v-model="newTournament.invitation_only"
												name="invitation_only"
												native-value="false">
												No
											</b-radio>
										</div>
									</b-field>
									<div class="via-emt" v-if="newTournament.registrants_pay_via_emt">
										<p style="font-size: 20px;"><b>Payment via EMT</b></p>
										<b-field label="Your EMT Email" style="margin-top: 10px;">
											
												<b-input 
													v-model="newTournament.emt_email"
													icon-pack="fas"
													icon="at">
												</b-input>
											
										</b-field>
										<b-field label="Your Preferred EMT Password">
											
												<b-input 
													v-model="newTournament.emt_password"
													icon-pack="fas"
													icon="lock">
												</b-input>
											
										</b-field>
									</div>
									
									<b-field label="Do you require a deposit for team registration?">
									
										<div class="block">
											<b-radio v-model="newTournament.deposit_required"
												name="deposit_required"
												:native-value="true">
												Yes
											</b-radio>
											<b-radio v-model="newTournament.deposit_required"
												name="deposit_required"
												:native-value="false">
												No
											</b-radio>
										</div>
									</b-field>
									<div class="deposit-amount" v-if="newTournament.deposit_required">
										<b-field label="Deposit Amount">
											
												<b-input v-model="newTournament.deposit_amount"
														icon-pack="fas"
														icon="dollar-sign"></b-input>
											
										</b-field>
									</div>
								
									<div class="row row-margin">
										<div class="col-md-12">
											
										</div>
									</div>
														
								</b-step-item>
							
								<b-step-item step="2" label="Divisions" :clickable="false">
									<h1 class="title has-text-centered">Select Your Divisions</h1>
									<div class="selected-divisions">
										<div class="row">
											<div class="col-md-12">
												<h2 class="">Mens</h2>

								
												<b-dropdown hoverable aria-role="list" v-model="newTournament.mens_divisions" multiple>
													<div class="button is-info" slot="trigger">
														<span>
															Select Men's Division
														</span>
														<b-icon icon="menu-down"></b-icon>
													</div>

													<b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
												</b-dropdown>
												<nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.mens_divisions">
													<div class="panel-heading">
														<div class="row">
															<div class="col-md-7">
																<b>{{ division.name }} Division</b>
															</div>
															<div class="col-md-5">
																<b-field label="Qualifier Type" horizontal custom-class="is-small">	
																		<b-dropdown hoverable aria-role="list" v-model="division.qualifier">
																			<div class="button is-info" slot="trigger">
																				<span>
																					{{ division.qualifier }}
																				</span>
																				<b-icon icon="menu-down"></b-icon>
																			</div>

																			<b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
																				Provincial Qualifer
																			</b-dropdown-item>
																			<b-dropdown-item value="National Qualifer" aria-role="listitem">
																				National Qualifer
																			</b-dropdown-item>
																			<b-dropdown-item value="Non-Qualifer" aria-role="listitem">
																				Non-Qualifer
																			</b-dropdown-item>
																		</b-dropdown>
																</b-field>
															</div>
														</div>
													</div>
													

													<div class="panel-block" style="display: block;">
														<div class="row">
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum # of Teams" custom-class="is-small">
																		<b-numberinput type="is-info" min="3" v-model="division.min_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Max # of Teams" custom-class="is-small">
																		<b-numberinput type="is-info" min="3" v-model="division.max_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum Age" custom-class="is-small">
																		<b-numberinput min="16" type="is-info" max="99" v-model="division.min_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Maximum Age" custom-class="is-small">
																		<b-numberinput min="16"  type="is-info" max="99" v-model="division.max_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
														</div>
													</div>
												</nav>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<h2 class="">Ladies</h2>
												
												<b-dropdown hoverable aria-role="list" v-model="newTournament.ladies_divisions" multiple>
													<div class="button is-primary" slot="trigger">
														<span>
															Select Ladies Divisions
														</span>
														<b-icon icon="menu-down"></b-icon>
													</div>

													<b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
												</b-dropdown>
												<nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.ladies_divisions">
													<div class="panel-heading">
														<div class="row">
															<div class="col-md-7">
																<b>{{ division.name }} Division</b>
															</div>
															<div class="col-md-5">
																<b-field label="Qualifier Type" horizontal custom-class="is-small">
																		<b-dropdown hoverable aria-role="list" v-model="division.qualifier">
																			<div class="button is-primary" slot="trigger">
																				<span>
																					{{ division.qualifier }}
																				</span>
																				<b-icon icon="menu-down"></b-icon>
																			</div>

																			<b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
																				Provincial Qualifer
																			</b-dropdown-item>
																			<b-dropdown-item value="National Qualifer" aria-role="listitem">
																				National Qualifer
																			</b-dropdown-item>
																			<b-dropdown-item value="Non-Qualifer" aria-role="listitem">
																				Non-Qualifer
																			</b-dropdown-item>
																		</b-dropdown>
																</b-field>
															</div>
														</div>
													</div>
													

													<div class="panel-block" style="display: block;">
														<div class="row">
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum # of Teams" custom-class="is-small">
																		<b-numberinput v-model="division.min_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Max # of Teams" custom-class="is-small">
																		<b-numberinput v-model="division.max_teams"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Minimum Age" custom-class="is-small">
																		<b-numberinput min="16" v-model="division.min_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-3">
																<div class="padding">
																	<b-field label="Maximum Age" custom-class="is-small">
																		<b-numberinput min="16" v-model="division.max_age"></b-numberinput>
																	</b-field>
																</div>
															</div>
														</div>
													</div>
												</nav>
											</div>
											<div class="row">
												<div class="col-md-12">
													<h2 class="">Coed</h2>
													<b-dropdown hoverable aria-role="list" v-model="newTournament.coed_divisions" multiple>
														<div class="button is-danger" slot="trigger">
															<span>
																Select Coed Divisions
															</span>
															<b-icon icon="menu-down"></b-icon>
														</div>

														<b-dropdown-item :key="key" :value="division" v-for="(division, key) in divisions" aria-role="listitem">{{ division.name }}</b-dropdown-item>
													</b-dropdown>
													<nav class="panel" style="margin-top: 20px;" :key="key" v-for="(division, key) in newTournament.coed_divisions">
														<div class="panel-heading">
															<div class="row">
																<div class="col-md-7">
																	<b>{{ division.name }} Division</b>
																</div>
																<div class="col-md-5">
																	<b-field label="Qualifier Type" horizontal custom-class="is-small">
																		
																			<b-dropdown hoverable aria-role="list" v-model="division.qualifier">
																				<div class="button is-danger" slot="trigger">
																					<span>
																						{{ division.qualifier }}
																					</span>
																					<b-icon icon="menu-down"></b-icon>
																				</div>

																				<b-dropdown-item value="Provincial Qualifer" aria-role="listitem">
																					Provincial Qualifer
																				</b-dropdown-item>
																				<b-dropdown-item value="National Qualifer" aria-role="listitem">
																					National Qualifer
																				</b-dropdown-item>
																				<b-dropdown-item value="Non-Qualifer" aria-role="listitem">
																					Non-Qualifer
																				</b-dropdown-item>
																			</b-dropdown>
																		
																	</b-field>
																</div>
															</div>
														</div>
														

														<div class="panel-block" style="display: block;">
															<div class="row">
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Minimum # of Teams" min="3" custom-class="is-small">
																			<b-numberinput type="is-danger" v-model="division.min_teams"></b-numberinput>
																		</b-field>
																	</div>
																</div>
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Max # of Teams" custom-class="is-small">
																			<b-numberinput type="is-danger" min="3" v-model="division.max_teams"></b-numberinput>
																		</b-field>
																	</div>
																</div>
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Minimum Age" custom-class="is-small">
																			<b-numberinput type="is-danger" min="16" max="99" v-model="division.min_age"></b-numberinput>
																		</b-field>
																	</div>
																</div>
																<div class="col-md-3">
																	<div class="padding">
																		<b-field label="Maximum Age" custom-class="is-small">
																			<b-numberinput type="is-danger" min="16" max="99" v-model="division.max_age"></b-numberinput>
																		</b-field>
																	</div>
																</div>
															</div>
														</div>
													</nav>
												</div>
											</div>
										</div>
									</div>
								</b-step-item>
							
								<b-step-item step="3" :visible="true" label="Marketing" :clickable="false">
									<div class="row">
										<div class="col-md-6">
											<h2>Marketing</h2>
											<div class="padding">
												<b-field label="Do you want your name advertised on the tournament calendar so potential teams can contact you?">
													<b-select placeholder="Select a name" v-model="newTournament.name_advertised">
														<option
															:value="true">
															Yes
														</option>
														<option
															:value="false">
															No
														</option>
													</b-select>
												</b-field>
												<b-field v-if="newTournament.name_advertised" label="Enter your email you prefer to be contacted at for registrations:">
													<b-input v-model="newTournament.preferred_email"></b-input>
												</b-field>
												<b-field v-if="newTournament.name_advertised" label="Enter your phone number you prefer to be contacted at for registrations:">
													<b-input v-model="newTournament.preferred_phone"></b-input>
												</b-field>
												<hr>
												<b-field label="Your Website URL">
													<b-input v-model="newTournament.website_url"></b-input>
												</b-field>
												<b-field label="Your Facebook Event URL">
													<b-input v-model="newTournament.facebook_event_url"></b-input>
												</b-field>
												<b-field label="Your Facebook Group/Page URL">
													<b-input v-model="newTournament.facebook_group_url"></b-input>
												</b-field>
												
											</div>
										</div>
										<div class="col-md-6" style="text-align: center;">
											<h2>Your SPN Event Preview</h2>	
											<div class="custom-event-area" style="text-align: center;">
												<b-field>
													<b-upload
														multiple
														expanded
														drag-drop>
														<section class="section">
															<div class="content has-text-centered">
																<p>
																	<b-icon
																		icon="upload"
																		size="is-large">
																	</b-icon>
																</p>
																<p>Upload a poster here if you have one! If you don't, you can use the poster generator after you finish creating your event.</p>
															</div>
														</section>
													</b-upload>
												</b-field>
												<div class="custom-event-box">
													<div class="custom-tournament-name">
														{{ newTournament.name }}
													</div>
													<div class="custom-tournament-description">
														{{ newTournament.description }}
													</div>
													<div class="custom-tournament-divisions">
														<div class="custom-tournament-divisions-header">
															Divisions Offered:
														</div>
														<div class="row">
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Men's
																</div>
																<b-tag
                                                    				type="is-danger" :key="key" v-for="(division, key) in newTournament.mens_divisions">
																	
																		{{ division.name }}
																	
																</b-tag>
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Ladies
																</div>
																<b-tag
                                                    				type="is-danger" :key="key" v-for="(division, key) in newTournament.ladies_divisions">
																	
																		{{ division.name }}
																	
																</b-tag>
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Coed
																</div>
																<b-tag
                                                    				type="is-danger"  :key="key" v-for="(division, key) in newTournament.coed_divisions">
																	<div class="custom-tournament-division">
																		{{ division.name }}
																	</div>
																</b-tag>
															</div>
														</div>
													</div>
													<div class="custom-tournament-entry-fees">
														<div class="entry-fee-text">
															Entry Fee:
														</div>
														<div class="entry-fee-team">
															<i class="fas fa-users"></i> ${{ newTournament.team_price }}
														</div>
														<div class="entry-fee-team" 		v-if="newTournament.individual_price">
															<i class="fas fa-user"></i> ${{ newTournament.individual_price }}
														</div>
													</div>
												</div>
								
											</div>
										</div>
									</div>
									
									
								</b-step-item>
								
								<b-step-item step="4" :visible="true" label="Finish &amp; Confirm" :clickable="false">
									<h1 class="title has-text-centered">Finish &amp; Confirm</h1>
									<div class="row">
										<div class="col-md-7">
											<div class="payment-box" v-if="newTournamentRegion">
												
												<NewPaymentProcessor
													v-if="authUser !== null"
													:productId="computedProduct"
													:regionId="newTournamentRegion.id"
													:tournamentName="newTournament.name"
													v-on:payment:successful="paymentSuccessSwitch($event)"
													>
												</NewPaymentProcessor>
												<b-message type="is-danger" has-icon aria-close-label="Close message">
													<b>After submitting your payment, please press the "FINAL STEP: Submit Your Tournament For Approval" button!</b>
												</b-message>
												<a class="admin-button" role="button" v-if="paymentSuccess && !submittingTournament" style="margin-top: 10px;" v-on:click="completeTournamentSubmission()"><i class="fas fa-save"></i>FINAL STEP: Submit Your Tournament For Approval</a>

												<a class="admin-button" role="button" v-if="paymentSuccess && submittingTournament" style="margin-top: 10px;"><i class="fas fa-save"></i>Submitting... please wait</a>
												
											</div>
											
										</div>
										<div class="col-md-5">
											<div class="custom-event-area" style="text-align: center;">
												<div class="custom-event-box">
													<div class="custom-tournament-name">
														{{ newTournament.name }}
													</div>
													<div class="custom-tournament-description">
														{{ newTournament.description }}
													</div>
													<div class="custom-tournament-divisions">
														<div class="custom-tournament-divisions-header">
															Divisions Offered:
														</div>
														<div class="row">
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Men's
																</div>
																<b-tag
                                                    			type="is-danger" :key="key" v-for="(division, key) in newTournament.mens_divisions">
																	<div class="custom-tournament-division">
																		{{ division.name }}
																	</div>
																</b-tag>
																
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Ladies
																</div>
																<b-tag
                                                    				type="is-danger"  :key="key" v-for="(division, key) in newTournament.ladies_divisions">
																	<div class="custom-tournament-division">
																		{{ division.name }}
																	</div>
																</b-tag>
															</div>
															<div class="col-md-4">
																<div class="custom-tournament-division-header">
																	Coed
																</div>
																<b-tag
                                                    				type="is-danger" :key="key" v-for="(division, key) in newTournament.coed_divisions">
																	<div class="custom-tournament-division">
																		{{ division.name }}
																	</div>
																</b-tag>
															</div>
														</div>
													</div>
													<div class="custom-tournament-entry-fees">
														<div class="entry-fee-text">
															Entry Fee:
														</div>
														<div class="entry-fee-team">
															<i class="fas fa-users"></i> ${{ newTournament.team_price }}
														</div>
														<div class="entry-fee-team" v-if="newTournament.individual_price">
															<i class="fas fa-user"></i> ${{ newTournament.individual_price }}
														</div>
													</div>
												</div>
											
											</div>
										</div>
									</div>
								</b-step-item>	

								<template
									slot="navigation"
									slot-scope="{previous, next}">
									<b-button
										outlined
										v-if="registrationStep !== 0"
										type="is-danger"
										icon-pack="fas"
										icon-left="backward"
										:disabled="previous.disabled"
										@click.prevent="previous.action">
										Previous Step
									</b-button>
									<b-button
										outlined
										v-if="registrationStep !== 3"
										type="is-success"
										icon-pack="fas"
										icon-right="forward"
										:disabled="next.disabled"
										native-type="submit"
										>
										Next Step
									</b-button>
								</template>
							</b-steps>
						</form>
					</ValidationObserver>
				</div>
				<div class="page-errors" v-if="validation_errors.length > 0">
					<div class="page-error-header">Please fix these errors before going to the next step!</div>
					<div class="page-error" v-for="(validation_error, key) in validation_errors" :key="key">
						{{ validation_error.reason }}
					</div>
				</div>	

			</div>
		</div>
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import validation from './../../../validation'

import PaymentProcessor from '../../components/PaymentProcessor.vue'
import NewPaymentProcessor from '../../components/NewPaymentProcessor.vue'
import axios from 'axios'
import moment from 'moment'

export default {
	data() {
		const today = new Date()
		return {
			page: null,
			content_width: false,
			autocompleteAddress: null,
			newTournamentRegion: null,
			duplicating: false,
			newTournament: {
				invitation_only: false,
				max_roster_size: 16,
				name: null,
				fr_name: null,
				qualifier: 1,
				event_start_date: null,
				event_end_date: null,
				deposit_required: false,
				deposit_amount: null,
				overnight: false,
				registration_start_date: null,
				registration_end_date: null,
				roster_lock_date: null,
				description: '',
				headquarter_location: null,
				headquarter_google_address: null,
				team_price: null,
				individual_price: null,
				selling_alcohol: false,
				mens_divisions: [],
				ladies_divisions: [],
				coed_divisions: [],
				city: null,
				website_url: null,
				facebook_event_url: null,
				facebook_group_url: null,
				method_of_payment: 'card',
				registrants_pay_via_spn: false,
				registrants_pay_via_emt: false,
				registrants_pay_via_cash: false,
				emt_email: null,
				emt_password: null,
				reg_payment: [],
				name_advertised: false,
				preferred_email: null,
				preferred_phone: null
			},
			validation_errors: [],
			submittingTournament: false,
			regions: [],
			region_rate: null,
			divisions: [],
			authUser: null,
			paymentSuccess: false,
			registrationStep: 0,
			minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
		};
	},
	components: {
		Header,
		Footer,
		ValidationProvider,
		ValidationObserver,
		PaymentProcessor,
		NewPaymentProcessor
	},
	watch: {
		newTournamentRegion: function() {
			this.getRegionRate()
		}
	},
	mounted: function() {
		var vm = this
		this.page = this.$route.params.page

		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
				vm.authUser = results.data.user

				axios.get('/api/tournaments/regions').then(results => {
					vm.regions = results.data.regions

					var region_data = _.find(vm.regions, function(region) {
						if(region.id === vm.authUser.default_region_id) {
							return true
						}
					})

					vm.newTournamentRegion = region_data
				})
			}
		})

		if(sessionStorage.getItem('order-tourney')) {
			var json = sessionStorage.getItem('order-tourney')
			vm.paymentSuccess = JSON.parse(json)
		}

			
		axios.get('/api/tournaments/regions').then(results => {
			vm.regions = results.data.regions
		})

		axios.get('/api/tournaments/divisions').then(results => {
			vm.divisions = results.data.divisions
		})

		axios.get('/api/tournaments/default-tournament-settings').then(results => {
			var tourney_settings = results.data.tournament_settings
			if(tourney_settings) {
				vm.newTournament.team_price = tourney_settings.team_registration_fee
				vm.newTournament.individual_price = tourney_settings.player_registration_fee
				vm.newTournament.emt_password = tourney_settings.emt_password
				vm.newTournament.emt_email = tourney_settings.emt_email
				vm.newTournament.website_url = tourney_settings.website_url
				vm.newTournament.facebook_group_url = tourney_settings.facebook_group_url
				vm.newTournament.deposit_amount = tourney_settings.deposit_amount
				vm.newTournament.deposit_required = tourney_settings.deposit_required === 1 ? true : false
				vm.newTournament.registrants_pay_via_cash = tourney_settings.registrants_pay_via_cash === 1 ? true : false
				vm.newTournament.registrants_pay_via_emt = tourney_settings.registrants_pay_via_emt === 1 ? true : false
				vm.newTournament.registrants_pay_via_spn = tourney_settings.registrants_pay_via_spn === 1 ? true : false
				vm.newTournament.mens_divisions = tourney_settings.mens_divisions
				vm.newTournament.ladies_divisions = tourney_settings.ladies_divisions
				vm.newTournament.coed_divisions = tourney_settings.coed_divisions
			}
		})

		if(this.$route.query.d) {
			var d = this.$route.query.d
			
			axios.get(`/api/tournaments/get-tournament/${d}`).then(results => {
				
				if(results.data.success === true) {				
					// vm.newTournament = results.data.tournament
					var tournament = results.data.tournament
					vm.duplicating = tournament.id
					vm.newTournament.name = tournament.name
					// vm.newTournament.event_start_date = moment(tournament.event_start_date)
					vm.newTournament.team_price = tournament.team_price
					vm.newTournament.individual_price = tournament.individual_price
					vm.newTournament.event_start_date = new Date(tournament.event_start_date)
					vm.newTournament.event_end_date = new Date(tournament.event_end_date)
					if(tournament.registration_start_date) {
						vm.newTournament.registration_start_date = new Date(tournament.registration_start_date)
					}
					if(tournament.registration_end_date) {
						vm.newTournament.registration_end_date = new Date(tournament.registration_end_date)
					}
					if(tournament.roster_lock_date) {
						vm.newTournament.roster_lock_date = new Date(tournament.roster_lock_date)
					}
					
					var divisions = results.data.tournament.divisions
					if(divisions) {
						if(divisions.length > 0) {
							divisions.map(division => {
								
								division.name = division.rank_id
								division.min_teams = division.min_number_of_teams
								division.max_teams = division.max_number_of_teams
								return
							})

							var coed_divisions = divisions.filter(division => {
								return division.gender_slug === 'coed'
							})
							vm.newTournament.coed_divisions = coed_divisions

							var mens_divisions = divisions.filter(division => {
								return division.gender_slug === 'mens'
							})
							vm.newTournament.mens_divisions = mens_divisions

							var ladies_divisions = divisions.filter(division => {
								return division.gender_slug === 'ladies'
							})
							vm.newTournament.ladies_divisions = ladies_divisions

							// console.log('filter', filter)
						}
					}
					
					var i = 0
					
					
					axios.post('/api/tournaments/region-rate', {
						region: tournament.region_id
					}).then(results => {
						if(results.data.success === true) {
							vm.region_rate = results.data.region_rate
						}
					})

					
					// finish this a little later
				}
				// more needs to be loaded... propbaby region_rate
			})
		}

	},
	computed: {
		computedProduct: function() {
			var vm = this

			
			if(parseInt(this.newTournament.qualifier) === 1) {
				return 3
			}
			if(parseInt(this.newTournament.qualifier) === 2){
				return 2
			}
			if(parseInt(this.newTournament.qualifier) === 5) {
				return 12
			}
        }
	},
	methods: {
		getRegionRate: function() {
			var vm = this
			axios.post('/api/tournaments/region-rate', {
				region: vm.newTournamentRegion.id
			}).then(results => {
				if(results.data.success === true) {
					vm.region_rate = results.data.region_rate
				}
			})
		},
		getAddressData: function (addressData, placeResultData, id) {

		},
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.newTournament.headquarter_location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'AB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'YT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'BC') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			var street_number_long_name = null

			if(street_number) {
				street_number_long_name = street_number.long_name
			}

			var route_long_name = null

			if(route) {
				route_long_name = route.long_name
			}

			var written_address_new = null
			var street_address_new = null
			var city_new = null
			var province_new = null
			var latitude_new = null
			var longitude_new = null
			var postal_code_new = null

			if(place) {
				written_address_new = place.formatted_address
			}

			if(street_number_long_name) {
				street_address_new = street_number_long_name + ' ' + route_long_name
			}

			if(city) {
				city_new = city.long_name
			}

			if(postal_code) {
				postal_code_new = postal_code.long_name
			}

			if(province) {
				province_new = province.short_name
			}

			if(place) {
				latitude_new = place.geometry.location.lat()
			}

			if(place) {
				longitude_new = place.geometry.location.lng()
			}

			vm.newTournament.headquarter_google_address = {
				written_address: written_address_new,
				street_address: street_address_new,
				city: city_new,
				province: province_new,
				postal_code: postal_code_new,
				latitude:  latitude_new,
				longitude: longitude_new
			}

			// set province to region  -- newTournamentRegion - need to only send the shortcode and then match them here (some provinces will have to apply to multiple areas)
				// maritimes == NS, PEI, NB

				

			// var i = 0
			// var string = ''
			// for(i; i < place.address_components.length; i++) {
			// 	if(place.address_components[i].types[0] !== 'subpremise') {
			// 		string += place.address_components[i].long_name + ', '
			// 	}
			// }
			// this.newTournament.headquarter_location = string
			// console.log(string)
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		completeTournamentSubmission: function() {
			var vm = this
			this.submittingTournament = true

			axios.post('/api/tournaments/save-new-tournament', {
				tournament: vm.newTournament,
				duplicating: vm.duplicating,
				region: vm.newTournamentRegion,
				order_id: vm.paymentSuccess.order.id
			}).then(results => {
				if(results.data.success === true) {
					if(sessionStorage.getItem('order-tourney')) {
						sessionStorage.removeItem('order-tourney')
					}
					
					vm.$router.push({ path: '/dashboard/convenors/my-tournaments'  })
					this.$buefy.snackbar.open({
                        message: 'Sent the tournament for approval! You will receive an email soon.',
                        type: 'is-success', // is-danger
                        duration: 5000,
                        queue: false
                    })

					this.submittingTournament = false
				} else {
					this.$buefy.snackbar.open({
                        message: 'Something went wrong when submitting your tournament! Contact us.',
                        type: 'is-danger', // is-danger
                        duration: 5000,
                        queue: false
                    })
					this.submittingTournament = false
				}
			}).catch(err => {
				this.$buefy.snackbar.open({
					message: 'Something went wrong when submitting your tournament!',
					type: 'is-danger', // is-danger
					duration: 5000,
					queue: false
				})
			})
		},
		
		submitYourTournament: function() {
			var vm = this

			vm.$refs.form.validate().then(success => {
				if (!success) {
					return;
				}
				vm.registrationStep++

				if(vm.registrationStep === 2) {

					var divisions = 0

					divisions = divisions + vm.newTournament.mens_divisions.length
					divisions = divisions + vm.newTournament.ladies_divisions.length
					divisions = divisions + vm.newTournament.coed_divisions.length
					
					if(divisions === 0) {
						vm.registrationStep = 1

						this.$buefy.snackbar.open({
							message: 'You need to add at least one division',
							type: 'is-danger', // is-danger
							duration: 5000,
							queue: false
						})
					}
				}
				
				if(vm.registrationStep === 3) {
					
				}
			})			
		},
		checkValidation: function() {
			var vm = this
			var validation_errors = []
			vm.validation_errors = []

			if(vm.registrationStep === 0) {
				if(vm.newTournament.name === null) {
					validation_errors.push({
						reason: 'No tournament name entered.'
					})
				} else {
					if(vm.newTournament.name.length < 4) {
						validation_errors.push({
							reason: 'Tournament name must be more than four letters.'
						})
					}
				}
				if(vm.newTournament.registration_start_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a registration start date.'
					})
				}
				if(vm.newTournament.registration_end_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a registration end date.'
					})
				}
				if(vm.newTournament.event_start_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a event start date.'
					})
				}
				if(vm.newTournament.event_end_date === null) {
					validation_errors.push({
						reason: 'Tournament needs a event end date.'
					})
				}
				// if(vm.newTournament.headquarter_location === null) {
				// 	validation_errors.push({
				// 		reason: 'Tournament needs a headquarter location.'
				// 	})
				// }
				if(vm.newTournament.team_price === null) {
					validation_errors.push({
						reason: 'Tournament needs a team price.'
					})
				}
				if(vm.newTournament.team_price === '') {
					validation_errors.push({
						reason: 'Tournament needs a team price.'
					})
				}
				// todo - check for one payment method selected
			}

			if(vm.registrationStep === 1) {
				var mens_divisions_count = vm.newTournament.mens_divisions.length
				var ladies_divisions_count = vm.newTournament.ladies_divisions.length
				var coed_divisions_count = vm.newTournament.coed_divisions.length
				var total_divisions = mens_divisions_count + ladies_divisions_count + coed_divisions_count
				if(total_divisions === 0) {
					validation_errors.push({
						reason: 'Tournament needs at least one division.'
					})
				}
			}

			vm.validation_errors = validation_errors

			return validation_errors
		},
		pressedNext: function(action) {
			var vm = this
			var checkedVal = vm.checkValidation()	
			if(checkedVal.length === 0) {
				vm.registrationStep++
			} else {
				
			}
			
		},
		unselectableDates(day) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			return day < today;
		},
		paymentSuccessSwitch: function($event) {
			var vm = this

			// if it comes back approved, then create the tournament 

            vm.paymentSuccess = $event
			try {
				vm.completeTournamentSubmission()
			} catch(err) {

			}
        },
	}
}
</script>

<style lang="scss">
    
</style>
