import axios from 'axios'

export default function auth({ next, router }) {
    if(typeof window !== 'undefined') {
        
        axios.get('/auth/check').then(results => {

            if(results.data.type === "success") {
                window.authUser = results.data.authUser
            } 
            return next();
        })
    }
  
    return next();
}