<template>
    <div>
        <div style="max-width: 600px; margin: 0 auto;">
            
            <div class="full-payment-box" v-if="computingTheBill">
                <div v-if="payment_complete">
                    <div class="payment-complete">
                        <div class="icon-row">
                            <i class="far fa-check-circle"></i>
                        </div>
                        <div class="complete-text">
                            <div v-if="payment_type_selected === 'card'">
                                <p><b>Payment Successful.</b></p> 
                                <p class="smal">Please check your email for an invoice.</p>
                            </div>
                            <div v-if="payment_type_selected !== 'card'">
                                <p><b>Awaiting your payment.</b></p>
                                <p class="smal">Submit the form below and we will approve it as soon as funds are received.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h3 style="font-size: 20px; font-weight: 700; padding: 20px; padding-bottom: 0px;">Your Receipt</h3>
                    <div class="full-payment-reciept-area">
                        <div class="row" style="margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px solid #ddd;">
                            <div class="col-md-12" style="text-align: center;">
                                <b>{{ computingTheBill.product_name }} {{ computingTheBill.region_name }}</b>
                            </div>
                        </div>
                        <div class="row" v-if="computingTheBill.hst_amount" style="padding: 0 10px;">
                            <div class="col-md-6">
                                <b>Tax Amount:</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                $
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                {{ roundMoney(computingTheBill.hst_amount).toFixed(2) }}
                            </div>
                        </div>
                        <div class="row" v-if="computingTheBill.gst_amount" style="padding: 0 10px;">
                            <div class="col-md-6">
                                <b>GST Amount:</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                $
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                {{ roundMoney(computingTheBill.gst_amount).toFixed(2) }}
                            </div>
                        </div>
                        <div class="row" v-if="computingTheBill.pst_amount" style="padding: 0 10px;">
                            <div class="col-md-6">
                                <b>PST Amount:</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                $
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                {{ roundMoney(computingTheBill.pst_amount).toFixed(2) }}
                            </div>
                        </div>
                        <div class="row" style="background: #eee; padding: 0 10px;">
                            <div class="col-md-6">
                                <b>You owe:</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                <b>$</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                {{ roundMoney(computingTheBill.total).toFixed(2) }}
                            </div>
                        </div>
                        <div class="row"  style="background: #eee; padding: 0 10px;" v-if="computingTheBill.amount_paid">
                            <div class="col-md-6">
                                <b>Amount paid:</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                <b>$</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">  
                                {{ roundMoney(computingTheBill.amount_paid).toFixed(2) }}
                            </div>
                        </div>
                        <div class="row"  style="background: #eee; padding: 0 10px;" v-if="computingTheBill.processing_fee">
                            <div class="col-md-6">
                                <b style="color: red;">Processing fee:</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                <b style="color: red;">$</b>
                            </div>
                            <div class="col-md-3" style="text-align: right; color: red;">
                                {{ roundMoney(computingTheBill.processing_fee).toFixed(2) }}
                            </div>
                        </div>
                        <div class="row"  style="background: #eee; padding: 0 10px;" v-if="computingTheBill.amount_due">
                            <div class="col-md-6">
                                <b>Amount due:</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                <b>$</b>
                            </div>
                            <div class="col-md-3" style="text-align: right;">
                                {{ roundMoney(computingTheBill.amount_due).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                    <div class="padding">
                        <div v-if="computingTheBill.amount_due !== 0">
                            <h3 style="font-size: 20px; font-weight: 700; margin-top: 20px;">Process Your Payment</h3>
                            <p class="note">Note: Credit card payments include a processing fee.</p>
                            <b-field label="How would you like to pay?">
                                <b-select v-model="payment_type_selected">
                                    <option :value="'card'">Credit Card (Secured)</option>
                                    <option :value="'emt'">EMT</option>
                                    <option :value="'cheque'">Cheque</option>
                                </b-select>
                            </b-field>
                            <div class="payment-details-box" v-if="payment_type_selected === 'card'">
                                <div class="payment-processor-box">
                                    <div class="row">
                                        <div :class="[{ 'col-md-7': previously_used_cards.length > 0 }, { 'col-md-12': previously_used_cards.length === 0}]" v-if="!previous_selected_card">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-field label="Card Number (16 or 17 digits)">
                                                        <b-input v-model="selected_card_details.card_number"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="row" style="margin-top: 10px;">
                                                <div class="col-md-5">
                                                    <b-field label="Month">
                                                        <b-select v-model="selected_card_details.month">
                                                            <option 
                                                                v-for="(month, key) in months"
                                                                :value="month.value"
                                                                :key="key">
                                                                {{ month.date }}
                                                            </option>
                                                        </b-select>
                                                    </b-field>
                                                </div>
                                                <div class="col-md-5">
                                                    <b-field label="Year">
                                                        <b-select v-model="selected_card_details.year">
                                                            <option 
                                                                v-for="(year, key) in years"
                                                                :value="year"
                                                                :key="key">
                                                                {{ year }}
                                                            </option>
                                                        </b-select>
                                                    </b-field>
                                                </div>
                                                <div class="col-md-2">
                                                    <b-field label="CVC">
                                                        <b-input v-model="selected_card_details.cvc"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5" v-if="previously_used_cards.length > 0">
                                            <div class="previously-saved-cards" >
                                                <p style="font-size: 10px; margin-bottom: 10px; border-bottom: 1px solid #ddd;"><b>PREVIOUSLY USED</b></p>
                                                <b-field :key="key" v-for="(card, key) in previously_used_cards">
                                                    <b-radio 
                                                        v-model="previous_selected_card"
                                                        :native-value="card"
                                                        >
                                                        {{ card.brand }} <span style="font-size: 10px;"><b>ends with {{ card.last4 }}</b></span>
                                                    </b-radio>
                                                </b-field>
                                                <b-field>
                                                    <b-radio 
                                                        v-model="previous_selected_card"
                                                        :native-value="null"
                                                        >
                                                        New Card</span>
                                                    </b-radio>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="payment-details-box" v-if="payment_type_selected === 'emt'">
                                <p class="note">Please save the below details and EMT us as soon as possible. We cannot fully process this until funds are received.</p>
                                <p><b>Please send payment to: </b>payspn@slo-pitch.com</p>
                                <p>When sending an e-transfer please use the password SPN and the current year. Like this: SPN2024 OR send the password to Tara!</p>

                                <b-field label="What email will the EMT come from?">
                                    <b-input v-model="emt_payment_email"></b-input>
                                </b-field>
                            </div>
                            <div class="payment-details-box" v-if="payment_type_selected === 'cheque'">
                                <p class="note">Please save the below details and send us the cheque as soon as possible. We cannot fully process this until funds are received.</p>
                                <p><b>Please make all cheques out to:</b> Slo-Pitch National</p>
                                <p v-if="regionId === 1"><b>Please mail or deliver your cheque to:</b> #115, 1925 – 18 Avenue NE Calgary AB T2E-7T8</p>
                                <p v-else-if="regionId === 2"><b>Please mail or deliver your cheque to:</b> 2581 Spring Bank Avenue, Merritt BC V1K 1S1</p>
                                <p v-else-if="regionId === 3"><b>Please mail or deliver your cheque to:</b> 20 De La Seigneurie Boulevard Winnipeg, MB R3X 0E9</p>
                                <p v-else-if="regionId === 5"><b>Please mail or deliver your cheque to:</b> 1163 Beaver Bank Road Beaver Bank, NS B4G 1B1</p>
                                <p v-else-if="regionId === 6"><b>Please mail or deliver your cheque to:</b> 1840 Route 222, St Denis de Brompton, QC J0B 2P0</p>
                                <p v-else-if="regionId === 7"><b>Please mail or deliver your cheque to:</b> 26 Castors Drive Mount Pearl, NL A1N 4Y7</p>
                                <p v-else-if="regionId === 8"><b>Please mail or deliver your cheque to:</b> 538 Highland Terrace Saskatoon, SK S7H 4Y6</p>
                                <p v-else><b>Please mail or deliver your cheque to:</b> 9-20 Lightbeam Terrace, Brampton ON L6Y 6H9</p>

                                <b-field label="How do you plan to handle the cheque?">
                                    <b-select v-model="cheque_tracking">
                                        <option value="mail">I will mail it</option>
                                    </b-select>
                                </b-field>
                            </div>
                        
                            <a role="button" class="admin-button" style="margin-top: 20px;" v-on:click="submitPaymentAttempt()" v-if="awaiting_response_from_server === false"><i class="fas fa-save" ></i> Submit Payment</a>      
                        </div>
                        <div v-if="computingTheBill.amount_due === 0">
                            <h3 style="font-size: 20px; font-weight: 700; margin-top: 20px;">Submit Your Order</h3>
                            <a role="button" class="admin-button" style="margin-top: 20px;" v-on:click="submitPaymentAttempt()" v-if="awaiting_response_from_server === false"><i class="fas fa-save" ></i> Submit Order</a>      
                        </div>
                        <a role="button" class="admin-button gray" v-if="awaiting_response_from_server === true" style="margin-top: 20px;"><i class="fas fa-save" ></i> Processing... please wait.</a>   
                    </div>
                </div>
            </div>
        </div
        >
        
    </div>
</template>


<script>
import axios from 'axios'
import moment from 'moment'
import roundMoney from 'round-money'

export default {
	data() {
		return {
            en: true,
            awaiting_response_from_server: false,
            emt_payment_email: null,
            payment_type_selected: null,
            previous_selected_card: null,
            cheque_tracking: 'mail',
            tournamentSelected: null,
            selected_card_details: {
                card_number: null,
                month: null,
                year: null,
                cvc: null
            },
            previously_used_cards: [],
            selected_product: null,
            
            previous_order: null,
            payment_complete: false
		};
	},
	computed: {
        months: function() {
            var i = 1
            var months = []
            for(i; i < 13; i++) {
                var date = moment(i, 'M').format('MMMM')
                months.push({
                    date: date,
                    value: i
                })
            }
            return months
        },
        years: function() {
            var i = parseInt(moment().format('YYYY'))
            var final = parseInt(i) + 10
            var years = []
            for(i; i < final; i++) {
                years.push(i)
            }
            return years
        },
        computingTheBill: function() {
            var vm = this
            var product = vm.selected_product

            if(product) {
                var cart = {
                    previous_order_id: null,
                    product_id: null,
                    product_name: null,
                    region_id: null,
                    region_name: null,
                    region_rate_id: null,
                    tax_settings_id: null,
                    product_price: null,
                    subtotal: null,
                    quantity: 1,
                    hst_amount: null,
                    gst_amount: null,
                    pst_amount: null,
                    total: null,
                    processing_fee: null,
                    amount_paid: null,
                    amount_due: null
                }

                cart.product_id = product.id
                cart.product_name = product.name
                cart.region_id = product.region.id
                cart.region_name = product.region.name
                cart.region_rate_id = product.region_rate.id
                cart.tax_settings_id = product.tax_settings.id
                
                if(vm.previous_order) {
                    cart.previous_order_id = vm.previous_order.id
                    cart.amount_paid = vm.previous_order.amount_paid
                }
                
                if(vm.quantityOverride) {
                    cart.quantity = parseInt(vm.quantityOverride)
                }


                cart.subtotal = product.region_rate[product.slug] * parseInt(cart.quantity)

                cart.hst_amount = cart.subtotal * product.tax_settings.hst_amount
                cart.gst_amount = cart.subtotal * product.tax_settings.gst_amount
                cart.pst_amount = cart.subtotal * product.tax_settings.pst_amount

                var final_total = 0.00
                
                if(product.with_insurance === 1) {
                    var quantity_insurance = 28.50 * cart.quantity
                    final_total = final_total + quantity_insurance
                }
                

                final_total = roundMoney(final_total + cart.subtotal + cart.hst_amount + cart.gst_amount + cart.pst_amount)

                cart.total = final_total
                

                cart.amount_due = cart.total


                if(cart.amount_paid) {
                    cart.amount_due = cart.amount_due - cart.amount_paid
                }

                // now figure out the stripe fees

                if(vm.payment_type_selected === 'card') {
                    var new_amount_due = (cart.amount_due + 0.30) / (1 - 2.9 / 100)
                    var old_amount_due = cart.amount_due
                    cart.processing_fee = roundMoney(new_amount_due - old_amount_due)
                    cart.amount_due = roundMoney(new_amount_due)
                }


                return cart
            }

            return null
            // subtotal, hst amount, gst amount, pst amount 
            // then if it's "fully insured" add 28.50 after the taxes
            // if it's stripe it shows the extra, if it's not it simply doesn't
            // this all gets sent into the system and used, not edited
        }
	},
	mounted: function() {
        var vm = this

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                
                axios.get('/api/payments/previously-used-cards').then(results => {
                    if(results.data.success === true) { vm.previously_used_cards = results.data.cards }
                })
            }
        })

        // axios.post('/api/payments/get-product/', {
        //     product_id: vm.productId,
        //     region_id: vm.regionId
        // }).then(results => {
        //     if(results.data.success === true) {
        //         vm.selected_product = results.data.cart
        //     }
        // })

        if(vm.orderId) {
            axios.get('/api/payments/get-previous-order/'+ vm.orderId).then(results => {
                if(results.data.success === true) {
                    vm.previous_order = results.data.order
                }
            })
        }
    },
    props: ['productId', 'regionId','orderId', 'quantityOverride', 'tournamentId'],
    watch: {
        payment_type_selected: function() {
            // check for credit card
        },
        tournamentId: function() {
            var vm = this
            axios.get('/api/users/get-tournament/' + vm.tournamentId).then(results => {
                if(results.data.success === true) {

                    vm.tournamentSelected = results.data.tournament
                }
            })
        }
        // productId: function() {
        //     var vm = this
        //     axios.post('/api/payments/get-product/', {
        //         product_id: vm.productId,
        //         region_id: vm.regionId
        //     }).then(results => {
        //         if(results.data.success === true) {
        //             vm.selected_product = results.data.cart
        //         }
        //     })
        // }
    },
	methods: {
		submitPaymentAttempt: function() {
            var vm = this
            // send in computingTheBill, if done effectively this should have everything i need

            vm.awaiting_response_from_server = true

            if(vm.payment_type_selected === null && vm.computingTheBill.amount_due > 0) {
                vm.$buefy.snackbar.open({
                    message: 'Please select a method of payment!',
                    type: 'is-danger' // is-danger
                })
                vm.awaiting_response_from_server = false   
            } else {
                axios.post('/api/payments/new-attempt-payment', {
                    computed_bill: vm.computingTheBill,
                    payment_type_selected: vm.payment_type_selected,
                    selected_card_details: vm.selected_card_details,
                    previous_selected_card: vm.previous_selected_card,
                    emt_payment_email: vm.emt_payment_email,
                    cheque_tracking: vm.cheque_tracking,
                    user_id: vm.authUser.id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.payment_complete = true
                        vm.$emit('payment:successful', { 
                            order: results.data.order,
                        })

                        vm.$buefy.snackbar.open({
                            message: 'Payment submission was successful!',
                            type: 'is-success' // is-danger
                        })
                        // trigger an emit to the level up that it's done
                    }
                })
            }

        },
        roundMoney: function(item) {
            return roundMoney(item)
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>