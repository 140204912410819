<template>
	<div>
        <div class="sponsored-bar-header">

        </div>
        <div class="sponsored-iframe" v-if="featuredSite">
            <iframe :src="featuredSite" frameborder="0"></iframe>
        </div>

	</div>
</template>

<script>

import axios from 'axios'

export default {
	data() {
		return {
            featuredSite: null,
		};
    },
    
	components: {
	
    },
    watch: {
       
    },
	mounted: function() {
        // questions need to be pulled from the database
        var vm = this
        
        var query = this.$route.query.q

        vm.featuredSite = query

        if(sessionStorage.getItem('lang')) {    
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }        
	},
	computed: {
      
	},
	methods: {
        
    }
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
