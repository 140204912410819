<template>
	<div>
		<Header></Header>
		
		<div class="content-area">
			<div class="standard-page-header">
				Umpires
			</div>
            <div class="frontend-content-wrapper">
				<div class="section-spacing">
                
                    <div class="textarea-content-block" v-if="clinics">
					
                    </div>
                </div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import moment from 'moment'
export default {
	metaInfo() {
        return { 
            title: "Umpire Clinics - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Umpire clinic information across Canada! Come check out Slo-Pitch National to register.' },
				{ property: 'og:title', content: "Umpire Clinics - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/umpire-clinics' },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/umpireclinics.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
			page: null,
			clinics: null,
			selectedRegion: null,
            umpires: []
		};
	},
	components: {
		Header,
		Footer,
		Form
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        var vm = this

        axios.get('/api/umpires/all-clinics').then(results => {
            
            if(results.data.success === true) {
                vm.clinics = results.data.clinics
            }
        })
 
	},
	componentWillReceiveProps(nextProps){

		
	},
	computed: {
        officialComputedUmpires: function() {
            var umpires = this.umpires

            var grouped = _.groupBy(umpires, 'region_name')

            return grouped
		},
		officialClinics: function() {
			var vm = this

			var clinics = vm.clinics

			if(vm.selectedRegion) {
				clinics = _.filter(clinics, function(clinic) {
					return clinic.region_id === vm.selectedRegion
				})
			}

			return clinics
		}
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		},
		openPanel:function(panel) {

			if(panel.switched === "false") {
				panel.switched = "true"

			} else {
				panel.switched = "false"

			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {

				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
