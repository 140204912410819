<template>
	<div>
		<Header @update-lang="updateLanguage($event)"></Header>
		<div class="content-area">
            <div class="standard-page-header">
				Rulebook
			</div>
            <div class="frontend-content-wrapper">
                
                <div v-if="en === true">
                    <iframe src="https://spncloud.egnyte.com/dl/IQUZU4nGrY" style="width: 100%; height: 1200px;" frameborder="0"></iframe>
                </div>
                <div v-if="en === false">
                    <iframe src="https://spncloud.egnyte.com/dl/ClUAozDwPy" style="width: 100%; height: 1200px;" frameborder="0"></iframe>
                    
                </div>
            </div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
export default {
	metaInfo() {
        return { 
            title: "Forms - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Slo-Pitch National forms are listed here, like Incident Report, Request an Insurance Certificate and more.' },
				{ property: 'og:title', content: "Forms - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/forms' },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/forms.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			forms: null,
			selectedForm: null,
            en: true
		};
	},
	components: {
		Header,
		Footer,
		Form
	},
	mounted: function() {
		var vm = this
		axios.get('/api/forms/all').then(results => {
			if(results.data.success === true) {
				vm.forms = results.data.forms
			}
		})

        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
		
	},
	computed: {
	
	},
	methods: {
        updateLanguage: function($event) {
			this.en = $event
			
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		viewForm: function(form) {
			var vm = this

			this.selectedForm = form
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Forms.scss';
</style>
